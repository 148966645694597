import React, { useState } from 'react';
import { StyledQuestionList, 
  StyledAnswerButtonsContainer, 
  StyledAnswerButtonTick,
  StyledAnswerButtonCross, 
  StyledQuestionsContainer, 
  StyledActiveQuestion } from './QuestionList.styled';

import { IQuestion } from '../../models/IQuestion';
import { QuestionListItem } from './QuestionListItem/QuestionListItem';

import { ReactComponent as IconTick } from '../../images/icons/tick.svg';
import { ReactComponent as IconCross } from '../../images/icons/cross.svg';
import { getUuid } from '../../utils/helper';

interface IQuestionListProps {
  questions: IQuestion[]

  onYes?: (question: IQuestion) => void;
  onNo?: (question: IQuestion) => void;
}

// Receives a set of IStrategyQuestions, and shows each question with tick/cross buttons
// This component should not manage question state, use onYes/onNo to modify question values
export const QuestionList: React.FC<IQuestionListProps> = ({ questions, onYes, onNo }) => {
  
  // Indicate if at least one question has been answered
  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  
  // activeQuestion is first question without points assigned
  const activeQuestion = questions.find(question => !question.points);

  const getAnsweredQuestions = () => {
    return questions.filter(question => question.points).map((question, index) => 
      <QuestionListItem key={index} text={question.answeredText || question.text} ticked={question.points === 1} />,
    )
  };

  const answer = (answerCallback?: (question: IQuestion) => void) => {
    if (answerCallback && activeQuestion) answerCallback(activeQuestion);
    if (!isInitialised) setIsInitialised(true);
  }

  const getAnswerButtons = () => {
    if (!activeQuestion) return null;

    return <StyledAnswerButtonsContainer 
        key={`buttons-${getUuid(activeQuestion.text)}`}
        isInitialised={isInitialised} 
      >
        <StyledAnswerButtonTick onClick={() => answer(onYes)}>
          <IconTick/>
        </StyledAnswerButtonTick>
        <StyledAnswerButtonCross onClick={() => answer(onNo)}>
          <IconCross/>
        </StyledAnswerButtonCross> 
      </StyledAnswerButtonsContainer>
  };

  return <StyledQuestionList>
    <StyledQuestionsContainer>
      {getAnsweredQuestions()}
      {activeQuestion && <StyledActiveQuestion key={`question-${getUuid(activeQuestion.text)}`} text={activeQuestion.text} />}
    </StyledQuestionsContainer>
    {activeQuestion && getAnswerButtons()}
  </StyledQuestionList>
};