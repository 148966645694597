import React, { useState } from 'react';
import Auth from '../../../services/Auth';
import { isValidEmail } from '../../../utils/validation';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { FormFunctionProps, withForm } from '../Form';
import { StyledDescContainer, StyledFormButton, StyledFormText, StyledHeadingContainer } from '../Form.styled';
import { TextField } from '../../TextField/TextField';
import { FormLogo } from '../FormLogo/FormLogo';
import { Heading } from '../../Heading/Heading';
// import { useHistory } from 'react-router';
import { FAUX_EMAIL_SUFFIX } from '../../../constants';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
import Api from 'services/Api';

const ResetPasswordForm: React.FC<FormFunctionProps> = ({ getFormData, setIsLoading, setFormFieldValue, setFormFieldError, validateFormFields, clearFormErrors }) => {
  const { t } = useTranslation();
  const formData = getFormData();

  const [isSubmitted, setIsSubmitted] = useState(false);

  // const history = useHistory();
  const navigate = useNavigate();

  const onFormSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (formData.isLoading || !validateFormFields(['email'])) return;

    setIsLoading(true);

    const emailValue = formData.fields.email.value as string;

    // redirect to contact us page if using a faux email
    if (emailValue.endsWith(FAUX_EMAIL_SUFFIX)) {
      navigate('/contact-us?type=password-reset');
      return;
    }

    setIsLoading(true);
    await Api.resetPassword(emailValue).then((res)=> {
      if (res !== true) {
        // eslint-disable-next-line no-console
        console.log(res);
        const result = res as Response;
        setFormFieldError('email', `${result.body as unknown as string}`);
      } else {
        setIsSubmitted(true);
      }

      setIsLoading(false);
    }) 
  };

  const onBackToLoginClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e && e.preventDefault();
    navigate('/login');
  }

  return <>
    <FormLogo />
    {isSubmitted
      ? <>
        <StyledHeadingContainer>
          <Heading level="h4" primary text="Check your email" />
        </StyledHeadingContainer>
        <StyledDescContainer>
          <StyledFormText>
            You can log in here once you have reset your password.
          </StyledFormText>
        </StyledDescContainer>
        <StyledFormButton type="submit" onClick={onBackToLoginClick} label="Back to log in" hollow={false} />
      </>
      : <>
        <StyledHeadingContainer>
          <Heading level="h4" primary text="FORGOT YOUR PASSWORD?" />
        </StyledHeadingContainer>
        <StyledDescContainer>
          <StyledFormText>
            {'Confirm your email address and we’ll send instructions on how to reset your password.'}
          </StyledFormText>
        </StyledDescContainer>
        <TextField
          type="email"
          placeholder={t('form.email.placeholder')}
          errorMessage={formData.fields.email.error}
          onChange={(newValue) => setFormFieldValue('email', newValue)}
        />
        <StyledFormButton type="submit" onClick={onFormSubmit} label="Reset password" />
        <StyledFormButton type="button" label="Back" primary={false} hollow={true} onClick={() => navigate(-1)} />
      </>
    }
  </>
};

export default withForm(ResetPasswordForm, {
  fields: {
    email: {
      value: '',
      validate: (value: string) => isValidEmail(value) ? '' : i18n.t('form.email.invalidError'),
    },
  },
});