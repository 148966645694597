import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IStyledProps } from '../../../theme';

export const ForgotLink = styled(Link)`
  position: absolute;
  bottom: 2px;
  right: 0;
  font-size: 14px;
  opacity: 0.6;
  font-family: 'Overpass';
  text-decoration: none;
  border-bottom: 1px solid ${(props: IStyledProps) => props.theme.colors.greys.a}22;
  color: ${(props: IStyledProps) => props.theme.colors.greys.a};
`
export const StyledInputContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

