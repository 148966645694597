import styled, { keyframes } from 'styled-components';
import { IStyledProps } from '../../theme';
import loadingSpinnerPng from './loadingSpinner.png';

const getLoadingSpinnnerKeyFrames = () => {
  return keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `;
};

const loadingTextInKeyFrames = keyframes`
  0% { 
    opacity: 0;
    transform: translateY(32px) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(0%) scale(1);
  }
`;

const loadingTextOutKeyFrames = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(-32px) scale(0.8);
  }
`;

const LOADING_SPINNER_SIZE_PX = 100;

interface IStyledLoadingSpinnerProps extends IStyledProps {
  backgroundColour?: string
}

export const StyledLoadingSpinner = styled.div`
  position: absolute;
  left: calc(50% - ${LOADING_SPINNER_SIZE_PX / 2}px);
  top: calc(50% - ${LOADING_SPINNER_SIZE_PX / 2}px);
  font-size: 10px;
  width: ${LOADING_SPINNER_SIZE_PX}px;
  height: ${LOADING_SPINNER_SIZE_PX}px;
  background: url(${loadingSpinnerPng});
  background-size: contain;
  background-repeat: no-repeat;

  animation: ${getLoadingSpinnnerKeyFrames()} 1.4s infinite linear;
`;

export const StyledLoadingSpinnerContainer = styled.div<IStyledProps & { backgroundColour?: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${(props: IStyledLoadingSpinnerProps) => (props.backgroundColour || '')};
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
`;

export const StyledLoadingSpinnerTextContainer = styled.div`
  position: absolute;
  top: calc(50% + ${LOADING_SPINNER_SIZE_PX / 2 + 48}px);
  left: 0;
  width: 100%;
  text-align: center;
`;

export const StyledLoadingSpinnerText = styled.div<IStyledProps & { leaving?: boolean }>`
  font-size: 20px;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  font-family: 'Teko';
  text-transform: uppercase;
  color: #fff;

  animation: ${props => props.leaving ? loadingTextOutKeyFrames : loadingTextInKeyFrames} 1s ease-in-out forwards;
`;