import styled from 'styled-components';
import { IMAGES_URL, Z_INDEX } from '../../constants';
import { IStyledProps } from 'theme';

interface IStyledChapterVideoProps extends IStyledProps {
  image: string;
}

export const StyledChapterVideo = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${(props: IStyledChapterVideoProps) => `url(${IMAGES_URL}${props.image})`};
`;

export const StyledSkipButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${Z_INDEX.skipButton};
  padding: 10px;
`;