import { v4 as uuidv4 } from 'uuid';
import getUuidByString from 'uuid-by-string';
import { APP_MAX_WIDTH_PX } from '../constants';

export const numToAlpha = (num: number) => String.fromCharCode(num + 64);

export const getQueryParam = (paramName: string) : string | null => {
  const params = new URLSearchParams(window.location.search);
  return params.has(paramName)
    ? params.get(paramName)
    : null;
};

export const bindViewportListeners = (fn: () => void, shouldInvokeImmediately = false) => {
  window.addEventListener('resize', fn);
  window.addEventListener('orientationchange', fn);

  if (shouldInvokeImmediately) fn();
};

export const getUuid = (text?: string): string => {
  return text ? getUuidByString(text) : uuidv4();
};

export const getWindowWidthPx = () => {
  //Returns the window inner width, unless it's beyond the app_max_width property
  return Math.min(window.innerWidth, APP_MAX_WIDTH_PX);
};

// Mainly created to insert dynamic variables into contentbase strings
export const replaceLabels = (rawStr: string, labels: string[][]) => {
  let output = rawStr;

  for (const [label, value] of labels) {
    output = output.replaceAll(`{${label}}`, value) 
  }

  return output
}

export const shuffleArray = (array: string[]) => { 
  for (let i = array.length - 1; i > 0; i--) { 
    const j = Math.floor(Math.random() * (i + 1)); 
    [array[i], array[j]] = [array[j], array[i]]; 
  } 
  return array; 
}; 