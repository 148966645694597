/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-console */
import Auth from './Auth';

const SITE_ID = '479233787';
const SIGNUP_FORM_NAME = 'AOTG_SIGNUP';
const FORM_URL = 'https://s' + SITE_ID + '.t.eloqua.com/e/f2';
const CHAPTER_COMPLATE_FORM_NAME = 'AOTG_CHAPTER_COMPLETE';

const generateRequestBody = (params: any) => {
    return Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }).join('&');
};

export default { 
    register() {
        const user = Auth.getUser();
        if (!user) {
          console.log('ELOQUA ERROR: user is not logged in to regiser');
          return;
        }

        const email = user.email;

        const data: any = {
            elqSiteId: SITE_ID,
            elqFormName: SIGNUP_FORM_NAME,
            email_address: email,
        };

        fetch(FORM_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: generateRequestBody(data),
        }).then((res: Response) => {
            if (res.status !== 200) {
                console.error('Eloqua register error:', res.status, 'with email:', email);
            } else {
                console.log('Eloqua register suceed, with email:', email);
            }
        }).catch(error => {
            console.error('Eloqua register error:', error, 'with email:', email);
        });
    },

    chapterComplete(chapterId: string) {
      const user = Auth.getUser();

      if (!user) return;

      const email = user.email;

      const data: any = {
          elqFormName: CHAPTER_COMPLATE_FORM_NAME,
          elqSiteID: SITE_ID,
          email_address: email,
          last_chapter_complete: chapterId,
      }

      return fetch(FORM_URL, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: generateRequestBody(data),
      })
    },
}