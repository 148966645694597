import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Content from '../../../services/Content';
import { StyledTeamFeedbackContainer } from '../TeamFeedback.styled';
import {
  StyledStrengthPopUpButton,
  StyledStrengthPopUpCloseButton,
  StyledStrengthPopUpCloseButtonContainer,
  StyledStrengthPopUpContent,
  StyledStrengthPopUpHeading,
  StyledStrengthPopUpList,
  StyledStrengthPopUpListContainer,
} from './StrengthPopUp.styled';
import { ThemeContext } from 'styled-components'

interface IStrengthPopUp {
  feedbackName: string;
}

export const StrengthPopUp: React.FC<IStrengthPopUp> = ({ feedbackName }) => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const finaleBackground = Content.getFinaleBackgroundImage();
  const themeContext = useContext(ThemeContext);

  //When user copies URL into new tab, rendering will not work. The if statement is used to soften the blow, but not fix it
  if (!location.state) {
    return null;
  } 
    const teammateNames = location.state as string[];

  return (
    <StyledTeamFeedbackContainer image={finaleBackground}>
      <StyledStrengthPopUpContent>
        <StyledStrengthPopUpHeading primary>
          These Teammates said <span style={{ color: themeContext.colors.primary.b }}>{feedbackName}</span> was one of your strengths
        </StyledStrengthPopUpHeading>
        <StyledStrengthPopUpListContainer>
          <StyledStrengthPopUpList>
            {teammateNames.map((teammateName, index) => (
              <StyledStrengthPopUpButton
                primary
                key={index}
                label={teammateName}
                isCompleted={true}
                isDisabled={false}
              />
            ))}
          </StyledStrengthPopUpList>
        </StyledStrengthPopUpListContainer>
        <StyledStrengthPopUpCloseButtonContainer>
          <StyledStrengthPopUpCloseButton
            primary
            onClick={() => navigate('/team-feedback')}
            label={'Close'}
          />
        </StyledStrengthPopUpCloseButtonContainer>
      </StyledStrengthPopUpContent>
    </StyledTeamFeedbackContainer>
  );
};

export default StrengthPopUp;
