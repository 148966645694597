import { DefaultTheme } from 'styled-components';

// ADDING A COLOUR TO A THEME?
// - 1: Check if the colour already exists, can the variable be re-used for your purpose?
// - 2: Check if a very very similar colour already exists, and discuss it as an option with the designers.
// - 3: If it's never changing between the different versions of the app, should it be in the theme? (Up for debate)
// - 4: If you've decided it's still worth adding to the theme, make sure it's meaningfully named and described here

//The goal is to keep the theme a MINIMUM set of colours. If a colour isn't used project-wide, it shouldn't be here.

const colors = {  
  defaultBackground: '#dedede',
  white: '#ffffff',
  black: '#090909',

  primary: {
    a: '#173ed7',
    b: '#090909',
    c: '#ffffff',
  },
  greys: {
    a: '#434240',
    b: '#7d7d7d',
    c: '#f1f1f1',
    d: '#3E3E3E',
  },
  accent: {
    error: '#D40000',
    success: '#678ABD',
    count: '#EE0001',
  },
  
  //populated below to use refs to this obj
  videoStickers: [{ color: '#408fb0' }, { color: '#a851b8' }, { color: '#b16240' }],

  chooseCards: ['#008396', '#006a96', '#ae021b', '#972e01', '#7b4601', '#02694f', '#8b17a1'],
  plans: ['#02694f', '#972e01', '#8B17A1'],
  breathingRings: { 1: '#83EF9D', 2: '#8EF6A6', 3: '#9FFCB5', 4: '#BDFCCB' },
};

const breakpoints = {
  xxs: '320px',
  xs: '520px',
  sm: '768px',
  md: '1024px',
  lg: '1280px',
  xl: '1760px',
};

export const ThemeDefault: DefaultTheme = {
  dark: false,
  name: 'AOTG MHR Theme',
  colors,
  breakpoints,
  containerMaxWidth: '1080px',
  fonts: {
    body: 'Overpass',
    heading: 'Teko',
  },
};

//For styled components props
export interface IStyledProps {
  theme: DefaultTheme;
}