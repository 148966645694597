import React from 'react';
import { StyledLockIcon, StyledTopText, StyledBottomText, StyledVideoController, StyledPlayIcon, StyledPlayIconContainer, StyledPlayIconRing, StyledPlayIconRingContainer } from './VideoController.styled';
import { ReactComponent as IconLock } from '../../../images/icons/lock.svg';
import { ReactComponent as IconPlay } from '../../../images/icons/play.svg';
import { ReactComponent as IconReplay } from '../../../images/icons/replay.svg';

interface IVideoControllerProps {
  to: string;
  type: string;
  topText?: string;
  bottomText?: string;
  bottomTextOnClick?: () => void;
}

export const VideoController: React.FC<IVideoControllerProps> = ({ to, type, topText, bottomText, bottomTextOnClick, ...rest }) => {
  const getIcon = () => {
    switch (type) {
      case 'play':
      case 'autoplay':
        return <IconPlay/>
      case 'replay':
        return <IconReplay/>
      case 'lock':
        return <IconLock/>
    }
  }
  return (
    <StyledVideoController 
      type={type}
      to={to}
      {...rest}
    >
      <StyledTopText>{topText}</StyledTopText>
      { type === 'lock' 
        ? <StyledLockIcon><IconLock /></StyledLockIcon>
        : <StyledPlayIconContainer>
          <StyledPlayIconRingContainer>
            <StyledPlayIconRing/>
          </StyledPlayIconRingContainer>
          <StyledPlayIcon>
            { getIcon() }
          </StyledPlayIcon>
        </StyledPlayIconContainer>
      }
      <StyledBottomText onClick={(event) => {
        if (bottomTextOnClick) {
          event.preventDefault();
          bottomTextOnClick();
        }
      }}>{bottomText}</StyledBottomText>
    </StyledVideoController>
  );
}
