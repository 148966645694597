import styled, { keyframes } from 'styled-components';
import { IStyledProps } from '../../theme';
import { Button, IButtonProps } from '../Button/Button';
import { Z_INDEX, APP_MAX_WIDTH_PX } from '../../constants';
import { PAGE_PADDING_PX } from '../Page/Page.styled';
import { Heading } from '../Heading/Heading';

const entryAnimation = keyframes`
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
`;

export const StyledWelcomeScreenContainer = styled.div<IStyledProps>`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  z-index: ${Z_INDEX.applicationOverlay};
  margin: 0 auto;
  background: none;

  :before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    animation: ${entryAnimation} 1s ease-in-out forwards;
    background: rgba(0,0,0,0.5);
  }
`;

export const StyledWelcomeHeaderText = styled(Heading)`
  margin: 0;
  text-align: center;
  font-family: Teko;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 0.75em;
  font-size: 206.4px;

  width: 100%;
  color: ${(props: IStyledProps) => props.theme.colors.white};
  text-align: left;
  display: flex;
  justify-content: center;
`;

export const StyledWelcomeScreenInner = styled.div<IStyledProps & { opaque: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: 100%;
  width: 100%;
  padding: ${PAGE_PADDING_PX}px;

  opacity: ${props => props.opaque ? 1 : 0};
  transition: opacity 0.4s ease-in;
  pointer-events: ${props => props.opaque ? 'auto' : 'none'};
`;

export const StyledLogoWrapper = styled.div`
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
`;

export const StyledWelcomeTextWrapper = styled.div`
  color: white;
  font-size: 22px;
  flex: 1;
  margin: 1rem 0;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const StyledWelcomeBodyText = styled.div`
  font-weight: bold;
  font-size: 20px;
  font-family: Overpass;
  font-weight: 400;
  margin-top: 24px;
  line-height: 1.5em;
  margin-left:9px;
`;

export const StyledWelcomeEndText = styled.div`
  margin-top: 2rem;
  opacity: 0.9;
`;

export const StyledWelcomeScreenVideoContainer = styled.div`
  video {
    position: absolute;
    z-index: -1;
    width: 100%; 
    height: 100%;
    object-fit: cover;
    right: 0px;
  }
`;

export const StyledWelcomeScreenButton = styled(Button)<IButtonProps>`
  width: 100%;
  position: relative;
  padding: 21px;
`;