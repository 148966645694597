import styled, { css, keyframes } from 'styled-components';
import { IStyledProps } from '../../theme';
import { Button } from '../Button/Button';
import { Heading } from '../Heading/Heading';

import { PagePanel } from '../Page/Page';
import { SvgIcon } from '../SvgIcon/SvgIcon';
import backgroundImage from './phone-down-background.webp';

interface IBottomButtonProps extends IStyledProps {
  show: boolean;
}

const slide = keyframes`
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: 50% 1000px;
  }
`;

export const StyledPhonesDownContainer = styled(PagePanel)`
  background: ${(props: IStyledProps) => props.theme.colors.black};
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  animation: ${slide} 35s linear infinite;

  display: flex;
  flex-direction: column;
  height: 100%;
  
`;

export const StyledPhonesDownInner = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const StyledPhonesDownHeader = styled(Heading)`
  width: 100%;
  color: ${(props: IStyledProps) => props.theme.colors.white};
  font-size: 140px;
  text-align: left;
  display: flex;
  justify-content: center;

  & span {
    width: 300px;
  }
`;

export const StyledPhonesDownImage = styled(SvgIcon)`
  width: 174px;

  svg {
    rect, polygon, path {
      fill: ${(props: IStyledProps) => props.theme.colors.white};
    }
  }
`;

export const StyledPhonesDownContent = styled.div`
  text-align: center;
  margin-top: 64px;
  font-family: Overpass;
  font-size: 19px;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.colors.white};
`;

export const StyledPhonesDownBottomButton = styled(Button)`
  width: 100%;
  border: 1px solid white;
  opacity: ${(props: IBottomButtonProps) => props.show ? 1 : 0};
  ${(props: IBottomButtonProps) => props.show ? '' : css`pointer-events: none;`};
  color: ${(props: IStyledProps) => props.theme.colors.white};
  transition: opacity 0.5s;
`;