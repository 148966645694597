import React, { useEffect, useRef, useState } from 'react';
import { StyledGameViewFacilitator, StyledTeams, StyledTeamPoints, StyledGameControls } from './GameViewFacilitator.styled';
import { useParams } from 'react-router-dom';
import Api from 'services/Api';
import IPlayerState, { GAME_STAGE, IGameState } from 'models/IGameState';
import { PlayerBoard } from '../PlayerBoard/PlayerBoard';
import qrCode from './qrcode.png';

export const GameViewFacilitator: React.FC = () => {
  const ws = useRef<null | WebSocket>(null);

  const params = useParams();

  const [gameState, setGameState] = useState<IGameState | null>();
  const gameStateRef = useRef(gameState);

  useEffect(() => {
    gameStateRef.current = gameState;
  }, [gameState])

  useEffect(() => {
    ws.current = Api.wsConnectFacilitator(params.accessCode || '', () => {
      const initialGameState = gameStateRef.current || {
        stage: GAME_STAGE.LOBBY,
        lastUpdated: Date.now(),
        isFrozen: false,
        playerStates: [],
        teams: [{
          name: 'Blue',
          points: 0,
        }, {
          name: 'Red',
          points: 0,
        }],
      };

      Api.wsSetGameState(ws.current, initialGameState);
      setGameState(initialGameState);
    }, 
    (newPlayerState: IPlayerState) => {
      //Upon receiving new player state, update it here and pass it along to others
      const currentGameState = gameStateRef.current;
      if (!currentGameState) return;

      let updatedState: IGameState

      if (currentGameState?.playerStates.find(p => p.playerId === newPlayerState.playerId)) {
        updatedState = {
          ...currentGameState,
          playerStates: currentGameState?.playerStates.map(p => p.playerId === newPlayerState.playerId ? newPlayerState : p),
        };
      } else {
        updatedState = {
          ...currentGameState,
          playerStates: [...currentGameState.playerStates, {
            ...newPlayerState,
            team: currentGameState.playerStates.length % 2,
          }],
        };
      }

      Api.wsSetGameState(ws.current, updatedState); //send to the players
      setGameState(updatedState); //set for facilitator (self)
    });

    //Cleanup on unmount
    const wsCurrent = ws.current;
    if (wsCurrent) return () => wsCurrent.close();
  }, []);

  const changeTeamPoints = (teamIdx: number, points: number) => {
    if (!gameState) return;
    const newGameState = {
      ...gameState,
      teams: gameState.teams.map((team, idx) => idx === teamIdx 
      ? { ...team, points: team.points + points } 
      : team),
    };

    Api.wsSetGameState(ws.current, newGameState);
    setGameState(newGameState);
  }

  const getChangeStageButton = (stage: GAME_STAGE, text: string) => {
    if (!gameState) return;
    return <button disabled={gameState.stage === stage} onClick={() => {
      let updatedState = {
        ...gameState,
        stage: stage,
      };

      if (stage === GAME_STAGE.LOBBY) {
        updatedState = {
          ...updatedState,
          playerStates: gameState.playerStates.map(playerState => {
            return {
              ...playerState,
              response: undefined,
            };
          }),
        };
      }

      Api.wsSetGameState(ws.current, updatedState);
      setGameState(updatedState);
    }}>{text}</button>;
  }

  const freezeState = (newIsFrozen: boolean) => {
    if (!gameState) return;
    const updatedState = {
      ...gameState,
      isFrozen: newIsFrozen,
    };

    Api.wsSetGameState(ws.current, updatedState);
    setGameState(updatedState);
  }
  
  return <StyledGameViewFacilitator>
    {/* Using the session ID in the URL, show the access code */}
    <h1>Ahead of the Game</h1>
    {!!gameState && <div>
      <StyledGameControls>
        <img width={150} src={qrCode} />
        <h2>Access Code: <b>JBOX</b></h2>
        <p>Players are currently seeing: {gameState.stage} {gameState.isFrozen ? '(frozen)' : ''}</p>
        <br />
        <button onClick={() => freezeState(!gameState.isFrozen)}>{gameState.isFrozen ? 'Unfreeze' : 'Freeze'}</button>
        {getChangeStageButton(GAME_STAGE.LOBBY, 'Back to lobby')}
        <br />
        {getChangeStageButton(GAME_STAGE.CHOOSE_THOUGHTS, 'Choose your thoughts!')}
        {getChangeStageButton(GAME_STAGE.HOW_YOU_FEEL, 'How would you feel?')}
        {getChangeStageButton(GAME_STAGE.TRUE_OR_FALSE, 'True or false?')}
      </StyledGameControls>
      <StyledTeams>
        {gameState.teams.map((team, idx) => 
        <StyledTeamPoints key={idx}>{team.name} Team
          <button onClick={() => changeTeamPoints(idx, +1)}>+</button>
          <button onClick={() => changeTeamPoints(idx, -1)}>-</button>
        </StyledTeamPoints>)}
      </StyledTeams>
      <PlayerBoard gameState={gameState} />
    </div>}
  </StyledGameViewFacilitator>
};