import React, { useContext, useEffect, useState } from 'react';
import { RadioButtonGroup } from '../RadioButtonGroup/RadioButtonGroup';
import { StyledToast, StyledToastBody, StyledToastButton, StyledToastOverlay, StyledToastHeading, StyledToastImage } from './Toast.styled';
import { IToastContext, ToastContext, IToast } from './ToastContext/ToastContext';

interface IToastProps {
  toast: IToast
}

export const Toast: React.FC<IToastProps> = ({ toast }) => {
  const { icon, heading, body, selectionList, buttonLabel, onDismissed, onChange } = toast;
  const { setToast }: IToastContext = useContext(ToastContext);

  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    //We slightly delay the setting of isShown to give the chance for it to go from false to true
    //Otherwise it seems react tries to be clever and immediately make it true (for first strategy exercise anyway)
    if (!isShown) setTimeout(() => setIsShown(true), 0);
  }, []);

  const onDismiss = () => {
    setIsShown(false);
    onDismissed && onDismissed();
    setTimeout(() => {
      setToast();
    }, 400);
  }

  return <StyledToastOverlay isShown={isShown} onClick={onDismiss}>
    <StyledToast isShown={isShown} onClick={e => e.stopPropagation()}>
      {icon && <StyledToastImage>{icon}</StyledToastImage>}
      {heading && <StyledToastHeading primary level="h4">{heading}</StyledToastHeading>}
      {body && <StyledToastBody>{body}</StyledToastBody>}
      {selectionList && <RadioButtonGroup 
          name="teamSelections"
          options={selectionList}
          onChange={option => {
            onChange && onChange(option.label);
            onDismiss();
          }}
        />}
      {buttonLabel && <StyledToastButton label={buttonLabel} onClick={onDismiss}/>}
    </StyledToast>
  </StyledToastOverlay>;
}
