/* eslint-disable @typescript-eslint/no-shadow */
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { IStyledProps } from '../../../theme';
import { getFlashKeyFrames, getFadeInKeyFrames, getProcessRingFrames } from '../../../utils/animations.styled';
import { SvgIcon } from '../../SvgIcon/SvgIcon';

const RADIUS = 15;
const STROKE_WIDTH = 2;
const COINTAINER_SIZE = (RADIUS + STROKE_WIDTH) * 2;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

interface IStyledVideoControllerProps extends IStyledProps {
  type?: string;
}

export const StyledTopText = styled.div`
  position: absolute;
  top: 0;
`;

export const StyledBottomText = styled.div`
  position: absolute;
  bottom: 0;
`;

export const StyledLockIcon = styled(SvgIcon)`
  width: 30px;
  height: 30px;
  margin-bottom: 4px;
  display: block;

  > svg {
    path {
      fill: ${(props: IStyledVideoControllerProps) => props.theme.colors.white};
    }
  }

  &+${StyledBottomText} {
    position: relative;
  }
`;

export const StyledPlayIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${COINTAINER_SIZE}px;
  height: ${COINTAINER_SIZE}px;

  svg {
    display: block;
    margin: auto;
  }
`;

export const StyledPlayIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${(RADIUS - STROKE_WIDTH) * 2}px;
  height: ${(RADIUS - STROKE_WIDTH) * 2}px;
  
  svg {
    width: 15px;
    height: 15px;
    padding-left: 1px;

    path {
      fill: ${(props: IStyledProps) => props.theme.colors.white};
    }
  }
`;

export const StyledPlayIconRingContainer = styled.svg.attrs({
  height: COINTAINER_SIZE,
  width: COINTAINER_SIZE,
})`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledPlayIconRing = styled.circle.attrs((props: IStyledProps) => ({ 
  stroke: props.theme.colors.white,
  fill: 'transparent',
  strokeWidth: STROKE_WIDTH,
  r: RADIUS,
  cx: COINTAINER_SIZE / 2,
  cy: COINTAINER_SIZE / 2,
  strokeDasharray: `${CIRCUMFERENCE} ${CIRCUMFERENCE}`,
}))``;

export const StyledVideoController = styled(Link)`
  position: relative;
  padding: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${(props: IStyledVideoControllerProps) => (props.type === 'lock' || props.type === 'autoplay') ? props.theme.colors.greys.a : props.theme.colors.primary.b};
  opacity: ${(props: IStyledVideoControllerProps) => (props.type === 'lock' || props.type === 'autoplay') ? '0.67' : '1'};
  text-decoration: none;
  pointer-events: ${(props: IStyledVideoControllerProps) => (props.type === 'lock' || props.type === 'autoplay') ? 'none' : 'auto'};
  ${(props: IStyledVideoControllerProps) => props.type === 'autoplay' && css`
    opacity: 0;  
    animation: ${getFadeInKeyFrames()} 3s ease-in-out forwards;
    ${StyledPlayIconRing} {
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
      animation: ${getProcessRingFrames(32 * 2 * Math.PI)} 5s ease-in-out;
    }
    ${StyledPlayIconContainer} {
      ${(props: IStyledVideoControllerProps) => props.type === 'autoplay' && css`
        animation: ${getFlashKeyFrames()} 1s ease-in-out 5s;
      `};
    }
    ${StyledBottomText} {
      pointer-events: auto;
    }
  `};

`;