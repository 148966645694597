import React, { useEffect } from 'react';
import { ANIMATION_DELAY_SECONDS, ANIMATION_TIME_SECONDS, StyledChapterTransition } from './ChapterTransition.styled';

interface IChapterTransitionProps {
  onComplete: () => void;
  chapterOrdinal: number;
}

export const ChapterTransition: React.FC<IChapterTransitionProps> = ({ onComplete, chapterOrdinal }) => {
  // because this is currently only for AFL, we don't need to look up the sport type.
  const backgroundImage = `images/dashboard/afl/chapter-${chapterOrdinal}.webp`;

  useEffect(() => {
    setTimeout(() => onComplete(), (ANIMATION_TIME_SECONDS + ANIMATION_DELAY_SECONDS) * 1000);
  }, []);

  return <StyledChapterTransition backgroundImage={backgroundImage} />;
}