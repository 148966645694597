import React, { PropsWithChildren } from 'react';
import { StyledCard, StyledUpperCardContent, StyledLowerCardContent } from './Card.styled';

//A base component used for consistent styling
//Avoid putting lots of optional props onto this

interface ICardProps {
  onClick?: () => void
  isActive?: boolean //Makes the card a bright blue
  isDisabled?: boolean //Makes the card grey and unclickable

  //If upperContent or lowerContent are specified, the card will have two inner containers (upper and lower)
  //Which are most commonly used for an icon up top and heading down bottom.
  //If neither are specified, the card just shows its children
  upperContent?: JSX.Element,
  lowerContent?: JSX.Element,

  className?: string //Used to inherit any other styles
}

export const Card: React.FC<PropsWithChildren<ICardProps>> = ({ className, children, upperContent, lowerContent, onClick, isActive, isDisabled }) => {
  const cardProps = {
    className,
    isDisabled,
    onClick: () => !isDisabled && onClick && onClick(),
    isClickable: !!onClick,
    isActive,
  }

  return upperContent || lowerContent 
    ? <StyledCard {...cardProps}>
      <StyledUpperCardContent>{upperContent}</StyledUpperCardContent>
      {children} {/* Only really used for absolute positioned children */}
      <StyledLowerCardContent>{lowerContent}</StyledLowerCardContent>
    </StyledCard>
    : <StyledCard {...cardProps}>{children}</StyledCard>
};