// a component to input a code and then show other players who used the same code

import React, { useEffect, useRef } from 'react';
import { useState } from 'react';

import { StyledGameViewPlayer, StyledFreezeOverlay, StyledPlayerBar, StyledGameViewPlayerGameArea, StyledGameViewPlayerInfo, StyledEmojis, StyledEmoji, StyledSelectedEmoji } from './GameViewPlayer.styled';
import Api from 'services/Api';
import { Button } from 'components/Button/Button';
import IPlayerState, { GAME_STAGE, IGameState } from 'models/IGameState';
import { PlayerBoard } from '../PlayerBoard/PlayerBoard';
import { PlayerAvatar } from '../PlayerAvatar/PlayerAvatar';
import { AppLogo } from '../../AppLogo/AppLogo';
import { StrategyExerciseChoose } from 'components/StrategyExercises/StrategyExerciseChoose/StrategyExerciseChoose';

export const GameViewPlayer = () => {
  const ws = useRef<null | WebSocket>(null);

  const [accessCode, setAccessCode] = useState<string>('');
  const [playerId, setPlayerId] = useState<string | null>(null);

  const [gameState, setGameState] = useState<IGameState | null>();

  const playerState  = gameState?.playerStates.find((p) => p.playerId === playerId);
  const playerResponse = playerState ? playerState.response : '';

  const submitAccessCode = () => {
    ws.current = Api.wsConnectPlayer(accessCode, () => {
      const newPlayerId = Math.floor(Math.random() * 1000000).toString();
      const newPlayer = {
        //generate a random player
        playerId: newPlayerId,
        avatar: `https://api.dicebear.com/9.x/pixel-art/svg?seed=${Math.floor(Math.random() * 1000)}.svg`,
        points: 0,
      } as IPlayerState;
  
      Api.wsSetPlayerState(ws.current, newPlayer);
      setPlayerId(newPlayerId);

    }, (newGameState: IGameState) => {
      setGameState(newGameState);
    });

    //If no WS returned (bad access code) clear the code
    if (!ws.current) {
      setAccessCode('');
      return;
    }
  };
  
  const setResponse = (response: string) => {
    if (!gameState || !playerState) return;

    const updatedPlayerState = {
      ...playerState,
      response: response,
    };

    Api.wsSetPlayerState(ws.current, updatedPlayerState);
  };

  const getGame = () => {
    if (!gameState) return null;

    if (gameState.stage === GAME_STAGE.LOBBY) {
      return null;
    } else if (gameState.stage === GAME_STAGE.HOW_YOU_FEEL) {
      const emojis = ['😌', '🥲', '😭', '😔', '🤔', '😑', '😠', '🙄', '😮‍💨'];

      return <div>
        <h2>How would you feel?</h2>
        <StyledEmojis>
          {emojis.map((emoji, idx) => {
            const rotation = (360 / emojis.length) * idx;
            return <StyledEmoji rotation={rotation} key={idx} onClick={() => setResponse(emoji)}>{emoji}</StyledEmoji>;
          })}
          {playerResponse && <StyledSelectedEmoji>{playerResponse}</StyledSelectedEmoji>}
        </StyledEmojis>
      </div>
    } else if (gameState.stage === GAME_STAGE.CHOOSE_THOUGHTS) {
      return <div>
        <h2>Choose your thoughts!</h2>
        <StrategyExerciseChoose onComplete={() => {
          if (!playerState || playerState.team === undefined) return;
          // Api.wsChangeTeamPoints(ws.current, gameState, player.team, 1);
        }}
        strategyExerciseChoose={{
          cardsHeading: 'Is this a <b>helpful</b> thought?',
          cards: [
            {
              questionText: 'I’ve done this before. I’ve got this.',
              isCorrect: true,
              hint: 'This is a reassuring and helpful thought. It’s a good example of backing yourself.',
            },
            {
              questionText: 'I’m a total loser',
              isCorrect: false,
              hint: 'That doesn’t sound like a very helpful or confident thought. How would it feel if someone said this to you?',
            },
            {
              questionText: 'I’m an idiot',
              isCorrect: false,
              hint: 'Nope not a helpful thought. You wouldn’t say this to a mate who was feeling down on themselves.',
            },
            {
              questionText: 'I’ll be alright. No big deal',
              isCorrect: true,
              hint: 'It’s a good start in helpful thinking.',
            },
            {
              questionText: 'I’ve got a long way to go, but whatever',
              isCorrect: false,
              hint: 'Hmmm. Probably not a very helpful thought.',
            },
            {
              questionText: 'Time for a sausage roll break',
              isCorrect: false,
              hint: 'Good idea. But not super helpful maybe?',
            },
            {
              questionText: 'I’ll bounce back',
              isCorrect: true,
              hint: 'Telling yourself that you’ll bounce back is a resilient and helpful thought.',
            },
            {
              questionText: 'I totally suck!',
              isCorrect: false,
              hint: 'You wouldn’t say it to a mate to help them out. It’s not helpful, motivating or kind.',
            },
            {
              questionText: 'I’m doing my best',
              isCorrect: true,
              hint: 'I’m doing my best is a good start in helpful thinking and shows some self-kindness.',
            },
            {
              questionText: 'I should practice my kicking',
              isCorrect: true,
              hint: 'This is actually helpful. It involves taking action and focusing on what you should do.',
            },
            {
              questionText: 'I need to stop missing so many goals',
              isCorrect: false,
              hint: 'For helpful thoughts you want to focus on what you should do, rather than things you shouldn’t be doing.',
            },
            {
              questionText: 'I’m injured so no point going to training',
              isCorrect: false,
              hint: 'Not necessarily. There might be some things you can still work on safely. So might not be a helpful thought?',
            },
            {
              questionText: 'I’m good at this',
              isCorrect: true,
              hint: 'This is optimistic and confident. It’s a helpful thought.',
            },
          ],
        }} />
      </div>
    }
  }

  const game = getGame();

  return (
    <StyledGameViewPlayer>
      {!playerId ? <>
          <AppLogo  />
          <form onSubmit={() => submitAccessCode()}>
          {/* input to provide the access code */}
          <h2>Enter your access code to kick off!</h2>
          <input type="text" placeholder="Access Code" name="accessCode" value={accessCode} onChange={(e) => setAccessCode(e.target.value)} />
          <Button primary label="Submit" type='submit' onClick={(e) => {
            e.preventDefault();
            submitAccessCode();
          }} />
        </form>
      </>
      : <>
        {!!gameState && !!playerState ? <>
          {gameState.isFrozen && <StyledFreezeOverlay />}
          <StyledPlayerBar team={playerState.team}>
            <PlayerAvatar player={playerState} />
          </StyledPlayerBar>
          <StyledGameViewPlayerGameArea height={game ? '80%' : '0%'}>
            {game}
          </StyledGameViewPlayerGameArea>
          <StyledGameViewPlayerInfo height={game ? '20%' : '100%'}>
            {playerState && <PlayerBoard gameState={gameState} />}
          </StyledGameViewPlayerInfo>
        </> : 'Loading...'}
      </>}
    </StyledGameViewPlayer>
  );
}