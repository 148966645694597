/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import Auth from './Auth';
import PlayerData from './PlayerData';

const webkit: any = (window as any).webkit;
const messageHandler: any = (window as any).messageHandler;

// ios is handled through the webkit.messagehandlers
// while android is handled through an injected javascript object: messageHandler

export enum PhoneType {
  Android = 1,
  iOS = 2,
  Web = 3,
}

export default {
  requestInAppBrowserWindow(url: string) {
    const browserBean = {
      url: url,
    };
  
    const messageBeanString = JSON.stringify(browserBean);
  
    try {
      // attempt to schedule a notification
      if (messageHandler?.postMessage) {
        // android
        messageHandler.postMessage('inAppWebPage', messageBeanString);
      } else if (webkit?.messageHandlers?.notificationSchedule) {
        // ios
        webkit.messageHandlers.inAppWebPage.postMessage(messageBeanString);
      } else {
        window.open(url, '_blank')
      }
    } catch (e) {
      console.error('Unknown error, launching in-app browser window', e);
    }
  },
  requestNativeDeviceRegistration() {
    const userId = Auth.getUserId();
    if (!userId) {
      // eslint-disable-next-line no-console
      console.log('Device Registration Error: user is not logged in to regiser device');
      return;
    }

    const registrationBean = {
      userId: userId,
    };

    const registrationBeanString = JSON.stringify(registrationBean);

    try {
      if (messageHandler?.postMessage) {
        // android
        messageHandler.postMessage('register', registrationBeanString);
      } else if (webkit?.messageHandlers?.register) {
        // ios
        webkit.messageHandlers.register.postMessage(registrationBeanString);
      } else {
        console.log('Unable to register native device, no native device hooks found');
      }
    } catch (e) {
      console.error('Unknown error, when attempting to register native device', e);
    }
  },
  setUserToken(token: string) {
    const userId = Auth.getUserId();
    if (!userId) {
      console.log('Device Registration Error: user is not logged in to regiser device');
      return;
    }
    PlayerData.updateUserTimeZone();
    // send to the api our new device token
    PlayerData.setUserDeviceToken(token);
  },
  getPhoneType(): PhoneType {
    if (webkit?.messageHandlers?.notificationSchedule) {
      return PhoneType.iOS;
    } else if (messageHandler && messageHandler.postMessage) {
      return PhoneType.Android;
    }

    return PhoneType.Web;
  },
  getPhoneTopInsetPx() {
    //Returns a pixel value for the amount of space taken up by the TOP inset (notch) on a device
    const iPhoneMediaQueries = [
      '(device-width: 375px) and (device-height: 812px)',
      '(device-width: 390px) and (device-height: 844px)',
      '(device-width: 428px) and (device-height: 926px)',
      '(device-width: 414px) and (device-height: 896px)'];

    if (webkit?.messageHandlers?.notificationSchedule) {
      // check some media queries
      const mediaQueryCheck = iPhoneMediaQueries.find(query => {
        const check = window.matchMedia(query);

        return check.matches;
      });

      if (mediaQueryCheck) {
        return 32;
      }
    }
    // not iOS, no offset.
    return 0;
  },
}