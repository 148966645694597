/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from 'react';
import Auth from '../../../services/Auth';
import Api from '../../../services/Api';
import { isValidEmail, isValidPassword, isValidTeamCode } from '../../../utils/validation';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { TeamCodeInput } from '../TeamCodeInput/TeamCodeInput';
import { FormFunctionProps, withForm } from '../Form';
import { Link } from 'react-router-dom';
import { StyledFormButton, StyledHeadingContainer, StyledFormText, StyledFormTerms, StyledContentContainer } from '../Form.styled';
import { TextField } from '../../TextField/TextField';
import { FormLogo } from '../FormLogo/FormLogo';
import { Heading } from '../../Heading/Heading';
import { RadioButtonGroup } from '../../RadioButtonGroup/RadioButtonGroup';
import Native from '../../../services/Native';
import { COLLECTIONS_URL, PRIVACY_POLICY_URL, TERMS_URL, SPORT_TYPE } from '../../../constants';

import { StyledGeneratedEmail, StyledGeneratedEmailLabel, StyledGeneratedEmailTip, StyledAgeSelectionContainer, StyledDigitalConsentContainer, StyledCheckBoxUl, StyledSubHeader, StyledLinkDiv, StyledErrorMessage } from './RegisterForm.styled';
import DataLayer, { GA4FormNameEnum, GTM4Events } from '../../../services/DataLayer';
import { Checkbox } from '../../Checkbox/Checkbox';
import { Modal } from '../../Modal/Modal';
import { StaticPage } from '../../Page/Page';
import Environment from '../../../services/Environment';
import PlayerData from 'services/PlayerData';
import CohortData from 'services/CohortData';

export enum RegisterState {
  VerifyTeamCode,
  SelectAge,
  DigitalConsent,
  InputName,
  InputEmailAndPw,

  // Extra states for automatically generated emails
  NoEmail_GenerateEmail,
  NoEmail_CreatePw,
}

export const RegisterFormStepValue = {
  teamCode: { label: 'Team code', ordinal: 1 },
  termsAndConditions: { label: 'Terms & Conditions', ordinal: 2 },
  userName: { label: 'User Name', ordinal: 3 },
  emailAndPassword: { label: 'Email & Password', ordinal: 4 },
}

const RegisterForm: React.FC<FormFunctionProps> = ({ getFormData, setIsLoading, setFormFieldValue, setFormFieldError, validateFormFields, clearFormErrors }) => {
  const { t } = useTranslation();
  const formData = getFormData();
  const [registerState, setRegisterState] = useState<RegisterState>(RegisterState.VerifyTeamCode);
  const [isTeamFeedbackEnabled, setIsTeamFeedbackEnabled] = useState<boolean>(false);
  const [teams, setTeams] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [checkBoxErrorMessage, setCheckBoxErrorMessage] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pageIdentifier, setPageIdentifier] = useState<string>('');
  const BUTTON_SIGNUP_TEXT = 'Sign up'

  const startFormEvent: GTM4Events['startForm'] = {
    form_name: GA4FormNameEnum.signup,
    step_label: BUTTON_SIGNUP_TEXT,
    step_number: 0,
  }
  useEffect(() => {
    // mark the user as not a demo user, there are two entry ways into the app(login and register)
    // we have to mark the user as not in demo mode at the start of each process
    
    // --- DEMO MODE ---
    // Only REGISTERED USERS CAN 'REGISTER' TO DEMO MODE COHORTS.
    // --- DEMO MODE ---
    CohortData.setDemoState(false);

    DataLayer.pushStartForm_GA4(startFormEvent);
  }, []);

  const onTeamCodeSubmit = async (e?: React.MouseEvent<HTMLButtonElement>) => {
    // check the teamcode
    const teamCode = formData.fields.teamCode.value as string;

    if (e) e.preventDefault();
    await Environment.tryShortcutAccessCodeRedirect(teamCode); //TAKEMETOTEST, TAKEMETOPROD etc...
    
    clearFormErrors();

    if (formData.isLoading || !validateFormFields(['teamCode'])) return;

    setIsLoading(true);

    const customEvent: GTM4Events['progressForm'] = {
      form_name: GA4FormNameEnum.signup,
      step_label: BUTTON_SIGNUP_TEXT,
      step_number: 1,
    }

    DataLayer.pushProgressForm_GA4(customEvent);

    // check if the team code is for rct, because we can't check it here
    await Environment.tryShortcutAccessCodeRedirect(formData.fields.teamCode.value as string);

    try {
      const cohortData = await Api.getCohortDataByAccessCode(formData.fields.teamCode.value as string);
      if (cohortData) {
        // check if it's a demo cohort
        CohortData.setDemoState(!!cohortData.IsDemo);
        CohortData.setCohortData(cohortData);

        // get teams
        setTeams(cohortData.Teams);
        // prepopulate the field if there's only one team selection
        if (cohortData.Teams.length === 1) setFormFieldValue('team', cohortData.Teams[0]);
        //get isTeamFeedbackEnabled
        setIsTeamFeedbackEnabled(cohortData.IsTeamFeedbackEnabled);

        if ([SPORT_TYPE.AustralianRules, SPORT_TYPE.AustralianRulesOneSession].includes(cohortData.Sport)) {
          setRegisterState(RegisterState.DigitalConsent);
        } else {
          setRegisterState(RegisterState.SelectAge);
        }
      }
    } catch (error) {
      setFormFieldError('teamCode', t('form.teamCode.verifyError'));
      const errorEvent: GTM4Events['errorField'] = {
        form_name: GA4FormNameEnum.signup,
        step_label: BUTTON_SIGNUP_TEXT,
        step_number: RegisterFormStepValue.teamCode.ordinal,
        field_id: RegisterFormStepValue.teamCode.label,
        message: t('form.teamCode.verifyError'),
      }
      DataLayer.pushErrorField_GA4(errorEvent);
      console.error('Team code verification failed, error:');
      console.error(error);
    }

      // don't bother checking, as there's no shared RCT database
      setIsLoading(false);

  };

  const onTermsAndConditionsSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e && e.preventDefault();
    clearFormErrors();

    if (formData.isLoading || !validateFormFields(['isOverLegalAge'])) return;
    const customEvent: GTM4Events['progressForm'] = {
      form_name: GA4FormNameEnum.signup,
      step_label: RegisterFormStepValue.termsAndConditions.label,
      step_number: RegisterFormStepValue.termsAndConditions.ordinal,
    }
    DataLayer.pushProgressForm_GA4(customEvent);
    setRegisterState(RegisterState.InputName);
  };

  const onDigitalConsentSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e && e.preventDefault();
    clearFormErrors();
    if (isChecked) {
      setRegisterState(RegisterState.InputName);
    } else {
      setCheckBoxErrorMessage('Please accept digital consent to continue')
    }
  }

  const onNameSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e && e.preventDefault();
    clearFormErrors();
    const validateFormFieldsList = ['firstName', 'lastName'];
    if (teams.length > 0 && isTeamFeedbackEnabled) validateFormFieldsList.push('team');
    if (formData.isLoading || !validateFormFields(validateFormFieldsList)) return;

    const customEvent: GTM4Events['progressForm'] = {
      form_name: GA4FormNameEnum.signup,
      step_label: RegisterFormStepValue.userName.label,
      step_number: RegisterFormStepValue.userName.ordinal,
    }
    DataLayer.pushProgressForm_GA4(customEvent);
    setRegisterState(RegisterState.InputEmailAndPw);
  };

  const onDetailsSubmitAuthError = (err: any, res: any) => {
    if (err) {
      // console.log(err);
      // this will show the error when the user used an existing email address
      setIsLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      setFormFieldError('email', err.description);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      DataLayer.pushSignUpFormError(err.description);
      const errorEvent: GTM4Events['errorField'] = {
        form_name: GA4FormNameEnum.signup,
        step_label: BUTTON_SIGNUP_TEXT,
        step_number: RegisterFormStepValue.emailAndPassword.ordinal,
        field_id: RegisterFormStepValue.emailAndPassword.label,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        message: err.description,
      }
      DataLayer.pushErrorField_GA4(errorEvent);
    }
    //If there is NO error, the page will reload for Auth0. DB user is created on successful authenticate.
  }

  const onDetailsSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e && e.preventDefault();
    clearFormErrors();

    if (formData.isLoading) return;

    // TODO: what is this? 'movember'? It should be something no one could ever put in :S
    const pw = formData.fields.password.value as string;
    if (pw.trim().toLowerCase() === 'movember' && !formData.fields.email.value) {
      setIsLoading(true);
      const teamCode = formData.fields.teamCode.value as string;
      const firstname = formData.fields.firstName.value as string;
      const generatedEmail = await Api.getGeneratedEmail(teamCode, firstname);
      setIsLoading(false);

      setFormFieldValue('email', generatedEmail);
      setFormFieldValue('password', '');
      setRegisterState(RegisterState.NoEmail_GenerateEmail);
      return;
    }

    if (!validateFormFields(['email', 'password'])) return;

    setIsLoading(true);

    // check if we're in demo mode.
    if (CohortData.getDemoState()) {
        
      // --- DEMO MODE ---
      // Only REGISTERED USERS CAN 'REGISTER' TO DEMO MODE COHORTS.
      // --- DEMO MODE ---
      Auth.loginWithEmailPassword(
        formData.fields.email.value?.toString() ?? '',
        formData.fields.password.value?.toString() ?? '',
        onDetailsSubmitAuthError);

      return;
    }

    const customEvent: GTM4Events['progressForm'] = {
      form_name: GA4FormNameEnum.signup,
      step_label: RegisterFormStepValue.emailAndPassword.label,
      step_number: RegisterFormStepValue.emailAndPassword.ordinal,
    }
    DataLayer.pushProgressForm_GA4(customEvent);
    //This is just before register is validated on the server (it still may error below)
    //but since the form is frontend validated, and there is no callback for successful signup, it'll do for now
    DataLayer.pushSignUpComplete(formData.fields.isOverLegalAge.value !== undefined, formData.fields.teamCode.value as string, formData.fields.team.value as string);
    const submitEvent: GTM4Events['submitForm'] = {
      form_name: GA4FormNameEnum.signup,
      step_label: BUTTON_SIGNUP_TEXT,
      step_number: RegisterFormStepValue.emailAndPassword.ordinal,
    }
    DataLayer.pushSubmitForm_GA4(submitEvent);
    Auth.registerWithEmailPassword({
      email: (formData.fields.email.value?.toString()) ?? '',
      password: formData.fields.password.value?.toString() ?? '',
      firstName: formData.fields.firstName.value?.toString() ?? '',
      lastName: formData.fields.lastName.value?.toString() ?? '',
      accessCode: formData.fields.teamCode.value?.toString() ?? '',
      team: formData.fields.team.value?.toString() ?? '',
      callback: onDetailsSubmitAuthError,
    },
    );
  };

  const onShowModal = (pageName: string) => {
    setShowModal(true);
    setPageIdentifier(pageName);
  }

  const getContent = () => {
    if (registerState === RegisterState.VerifyTeamCode) {
      // check if we have url parameters, if we do, do, populate the initial value
      const urlParams = new URLSearchParams(window.location.search);
      const registrationCode = urlParams.get('registrationCode') || undefined;

      return <>
        <TeamCodeInput data-cy="teamCode" initialValue={registrationCode} errorMessage={formData.fields.teamCode.error} onChange={newValue => setFormFieldValue('teamCode', newValue)} />
        <StyledFormButton data-cy="submit" type="submit" onClick={onTeamCodeSubmit} label="Next" />
      </>
    } else if (registerState === RegisterState.SelectAge) {
      return <>
        <StyledAgeSelectionContainer>
          <RadioButtonGroup
            name="legalAgeSelections"
            options={[
              { id: 'legal-age-over-16', label: 'I am 16 or older' },
              { id: 'legal-age-under-16', label: 'I am under 16' }]}
            onChange={option => setFormFieldValue('isOverLegalAge', option.id === 'legal-age-over-16')}
            data-cy="ageSelection"
          />
        </StyledAgeSelectionContainer>
        {formData.fields.isOverLegalAge.value !== undefined
          && <>
            <StyledFormTerms>
              {formData.fields.isOverLegalAge.value
                ? 'I agree to '
                : 'I confirm that my parent or legal guardian has agreed to '
              }
              the Ahead of the Game <a onClick={() => Native.requestInAppBrowserWindow(TERMS_URL)}>terms and conditions</a> and
            </StyledFormTerms>
            <StyledFormTerms>
              Movember’s collection and handling of my personal information in accordance with it’s <a onClick={() => Native.requestInAppBrowserWindow(PRIVACY_POLICY_URL)}>privacy policy</a> and <a href="javascript:void(0)" onClick={() => Native.requestInAppBrowserWindow(COLLECTIONS_URL)}>collection statement</a>.
            </StyledFormTerms>
            <StyledFormButton data-cy="submit" type="submit" onClick={onTermsAndConditionsSubmit} label="I AGREE" />
          </>
        }
      </>
    } else if (registerState === RegisterState.DigitalConsent) {
      return <>
        <StyledDigitalConsentContainer data-cy="digitalConsent">
          <StyledSubHeader>Digital Consent</StyledSubHeader>
          {
            !isChecked && checkBoxErrorMessage &&
            <StyledErrorMessage>{checkBoxErrorMessage}</StyledErrorMessage>
          }
          <Checkbox  onChange={() => setIsChecked(!isChecked)} isCheckedInitially={isChecked} hasError={checkBoxErrorMessage ? true : false}>
            By checking this box, you agree and acknowledge that:
          </Checkbox>
          <StyledCheckBoxUl>
            <li>You are taking part in the Ahead of the Game mental fitness workshops</li>
            <li>You will comply with the <StyledLinkDiv onClick={()=>onShowModal('terms-and-conditions')}>Terms and Conditions</StyledLinkDiv> of Use for the Ahead of the Game e-learning platform/app and</li>
            <li>You have read and understood the <StyledLinkDiv onClick={()=>onShowModal('collection-statement')}>Collection Statement</StyledLinkDiv> and agree that Movember will collect and handle your personal information for the purpose of delivering the Ahead of the Game mental fitness workshops,
              in accordance with that <StyledLinkDiv onClick={()=>onShowModal('collection-statement')}>Collection Statement</StyledLinkDiv> and our <a onClick={() => Native.requestInAppBrowserWindow(PRIVACY_POLICY_URL)}>Privacy Policy</a>.</li>
          </StyledCheckBoxUl>

        </StyledDigitalConsentContainer>
        <StyledFormButton data-cy="submit" type="submit" onClick={onDigitalConsentSubmit} label="CONTINUE" />
      </>
    } else if (registerState === RegisterState.InputName) {

      return <>
        <TextField
          placeholder={t('form.firstName.placeholder')}
          onChange={(newValue) => setFormFieldValue('firstName', newValue)}
          errorMessage={formData.fields.firstName.error}
          data-cy='firstname'
        />
        <TextField
          placeholder={t('form.lastName.placeholder')}
          onChange={(newValue) => setFormFieldValue('lastName', newValue)}
          errorMessage={formData.fields.lastName.error}
          data-cy='lastname'
        />
        {(teams.length > 0 && isTeamFeedbackEnabled) && <TextField
          placeholder={t('form.teamSelection.placeholder')}
          onChange={(newValue) => setFormFieldValue('team', newValue)}
          errorMessage={formData.fields.team.error}
          type='dropdown'
          data-cy='selectteam'
          dropdownList={teams}
          initialValue={teams.length === 1 ? teams[0] : undefined} // prepopulate the field if there's only one team selection
        />}
        <StyledFormButton data-cy="submit" type="submit" onClick={onNameSubmit} label="Next" />
      </>
    } else if (registerState === RegisterState.InputEmailAndPw) {
      return <>
        <TextField
          type="email"
          placeholder={t('form.email.placeholder')}
          onChange={(newValue) => setFormFieldValue('email', newValue)}
          errorMessage={formData.fields.email.error}
          data-cy='email'
        />
        <TextField
          placeholder={t('form.password.placeholder')}
          onChange={(newValue) => setFormFieldValue('password', newValue)}
          errorMessage={formData.fields.password.error}
          type="password"
          data-cy='password'
        />
        <StyledFormButton data-cy='submit' type="submit" onClick={(e) => void onDetailsSubmit(e)} label={BUTTON_SIGNUP_TEXT} />
      </> 
    } else if (registerState === RegisterState.NoEmail_GenerateEmail) {
      return <>
        <StyledGeneratedEmailLabel>Email</StyledGeneratedEmailLabel>
        <StyledGeneratedEmail> {formData.fields.email.value} </StyledGeneratedEmail>
        <StyledGeneratedEmailTip>
          <div>Don’t forget this email</div>
          <div>Take a screen shot or write it down. You’ll need it later.</div>
        </StyledGeneratedEmailTip>
        <StyledFormButton onClick={() => setRegisterState(RegisterState.NoEmail_CreatePw)} label="GOT IT" />
      </>
    } else if (registerState === RegisterState.NoEmail_CreatePw) {
      return <>
        <StyledGeneratedEmailLabel>Email</StyledGeneratedEmailLabel>
        <StyledGeneratedEmail> {formData.fields.email.value} </StyledGeneratedEmail>
        <TextField
          placeholder={t('form.password.noEmailPlaceholder')}
          onChange={(newValue) => setFormFieldValue('password', newValue)}
          errorMessage={formData.fields.password.error}
          type="password"
        />

        <StyledFormButton type="submit" onClick={(e) => onDetailsSubmit(e)} label={BUTTON_SIGNUP_TEXT} />
      </>
    }
  };

  // check if we're using urlparams, if we are, initiate a check.
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const registrationCode = urlParams.get('registrationCode') || '';

    if (!registrationCode) return

    formData.fields.teamCode.value = registrationCode;
    onTeamCodeSubmit().catch(err => console.log(err));

  }, [formData.fields.teamCode, onTeamCodeSubmit]);
  const text = t('title.login');
  return <>
    {
      showModal && <Modal onClose={() => setShowModal(false)}><StaticPage pageIdentifier={pageIdentifier} /></Modal>
    }
    <FormLogo />
    <StyledHeadingContainer>
      <Heading level="h4" primary text={BUTTON_SIGNUP_TEXT} />
    </StyledHeadingContainer>
    <StyledContentContainer key={`signup-${registerState}`}>
      {getContent()}
    </StyledContentContainer>
    <StyledFormText>Already signed up? <Link data-cy="loginLink" to="/login">
      {text}
    </Link></StyledFormText>
  </>
};

export default withForm(RegisterForm, {
  fields: {
    teamCode: {
      value: '',
      validate: (value: string) => isValidTeamCode(value) ? '' : i18n.t('form.teamCode.invalidError'),
    },
    email: {
      value: '',
      validate: (value: string) => isValidEmail(value) ? '' : i18n.t('form.email.invalidError'),
    },
    firstName: {
      value: '',
      validate: (value: string) => value ? '' : i18n.t('form.firstName.invalidError'),
    },
    lastName: {
      value: '',
      validate: (value: string) => value ? '' : i18n.t('form.lastName.invalidError'),
    },
    team: {
      value: '',
      validate: (value: string) => value ? '' : i18n.t('form.teamSelection.invalidError'),
    },
    password: {
      value: '',
      validate: (value: string) => isValidPassword(value) ? '' : i18n.t('form.password.invalidError'),
    },
    isOverLegalAge: {
      value: undefined,
      validate: (value: boolean | undefined) => value !== undefined ? '' : i18n.t('form.isOverLegalAge.invalidError'),
    },
  },
});
