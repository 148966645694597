import React, { useEffect } from 'react';
import Auth from '../../../services/Auth';
import { isValidEmail, isValidPassword } from '../../../utils/validation';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { FormFunctionProps, withForm } from '../Form';
import { TextField } from '../../TextField/TextField';
import { StyledFormButton, StyledHeadingContainer, StyledFormText } from '../Form.styled';
import { FormLogo } from '../FormLogo/FormLogo';
import { Link } from 'react-router-dom';
import { StyledInputContainer, ForgotLink } from './LoginForm.styled';
import { Heading } from '../../Heading/Heading';
import PlayerData from 'services/PlayerData';
import CohortData from 'services/CohortData';

const LoginForm: React.FC<FormFunctionProps> = ({ getFormData, setIsLoading, setFormFieldValue, setFormFieldError, setFormError, validateFormFields, clearFormErrors }) => {
  const { t } = useTranslation();
  const formData = getFormData();

  useEffect(() => {
    // mark as not a demo cohort, there are two entry ways into the app(login and register)
    // we have to mark the user as not in demo mode at the start of each process

    CohortData.setDemoState(false);
  }, []);

  const onFormSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e && e.preventDefault();
    clearFormErrors();

    if (formData.isLoading || !validateFormFields(['email', 'password'])) return;

    setIsLoading(true);

    Auth.loginWithEmailPassword(formData.fields.email.value as string, formData.fields.password.value as string, (err: any, res: any) => {
      if (err) {
        setIsLoading(false);
        // eslint-disable-next-line no-console
        console.log(err);
        setFormError("Email and password don't match");
      }
    });
  };

  return (
    <>
      <FormLogo />
      <StyledHeadingContainer>
        <Heading
          level="h4"
          primary
          text="Log in"
        />
      </StyledHeadingContainer>
      <StyledInputContainer>
        <TextField
          type="email"
          placeholder={t('form.email.placeholder')}
          errorMessage={formData.fields.email.error || formData.formError}
          onChange={(newValue) => setFormFieldValue('email', newValue)}
          data-cy="email"
        />
        <ForgotLink
          tabIndex={-1}
          to="/contact-us"
        >
          Forgot email?
        </ForgotLink>
      </StyledInputContainer>
      <StyledInputContainer>
        <TextField
          placeholder={t('form.password.placeholder')}
          errorMessage={formData.fields.password.error}
          onChange={(newValue) => setFormFieldValue('password', newValue)}
          type="password"
          data-cy="password"
        />
        <ForgotLink
          tabIndex={-1}
          to="/reset-password"
        >
          Forgot password?
        </ForgotLink>
      </StyledInputContainer>
      <StyledFormButton
        type="submit"
        onClick={onFormSubmit}
        label="Log in"
        data-cy="loginSubmit"
      />
      <StyledFormText>
        Don’t have an account? <Link to="/register">{t('title.register')}</Link>
      </StyledFormText>
    </>
  );
};

export default withForm(LoginForm, {
  fields: {
    email: {
      value: '',
      validate: (value: string) => (isValidEmail(value) ? '' : i18n.t('form.email.invalidError')),
    },
    password: {
      value: '',
      validate: (value: string) => (isValidPassword(value) ? '' : i18n.t('form.password.invalidError')),
    },
  },
});
