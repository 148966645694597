/* eslint-disable @typescript-eslint/no-shadow */
import styled, { css, keyframes } from 'styled-components';
import { IStyledProps } from '../../../theme';
import { ChapterInstruction } from '../../ChapterInstruction/ChapterInstruction';
import { AdvancedCard } from '../../AdvancedCard/AdvancedCard';
import { Button } from '../../Button/Button';
import { getFadeInKeyFrames, getSlideUpAndFadeIn, getFadingKeyFrames } from '../../../utils/animations.styled';
import { StyledChapterInstruction } from '../../ChapterInstruction/ChapterInstruction.styled';
import { StripButton } from '../../StripButton/StripButton';
import { StyledAdvancedCard } from '../../AdvancedCard/AdvancedCard.styled';
import { CHAPTER_TRANSITION } from '../../../constants';
import { Heading } from '../../Heading/Heading';
import { StyledStrategyExercisesButton } from '../StrategyExercises.styled';


interface IStyledPlanOptionProps extends IStyledProps {
  index: number;
  clickedColour: string;
  isDisabled?: boolean;
  isActive?: boolean;
}

export const StyledPlanOption = styled(StripButton)`
  opacity: 0;
  margin-bottom: 10px;
  ${(props: IStyledPlanOptionProps) => props.isActive && css`
    border: none;
    background-color: ${props.clickedColour}
    color: ${props.theme.colors.white};
    svg {
      path, rect {
        fill: ${props.theme.colors.white};
      }
    }
    :after {
      border-top-color: ${(props: IStyledPlanOptionProps) => props.theme.colors.white};
      border-right-color: ${(props: IStyledPlanOptionProps) => props.theme.colors.white};
    }
  `}

  &:active {
    background-color: ${(props: IStyledPlanOptionProps) => props.isDisabled ? '' : props.clickedColour};
  }

  ${(props: IStyledPlanOptionProps) => css`
    &:nth-child(${props.index + 1}) {
      animation: ${getFadingKeyFrames(0, props.isDisabled ? 0.5 : 1)} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out ${CHAPTER_TRANSITION.delayTime + props.index * CHAPTER_TRANSITION.fadingTime}s;
    }
  `}
`;

export const StyledPlanSelector = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledPlanSelectorContainer = styled.div`
  >${StyledChapterInstruction} {
    animation: ${getSlideUpAndFadeIn('-200px')} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out;
  }
`;

export const StyledChooseInstruction = styled(ChapterInstruction)`
  position: relative;
  &&& { 
    margin-bottom: 40px;
  }
  width: 80%;
  margin: auto;

  @media screen and (min-width: 375px) {
    width: 70%;
  }

  @media screen and (min-width: 420px) {
    width: 60%;
  }
`;

export const StyledChooseBestPlanContainer = styled.div`
  > * {
    opacity: 0;
  }

  ${StyledAdvancedCard} {
    &:nth-of-type(1) {
      animation: ${getSlideUpAndFadeIn('50px')} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out ${CHAPTER_TRANSITION.delayTime / 2}s;
    }
    // slightly longer delay on this one to fit the design
    &:nth-of-type(2) {
      animation: ${getSlideUpAndFadeIn('100px')} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out ${CHAPTER_TRANSITION.delayTime / 2 + 0.1}s;
    }
  }

  ${StyledChooseInstruction} {
    animation: ${getFadeInKeyFrames()} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out;
  } 
`;

export const StyledContainer = styled.div`
  height:100%;

  &.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;



    & .card-container {
      height: fit-content;
    }
  }

  &.non-flex-column {
    display: unset;
    margin-top: 34px;
  }

  & .title-text {
    font-family: ${(props: IStyledProps) => props.theme.fonts.heading};
    font-size: 38px;
    letter-spacing: 5.75px;
  }

  & .card-container{
    height:100%;
  }

  & .plan-describe{
    line-height:2rem;
  }

`;

export const StyledFooterContainer = styled.div`
`;

export const StyledChooseBestPlanCard = styled(AdvancedCard)`
  margin-bottom: 30px;
`;

export const StyledExpandedAdvancedCard = styled(AdvancedCard)`
  margin-top: 20px;
  min-height: min(80%, 490px);
`;

export const StyledDescription = styled(Heading)`
  margin-top: 15px;
`;

export const StyledHeading = styled(Heading).attrs({ primary: true, level: 'h1' })`
  margin-bottom: 20px;
  font-size: 60px;
`;

const StyledBaseButton = styled(StyledStrategyExercisesButton)`
  margin-top: 20px;
  width: 100%;

  & h6{
  transform: translate3d(0px, 0px, 0px)
}
`;


export const StyledContinueButton = styled(StyledBaseButton)`
 
  background-color: ${(props: IStyledProps) => props.theme.colors.primary.b};
  border:1px solid ${(props: IStyledProps) => props.theme.colors.primary.b};
`;

const getBarFillAnimation = () => {
  return keyframes`
    from {
      width: 0%
    }
    to {
      width: 100%;
    }
  `;
}

export const StyledStopButton = styled(StyledBaseButton)`
::before { 
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    animation:  ${getBarFillAnimation()} 5s ease forwards;
    height: 100%;
    background-color: ${(props: IStyledProps) => props.theme.colors.primary.b};
  }
  background-color: unset;
  border:1px solid black;
  color: black;
`;


export const StyledInstructionContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledInstructionTextWrapper = styled.div`
  width: 80%;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledInstructionHeadingWrapper = styled.div`
  font-family: ${(props: IStyledProps) => props.theme.fonts.heading};
  font-size: 2.125rem;

  @media screen and (min-width: 360px) {
    font-size: 2.5rem;
  }
  @media screen and (min-width: 400px) {
    font-size: 3.125rem;
  }
`;

export const StyledInstructionHeading = styled.div`
`;

export const StyledInstructionSubHeading = styled.div`
  margin-bottom: 3.125rem;
`;

export const StyledInstructionBodyTextWrapper = styled.div`
  font-size: 1rem;
  margin-bottom: 1.875rem;

  @media screen and (min-width: 360px) {
    font-size: 1.1rem;
  }
  @media screen and (min-width: 400px) {
    font-size: 1.25rem;
  }
`;

export const StyledInstructionText = styled.div`
  margin-bottom: 1.875rem;
`;

export const StyledInstructionButton = styled(Button)`
  width: 90%;
  bottom: 5%;
  position: absolute;
`;
