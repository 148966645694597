import React, { useEffect, useState } from 'react';
import { ITransitionStep, TransitionStepType, useTransition } from '../TransitionContext/TransitionContext';
import { StyledScrollingChevrons, StyledTransitionMask } from './TransitionMask.styled';

export const TransitionMask: React.FC = () => {
  const { transition, goToNextTransitionStep, transitionStep } = useTransition();

  const [currentFadeStep, setCurrentFadeStep] = useState<ITransitionStep | null>(null);
  const [currentOverlay, setCurrentOverlay] = useState<ITransitionStep | null>(null);
  // extend the transition mask to handle dashboard transition in.

  useEffect(() => {
    if (transition && !transition.paused) {
      // Go to next stage after specific time period
      const transitionTimeout = setTimeout(() => goToNextTransitionStep(), transition.steps[transition.currentStepIndex].durationSeconds * 1000);

      if (transitionStep?.type && [TransitionStepType.FadeIn, TransitionStepType.FadeOut].includes(transitionStep?.type)) {
        setCurrentFadeStep(transitionStep);
      }

      if (transitionStep?.type && [TransitionStepType.EntryTransition].includes(transitionStep?.type)) {
        setCurrentOverlay(transitionStep);
      }

      // clean up timeout once the mask is remounted
      return () => {
        clearTimeout(transitionTimeout)
      }
    }

    if (!transition) {
      setCurrentFadeStep(null);
      setCurrentOverlay(null);
    }
    // eslint-disable-next-line
  }, [transition]);

  return <>
    {currentOverlay !== null && <StyledScrollingChevrons key="scrollingChevrons" currentStep={currentOverlay} />}
    {<StyledTransitionMask 
      key={'transitionMask'}
      currentStep={currentFadeStep}
    />}
  </>;
}
