import styled, { css, keyframes } from 'styled-components';
import { IStyledProps } from '../../../theme';
import { AdvancedCard } from '../../AdvancedCard/AdvancedCard';

import LargeTick from '../../../images/icons/large-tick.svg';
import LargeCross from '../../../images/icons/large-cross.svg';
import Tick from '../../../images/icons/tick.svg';
import Cross from '../../../images/icons/cross.svg';
import { getSlideUpAndFadeIn, getSlideUpAndFadeOut } from '../../../utils/animations.styled';
import { CHAPTER_TRANSITION } from '../../../constants';
import { ChapterInstruction } from '../../ChapterInstruction/ChapterInstruction';

interface IExerciseProps extends IStyledProps {
  isCompleted?: boolean;
}

export const StyledInstruction = styled(ChapterInstruction)`
  opacity: 0;
  animation: ${getSlideUpAndFadeIn('-200px')} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out 1.8s;
  ${(props: IExerciseProps) => props.isCompleted && css`
    opacity: 1;
    animation: ${getSlideUpAndFadeOut('-200px')} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out;
  `}
  position: relative;
  top: -5px;

  @media screen and (min-height: 600px) {
    top: 0px;
  }

  @media screen and (min-height: 700px) {
    top: 25px;
  }
`;

export const StyledStrategyExerciseChoose = styled.div`
  height: 100%;
  width: 100%;
`;

interface ISwipeCardProps extends IStyledProps {
  stackCount: number;
  stackIndex: number;
  state: string;
}

interface ICenteredImage extends IStyledProps {
  opacity?: number;
  type?: string;
}

export const FLING_DISTANCE = 780;
export const FLING_ROTATION = 45;

const CARD_STACK_OFFSET = 7;
const CARD_STACK_SCALE_OFFSET = 0.02;


const shakeAnimation = keyframes`
  10%, 90% {
    transform: translate(calc(-50% + -5px), -50%) rotate(-1deg);
  }
  20%, 80% {
    transform: translate(calc(-50% + 10px), -50%) rotate(2deg);
  }
  30%, 50%, 70% {
    transform: translate(calc(-50% + -20px), -50%) rotate(-3deg);
  }
  40%, 60% {
    transform: translate(calc(-50% + 20px), -50%) rotate(3deg);
  }
  100% {
    transform: translate(calc(-50%),-50%) rotate(0deg);
  }
`;
const getCardEnterAnimation = (progress: number) => keyframes`
  0% {
    transform: translate(calc(${FLING_DISTANCE}px),25vh) rotate(${FLING_ROTATION}deg);
  }
  ${progress * 50}% {
    transform: translate(calc(${FLING_DISTANCE}px),25vh) rotate(${FLING_ROTATION}deg);
  }
  ${progress * 50 + 50}% {
    transform: translate(calc(-50%),-50%) rotate(0deg);
  }
`;

export const StyledSwipeCardContainer = styled.div`
  position: relative;
  height: 100%;
`;

// Handles the y offset and scale of cards lower in the stack
// it's magic happens mostly in the js.
export const StyledSwipeCardScalar = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;

  ${(props: ISwipeCardProps) => {
    // manage all the states! lets go!
    switch (props.state) {
      case 'fling-left':
        return css`pointer-events: none;`;
      case 'fling-right':
        return 'pointer-events: none;';
      case 'done':
        return 'pointer-events: none;';
    }

    return `
    `;
  }};

  transform: ${(props: ISwipeCardProps) => `translateY(${(props.stackCount - props.stackIndex) * CARD_STACK_OFFSET}px) scale(${1 - (props.stackCount - props.stackIndex) * CARD_STACK_SCALE_OFFSET})`};
`;
export const StyledAdvancedCard = styled(AdvancedCard)`
  width: 335px;
  max-width: calc(100vw - 20px);
  min-width: 100%;
  height: 491px;
  min-height: calc(100% - 32px);
  max-height: 75vh;
`;

export const StyledSwipeCardDummy = styled.div`
  position: absolute;

  top: 40%;
  left: 50%;

  transition: transform 0.3s;
  transform: translate(-50%, -50%) rotate(0deg);

  ${(props: ISwipeCardProps) => {
    const inverseIndex = props.stackIndex;

    // manage all the states! lets go!
    switch (props.state) {
      case 'pre-enter':
        return css`
          animation: ${getCardEnterAnimation(inverseIndex / (props.stackCount - 1))} 1.8s cubic-bezier(0.79, 0.44, 0.81, 1);
        `;
      case 'standing':
        return 'transition: transform 0.3s;';
      case 'shake':
        return css`
          pointer-events: none;
          transition: none !important;
          animation: ${shakeAnimation} 2s cubic-bezier(.36,.07,.19,.97) forwards`;
      case 'fling-left':
        return css`
          pointer-events: none;
          transition: transform 0.8s !important;
          transform: translate(calc(-50% - ${FLING_DISTANCE}px), -50%) rotate(-${FLING_ROTATION}deg) !important;`;
      case 'fling-right':
        return `
          pointer-events: none;
          transition: transform 0.8s !important;
          transform: translate(calc(-50% + ${FLING_DISTANCE}px), -50%) rotate(${FLING_ROTATION}deg) !important;`;
      case 'done':
        return `
          pointer-events: none;
          opacity: 0;
          transform: translate(calc(-50% + ${FLING_DISTANCE}px), -50%) rotate(${FLING_ROTATION}deg) !important;
        `;
    }

    return `
    `;
  }};
`;

export const StyledSwipeCardOverlay = styled.div`
  pointer-events: none;

  position: absolute;
  top: 0px;
  left: 0px;
  width:100%;
  height: 100%;
  border-radius: 15px;
  opacity: 0;
  transition: opacity 0.3s;

  background-color: ${(props: ICenteredImage) => `${props.theme.colors.greys.a}bb`};

  :after {
    content: '';
    position: absolute;

    background: white;
    
    top: 50%;
    left: 50%;
    width: 128px;
    height: 128px;
    
    transform: translate(-50%, -50%);
    
    background: url(${LargeCross});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.tick {
    background-color: ${(props: ICenteredImage) => `${props.theme.colors.primary.b}bb`};

    ::after {
      background-image: url(${LargeTick})
    }
  }
`;

export const StyledSwipeCardQuestionText = styled.div`
  display: flex;
  height: 128px;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;

  padding: 0px min(12vw,8px);

  user-select: none;
  font-family: Overpass;
  font-size: 30px;
  line-height: 38px;
  font-weight: 900;

  color: white;
`;
export const StyledSwipeCardButton = styled.div`
  position: absolute;
  bottom: 56px;
  left: ${(props: ICenteredImage) => props.type === 'tick' ? 'calc(100% - 64px)' : '64px'};
  width: 60px;
  height: 60px;
  border-radius: 100%;

  transform: translate(-50%, 50%);

  cursor: pointer;

  background: url(${(props: ICenteredImage) => props.type === 'tick' ? Tick : Cross});
  background-size: ${(props: ICenteredImage) => props.type === 'tick' ? '24px 24px' : '20px 20px'};
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${(props: ICenteredImage) => props.theme.colors.black};
`;
