import React, { useEffect, useRef, useState } from 'react';
import { StyledLoadingSpinner, StyledLoadingSpinnerContainer, StyledLoadingSpinnerText, StyledLoadingSpinnerTextContainer } from './LoadingSpinner.styled';
import { shuffleArray } from '../../utils/helper';

interface IVideoOverlayProps {
  backgroundColour?: string
}

export const LOADING_SPINNER_TEXT_SNIPPETS = shuffleArray([
  'Filling water bottles',
  'Strapping shoulders',
  'Rinsing mouthguards',
  'Tying laces',
  'Finalising game plan',
  'Telling the team to keep it simple',
  'Asking parents to keep it civil',
  'Getting into position',
  'Waiting for the whistle',
]);

//A layer over a videoPlayer which holds elements inside, creating an overlay
export const LoadingSpinner: React.FC<IVideoOverlayProps> = ({ backgroundColour }) => {
  const [loadingSpinnerTextSnippetIndex, setLoadingSpinnerTextSnippetIndex] = useState(0);
  const previousSnippetIndexRef = useRef(-1);

  useEffect(() => {
    // setup the loading spinner text...
    const loadingSpinnerTextInterval = setInterval(() => {
      setLoadingSpinnerTextSnippetIndex((prevIndex) => {
        previousSnippetIndexRef.current = prevIndex;

        return (prevIndex + 1) % LOADING_SPINNER_TEXT_SNIPPETS.length
      });
    }, 4500);

    return () => {
      clearInterval(loadingSpinnerTextInterval);
    };
  }, []);

  const currentSnippet = LOADING_SPINNER_TEXT_SNIPPETS[loadingSpinnerTextSnippetIndex];
  const previousSnippet = LOADING_SPINNER_TEXT_SNIPPETS[previousSnippetIndexRef.current];

  return <StyledLoadingSpinnerContainer backgroundColour={backgroundColour} data-testid="loading-spinner">
    <StyledLoadingSpinner />
    {backgroundColour && <StyledLoadingSpinnerTextContainer>
      {previousSnippet && <StyledLoadingSpinnerText key={`previousSnippet-${currentSnippet}`} leaving={true} data-testid="loading-spinner-text">{previousSnippet}</StyledLoadingSpinnerText>}
      {currentSnippet && <StyledLoadingSpinnerText key={`currentSnippet-${currentSnippet}`} data-testid="loading-spinner-text">{currentSnippet}</StyledLoadingSpinnerText>}
    </StyledLoadingSpinnerTextContainer>}
  </StyledLoadingSpinnerContainer>
};