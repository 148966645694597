import React from 'react';
import {
  StyledSupportServicesContainer,
  StyledSupportServicesHeading,
  StyledSupportServicesIconContainer,
  StyledSupportServicesSubHeading,
  StyledSupportServicesDescription,
  StyledSupportServicesLink,
  StyledSupportServicesButton,
  StyledSupportServicesNumber,
  StyledSupportServicesCloseIcon,
} from './SupportServices.styled';
import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as Questions } from './questions.svg';
import { ReactComponent as Laptop } from './laptop.svg';
import { ReactComponent as Help } from './help.svg';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import Native from '../../services/Native';
import { IChapter } from '../../models/IChapter';
import { getQueryParam } from '../../utils/helper';

export const SupportServices: React.FC = () => {
  const navigate = useNavigate();

  const fromPath = getQueryParam('from');


  // return to the specific chapter summary you came from or return to the dash
  // -1 is the equivalent of hitting the back button 
  const goBack = () => {
    if (fromPath) {
      navigate(fromPath);
    } else {
      navigate(-1);
    }
  }

  return <StyledSupportServicesContainer>
    <StyledSupportServicesCloseIcon onClick={() => goBack()}>
      <CloseIcon />
    </StyledSupportServicesCloseIcon>
    <StyledSupportServicesHeading text={'Sources of help'} />
    <StyledSupportServicesIconContainer><Phone /></StyledSupportServicesIconContainer>
    <StyledSupportServicesSubHeading text={'Kids helpline'} />
    <StyledSupportServicesDescription>Kids Helpline is free, private and confidential 24/7 phone and online counselling service for youth.</StyledSupportServicesDescription>
    <StyledSupportServicesLink onClick={() => Native.requestInAppBrowserWindow('https://kidshelpline.com.au')}>kidshelpline.com.au</StyledSupportServicesLink>
    <StyledSupportServicesNumber>1800 55 1800</StyledSupportServicesNumber>
    <StyledSupportServicesIconContainer><Questions /></StyledSupportServicesIconContainer>
    <StyledSupportServicesSubHeading text={'Beyond blue'} />
    <StyledSupportServicesDescription>Beyond Blue provides information and 24/7 support to help young Australians achieve their best possible mental health.</StyledSupportServicesDescription>
    <StyledSupportServicesLink onClick={() => Native.requestInAppBrowserWindow('https://youthbeyondblue.com')}>youthbeyondblue.com</StyledSupportServicesLink>
    <StyledSupportServicesNumber>1300 22 4636</StyledSupportServicesNumber>
    <StyledSupportServicesIconContainer><Laptop /></StyledSupportServicesIconContainer>
    <StyledSupportServicesSubHeading text={'Headspace'} />
    <StyledSupportServicesDescription>Headspace provides information and support, headspace provides free online counselling to young people.</StyledSupportServicesDescription>
    <StyledSupportServicesLink onClick={() => Native.requestInAppBrowserWindow('https://headspace.org.au')}>headspace.org.au</StyledSupportServicesLink>
    <StyledSupportServicesIconContainer><Help /></StyledSupportServicesIconContainer>
    <StyledSupportServicesSubHeading text={'Lifeline'} />
    <StyledSupportServicesDescription>Lifeline provides free 24/7 crisis support and suicide prevention services to any young Australian experiencing emotional distress.</StyledSupportServicesDescription>
    <StyledSupportServicesLink onClick={() => Native.requestInAppBrowserWindow('https://lifeline.org.au')}>lifeline.org.au</StyledSupportServicesLink>
    <StyledSupportServicesNumber>131114</StyledSupportServicesNumber>
    <StyledSupportServicesButton label='close' onClick={() => goBack()} />
  </StyledSupportServicesContainer>
}