import styled from 'styled-components';
import { PagePanel } from '../../Page/Page';
import { VideoOverlay } from '../VideoOverlay';
import { IStyledProps } from 'theme';

interface IStyledVideoOverlayStrategyExercise extends IStyledProps {
  isScrollDisabled?: boolean
  isAutoHeight?: boolean
  isNonTransparentBg?: boolean
}

export const StyledVideoOverlayStrategyExercise = styled(VideoOverlay)`
  background: ${props => props.theme.colors.defaultBackground};
  min-height: 100%;

  /* Override height when necessary to enable window.scrollTo functionality */
  /* Originially property wasn't set so its default value was inherit */
  height: ${(props) => props.isAutoHeight ? 'auto' : 'inherit'};
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: ${(props: IStyledVideoOverlayStrategyExercise) => props.isScrollDisabled
    ? 'hidden'
    : 'scroll'};
`;
//The isScrollDisabled above is currently only used for the swipe card exercise
//As you drag the card around we don't want the overflow to cause scrollbars

//The chapter content container has the property space-between
// so that CTA buttons can be forced to the bottom of the responsive height device.
// If you need to remove the spacing between elements, group them in a div
export const StyledChapterContent = styled(PagePanel)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;