import React from 'react';
import { FormLogo } from '../Forms/FormLogo/FormLogo';
import { QuizConsentOverlayContainer, StyledButton, StyledDescription, StyledReject, StyledSection } from './QuizConsentPopup.styled';
import PlayerData from '../../services/PlayerData';
interface IQuizConsentOverlay {
    onComplete: ()=>void;
  }
  
export const QuizConsentPopup: React.FC<IQuizConsentOverlay> = ({ onComplete }) => {

    const updateQuizConsent = (skip: boolean) => {
        PlayerData.setQuizConsent(skip);
        if (onComplete) onComplete();
      }
      
    return (
        <QuizConsentOverlayContainer>
            <FormLogo />
            <StyledDescription>
                <p>
                Next, we’d like to ask you some survey questions about your current levels of resilience. 
                </p>
                <p>
                Your parent/guardian must have consented for you to do this prior to the workshop. If you're not sure, check in with the facilitator.
                </p>
            </StyledDescription>
            <StyledSection>
                <StyledButton onClick={() => updateQuizConsent(true)} label='Yes, I have their consent' /> 
                <StyledReject onClick={() => updateQuizConsent(false)} >I don’t have their consent</StyledReject>
            </StyledSection>
        </QuizConsentOverlayContainer>
    )
}