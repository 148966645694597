import React from 'react';
import { StyledButton, StyledHeadingTitle, StyledHeadingTitleContainer, StyledSubtitle, StyledStopBanner, StyledStopBannerContainer } from './VideoOverlayStopBanner.styled';

interface IVideoOverlayStopBannerProps {
  onComplete: () => void
  subtitle: string
}

export const VideoOverlayStopBanner: React.FC<IVideoOverlayStopBannerProps> = ({ onComplete, subtitle }) => {
  return <StyledStopBannerContainer>
        <StyledStopBanner>
          <StyledHeadingTitleContainer>
            <StyledHeadingTitle primary level="h1" text='STOP'></StyledHeadingTitle>
          </StyledHeadingTitleContainer>
          <StyledSubtitle text={subtitle}></StyledSubtitle>
        </StyledStopBanner>
        <StyledButton primary onClick={onComplete} label="Got it" />
    </StyledStopBannerContainer>;
}