import React, { useState, useEffect, useRef, PropsWithChildren } from 'react';
import Player, { TimeEvent, VimeoTextTrack } from '@vimeo/player';
import { useTranslation } from 'react-i18next';
import PlayerData from '../../services/PlayerData';
import { bindViewportListeners } from '../../utils/helper';

import {
  StyledVideoContainer,
  StyledControlsContainer,
  StyledControlContainer,
  StyledControls,
  StyledRightControls,
  StyledMainContainer,
  StyledCaptionsMenu,
  StyledCaptionsOption,
  StyledCCIcon,
  StyledVideoClickCapture,
} from './VideoPlayer.styled';

import { ReactComponent as PauseIcon } from './icons/pause.svg';
import { ReactComponent as PlayIcon } from './icons/play.svg';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { VideoOverlay } from '../VideoOverlay/VideoOverlay';
import { IVideoMoment } from '../../models/IVideoMoment';
import Environment, { ENVIRONMENT_TYPE } from '../../services/Environment';

type IVideoPlayerProps = PropsWithChildren<{
  videoId: string | null;
  isForwardSeekingDisabled?: boolean;
  autoplay?: boolean;
  onTimeUpdate: (currentSeconds: number, durationSeconds: number) => void;
  onReady?: (playerPlay: () => void, playerPause: () => void, playerSeek: (seconds: number) => void) => void;
  onEnded?: () => void;
  videoMoments?: IVideoMoment[];
}>;

const VIDEO_ASPECT_RATIO = [76, 135];

export const VideoPlayer: React.FC<IVideoPlayerProps> = ({ videoId, autoplay = true, children, onTimeUpdate, isForwardSeekingDisabled, onEnded, onReady, videoMoments }) => {
  const CONTAINER_REF = useRef<HTMLDivElement>(null);
  const player = useRef<Player>();
  const furthestTimeWatched = useRef(0);
  const iframe = useRef<HTMLIFrameElement>(null);
  let duration = 0;

  //State
  const [volume, setVolume] = useState(1);
  const [playerWidth, setWidth] = useState(0);
  const [isSkipping, setIsSkipping] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Set the default value as true, to avoid interactions before the video's loaded

  const [isPlayerInitialised, setIsPlayerInitialised] = useState(false);
  const [hasStartedPlaying, setHasStartedPlaying] = useState(autoplay);
  
    //NOTE: these are for this component's CAPTION SELECTOR only - the vimeoplayer itself is what dictates options + current selection (see setCurrentCaptionsTrack for info)
    const [captionsTracks, setCaptionsTracks] = useState<VimeoTextTrack[]>([]); 
    const [isCaptionsMenuVisible, setIsCaptionsMenuVisible] = useState(false);

  const playerPlay = () => {
    if (player.current) {
      return player.current.play().then((r) => {
        return r;
      });
    }
    return Promise.resolve();
  };

  const playerPause = () => {
    if (player.current) {
      return player.current.pause().then((r) => {
        return r;
      });
    }
    return Promise.resolve();
  };

  const playerSeek = async (seconds: number) => {
    if (player.current) {
      const r = await player.current.setCurrentTime(seconds);
      return r;
    }
    return Promise.resolve();
  };
  const playerSetVolume = async (newVolume: number) => {
    if (player) {
      if (newVolume < 0) newVolume = 0;
      if (newVolume > 1) newVolume = 1;
      player.current && (await player.current.setVolume(newVolume));
      PlayerData.setVideoVolumePreference(newVolume);
    }
  };
  
  //Functions that interact with the vimeo player, then subsquently the state
  const playerTogglePlaying = (event?: React.MouseEvent<HTMLDivElement>) => {
    if (event) event.stopPropagation();

    //Toggleplaying checks the player's state directly and then either plays or pauses
    if (!player.current) return;

    player.current
      .getPaused()
      .then(async (isPaused: boolean) => {
        if (isPaused) {
          return playerPlay();
        } else {
          return playerPause();
        }
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e));
  };

  const setCurrentCaptionsTrack = async (trackLanguageCode: string) => {
    if (player.current) {
      await player.current.getTextTracks().then(async (tracks: VimeoTextTrack[]) => {
        const track = tracks.find((tt) => tt.language === trackLanguageCode) || '';

        if (track !== PlayerData.getVideoCaptionsLanguagePreference()) {
          PlayerData.setVideoCaptionsLanguagePreference(track ? track.language : '');
        }

        if (player.current) {
          if (track) {
            await player.current.enableTextTrack(track.language);
          } else {
            await player.current.disableTextTrack();
          }
        }

        setIsCaptionsMenuVisible(false);
        setCaptionsTracks(tracks || []); //we have to update the tracks options for the UI selector
      });
    }
  };

  const onSkip = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    const SKIP_TO_TIME_DELAY_S = 0.5;

    if (!player.current) return;
    event.stopPropagation();
    const currentTime = await player.current.getCurrentTime();
    const theDuration = await player.current.getDuration();
    const nextVideoMoment = videoMoments?.find((videoMoment) => (videoMoment.seconds ?? -1) >= currentTime);

    setIsSkipping(true);

    // if there's no more video moments, skip to the end
    const skipToTime: number = (nextVideoMoment?.seconds || theDuration) - SKIP_TO_TIME_DELAY_S;

    // eslint-disable-next-line no-console
    console.log('jump to time: ', skipToTime);

    await player.current.setCurrentTime(skipToTime);
    setTimeout(() => {
      setIsSkipping(false);
    }, SKIP_TO_TIME_DELAY_S * 4);
  };

  //Vimeo player listeners/handlers (starting with _)
  //These can update state but they CANNOT USE/READ STATE. They will be stuck with initial values set at the top of the file.
  const _handleResize = () => {
    if (CONTAINER_REF && CONTAINER_REF.current) {
      setWidth((CONTAINER_REF.current.getBoundingClientRect().height / VIDEO_ASPECT_RATIO[0]) * VIDEO_ASPECT_RATIO[1]);
    }
  };
  const _handlePaused = () => {
    setIsPlaying(false);
  };
  const _handlePlaying = () => {
    setIsPlaying(true);
    setIsLoading(false);
  };
  const _handleVolumeChange = async () => {
    player.current &&
      await player.current.getVolume().then((newVolume: number) => {
        setVolume(newVolume);
      });
  };
  const _handleVideoEnd = () => {
    if (onEnded) onEnded();
  };
  const _handleLoadStart = () => {
    setIsLoading(true);
  };
  const _handleLoadedData = () => {
    setIsLoading(false);
  };
  const _handlePlay = (data: TimeEvent) => {
    // This event fires when the video plays.
    if (data.seconds === 0) setHasStartedPlaying(true);
  };

  const initialisePlayer = async () => {
    if (isPlayerInitialised) return;

    // const iframe = document.getElementById('player-iframe');
    if (!iframe.current) return;

    player.current = new Player(iframe.current);
    if (!player.current) return;

    const _handleTimeUpdate = (data: TimeEvent) => {
      const currentSeconds = parseFloat(`${data.seconds}`);

      if (currentSeconds - 1 <= furthestTimeWatched.current && currentSeconds >= furthestTimeWatched.current) {
        furthestTimeWatched.current = currentSeconds;
      }

      onTimeUpdate && onTimeUpdate(currentSeconds, duration);
    };

    const _handleSeeked = async (data: TimeEvent): Promise<void> => {
      if (!player.current) return;
      const secondsAsInt = parseInt(`${data.seconds}`);

      // If forward seeking is disabled, snap back to the last TIME_WATCHED (progress that they hit naturally)
      if (isForwardSeekingDisabled && secondsAsInt >= furthestTimeWatched.current) {
        await player.current.setCurrentTime(furthestTimeWatched.current - 1 > 0 ? furthestTimeWatched.current - 1 : 0);
      }
    };

    // PLAYBACK EVENTS
    player.current.on('pause', _handlePaused);
    player.current.on('playing', _handlePlaying);
    player.current.on('ended', _handleVideoEnd);
    player.current.on('volumechange', _handleVolumeChange);
    player.current.on('loadeddata', _handleLoadedData);
    player.current.on('loadstart', _handleLoadStart);
    player.current.on('timeupdate', _handleTimeUpdate);
    player.current.on('seeked', _handleSeeked);
    player.current.on('play', _handlePlay);

    //Volume setup
    const initialVolumePref = PlayerData.getVideoVolumePreference();
    const initialVolumePrefNum = initialVolumePref !== null && !isNaN(Number(initialVolumePref)) ? Number(initialVolumePref) : 1;
    await playerSetVolume(initialVolumePrefNum);

    //Add viewport listeners to handle changing size of player
    bindViewportListeners(_handleResize, true);

    //Get the duration so we can intercept it with video moments
    duration = await player.current.getDuration();

    // Set the captions track based of player's existing preference, if any
    void setCurrentCaptionsTrack(PlayerData.getVideoCaptionsLanguagePreference());
    
    setIsPlayerInitialised(true);

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onReady && onReady(playerPlay, playerPause, playerSeek);
  };

  useEffect(() => {
    initialisePlayer().catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e);
    });
  }, []);

  const renderPlayPauseIcons = () => {
    return (
      <StyledControlContainer
        onClick={playerTogglePlaying}
        className="playIcon"
      >
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </StyledControlContainer>
    );
  };

  const { t } = useTranslation();

  const showControls = !!(player && !isPlaying);
  const currentCaptionsTrack = captionsTracks && captionsTracks.length > 0 && captionsTracks.find((captionsTrack) => captionsTrack.mode === 'showing');

  return (
    <StyledMainContainer ref={CONTAINER_REF}>
      <StyledVideoContainer
        className="video-container"
        showControls={showControls}
        playerWidth={playerWidth}
        aspectRatio={VIDEO_ASPECT_RATIO}
        hasStartedPlaying={hasStartedPlaying} // hide the video iframe until it's started playing
        onClick={(e) => {
          !isLoading && playerTogglePlaying(e);
        }}
      >
        <iframe
          ref={iframe}
          title={`Vimeo video ${videoId ?? ''}`}
          key={videoId}
          id="player-iframe"
          src={`https://player.vimeo.com/video/${videoId ?? ''}?playsinline=1&&controls=0&autoplay=${autoplay.valueOf().toString()}`}
          frameBorder="0"
          allow="autoplay;"
        ></iframe>

        {/* We'll always show children if there are any, otherwise show the controls if true */}
        <VideoOverlay>
          {children ||
            (isLoading && <LoadingSpinner backgroundColour="black" />) ||
            (showControls && (
              <StyledControlsContainer>
                {renderPlayPauseIcons()}
                <StyledControls>
                  <StyledRightControls onClick={(e) => e.stopPropagation()}>
                    {captionsTracks && captionsTracks.length > 0 && (
                      <StyledControlContainer>
                        {isCaptionsMenuVisible && (
                          <StyledCaptionsMenu>
                            <StyledCaptionsOption
                              isSelected={!currentCaptionsTrack}
                              onClick={() => void setCurrentCaptionsTrack('')}
                            >
                              {t('video.none')}
                            </StyledCaptionsOption>
                            {captionsTracks.map((captionsTrack, idx) => (
                              <StyledCaptionsOption
                                key={idx}
                                isSelected={captionsTrack.mode === 'showing'}
                                onClick={() => void setCurrentCaptionsTrack(captionsTrack.language)}
                              >
                                {captionsTrack.label}
                              </StyledCaptionsOption>
                            ))}
                          </StyledCaptionsMenu>
                        )}
                        <StyledControlContainer onClick={() => setIsCaptionsMenuVisible(!isCaptionsMenuVisible)}>
                          <StyledCCIcon isSelected={!!currentCaptionsTrack}>{t('video.cc')}</StyledCCIcon>
                        </StyledControlContainer>
                      </StyledControlContainer>
                    )}
                  </StyledRightControls>
                </StyledControls>
              </StyledControlsContainer>
            )) || (
              /* This div below captures clicks instead of player */
              <StyledVideoClickCapture onClick={playerTogglePlaying} />
            )}
          {(!Environment.isEnvironment(ENVIRONMENT_TYPE.PROD) && !children && !isSkipping) && 
            <button
              style={{ zIndex: 999, position: 'absolute', right: 0 }}
              onClick={(event) => void onSkip(event)}
            >
              Skip
            </button>
          }
        </VideoOverlay>
      </StyledVideoContainer>
    </StyledMainContainer>
  );
};
