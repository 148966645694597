/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { REACT_APP_GTM_ID, REACT_APP_GTM_ID_GA4 } from '../constants';
import { ThemeDefault } from '../theme';
import { IChapter } from '../models/IChapter';
import PlayerData from './PlayerData';
import Environment from './Environment';
import CohortData from './CohortData';

declare global {
  interface Window {
    dataLayer: any[];
    dataLayer_GA4: any[];
  }
}

export interface IDataLayerChapter {
  /** chapterIdentifier, Like 'managing-your-thoughts'*/
  id: string,
  title: string,
  ordinal: number,
  startTime: number
}

enum GA4EventsEnum {
  pageView = 'page_view',
  chapterStart = 'chapter_start',
  clickCTA = 'click_cta',
  quizAnswer = 'quiz_answer',
  quizStart = 'quiz_start',
  quizComplete = 'quiz_complete',
  chapterComplete = 'chapter_complete',
  completeForm = 'complete_form',
  startForm = 'start_form',
  progressForm = 'progress_form',
  submitForm = 'submit_form',
  errorField = 'error_field',
  enterFieldForm = 'enter_field_form',
}

export enum GA4ModuleCategoryEnum {
  AOTG = 'ATOG',
}

export enum GA4ModuleTypeEnum {
  chapter = 'chapter',
  plan = 'plan',
}

export enum GA4FormNameEnum {
  signup = 'Account Sign Up',
}

interface IModule {
  module_category: GA4ModuleCategoryEnum;
  module_id: string;
  module_name: string;
  module_subcategory: string;
  module_type: GA4ModuleTypeEnum;
}

interface IForm {
  form_id?: string;
  form_name: string;
  location?: string;
  reference_id?: string;
  step_label: string;
  step_number: number;
}

interface IFieldForm extends Omit<IForm, 'reference_id'> {
  field_id: string;
}

export interface GTM4Events {
  pageView: {
    current_uri: string;
    last_uri: string;
    page_title: string;
    word_count?: number;
  },
  chapterStart: {
    module: IModule,
  },
  clickCTA: {
    link_classes?: string;
    link_text: string;
    link_url: string;
    location: string;
  },
  quizAnswer: {
    answer: {
      answer_correct: boolean;
      answer_id: string;
      answer_text: string;
    },
    module: IModule,
    question: {
      question_id: string;
      question_number: string;
      question_section: string;
      question_text: string;
      user_id: string;
    }
  },
  quizStart: {
    module: IModule,
  },
  quizComplete: {
    module: IModule & { module_all_correct: string, module_step_count: string },
  }
  chapterComplete: {
    module: IModule,
  },
  startForm: IForm,
  progressForm: IForm,
  submitForm: IForm,
  completeForm: IForm,
  errorField: IFieldForm & {
    message: string;
  },
  enterFieldForm: IFieldForm,
}

const EVENT_CATEGORY = 'aotgthechallenge'; //change if needed

let isDataLayerInitialised = false;

//Used to reference active chapter over subsequent events
let activeChapter: IDataLayerChapter | null = null;
let activeChapterVideoTime: number | null = null;
let previousUrl = '';

export default {
  init() {
    if (isDataLayerInitialised) return; //Already initialised

    // TODO: we init this before the theme changed/ user login, do we still need to change it?
    const breakpoints = ThemeDefault.breakpoints;
    const viewportWidth = document.documentElement.clientWidth;
    const uiExperience =
      (viewportWidth <= +breakpoints.xs.replace('px', '') && 'mobile') ||
      (viewportWidth <= +breakpoints.sm.replace('px', '') && 'tablet') ||
      (viewportWidth <= +breakpoints.md.replace('px', '') && 'small desktop') ||
      (viewportWidth <= +breakpoints.lg.replace('px', '') && 'large desktop') ||
      (viewportWidth <= +breakpoints.xl.replace('px', '') && 'extra large desktop') ||
      undefined;

    const initialGtmData = [
      {
        content: {
          environment: process.env.REACT_APP_ENVIRONMENT,
          version: '1.0.0',
          uiExperience,
        },
      },
    ];
    window.dataLayer = initialGtmData;
    window.dataLayer_GA4 = initialGtmData;

    // run google tag manager (GTM) snippet - must occur after the above
    (function setDataLayerSnippet(w: any, d: any, s: any, l: any, i: any) {
      w[l] = w[l] || []; // eslint-disable-line no-param-reassign
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l !== 'dataLayer' ? `&l=${l}` : '';
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      f.parentNode.insertBefore(j, f);
    }(window, document, 'script', 'dataLayer', REACT_APP_GTM_ID));

    (function setDataLayerSnippet(w: any, d: any, s: any, l: any, i: any) {
      w[l] = w[l] || []; // eslint-disable-line no-param-reassign
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l !== 'dataLayer' ? `&l=${l}` : '';
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      f.parentNode.insertBefore(j, f);
    }(window, document, 'script', 'dataLayer_GA4', REACT_APP_GTM_ID_GA4));

    isDataLayerInitialised = true;
  },

  push(data: any, isGA4 = false) {
    this.init();
    if (!isDataLayerInitialised) return;

    if (!isGA4) {
      data.category = EVENT_CATEGORY; // GA4 events don't require top-level category field
      window.dataLayer.push(data);
    } else {
      window.dataLayer_GA4.push(data);
    }
    if (Environment.getCurrentEnvironment().isForDebugging) console.log(data);
  },

  pushRouteChange(to: string, from: string) {
    //The title is the last portion of the route (eg. managing-thoughts, dashboard, get-help)
    let title = to.substring(to.lastIndexOf('/') + 1);
    if (!title) title = 'root';

    this.push({
      event: 'custom.route.change',
      route: {
        current: to,
        previous: from,
        title: title,
      },
    });
  },

  pushChapterStart(chapter: IChapter) {
    const now = new Date().getTime();

    activeChapter = {
      id: chapter.chapterIdentifier,
      title: chapter.title,
      ordinal: chapter.ordinal,
      startTime: now,
    };
    if (!PlayerData.hasStartedChapter(chapter.chapterIdentifier)) {
      this.push({
        event: 'custom.chapter.start',
        chapter: activeChapter,
      })
    }
  },

  pushChapterVideoTimeUpdate(currentSeconds: number) {
    activeChapterVideoTime = currentSeconds;
    //This one does not actually fire an event (might be a bit intense/frequent)
    //But we do need to store the video time for other events to reference
  },

  pushChapterVideoMoment(momentType: string) {
    this.push({
      event: 'custom.chapter.moment',
      chapter: activeChapter,
      moment: {
        type: momentType,
        chapterVideoTime: activeChapterVideoTime,
      },
    })
  },

  pushChapterEnd() {
    if (activeChapter && !PlayerData.hasCompletedChapter(activeChapter.id)) {
      this.push({
        event: 'custom.chapter.end',
        chapter: activeChapter,
      });
    }

    //Clear the active chapter variables
    activeChapter = null;
    activeChapterVideoTime = null;
  },

  pushStickerSelected(prompt: string, stickerLabel: string) {
    this.push({
      event: 'custom.chapter.stickerselected',
      chapter: activeChapter,
      sticker: {
        prompt: prompt,
        label: stickerLabel,
        chapterVideoTime: activeChapterVideoTime,
      },
    })
  },

  pushStickersRemoved() {
    this.push({
      event: 'custom.chapter.stickersremoved',
      chapter: activeChapter,
    })
  },

  pushStrategyChooseCardSwiped(cardText: string, isCorrect: boolean, swipeValue: boolean) {
    this.push({
      event: 'custom.chapter.strategychoose.cardswiped',
      chapter: activeChapter,
      card: {
        text: cardText,
        correct: isCorrect,
        wasUserCorrect: swipeValue === isCorrect,
      },
    })
  },

  pushStrategyPlanSelected(planText: string) {
    this.push({
      event: 'custom.chapter.strategyplan.planselected',
      chapter: activeChapter,
      plan: {
        text: planText,
      },
    })
  },

  pushStrategyPlanQuestionAnswered(planText: string, questionText: string, questionCorrect: boolean, answeredCorrectly: boolean) {
    this.push({
      event: 'custom.chapter.strategyplan.questionanswered',
      chapter: activeChapter,
      plan: {
        text: planText,
      },
      question: {
        text: questionText,
        correct: questionCorrect,
        wasUserCorrect: answeredCorrectly,
      },
    })
  },

  pushStrategyPlanWinningSelected(planText: string) {
    this.push({
      event: 'custom.chapter.strategyplan.selectedwinner',
      chapter: activeChapter,
      plan: {
        text: planText,
      },
    })
  },

  pushStrategyStrengthCardSelected(cardText: string, isCustom: boolean) {
    this.push({
      event: 'custom.chapter.strategystrength.cardselected',
      chapter: activeChapter,
      card: {
        text: cardText,
        isCustom: isCustom,
      },
    })
  },

  pushStrategyStrengthQuestionAnswered(cardText: string, questionText: string, answer: boolean) {
    this.push({
      event: 'custom.chapter.strategystrength.questionanswered',
      chapter: activeChapter,
      card: {
        text: cardText,
      },
      question: {
        text: questionText,
        answer: answer, //?
      },
    })
  },

  pushStrategyControlCardSelected(cardText: string, isSelectingImmediates: boolean, isCorrect: boolean) {
    this.push({
      event: 'custom.chapter.strategycontrol.cardselected',
      chapter: activeChapter,
      card: {
        text: cardText,
        isSelectingImmediates,
        correct: isCorrect,
      },
    })
  },

  pushStrategyBreatheStart() {
    this.push({
      event: 'custom.chapter.strategybreathe.start',
      chapter: activeChapter,
    });
  },

  pushStrategyBreatheEnd() {
    this.push({
      event: 'custom.chapter.strategybreathe.end',
      chapter: activeChapter,
    });
  },

  pushSignUpComplete(isOverLegalAge: boolean, teamCode: string, team: string) {
    this.push({
      event: 'custom.form.complete',
      group: 'account sign up',
      label: 'complete',
      data: {
        age: isOverLegalAge ? 'over-16' : 'under-16', // over or under 16
        teamCode, // e.g. LEGO (only capture if this is not considered sensitive information)
        team,
      },
    });
  },

  pushSignUpFormError(error: string) {
    this.push({
      event: 'custom.form.error.view',
      group: 'account sign up',
      label: undefined,
      data: {
        error: error, // e.g. 'The user already exists'
      },
    });
  },

  pushRouteChange_GA4(route: GTM4Events['pageView']) {

    this.push({
      event: GA4EventsEnum.pageView,
      ...route,
      page_title: route.page_title || 'root',
    }, true);
  },

  pushQuizStart_GA4(quizStart: GTM4Events['quizStart']) {
    this.push({
      event: GA4EventsEnum.quizStart,
      ...quizStart,
    }, true);
  },

  pushClickCTA_GA4(clickCTA: GTM4Events['clickCTA']) {
    this.push({
      event: GA4EventsEnum.clickCTA,
      ...clickCTA,
    }, true);
  },

  pushStartForm_GA4(form: GTM4Events['startForm']) {
    this.push({
      event: GA4EventsEnum.startForm,
      ...form,
    }, true);
  },

  pushProgressForm_GA4(form: GTM4Events['progressForm']) {
    this.push({
      event: GA4EventsEnum.progressForm,
      ...form,
    }, true);
  },

  pushSubmitForm_GA4(form: GTM4Events['submitForm']) {
    this.push({
      event: GA4EventsEnum.submitForm,
      ...form,
    }, true);
  },

  pushQuizComplete_GA4(quiz: GTM4Events['quizComplete']) {
    this.push({
      event: GA4EventsEnum.quizComplete,
      module: quiz,
    }, true);
  },

  pushQuizAnswer_GA4(quizAnswer: GTM4Events['quizAnswer']) {
    this.push({
      event: GA4EventsEnum.quizAnswer,
      module: quizAnswer.module,
      question: quizAnswer.question,
      answer: quizAnswer.answer,
      // user_id: Auth.getUserId() // Check this later
    }, true);
  },

  pushChapterStart_GA4(module: GTM4Events['chapterStart']) {
    const now = new Date().getTime();

    activeChapter = {
      id: module.module.module_id,
      title: module.module.module_name,
      ordinal: +module.module.module_subcategory,
      startTime: now,
    };
    this.push({
      event: GA4EventsEnum.chapterStart,
      ...module,
    })
  },

  pushChapterComplete_GA4(module: GTM4Events['chapterComplete']) {
    this.push({
      event: GA4EventsEnum.chapterComplete,
      ...module,
    })
  },

  pushErrorField_GA4(form: GTM4Events['errorField']) {
    this.push({
      event: GA4EventsEnum.errorField,
      ...form,
    });
  },

  // may not use this one for now
  // pushEnterFieldForm_GA4(form: GTM4Events['enterFieldForm']) {
  //   this.push({
  //     event: GA4EventsEnum.enterFieldForm,
  //     ...form,
  //   });
  // },

  getActiveChapter() {
    return activeChapter;
  },

  //new GA4 active chapter
  setActiveChapter(newActiveChapter: IDataLayerChapter): void {
    activeChapter = {
      ...newActiveChapter,
    }
  },

  getPreviousUrl() {
    return previousUrl;
  },

  setPreviousUrl(newUrl: string) {
    previousUrl = newUrl;
  },

};