import React, { useRef, useState, useEffect } from 'react';
import { StyledWelcomeScreenContainer, StyledWelcomeScreenInner, StyledWelcomeScreenButton, StyledLogoWrapper, StyledWelcomeTextWrapper, StyledWelcomeBodyText, StyledWelcomeHeaderText, StyledWelcomeScreenVideoContainer } from './WelcomeScreen.styled';
import { AppLogo } from '../AppLogo/AppLogo';
import Content from '../../services/Content';
import { useTransition } from '../Transition/TransitionContext/TransitionContext';
import { TRANSITION_TYPES } from '../../constants';

interface IWelcomeScreenProps {
  onComplete: () => void;
}

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

// have to make the welcome screen fade in. 
export const WelcomeScreen: React.FC<IWelcomeScreenProps> = ({ onComplete }) => {
  const [showFirstScreen, setShowFirstScreen] = useState<boolean>(false);
  const [showSecondScreen, setShowSecondScreen] = useState<boolean>(false);

  const backgroundVideo = Content.getWelcomeScreenBackgroundVideo();

  const transition = useTransition();

  useEffect(() => {
    setTimeout(() => {
      setShowFirstScreen(true);
    }, 1250);
  }, [])

  const handleClick = async () => {
    setShowFirstScreen(false);
    await delay(1000);
    setShowSecondScreen(true);
    await delay(2600);
    transition.setTransition(TRANSITION_TYPES.PhonesDownEntry);
    await delay(2200);
    onComplete();
  };
  
  return <StyledWelcomeScreenContainer key="welcomeScreen">
    <StyledWelcomeScreenVideoContainer>
      <video autoPlay muted playsInline loop 
        preload="auto"
        poster="/videos/welcomeScreen/poster.webp"
        onCanPlay={(event) => { 
          if (!event.currentTarget) return; 
            event.currentTarget.playbackRate = 0.45; 
          }
        }>

        <source src={backgroundVideo} type="video/mp4" />
      </video>
    </StyledWelcomeScreenVideoContainer>
    <StyledWelcomeScreenInner opaque={showFirstScreen}>
      <StyledWelcomeTextWrapper>
        <StyledWelcomeHeaderText>Game On</StyledWelcomeHeaderText>
        <StyledWelcomeBodyText>You’re all set up and <br /> ready to play</StyledWelcomeBodyText>
        <StyledWelcomeBodyText>Get ready to lift your game <br /> ON and OFF the field.</StyledWelcomeBodyText>
      </StyledWelcomeTextWrapper>
      <StyledWelcomeScreenButton label="Let's go!" onClick={handleClick} primary />
    </StyledWelcomeScreenInner>

    <StyledWelcomeScreenInner opaque={showSecondScreen}>
      <StyledLogoWrapper><AppLogo isLight={true} isSportLockup={true} /></StyledLogoWrapper>
    </StyledWelcomeScreenInner>
  </StyledWelcomeScreenContainer>;
}