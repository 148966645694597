
import React, { useEffect, useState } from 'react';
import {
  StyledStrengthCard,
  StyledStrengthSelector,
  StyledCardsContainer,
  StyledCustomCardOverlay,
  StyledStrengthsSummary,
  StyledAdvancedCardIcon,
  StyledAdvancedCardHeading,
  StyledExpandedAdvancedCard,
  StyledEmptyText,
  StyledNextButton,

  StyledCustomCardContainer,
  StyledCustomStrengthCard,
  StyledCustomCardButtons,

  StyledResizableTextarea,
  StyledInstruction,
  StyledAddYourOwn,
  StyledContinueButton,
  StyledStrengthContainer,
} from './StrategyExerciseStrength.styled';

import { Heading } from '../../Heading/Heading';
import { AdvancedCard } from '../../AdvancedCard/AdvancedCard';
import { QuestionList } from '../../QuestionList/QuestionList';
import { SvgIcon } from '../../SvgIcon/SvgIcon';
import { CardList } from '../../CardList/CardList';
import { Button } from '../../Button/Button';
import { ChapterInstruction } from '../../ChapterInstruction/ChapterInstruction';
import { ICard } from '../../../models/ICard';
import DataLayer, { GA4ModuleCategoryEnum, GA4ModuleTypeEnum, GTM4Events } from '../../../services/DataLayer';
import Content from '../../../services/Content';
import { IQuestion } from '../../../models/IQuestion';
import { IExerciseStrength, IStrengthPromptSet, IStrengthPrompt } from '../../../models/exercises/IExerciseStrength';
import PlayerData from '../../../services/PlayerData';
import { IStrengthCard } from '../../../models/IStrengthCard';
import { StyledStrategyExercisesButton } from '../StrategyExercises.styled';

interface IStrategyExerciseStrengthProps {
  strategyExerciseStrength: IExerciseStrength;
  onComplete: () => void;
  scrollTopFn: (delayMs?: number) => void;
}

export const StrategyExerciseStrength: React.FC<IStrategyExerciseStrengthProps> = ({ strategyExerciseStrength, onComplete, scrollTopFn }) => {
  const [exercise, setExercise] = useState(strategyExerciseStrength);

  const [clickedCard, setClickedCard] = useState<ICard | null>(null);
  const [isMakingCustomCard, setIsMakingCustomCard] = useState(false);
  const [customCardText, setCustomCardText] = useState('');
  const [personalFeedback, setPersonalFeedback] = useState<string[]>([])

  const [currentPromptSetIdx, setCurrentPromptSetIdx] = useState(0);
  const currentPromptSet: IStrengthPromptSet = exercise.promptSets[currentPromptSetIdx];

  //Active prompt is the one without a selected card OR not completed yet
  const activePrompt = currentPromptSet.prompts.find(prompt => !prompt.selectedCard || !prompt.isCompleted);

  useEffect(() => {
    scrollTopFn();
  }, [activePrompt, activePrompt?.selectedCard])

  const activeChapter = DataLayer.getActiveChapter();

  const updateActivePrompt = (newActivePrompt: IStrengthPrompt) => {
    setExercise({
      ...exercise,
      promptSets: exercise.promptSets.map((promptSet, idx) => {
        if (currentPromptSet === promptSet) {
          promptSet.prompts = promptSet.prompts.map(prompt => {
            return activePrompt === prompt ? newActivePrompt : prompt;
          })
        }
        return promptSet;
      }),
    })
  };

  if (activePrompt && !activePrompt.selectedCard) {
    //1. If we have an activePrompt but no selectedCard for it, show it with its cards

    const onCardSelected = (card: IStrengthCard) => {
      // using this instead of a lock, so to not add in an extra 
      // re-render on an already re-render prone screen

      // get how many prompts we should have answered
      const expectedprompts = exercise.promptSets.reduce((expectedSum, promptSet, index) => {
        if (currentPromptSetIdx < index) return expectedSum;

        return expectedSum + promptSet.prompts.reduce((sum, prompt) => {
          return sum + (!!prompt.selectedCard ? 1 : 0);
        }, 0);
      }, 0);

      // if we have the expected amount of prompts, ignore
      if (personalFeedback.length > expectedprompts) return;

      DataLayer.pushStrategyStrengthCardSelected(card.text, isMakingCustomCard);

      if (activeChapter) {
        const customEvent: GTM4Events['clickCTA'] = {
          link_text: card.text,
          link_url: '',
          location: activeChapter.id,
        };
        DataLayer.pushClickCTA_GA4(customEvent);
      }

      setPersonalFeedback(x => [...x, card.slug]);

      const update = () => updateActivePrompt({ ...activePrompt, selectedCard: card });

      if (isMakingCustomCard) {
        setIsMakingCustomCard(false);
        update();
      } else {
        setClickedCard(card);
        setTimeout(update, 500)
      }
    }

    return <StyledStrengthSelector>
      {isMakingCustomCard &&
        <StyledCustomCardOverlay>
          <ChapterInstruction text={activePrompt.instructionCustom} />
          <StyledCustomCardContainer>
            <StyledCustomStrengthCard
              upperContent={<SvgIcon cmsSrc={'images/icons/strength/custom'} />}
              lowerContent={<StyledResizableTextarea
                isAutoFocus={true}
                onChange={(newValue: string) => setCustomCardText(newValue)}
                minRows={1}
                maxRows={2}
                maxLength={15}
                placeholder={''}
              />}
            />
            <StyledCustomCardButtons>
              <StyledStrategyExercisesButton isDisabled={!customCardText} onClick={() => onCardSelected({
                icon: 'images/icons/strength/custom',
                text: customCardText,
                slug: '',
              })} label="Next" />
              <StyledStrategyExercisesButton onClick={() => setIsMakingCustomCard(false)} label="Back" hollow={true} />
            </StyledCustomCardButtons>
          </StyledCustomCardContainer>
        </StyledCustomCardOverlay>}
      {!isMakingCustomCard && <StyledCardsContainer>
        <StyledInstruction key="cards" text={activePrompt.instruction} />
        <CardList cardComponents={Content.getStrengthCards(activePrompt.strengthCardsType).map(card =>
          <StyledStrengthCard
            key={card.slug}
            isActive={clickedCard === card}
            isDisabled={!!personalFeedback.find((feedback) => feedback === card.slug)}
            onClick={() => onCardSelected(card)}
            upperContent={<SvgIcon cmsSrc={card.icon} />}
            lowerContent={<Heading level="h4" text={card.text} />}
          />,
        )}
        />
        <StyledAddYourOwn onClick={() => setIsMakingCustomCard(true)}>
          <Heading primary level="h4" text="+" />
          <Heading primary level="h5" text="Add your own" />
        </StyledAddYourOwn>
      </StyledCardsContainer>}
    </StyledStrengthSelector>

  } else if (activePrompt && activePrompt.selectedCard) {
    //2. If a card has been selected/entered for the activePrompt, show the advanced card with Q+A for it

    const onQuestionAnswered = (answeredQuestion: IQuestion, points: number) => {
      DataLayer.pushStrategyStrengthQuestionAnswered(activePrompt.selectedCard.text, answeredQuestion.text, points > 0);

      if (activeChapter) {
        const customEvent: GTM4Events['quizAnswer'] = {
          answer: {
            answer_correct: true,
            answer_id: '',
            answer_text: points > 0 ? 'true' : 'false',
          },
          module: {
            module_category: GA4ModuleCategoryEnum.AOTG,
            module_id: activeChapter.id,
            module_name: activeChapter.title,
            module_subcategory: `${activeChapter.ordinal}`,
            module_type: GA4ModuleTypeEnum.chapter,
          },
          question: {
            question_id: '',
            question_number: '',
            question_section: '',
            question_text: answeredQuestion.text,
            user_id: '',
          },
        };
        DataLayer.pushQuizAnswer_GA4(customEvent);
      }

      updateActivePrompt({
        ...activePrompt,
        questions: activePrompt.questions.map(question => {
          return question === answeredQuestion
            ? { ...answeredQuestion, points } //Assign the points to the question
            : question
        })
          .filter(question => question.points === undefined || question.points > 0),
      });
    };

    const isEveryQuestionAnswered = activePrompt.questions.every(question => question.points);
    const questionsWithPositivePoints = activePrompt.questions.filter(question => question.points && question.points > 0);

    return <StyledExpandedAdvancedCard
      headerContent={
        <>
          <StyledAdvancedCardIcon cmsSrc={activePrompt.selectedCard.icon} />
          <StyledAdvancedCardHeading text={activePrompt.selectedCard.text} />
        </>
      }
      bodyContent={!isEveryQuestionAnswered
        ? <QuestionList
          questions={activePrompt.questions}
          onYes={(question) => onQuestionAnswered(question, 1)}
          onNo={(question) => onQuestionAnswered(question, -1)}
        />
        : <>
          {questionsWithPositivePoints.length > 0
            ? <QuestionList questions={activePrompt.questions} />
            : <StyledEmptyText>{currentPromptSet.emptyText}</StyledEmptyText>}
          <StyledNextButton onClick={() => updateActivePrompt({ ...activePrompt, isCompleted: true })} />
        </>
      }
    />

  } else {
    //3. If all prompts have had cards selected + q+a done, we're done here
    return <>
      <StyledStrengthContainer>
        <StyledInstruction className='summary' text="Here are the two strengths you chose to play to:" />
        <StyledStrengthsSummary>
          {currentPromptSet.prompts.map((prompt, index) => {
            const questionsWithPositivePoints = prompt.questions.filter(question => question.points && question.points > 0);

            return <AdvancedCard
              key={index}
              headerContent={<>
                <StyledAdvancedCardIcon cmsSrc={prompt.selectedCard.icon} />
                <StyledAdvancedCardHeading text={prompt.selectedCard.text} />
              </>}
              bodyContent={<>
                {questionsWithPositivePoints.length > 0
                  ? <QuestionList questions={questionsWithPositivePoints} />
                  : <StyledEmptyText>{currentPromptSet.emptyText}</StyledEmptyText>
                }
              </>}
            />;
          })}
        </StyledStrengthsSummary>
        <StyledContinueButton onClick={() => {
          if (currentPromptSetIdx < exercise.promptSets.length - 1) {
            setCurrentPromptSetIdx(currentPromptSetIdx + 1);
          } else {
            PlayerData.setPersonalFeedback(personalFeedback);
            onComplete();
          }
        }} />
      </StyledStrengthContainer>
    </>
  }
};