export enum ENVIRONMENT_TYPE {
  LOCAL = 'LOCAL',
  TEST = 'TEST',
  STAGING = 'STAGING',
  PROD = 'PROD',
  RCT = 'RCT', //Randomised control trial. May or may not be in use. Ask product manager for more info. 
}

interface IEnvironment {
  name: string;
  domain?: string;
  apiUrl: string;

  auth0Domain: string,
  auth0CustomDomain: string,
  auth0ClientId: string,

  tagColor: string;
  isForDebugging: boolean; //if TRUE, enables more logs, testing features etc for this environment. typically only on for LOCAL and TEST
  shortcutAccessCode?: string;
  hotjarId?: number;
}

const ENVIRONMENT_LOOKUP: { [key in ENVIRONMENT_TYPE]: IEnvironment } = {
  [ENVIRONMENT_TYPE.LOCAL]: {
    name: 'Local',
    // 'domain' could be aotg.dev.movember.com or aotgmhr.dev.movember.com etc. This one isn't important.
    apiUrl: 'https://api.aotg.test.movember.com', //localhost uses test api

    auth0Domain: 'aheadofthegame-test.us.auth0.com', //same as TEST
    auth0CustomDomain: 'auth.aheadofthegame.test.movember.com', //same as TEST
    auth0ClientId: 'PqMZm7lZtD4PWTnMUhaHT3PcBh3Udnnk', //same as TEST

    tagColor: '#ff4346',
    isForDebugging: true,
    // 'shortcutAccessCode' not needed
    // 'hotjarId' not needed
  },
  [ENVIRONMENT_TYPE.TEST]: {
    name: 'Test',
    domain: 'https://aotg.test.movember.com',
    apiUrl: 'https://api.aotg.test.movember.com',

    auth0Domain: 'aheadofthegame-test.us.auth0.com',
    auth0CustomDomain: 'auth.aheadofthegame.test.movember.com',
    auth0ClientId: 'PqMZm7lZtD4PWTnMUhaHT3PcBh3Udnnk',
    
    tagColor: '#fffb00',
    isForDebugging: true,
    shortcutAccessCode: 'TAKEMETOTEST',
    // 'hotjarId' not needed
  },
  [ENVIRONMENT_TYPE.STAGING]: {
    name: 'Staging',
    domain: 'https://aotg.staging.movember.com',
    apiUrl: 'https://api.aotg.staging.movember.com',

    auth0Domain: 'aheadofthegame-staging.us.auth0.com',
    auth0CustomDomain: 'auth.aheadofthegame.staging.movember.com',
    auth0ClientId: 'EOcLIFuffosFOnU5EBZoKsYX8npXggEW',
    
    tagColor: '#6dd911',
    isForDebugging: false,
    shortcutAccessCode: 'TAKEMETOSTAGING',
    // 'hotjarId' not needed
  },
  [ENVIRONMENT_TYPE.PROD]: {
    name: 'Production',
    domain: 'https://aotg.movember.com',
    apiUrl: 'https://api.aotg.movember.com',
    
    auth0Domain: 'aheadofthegame.us.auth0.com',
    auth0CustomDomain: 'auth.aheadofthegame.movember.com',
    auth0ClientId: 'N0nIYi2VVbRZiOam3C07vSxo7u5crOFD',

    tagColor: '', //no displayed tag for prod
    isForDebugging: false,
    shortcutAccessCode: 'TAKEMETOPROD',
    hotjarId: 2707121,
  },
  [ENVIRONMENT_TYPE.RCT]: {
    name: 'RCT',
    domain: 'https://aotgrct.movember.com',
    apiUrl: 'https://api.aotg.test.movember.com',

    auth0Domain: 'aheadofthegame-test.us.auth0.com', //same as TEST
    auth0CustomDomain: 'auth.aheadofthegame.test.movember.com', //same as TEST
    auth0ClientId: 'PqMZm7lZtD4PWTnMUhaHT3PcBh3Udnnk', //same as TEST

    tagColor: '#ffffff55',
    isForDebugging: false,
    shortcutAccessCode: 'RCTMO', //for RCT, cohort must START with this code (See tryShortcutToEnvironment below)
    hotjarId: 2707123,
  },
}

export default {
  isEnvironment(type: ENVIRONMENT_TYPE): boolean {
    switch (type) {
      case ENVIRONMENT_TYPE.RCT:
        return window.location.origin === ENVIRONMENT_LOOKUP.RCT.domain;
      case ENVIRONMENT_TYPE.PROD:
        return window.location.origin === ENVIRONMENT_LOOKUP.PROD.domain;
      case ENVIRONMENT_TYPE.STAGING:
        return window.location.origin === ENVIRONMENT_LOOKUP.STAGING.domain;
      case ENVIRONMENT_TYPE.TEST:
        return window.location.origin === ENVIRONMENT_LOOKUP.TEST.domain;
      case ENVIRONMENT_TYPE.LOCAL:
        return window.location.origin.startsWith('http://localhost') 
          || window.location.origin.startsWith('https://localhost')
          || window.location.origin.includes('.dev.');
      default:
        throw new Error('Invalid environment type');
    }
  },

  getCurrentEnvironment(): IEnvironment {
    const currentEnvType = Object.keys(ENVIRONMENT_LOOKUP).find((type) => this.isEnvironment(type as ENVIRONMENT_TYPE));
    if (!currentEnvType) throw Error('Missing environment type');
  
    return ENVIRONMENT_LOOKUP[currentEnvType as ENVIRONMENT_TYPE];
  },
  
  getEnvironmentByType(type: ENVIRONMENT_TYPE): IEnvironment {
    return ENVIRONMENT_LOOKUP[type];
  },

  getEnvironmentByShortcutAccessCode(accessCode: string): IEnvironment | undefined {
    if (!accessCode) return;
    
    const matchingAccessCodeEnvType = Object.keys(ENVIRONMENT_LOOKUP)
      .find((type) => {
        const env = ENVIRONMENT_LOOKUP[type as ENVIRONMENT_TYPE];
        if (type === ENVIRONMENT_TYPE.RCT && ENVIRONMENT_LOOKUP.RCT.shortcutAccessCode) {
          return accessCode.toUpperCase().startsWith(ENVIRONMENT_LOOKUP.RCT.shortcutAccessCode);
        } else {
          return ENVIRONMENT_LOOKUP[type as ENVIRONMENT_TYPE].shortcutAccessCode === accessCode.toUpperCase()
        }
      });
    if (!matchingAccessCodeEnvType) return;
  },

  tryShortcutAccessCodeRedirect(accessCode: string): Promise<boolean> {
    // Some access codes will redirect the user to a new environment upon being entered.
    return new Promise((resolve) => {
      if (!accessCode) resolve(false);

      let urlSuffix = '';
    
      const matchingAccessCodeEnvType = Object.keys(ENVIRONMENT_LOOKUP)
        .find((type) => {
          const env = ENVIRONMENT_LOOKUP[type as ENVIRONMENT_TYPE];
          if (!env.shortcutAccessCode) return false;
  
          if (type === ENVIRONMENT_TYPE.RCT) {
            //RCT codes must simply start with RCTMO

            //We add the access code to the domain redirect
            const searchParams = new URLSearchParams({ registrationCode: accessCode });
            urlSuffix = `/register?${searchParams.toString()}`;
        
            return accessCode.toUpperCase().startsWith(env.shortcutAccessCode);
          } else {
            return env.shortcutAccessCode === accessCode
          }
        });
      if (!matchingAccessCodeEnvType) resolve(false);
      
      const environment = ENVIRONMENT_LOOKUP[matchingAccessCodeEnvType as ENVIRONMENT_TYPE];
      if (!environment || !environment.domain || environment.domain === this.getCurrentEnvironment().domain) {
        resolve(false);
      } else {
        window.location.replace(`${environment.domain}${urlSuffix}`);
        //let the promise hang to redirect
      }
    })
  },
}