import styled from 'styled-components';
import { IStyledProps } from '../../theme';

interface IRadioButtonGroupProps extends IStyledProps {
  name: string;
}

export const StyledRadioButton = styled.input.attrs((props : IRadioButtonGroupProps) => ({
  type: 'radio', 
  name: props.name,
}))`
  width: 30px;
  height: 30px;
  margin: 1em 1.5em;  
  
  appearance: none;
  border-radius: 50%;
  border: 3px solid ${(props: IRadioButtonGroupProps) => props.theme.colors.greys.b};
  &:checked {
    border: ${(props: IRadioButtonGroupProps) => `3px solid ${props.theme.colors.primary.b}`};
    background: ${(props: IRadioButtonGroupProps) => `radial-gradient(${props.theme.colors.primary.b} 50%,transparent 57%)`};
  }
`;

export const StyledLabel = styled.label``;

export const StyledOption = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledGroupContainer = styled.div``;
