import { DefaultTheme } from 'styled-components';
import { ThemeDefault } from './ThemeDefault';

// CHANGING OR ADDING A NEW COLOUR TO A THEME?
// See instructions in ThemeDefault.ts
export const AFL_THEME_NAME = 'AOTG AFL Theme';

const colors = {
  //the main background colour
  defaultBackground: '#E1E8ED',
  white: '#FFFFFF',
  black: '#090909',

  primary: {
    a: '#023C91',
    b: '#EE0001',
    c: '#3664A7',
  },
  greys: {
    a: '#242424',
    b: '#7D7D7D', //used for DISABLED cards/things and radio button border
    c: '#f1f1f1', //used for cards
    d: '#3E3E3E', //used for buttons
  },
  accent: {
    error: '#D40000',
    success: '#678ABD',
    count: '#EE0001',
  },
  
  //populated below to use refs to this obj
  videoStickers: [] as { color: string, text?: string }[],
  chooseCards: [''],
  plans: [''],
  
  breathingRings: { 1: '#819ec8', 2: '#88a3cb', 3: '#90a9cf', 4: '#9ab1d3' },
};

//Arrays
colors.videoStickers = [{ color: '#EE0001' }, { color: colors.greys.c, text: colors.black }, { color: '#3664A7' }];
colors.chooseCards = [colors.primary.b, colors.primary.a];
colors.plans = [colors.primary.b, colors.primary.a];

  export const ThemeAFL: DefaultTheme = {
    dark: false,
    name: AFL_THEME_NAME,
    colors,
    breakpoints: ThemeDefault.breakpoints,
    containerMaxWidth: ThemeDefault.containerMaxWidth,
    fonts: ThemeDefault.fonts,
  }