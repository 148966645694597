import styled, { css } from 'styled-components';
import { IStyledProps } from '../../theme';
import { Z_INDEX } from '../../constants';
import { Button } from '../Button/Button';

export const QuizConsentOverlayContainer = styled.div`
  background:  ${(props: IStyledProps) => props.theme.colors.greys.c};
  
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  transition: opacity 0.3s;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 20px;

  z-index: ${() => Z_INDEX.applicationOverlay};

  > * {
    width: 100%;
  }
`;

export const StyledDescription = styled.div`
  font-size:20px;
  line-height: 25px;
  text-align: center;
`;

interface IButtonProps extends IStyledProps {
    primary?: boolean
    hollow?: boolean
}

export const StyledButton = styled(Button).attrs((props: IButtonProps) => ({ primary: props.primary === undefined ? true : props.primary }))`
  margin: 0 0 10px;
  padding: 15px;
  width: 100%;
  span {
    font-family: ${(props: IButtonProps) => props.theme.fonts.heading};
    letter-spacing: 2.5 px;
    font-size:24px;
    text-transform: uppercase;
  }

  ${(props: IButtonProps) => {
    if (props.primary) {
      return css`
        background: ${props.theme.colors.black};
      `;
    }
  }}
`;

export const StyledSection = styled.div`
  text-align:center;
`;

export const StyledReject = styled.div`
  color: #173ED7;
  cursor: pointer;
`;