import styled, { css } from 'styled-components';
import { Heading } from '../../Heading/Heading';
import { IStyledButtonProps, StyledTeammateList, StyledButton } from '../../StrategyExercises/StrategyExerciseStrength/TeammatesStrength/TeammatesStrength.styled';
import { StyledTeamFeedbackContent } from '../TeamFeedback.styled';


export const StyledStrengthPopUpContent = styled(StyledTeamFeedbackContent)`
    padding: 25px 10px;
`;

export const StyledStrengthPopUpListContainer = styled.div`
    height: 100%;
`;

export const StyledStrengthPopUpButtonContainer = styled.div``;

export const StyledStrengthPopUpHeading = styled(Heading)`
    font-size: 36px;
    font-weight: 400;
    margin-top: 1.5em;
`;

export const StyledStrengthPopUpCloseButton = styled(StyledButton)`
    margin-top: 2.5em;
    padding: 15px;
`;

export const StyledStrengthPopUpList = styled(StyledTeammateList)`
    height: 80%;
`;

export const StyledStrengthPopUpButton = styled(StyledButton)`
    padding: 15px;
    ${(props: IStyledButtonProps) => props.isCompleted && css`
        border-color: ${props.theme.colors.greys.b};
        h5 {
            color: ${props.theme.colors.black};
            font-weight: 400;
        }
        
        :before {
        border: none;
        transform: none;
        }
    `}
    `;

export const StyledStrengthPopUpCloseButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`