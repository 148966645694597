import styled, { createGlobalStyle } from 'styled-components';
import { IStyledProps } from './theme';
import Div100vh from 'react-div-100vh';
import { APP_MAX_WIDTH_PX, Z_INDEX } from './constants';

const googleFonts = 'https://fonts.googleapis.com/css?family=Overpass&display=swap';
export const GlobalStyle = createGlobalStyle`
    // fix this - doesn't work
    /* @import url(${(props: IStyledProps) => {
      return `https://fonts.googleapis.com/css?family='${props.theme.fonts.body}'&display=swap`;
    }}); */
    @import url(googleFonts);
    * {
        box-sizing: border-box;
    }
    body {
        padding: 0;
        margin: 0;
        font-family: ${(props: IStyledProps) => props.theme.fonts.body}, sans-serif;
        background: ${(props) => props.theme.colors.black};
        overscroll-behavior-y: none;
        overscroll-behavior-x: none;
    }
    a {
      text-decoration: none;
      color: ${(props: IStyledProps) => props.theme.colors.primary.a};
    }
    iframe.fullScreen {
      position:fixed;
      top:0;
      left:0;
      bottom:0;
      right:0;
      width:100%;
      height:100%;
      border:none;
      margin:0;
      padding:0;
      overflow:hidden;
      z-index:999999;
      transition: opacity 0.3s;
    }
`;

export const StyledApp = styled(Div100vh)`
  max-width: ${APP_MAX_WIDTH_PX}px;
  position: relative;
  background-color: ${(props: IStyledProps) => props.theme.colors.defaultBackground};
  margin: 0 auto;
  display: block;
`;

export const StyledEnvironmentIndicator = styled.div`
  width: 100px;
  height: 100px;
  transform: rotate(-45deg);
  text-transform: uppercase;
  position: absolute;
  left: -50px;
  top: -50px;
  display: flex;
  align-items: end;
  justify-content: center;
  z-index: ${Z_INDEX.environmentIndicator};
`;
