import React, { useRef } from 'react';
import { StyledChapterContent, StyledVideoOverlayStrategyExercise } from './VideoOverlayStrategyExercise.styled';
import { IChapter } from '../../../models/IChapter';
import { STRATEGY_EXERCISE_TYPE } from '../../../constants';
import { ChapterHeader } from '../../ChapterHeader/ChapterHeader';
import { StrategyExerciseChoose } from '../../StrategyExercises/StrategyExerciseChoose/StrategyExerciseChoose';
import { StrategyExercisePlan } from '../../StrategyExercises/StrategyExercisePlan/StrategyExercisePlan';
import { StrategyExerciseStrength } from '../../StrategyExercises/StrategyExerciseStrength/StrategyExerciseStrength';
import { StrategyExerciseControl } from '../../StrategyExercises/StrategyExerciseControl/StrategyExerciseControl';
import { StrategyExerciseBreathe } from '../../StrategyExercises/StrategyExerciseBreathe/StrategyExerciseBreathe';
import { TeammatesStrength } from '../../StrategyExercises/StrategyExerciseStrength/TeammatesStrength/TeammatesStrength';
import { IExerciseChoose } from '../../../models/exercises/IExerciseChoose';
import { IExercisePlan } from '../../../models/exercises/IExercisePlan';
import { IExerciseStrength } from '../../../models/exercises/IExerciseStrength';
import { IExerciseStrengthTeammates } from '../../../models/exercises/IExerciseStrengthTeammates';
import { IExerciseControl } from '../../../models/exercises/IExerciseControl';
import { IExerciseBreathe } from '../../../models/exercises/IExerciseBreathe';

interface IVideoOverlayStrategyExerciseProps {
  chapter: IChapter;
  onComplete: () => void;
  exerciseType: STRATEGY_EXERCISE_TYPE
  exercise: IExerciseChoose | IExercisePlan | IExerciseStrength | IExerciseStrengthTeammates | IExerciseControl | IExerciseBreathe;
}

export const VideoOverlayStrategyExercise: React.FC<IVideoOverlayStrategyExerciseProps> = ({ chapter, onComplete, exerciseType, exercise }) => {
  const scrollRef: React.RefObject<HTMLDivElement> = useRef(null);
  
  const scrollTopFn = (delayMs = 0, isSmoothScroll = true) => {
    if (!scrollRef.current) return;
    setTimeout(() => {
      if (scrollRef.current) {
        if (isSmoothScroll) scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        else scrollRef.current.scrollTop = 0;
      }
    }, delayMs);
  };

  let content;
  switch (exerciseType) {
    case STRATEGY_EXERCISE_TYPE.Choose:
      content = <StrategyExerciseChoose onComplete={onComplete} strategyExerciseChoose={exercise as IExerciseChoose} />;
      break;
    case STRATEGY_EXERCISE_TYPE.Plan:
      content = <StrategyExercisePlan onComplete={onComplete} strategyExercisePlan={exercise as IExercisePlan} />
      break;
    case STRATEGY_EXERCISE_TYPE.Strengths:
      content = <StrategyExerciseStrength scrollTopFn={scrollTopFn} onComplete={onComplete} strategyExerciseStrength={exercise as IExerciseStrength} />;
      break;
    case STRATEGY_EXERCISE_TYPE.StrengthsTeammates:
      content = <TeammatesStrength scrollTopFn={scrollTopFn} onComplete={onComplete} chapter={chapter}/>
      break;  
    case STRATEGY_EXERCISE_TYPE.Control:
      content = <StrategyExerciseControl onComplete={onComplete} strategyExerciseControl={exercise as IExerciseControl} />
      break;
    case STRATEGY_EXERCISE_TYPE.Breathe:
      content = <StrategyExerciseBreathe chapter={chapter} onComplete={onComplete} />
      break;
  } 

  return (
    <StyledVideoOverlayStrategyExercise
      ref={scrollRef}
      isScrollDisabled={ exerciseType === STRATEGY_EXERCISE_TYPE.Choose }
    >
      <ChapterHeader chapter={chapter} key={chapter.ordinal} />
      <StyledChapterContent>
        {content}
      </StyledChapterContent>
    </StyledVideoOverlayStrategyExercise>
  );
}