import styled from 'styled-components';
import { IStyledProps } from '../../../theme';
import { Card } from '../../Card/Card';
import { Heading } from '../../Heading/Heading';
import { SvgIcon } from '../../SvgIcon/SvgIcon';
import { ChapterInstruction } from '../../ChapterInstruction/ChapterInstruction';
import { getSlideUpAndFadeIn } from '../../../utils/animations.styled';
import { CHAPTER_TRANSITION } from '../../../constants';

export const StyledFocusCardContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledFocusHeading = styled(Heading)`
  margin-top: 20px;
`;

export const StyledControlCardIcon = styled(SvgIcon)`
  width: 52px;
  height: 52px;
`;

export const StyledControlCard = styled(Card)`
  width: 150px;
  height: 140px;
  font-style: italic;
`;

export const StyledCardHeading = styled(Heading).attrs({ level: 'h4' })`
  
`;

export const StyledFocusCardHeading = styled(Heading).attrs({ level: 'h4' })`
  color: ${(props: IStyledProps) => props.theme.colors.white};
`;

export const StyledFocusControlCard = styled(Card)`
  width: 180px;
  height: 160px;
  position: relative;
  font-style: italic;
`;

const TICK_HEIGHT_PX = 40;

export const StyledControlCardTick = styled.div`
  width: ${TICK_HEIGHT_PX}px;
  height: ${TICK_HEIGHT_PX}px;
  right: -${TICK_HEIGHT_PX / 3}px;
  top: -${TICK_HEIGHT_PX / 3}px;
  background-color: ${(props: IStyledProps) => props.theme.colors.accent.success};
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > svg {
    display: block;
    margin: auto;
    width: ${TICK_HEIGHT_PX * 0.6}px;
    height: ${TICK_HEIGHT_PX * 0.6}px;

    path {
      fill: none;
      stroke-width: 4px;
    }
  }
`;

export const StyledIcon = styled(SvgIcon)`
  > svg {
    path {
      fill: ${(props: IStyledProps) => props.theme.colors.primary.a};
    }
  }
`;

export const StyledInstruction = styled(ChapterInstruction)`
  animation: ${getSlideUpAndFadeIn('-200px')} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out;
`;