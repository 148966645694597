import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { ReactComponent as NavigationIcon } from '../../images/icons/chevron.svg';
import Native, { PhoneType } from '../../services/Native';
import PlayerData from '../../services/PlayerData';
import { NotificationChoiceBody, 
  NotificationChoiceButton, 
  NotificationChoiceButtonColumn, 
  NotificationChoiceOverlayContainer, 
  NotificationFormLogo, 
  NotificationSignupSuccessful, 
  NotificationSignupSuccessfulContainer,
  StyledAppLogo,
} from './NotificationChoiceOverlay.styled';

export const NOTIFICATION_FADE_OUT_DURATION = 600;

interface INotificationChoiceOverlay {
  onComplete: () => void;
}

export const isNotificationChoiceUnset = () => {
  // check to see if we need to show the notification state pop-up.
  return PlayerData.getNotificationSendingPermission() === undefined;
};

export const NotificationChoiceOverlay: React.FC<INotificationChoiceOverlay> = ({ onComplete }) => {
  const [isVisible, setIsVisible] = useState(true);

  const onNotificationSelected = (notificationsAccepted: boolean) => {
    setTimeout(() => {
      setIsVisible(false);
    }, NOTIFICATION_FADE_OUT_DURATION);

    // mark that we have asked them before.
    PlayerData.setNotificationSendingPermission(!!notificationsAccepted);

    if (notificationsAccepted) {
      Native.requestNativeDeviceRegistration();
    }

    if (onComplete) onComplete();
  }
  
  return <> { 
    isVisible && 
      <NotificationChoiceOverlayContainer >
        <StyledAppLogo />

        <NotificationSignupSuccessfulContainer>
          <NotificationSignupSuccessful></NotificationSignupSuccessful>
        </NotificationSignupSuccessfulContainer>
        
        <NotificationChoiceButtonColumn>
          <NotificationChoiceBody level="h5">Can we send you a couple of notifications to keep The Challenge alive?</NotificationChoiceBody>
          <NotificationChoiceButton data-cy="yeahSure" onClick={() => onNotificationSelected(true)} label="Yeah, sure" primary={true} />
          <NotificationChoiceButton data-cy="noThanks" onClick={() => onNotificationSelected(false)} label="No thanks" primary={false} hollow={true} />
        </NotificationChoiceButtonColumn>
      </NotificationChoiceOverlayContainer>
  } </>
};
