import styled, { css } from 'styled-components';
import { Button, IButtonProps } from '../Button/Button';

export const StyledModalContainer = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: rgba(255, 255, 255, 0.848448);;
    z-index:100;
    backdrop-filter: blur(27px);
    padding: 0 20px;
    & button {
        width:100%;
    }
`

export const StyledCloseButton = styled.div`
    text-align: right;
    height: 22px;
    margin: 10px -10px;
`

export const StyledChildrenSection = styled.div`
    height: calc( 100% - 155px );

`

export const StyledFormButton = styled(Button).attrs((props: IButtonProps) => ({ primary: props.primary === undefined ? true : props.primary }))`
  margin: 30px 0;
`;