import React, { useState } from 'react';
import { 
    StyledNotificationModal, 
    StyledNotificationModalArchiveIcon, 
    StyledNotificationModalButton, 
    StyledNotificationModalContainer, 
    StyledNotificationModalLowerContainer, 
    StyledNotificationModalParagraph, 
    StyledNotificationModalTitle,
    StyledNotificationModalUpperContainer,
} from './NotificationModal.styled';
import { ReactComponent as ArchiveIcon } from '../../images/icons/archive-icon.svg';

interface INotificationFuncProps {
    setNotificationModalIsTrue: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NotificationModal: React.FC<INotificationFuncProps> = ({ setNotificationModalIsTrue }: INotificationFuncProps) => {

    const [animate, setAnimate] = useState<boolean>(false);

    const closeModal = () => {
        setAnimate(true);
        setTimeout(() => {
            setNotificationModalIsTrue(false);
        }, 1000);
    }

    const modalUpperContent = () => {
        return (
            <StyledNotificationModalUpperContainer>
                <StyledNotificationModalArchiveIcon><ArchiveIcon/></StyledNotificationModalArchiveIcon>
                <StyledNotificationModalTitle primary text={"You're all Done"}/>
            </StyledNotificationModalUpperContainer>
        );
    }

    const modalLowerContent = () => {
        return (
            <StyledNotificationModalLowerContainer>
                <StyledNotificationModalParagraph>
                {"It's been a while since you were at The Challenge workshop. So we put the app into archive mode."}<br/><br/>
                {"In this mode you'll no longer be able to move through the chapters. You'll still be able to review the strategies you learnt."}
                </StyledNotificationModalParagraph>
                <StyledNotificationModalButton label={'Review my Strategies'} onClick={closeModal}/>
            </StyledNotificationModalLowerContainer>
        );
    }

    return (
        <StyledNotificationModalContainer animate={animate}>
            <StyledNotificationModal upperContent={modalUpperContent()} lowerContent={modalLowerContent()}/>
        </StyledNotificationModalContainer>
    );
}

export default NotificationModal;