import styled, { keyframes } from 'styled-components';
import { IStyledProps } from '../../theme';
import { PagePanel } from '../Page/Page';
import { IMAGES_URL, Z_INDEX } from '../../constants';

export const ANIMATION_TIME_SECONDS = 3;
export const ANIMATION_DELAY_SECONDS = 3;

const slide = keyframes`
  0% {
    background-position: 50% 100vh;
  }
  75% {
    background-position: 50% -32vh;
  }
  100% {
    background-position: 50% -32vh;
  }
`;
const fade = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;
const mainFade = keyframes`
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

interface IStyledChapterTransition extends IStyledProps {
  backgroundImage: string;
}

export const StyledChapterTransition = styled(PagePanel)<IStyledChapterTransition>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: ${(props: IStyledProps) => props.theme.colors.black};
  background-image: url(${IMAGES_URL}${(props: IStyledChapterTransition) => props.backgroundImage});
  background-size: cover;

  z-index: ${Z_INDEX.loadingOverlay};

  animation: ${mainFade} ${ANIMATION_TIME_SECONDS}s ease-out forwards;
  animation-delay: ${ANIMATION_DELAY_SECONDS}s;

  ::after {
    background-image: url('${IMAGES_URL}images/backgroundTransparent.webp');
    background-position: 50% 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    
    position: absolute;

    animation: ${slide} ${ANIMATION_TIME_SECONDS}s ease-out forwards;
    animation-delay: ${ANIMATION_DELAY_SECONDS}s;
  }
  ::before {
    background-color: #1a1a1a;

    content: '';
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    
    position: absolute;

    opacity: 0;
    animation: ${fade} ${ANIMATION_TIME_SECONDS}s ease-out forwards;
    animation-delay: ${ANIMATION_DELAY_SECONDS}s;
  }
`;