import React, { useEffect, useState } from 'react';
import { StyledPhonesDownBottomButton, StyledPhonesDownContainer, StyledPhonesDownInner, StyledPhonesDownContent, StyledPhonesDownHeader, StyledPhonesDownImage } from './PhonesDown.styled';
import { ReactComponent as Phone } from './phone.svg';

interface IPhonesDownProps {
  onComplete: () => void;
}

const DEFAULT_WAIT_SECONDS = 15;

export const PhonesDown: React.FC<IPhonesDownProps> = ({ onComplete }) => {
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const onCompleteClick = () => {
    // do we want transitions?
    setIsClicked(true);
    onComplete();
  };

  useEffect(() => {
    setTimeout(() => setShowContinueButton(true), DEFAULT_WAIT_SECONDS * 1000)
  }, []);

  return <StyledPhonesDownContainer>
    <StyledPhonesDownInner>
      <StyledPhonesDownHeader primary text="PHONES DOWN" />
      <StyledPhonesDownImage><Phone /></StyledPhonesDownImage>
    </StyledPhonesDownInner>
    {!showContinueButton &&
      <StyledPhonesDownContent>Await Instructions...</StyledPhonesDownContent>
    }

    <StyledPhonesDownBottomButton data-cy="letsGo" show={!isClicked && showContinueButton} label="Let's go!" onClick={onCompleteClick} hollow={true} data-testid="letsGo" />
  </StyledPhonesDownContainer>;
}
