import React, { useContext, useState } from 'react';
import { TRANSITION_CONFIGS } from '../../../constants';
import { IChapter } from '../../../models/IChapter';

export enum TransitionStepType {
  FadeOut = 1, // Step when the screen fades out
  FadeIn = 2, // Step when the screen fades in
  WaitNoInteraction = 3, // Step when no interactions are allowed
  WaitWithInteraction = 4, //  // Step when interactions are allowed
  ChapterTransition = 5,
  EntryTransition = 6,
}

export interface ITransitionStep {
  type: TransitionStepType;
  durationSeconds: number;
  animationSeconds?: number;
  identifier?: string; // Used to specify the target which would be affected by this transition
}

interface ITransition {
  type: string;
  paused?: boolean;
  steps: ITransitionStep[];
  currentStepIndex: number;
}

interface ITransitionContext {
  transition?: ITransition | null;
  setTransition: (transitionType: string | null, chapter?: IChapter) => void;
  // getTransitionStep: () => ITransitionStep | null;
  transitionStep: ITransitionStep | null;
  setTransitionPaused: (pausedState: boolean) => void;
  goToNextTransitionStep: () => void;
}

const TransitionContext = React.createContext<ITransitionContext>({} as ITransitionContext);
const TransitionContextConsumer = TransitionContext.Consumer;
// const TransitionContextProvider = TransitionContext.Provider;
const TransitionContextProvider: React.FC<React.PropsWithChildren<Record<string, any>>> = ({ children }) => {
  /** Internal state of the dashboard chapter transition */
  const [transitionState, setTransitionState] = useState<ITransition | null>(null);

  const setTransition = (transitionType: string | null, chapter?: IChapter | undefined): void => {
    if (transitionType) {
      const transitionSteps = [...TRANSITION_CONFIGS[transitionType]];

      transitionSteps &&
      setTransitionState({
          type: transitionType,
          steps: transitionSteps,
          currentStepIndex: 0,
        });
    } else {
      setTransitionState(null);
    }
  };

  const setTransitionPaused = (pausedState: boolean): void => {
    if (transitionState && transitionState.paused !== pausedState) {
      setTransitionState({
        ...transitionState,
        paused: pausedState,
      });
    }
  };
  
  const getTransitionStep = (): ITransitionStep | null => {
    return transitionState ? transitionState.steps[transitionState.currentStepIndex] : null;
  };

  const goToNextTransitionStep = (): void => {
    if (transitionState) {
      // increase current step index or set current transition to null if no more steps
      const nextStep = transitionState.steps[transitionState.currentStepIndex + 1];

      setTransitionState(
        nextStep
          ? {
              ...transitionState,
              currentStepIndex: transitionState.currentStepIndex + 1,
            }
          : null,
      );
    }
  };

  return (
    // This component will be used to encapsulate the whole App
    <TransitionContext.Provider
      value={{
        // context API methods here...
        transition: transitionState,
        setTransition,
        transitionStep: getTransitionStep(),
        setTransitionPaused,
        goToNextTransitionStep,
      }}
    >
      {children}
    </TransitionContext.Provider>
  );
};

/** A hook which exposes the transition context from a single function */
function useTransition(): ITransitionContext {
  const context = useContext(TransitionContext);

  if (!context) {
    throw new Error('useTransition must be used within a Provider');
  }

  return context;
}

export { useTransition, TransitionContextConsumer, TransitionContextProvider };
