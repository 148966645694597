import styled from 'styled-components';

export const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    display: block;
    margin: auto;
  }
`;