import React from 'react';
import { StyledChapterInstruction } from './ChapterInstruction.styled';

interface IChapterInstructionProps {
  text: string
  align?: string

  className?: string //Used to inherit any other styles
}

export const ChapterInstruction: React.FC<IChapterInstructionProps> = ({ className, align = 'center', text = '' }) => {
  return <StyledChapterInstruction className={className} align={align} text={text} />
}

