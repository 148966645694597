import React from 'react';
import { StyledCardList, StyledCardContainer } from './CardList.styled';

interface ICardListProps {
  cardComponents: JSX.Element[];
  fadeIn?: boolean; // Animation: card components fade in one by one
}

//Accepts card components as children and lays them out 2 at a time

export const CardList: React.FC<ICardListProps> = ({ cardComponents, fadeIn = true }) => {
  const isOddNumber = cardComponents.length % 2 === 1;

  return <StyledCardList>
    {cardComponents.map((cardComponent: JSX.Element, index: number) => 
      <StyledCardContainer 
        fadeIn={!!fadeIn}
        key={index} 
        index={index}
        isFullWidth={isOddNumber && index === cardComponents.length - 1}>
          {cardComponent}
      </StyledCardContainer>,
    )}
  </StyledCardList>
}