import styled, { css } from 'styled-components';
import { Sticker } from '../../Sticker/Sticker';
import { getFadeInKeyFrames, getFadeOutKeyFrames } from '../../../utils/animations.styled';
import { VideoOverlay } from '../VideoOverlay';
import { Heading } from '../../Heading/Heading';
import { IStyledProps } from '../../../theme';
import { getWindowWidthPx } from '../../../utils/helper';
import { StyledStrategyExercisesButton } from 'components/StrategyExercises/StrategyExercises.styled';

export const StyledStickerSelectorContainer = styled(VideoOverlay)`
  overflow: hidden;
`;

export const StyledStickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

interface ISelectableStickerProps extends IStyledProps  {
  rotation: number
}

export const FADE_OUT_SECONDS = 0.5;

export const StyledSelectableSticker = styled(Sticker)`
  opacity: 0;
  animation: ${getFadeInKeyFrames()} 0.5s 0s ease-in-out forwards;

  cursor: pointer;
  margin: 16px 0;

  transform-origin: center center;
  transform: rotate(${(props: ISelectableStickerProps) => props.rotation}deg);
`;

interface IStyledStickerFeedbackProps extends IStyledProps {
  isFading: boolean
}

export const FEEDBACK_OVERLAY_SECONDS = 0.5;
const FEEDBACK_INNER_SECONDS = 0.5;
const FEEDBACK_BUTTON_SECONDS = 0.5;

export const StyledStickerFeedbackContainer = styled.div`
  opacity: 0;
  animation: ${getFadeInKeyFrames()} ${FEEDBACK_OVERLAY_SECONDS}s 0s ease-in-out forwards;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  height: 100%;
  width: 100%;
  background: ${(props: IStyledProps) => props.theme.colors.greys.a}bf;
  transition: opacity ${FADE_OUT_SECONDS}s;
  ${(props: IStyledStickerFeedbackProps) => props.isFading && css`
    animation: ${getFadeOutKeyFrames()} ${FEEDBACK_OVERLAY_SECONDS}s 0s ease-in-out forwards;
  `}
`;

interface ISelectedStickerProps extends IStyledProps  {
  baseWidth: number
}

//We use the base sticker width and the window width to calculate the width adaptive. with range (0.85 - 1.5).
export const StyledSelectedSticker = styled(Sticker)`
  ${(props: ISelectedStickerProps) => css`transform: scale(min(1.5 , max(${(getWindowWidthPx() * 0.65) / props.baseWidth }, 0.85)));`}
`;

export const StyledStickerFeedbackInner = styled.div`
  max-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledStickerFeedback = styled(Heading).attrs({ level: 'h4' })`
  opacity: 0;
  animation: ${getFadeInKeyFrames()} ${FEEDBACK_INNER_SECONDS}s ${FEEDBACK_OVERLAY_SECONDS}s ease-in-out forwards;
  
  flex: 1;
  color: ${(props: IStyledProps) => props.theme.colors.white};
  text-align: left;
  padding: 80px 0;
  font-weight: 100;
  line-height: normal;
`;

export const StyledContinueButton = styled(StyledStrategyExercisesButton).attrs({ primary: true })`
  opacity: 0;
  animation: ${getFadeInKeyFrames()} ${FEEDBACK_BUTTON_SECONDS}s ${FEEDBACK_INNER_SECONDS + FEEDBACK_OVERLAY_SECONDS}s ease-in-out forwards;
  
  width: 100%;
`;