import styled from 'styled-components';
import { IStyledProps } from '../../../theme';

export const StyledGeneratedEmailLabel = styled.div`
  color: ${(props: IStyledProps) => props.theme.colors.primary.a};
`;

export const StyledGeneratedEmail = styled.div`
  margin-bottom: 40px;
  font-weight: 700;
  color: ${(props: IStyledProps) => props.theme.colors.primary.b};
`;

export const StyledGeneratedEmailTip = styled.div`
  position: relative;
  color: white;
  background: ${(props: IStyledProps) => props.theme.colors.greys.a};
  padding: 20px 20px 20px 55px;
  margin: 0 0 40px;
  width: calc(100% + 40px) !important;
  >*:nth-child(1) {
    font-weight: 600;
  }
  :after {
    content: "!";

    position: absolute;
    width: 20px;
    height: 20px;
    top: 20px;
    left: 20px;

    font-family: ${(props: IStyledProps) => props.theme.fonts.heading};
    font-weight: 500;
    text-align: center;
    color: ${(props: IStyledProps) => props.theme.colors.greys.a};
    border-radius: 50%;
    background: white;
  }
`;

export const StyledAgeSelectionContainer = styled.div`
  margin-bottom: 2em;
`;

export const StyledDigitalConsentContainer = styled.div`
  padding: 0 50px 50px 20px;
`
export const StyledCheckBoxUl = styled.ul`
  list-style: auto;
  font-size:12px;
  padding-inline-start: 30px;

  & li {
    padding-left:4px;
    color: ${(props: IStyledProps) => props.theme.colors.black};
    opacity: 0.7;
  }

  & a {
    color: ${(props: IStyledProps) => props.theme.colors.black};
    text-decoration: underline;
  }
`

export const StyledSubHeader = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
  margin-left: 33px;
`

export const StyledErrorMessage = styled.div`
  margin-left: 33px;
  position: relative;
  margin-bottom: 1em;
  display: flex;
  align-items: flex-start;
  font-size:12px;
  color: ${(props: IStyledProps) => props.theme.colors.accent.error};
`

export const StyledLinkDiv = styled.div`
  display: inline;
  text-decoration: underline;
`