import styled, { css } from 'styled-components';

export const StyledGameViewPlayer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledPlayerBar = styled.div<{ team?: number }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${(props: { team?: number }) => props.team && props.team === 1 ? 'red' : 'blue'};
  padding: 4px 0;
`;

export const StyledFreezeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  
  :before {
    content: 'AWAIT INSTRUCTIONS...';
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledGameViewPlayerGameArea = styled.div<{ height: string }>`
  height: ${(props: { height: string }) => props.height};
  transition: height 1s;
`;

export const StyledGameViewPlayerInfo = styled.div<{ height: string }>`
  height: ${(props: { height: string }) => props.height};
  transition: height 1s;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const CIRCLE_SIZE = 300;
const ITEM_SIZE = 20;

export const StyledEmojis = styled.div`
  position: relative;
  width: ${CIRCLE_SIZE}px;
  height: ${CIRCLE_SIZE}px;
  padding: 0;
  border-radius: 50%; 
  list-style: none;
  margin: 20px auto;
`;

export const StyledSelectedEmoji = styled.div`
  width: ${CIRCLE_SIZE / 2}px;
  height: ${CIRCLE_SIZE / 2}px;
  border-radius: 50%; 
  font-size: 128px;
  translate: 50% 50%;
`;

export const StyledEmoji = styled.div<{ rotation: number }>`
  display: block;
  position: absolute;
  top:  50%; 
  left: 50%;
  font-size: 32px;
  width: ${ITEM_SIZE}px;
  height: ${ITEM_SIZE};
  margin: -(${ITEM_SIZE} / 2);

  ${props => {
    return css`transform: rotate(${props.rotation * 1}deg) translate(${CIRCLE_SIZE / 2}px) rotate(${props.rotation * -1}deg);`;
  }}
}`;