/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// This is here to handle panning events for touchscreens and computer screens.
// I couldn't find a library to adequitely capture this behaviour.

// There is no provision to remove pan events currently, so we should turn them
// off by making the element they affect user-interaction: none.

// the options are onStart () => {}, a function that is called when touchdown
// onMove ({ox, oy, x, y, ele}) => {}, called when touchmove occurs
// onEnd () => {}, called when touch finish occurs.

const getOffset = (ele: HTMLElement) => {
  if (!ele) return { top: 0, left: 0 };

  const rect = ele.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
  };
};

export const addPanEvent = function (ele: HTMLElement, options: any) {
  const onPanFn = function (e: any) {
    const windowEle = document;
    const bodyEle = document.body;
    const handleEle = ele;
    // allow events to fall through onto other elements, by default: block it.
    const preventDefault = options.preventDefault ?? true;

    const previous: { x: number; y: number } = { x: e.pageX, y: e.pageY };

    if (options['onStart']) {
      const returnValue = options['onStart'].call(handleEle, e);

      if (!returnValue) {
        return;
      }
    }

    if (preventDefault) {
      e.preventDefault();
    }

    handleEle.classList.add('isPanning');

    const handleRemoval = function () {
      ['mousemove', 'touchmove'].forEach((type) => windowEle.removeEventListener(type, onMove, false));
      ['mouseup', 'touchend'].forEach((type) => windowEle.removeEventListener(type, onEnd, false));

      window.requestAnimationFrame(() => {
        bodyEle.classList.remove('isPanning');
      });

      (bodyEle.style as { [key: string]: any })['-webkit-touch-callout'] = '';
      (bodyEle.style as { [key: string]: any })['user-select'] = '';

      handleEle.classList.remove('isPanning');
    };

    (bodyEle.style as { [key: string]: any })['-webkit-touch-callout'] = 'none';
    (bodyEle.style as { [key: string]: any })['user-select'] = 'none';

    const originTime = Date.now();

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const onMove = function (e: any) {
      const timeDif = Date.now() - originTime;

      //Touch interaction or click interaction
      const isTouchEvent = e.touches && e.touches.length;
      const event = isTouchEvent ? e.touches[0] || {} : e;
      //When doing a touch-screen drag, the e.event.target always comes through as the elementthat the drag started on
      // - ie. if you started dragging on empty div space and then went over a sticker, the e.event.target would still be the empty div space.
      // Unfortunately this means wet must use 'elementFromPoint' which is probably less-efficient
      const eventTarget = isTouchEvent ? document.elementFromPoint(event.pageX, event.pageY) : event.target;

      const calcOffset: any = {};

      if (isTouchEvent) {
        const offset = getOffset(eventTarget);
        calcOffset.offsetX = event.pageX + offset.left;
        calcOffset.offsetY = event.pageY + offset.top;
      } else {
        calcOffset.offsetX = event.offsetX;
        calcOffset.offsetY = event.offsetY;
      }

      //dx + dy are the drag/difference from the previous move event.
      //if no prrevious event, subtract the whole number again (ie. result = 0, no movement)
      calcOffset.dx = event.pageX - (previous.x || event.pageX);
      calcOffset.dy = event.pageY - (previous.y || event.pageY);

      previous.x = event.pageX || 0;
      previous.y = event.pageY || 0;

      const returnBean = {
        x: calcOffset.offsetX,
        y: calcOffset.offsetY,

        dx: calcOffset.dx,
        dy: calcOffset.dy,

        target: eventTarget,
      };

      if (timeDif > 100 || Math.abs(event['dx']) > 5 || Math.abs(event['dy']) > 5) {
        bodyEle.classList.add('isDragging');
      }

      if (options['onMove']) {
        const returnVal = options['onMove'].call(handleEle, returnBean);

        if (!returnVal) {
          if (options['onEnd']) options['onEnd'].call(handleEle, returnBean);

          handleRemoval();
        }
      }

      if (preventDefault) e.preventDefault();
      return false;
    };

    const onEnd = function (ev: any) {
      if (options['onEnd']) options['onEnd'].call(handleEle, ev);

      handleRemoval();

      if (preventDefault) ev.preventDefault();
    };

    ['mousemove', 'touchmove'].forEach((type: string) => windowEle.addEventListener(type, onMove, { passive: false }));
    ['mouseup', 'touchend'].forEach((type: string) => windowEle.addEventListener(type, onEnd, { passive: false }));

    return false;
  };

  ['mousedown', 'touchstart', 'dragstart'].forEach((type) => ele.addEventListener(type, onPanFn, { passive: false }));

  return ele;
};

const IFRAME_FADE_OUT_DURATION = 300;

export const addIFrame = function (baseEle: HTMLElement, src: string, onCompleteDelay: number, onComplete: () => void, dataAttrib?: string) {
  const iFrameEle = document.createElement('iframe');
  iFrameEle.classList.add('fullScreen');
  
  // support for cypress custom data attribute on the iframe so we can select it
  if (dataAttrib) {
    iFrameEle.setAttribute('data-cy', dataAttrib);
  }

  // for this to work we need to hook into the iFrame
  // window.postMessage("close"), when it intends to
  // close.
  const removeIFrame = (e: any) => {
    const url = new URL(src);

    if (e.origin !== url.origin) return;

    onComplete();
    // this is part of the bellow work around, but to add some animation to the removal
    // we set the opacity to 0, and it transitions out.
    setTimeout(() => {
      iFrameEle.style.opacity = '0';
    }, onCompleteDelay - IFRAME_FADE_OUT_DURATION);

    // this is a workaround with the quiz pages we use,
    // upon showing the final screen they instantly leave
    // we want to show the final screen for a short amount of time
    setTimeout(() => {
      baseEle.removeChild(iFrameEle);
    }, onCompleteDelay);

    // clean up.
    window.removeEventListener('message', removeIFrame);
  };

  window.addEventListener('message', removeIFrame, false);

  // the iframe, has by default an opacity of 0, but while it loads we don't want to show it.
  baseEle.appendChild(iFrameEle);

  // setting an iframe source, starts the loading process.
  iFrameEle.src = src;

  return iFrameEle;
};
