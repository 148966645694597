import React, { PropsWithChildren } from 'react';
import { StyledVideoOverlay } from './VideoOverlay.styled';

interface IVideoOverlayProps {
  onClick?: () => void
  className?: string
}

//A layer over a videoPlayer which holds elements inside, creating an overlay
export const VideoOverlay = React.forwardRef<HTMLDivElement, PropsWithChildren<IVideoOverlayProps>>(({ children, className, onClick }, ref) => (
    <StyledVideoOverlay ref={ref} className={className} onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        //Stop propagating interaction to the player
        event && event.stopPropagation();
        onClick && onClick();
      }}>
        {children}
    </StyledVideoOverlay>
));