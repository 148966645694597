import styled, { css } from 'styled-components';
import { IStyledProps } from '../../../theme';
import { Card } from '../../Card/Card';
import { SvgIcon } from '../../SvgIcon/SvgIcon';
import { ResizableTextarea } from '../../ResizableTextarea/ResizableTextarea';
import { AdvancedCard } from '../../AdvancedCard/AdvancedCard';
import { Heading } from '../../Heading/Heading';
import { Button } from '../../Button/Button';
import { getFadeInKeyFrames, getSlideUpAndFadeIn, getFadingKeyFrames } from '../../../utils/animations.styled';
import { ChapterInstruction } from '../../ChapterInstruction/ChapterInstruction';
import { CHAPTER_TRANSITION } from '../../../constants';
import { StyledStrategyExercisesButton } from '../StrategyExercises.styled';

export const StyledStrengthsSummary = styled.div`
  > * {
    margin-bottom: 20px;
  }
  opacity: 0;
  animation: ${getFadeInKeyFrames()} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out ${CHAPTER_TRANSITION.delayTime}s;
`;

export const StyledContinueButton = styled(StyledStrategyExercisesButton).attrs({ primary: true, label: 'Continue' })`
  opacity: 0;
  animation: ${getFadeInKeyFrames()} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out 2s;
`;

export const StyledStrengthCard = styled(Card)`
  width: 150px;
  height: 140px;
  font-style: italic;

  ${(props) => {
    if (props.isDisabled || props.isActive) {
      return css`
        opacity: 1;
        background-color: ${props.theme.colors.primary.a};
       
        :after {
          content: "";
          top: 0;
          right: 5px;
          position: absolute;
          height: 14px;
          width: 7px;
          border-bottom: 3px solid white;
          border-right: 3px solid white;
          transform: rotate(35deg);
        }
        :before {
          content: "";
          top: -8px;
          right: -8px;
          position: absolute;
          height: 36px;
          width: 36px;
          background-color: ${props.theme.colors.accent.success};
          border-radius: 50%; 
        }
      `;
    }
  }} 

  svg {
    width: 52px;
    height: 52px;
  }
`;

export const StyledAdvancedCardIcon = styled(SvgIcon)`
  width: 64px;
  height: 64px;
  margin: 0 auto;

  > svg {
    path, rect {
      fill: ${(props: IStyledProps) => props.theme.colors.white};
    }
  }
`;

export const StyledAdvancedCardHeading = styled(Heading).attrs({ level: 'h3' })`
  font-style: italic;
`;

export const StyledAddYourOwn = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: ${(props: IStyledProps) => props.theme.colors.primary.a};
  margin: 0 auto;
  display: block;
  margin-top: 20px;
  width: 160px;
  opacity: 0;
  animation: ${getFadeInKeyFrames()} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out 4s;
`;

export const StyledCustomCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledCustomCardButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  > * {
    width: 160px;
    margin-top: 10px;
  }
`;

export const StyledCustomStrengthCard = styled(StyledStrengthCard)`
  width: 170px;
  height: 170px;
`;

export const StyledResizableTextarea = styled(ResizableTextarea)`
  width: 100%;
  background: ${(props: IStyledProps) => props.theme.colors.greys.c};
  color: ${(props: IStyledProps) => props.theme.colors.primary.a};
  border-radius: 4px;

  &:focus {
    border: 1px solid ${(props: IStyledProps) => props.theme.colors.primary.a};
  }
`;

export const StyledNextButton = styled(StyledStrategyExercisesButton).attrs({ primary: true, label: 'Next' })`
  align-self: stretch;
  opacity: 0;
  animation: ${getFadeInKeyFrames()} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out ${CHAPTER_TRANSITION.delayTime}s;
`;

export const StyledEmptyText = styled(Heading).attrs({ level: 'h5' })`
  padding: 20px 0;
  opacity: 0.7;
`;

export const StyledExpandedAdvancedCard = styled(AdvancedCard)`
  min-height: min(100%, 600px);
  ${StyledEmptyText} {
    opacity: 0;
    animation: ${getFadingKeyFrames(0, 0.7)} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out ${CHAPTER_TRANSITION.delayTime}s;
  }
`;

export const StyledStrengthContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-height: 985px) {
    button {
      position: absolute;
      bottom: 0;
      margin-bottom: 20px;
      width: 91%;
    }
  }
`;

export const StyledInstruction = styled(ChapterInstruction)`
  animation: ${getSlideUpAndFadeIn('-200px')} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out;

  &.summary {
    margin: auto;
    width: 80%;
    margin-bottom: 30px;
  }
`;

export const StyledCustomCardOverlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const StyledCardsContainer = styled.div`
  position: relative;
`;

export const StyledStrengthSelector = styled.div`
  position: relative;
`;