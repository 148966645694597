import React, { useEffect, useState } from 'react';
import { useTransition } from '../../Transition/TransitionContext/TransitionContext';
import Content from '../../../services/Content';
import { SvgIcon } from '../../SvgIcon/SvgIcon';
import completeText from './completed.webp';
import { ReactComponent as PlayerFeedbackIcon } from '../../../images/icons/player-feedback.svg';
import { StyledNotificationText, StyledTeamFeedbackCircleContainer } from '../../TeamFeedback/TeamFeedback.styled';
import PlayerData from '../../../services/PlayerData';
import {
  StyledFinale,
  StyledFinaleCompletedText,
  StyledFinaleStamp,
  StyledFinaleReview,
  StyledFinaleHeading,
  StyledFinaleCardList,
  StyledFinaleCard,
  StyledFinaleFeedbackCard,
  StyledFinalFeedBackCardHeading,
  StyledFeedbackCardContainer,
  StyledFinaleChallengeText,
  StyledServicesFeedbackCard,
  StyledFinaleButtonWrapper,
  StyledServicesCardContainer,
} from './Finale.styled';
import Api from '../../../services/Api';
import { useNavigate } from 'react-router-dom';
import { FRONTEND_TEAM_FEEDBACK_REFRESHMENT, SPORT_TYPE } from '../../../constants';
import { IChapter } from '../../../models/IChapter';
import { ReactComponent as CompletedAFLText } from './completed-text.svg';
import CohortData from 'services/CohortData';
interface IFinaleProps {
  chapterIdentifier?: IChapter;
}

export const Finale: React.FC<IFinaleProps> = ({ chapterIdentifier }) => {
  const navigate = useNavigate();

  const { transitionStep } = useTransition();
  const transitionStepIdentifier = (transitionStep && transitionStep.identifier) ?? null;

  const allChapters = Content.getAllChapters();

  const [isTeamFeedbackEnabled, setIsTeamFeedbackEnabled] = useState<boolean>(false);
  const [teamHasGivenFeedback, setTeamHasGivenFeedback] = useState<boolean>(PlayerData.hasReceivedFeedbacks());

  const sport = PlayerData.getSport();

  const initTeamFeedbackRefreshes = () => { //constantly POLL to see if there new feedback in playerdata
    if (!teamHasGivenFeedback) {
      const newFeedback = PlayerData.hasReceivedFeedbacks();
      (newFeedback !== teamHasGivenFeedback)
        ? setTeamHasGivenFeedback(newFeedback)
        : setTimeout(initTeamFeedbackRefreshes, FRONTEND_TEAM_FEEDBACK_REFRESHMENT)
    }
  };

  useEffect(() => {
    CohortData.loadCohortData()
      .then(cohortData => {
        if (cohortData) {
          setIsTeamFeedbackEnabled(cohortData.IsTeamFeedbackEnabled);
          setTimeout(initTeamFeedbackRefreshes, FRONTEND_TEAM_FEEDBACK_REFRESHMENT);
        }
      }).catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }, []);
  /* eslint-disable no-console */
  console.log(transitionStepIdentifier);

  return <StyledFinale>
    <StyledFinaleChallengeText>CHALLENGE</StyledFinaleChallengeText>
    {(sport != SPORT_TYPE.Rugby) ?
      <>
        {/* Do not change the SVG used for this, it will break the animation */}
        {/* I found out the hard way when I put this code back and forgot I had updated the SVG  */}
        {/* Perry forgot to put a warning so blame him for my pain  */}
        <StyledFinaleCompletedText transitionStepIdentifier={transitionStepIdentifier}>
          <CompletedAFLText />
        </StyledFinaleCompletedText>
      </> :
      <StyledFinaleStamp src={completeText} transitionStepIdentifier={transitionStepIdentifier} />
    }
    <StyledFinaleReview>
      <StyledFinaleHeading transitionStepIdentifier={transitionStepIdentifier}>Review your strategies</StyledFinaleHeading>
      <StyledFinaleCardList>
        {allChapters.map((chapter, index) =>
          <StyledFinaleCard
            key={index}
            index={index}
            transitionStepIdentifier={transitionStepIdentifier}
            isActive={true}
            onClick={() => navigate(`/chapter/${chapter.chapterIdentifier}/summary?from=finale`)}
            upperContent={<SvgIcon cmsSrc={chapter.icon} />}
          />,
        )}
        <StyledFinaleButtonWrapper>
          {isTeamFeedbackEnabled && (
            <StyledFeedbackCardContainer>
              <StyledFinaleFeedbackCard
                lowerContent={
                  <div>
                    <PlayerFeedbackIcon />
                    {teamHasGivenFeedback ?
                      <StyledFinalFeedBackCardHeading text={"Your Team's Feedback"} /> :
                      <StyledFinalFeedBackCardHeading text={'Feedback from your<br />team will appear here'} />
                    }
                  </div>
                }
                onClick={() => navigate('/team-feedback')}
                isActive={teamHasGivenFeedback}
                isDisabled={!teamHasGivenFeedback}
              >
                {teamHasGivenFeedback && (
                  <StyledTeamFeedbackCircleContainer>
                    <StyledNotificationText>{PlayerData.getUserStrengthContributorsLength()}</StyledNotificationText>
                  </StyledTeamFeedbackCircleContainer>
                )}
              </StyledFinaleFeedbackCard>
            </StyledFeedbackCardContainer>
          )}
          <StyledServicesCardContainer>
            <StyledServicesFeedbackCard onClick={() => navigate('/support-services?from=dashboard')}>Support Services</StyledServicesFeedbackCard>
          </StyledServicesCardContainer>
        </StyledFinaleButtonWrapper>
      </StyledFinaleCardList>
    </StyledFinaleReview>
  </StyledFinale>
};
