import React from 'react';
import { StyledTextSticker, StyledStickerText } from './Sticker.styled';
import { ISticker } from '../../models/IVideoMoment';

interface IStickerProps {
  //'sticker' should only contain properties that the sticker will ALWAYS have (bgColour, text), not positioning for a specific usage
  sticker: ISticker,

  className?: string
  onClick?: () => void
}

export const Sticker: React.FC<IStickerProps> = (props) => {
  return <StyledTextSticker {...props}><StyledStickerText sticker={props.sticker}>{props.sticker.text}</StyledStickerText></StyledTextSticker>
};