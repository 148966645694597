import styled from 'styled-components';
import { IStyledProps } from '../../../theme';
import { Heading } from '../../Heading/Heading';
import { SvgIcon } from '../../SvgIcon/SvgIcon';
import { getSlideUpKeyFrames, getFadeInKeyFrames } from '../../../utils/animations.styled';
import { VideoOverlay } from '../VideoOverlay';
import { StyledStrategyExercisesButton } from 'components/StrategyExercises/StrategyExercises.styled';

interface IStyledEndingPageProps extends IStyledProps {
  image?: string;
}

export const StyledStrategyBannerContainer = styled(VideoOverlay)`  
  animation: ${getFadeInKeyFrames()} 0.25s forwards ease-in-out 0s;
  background: ${(props: IStyledEndingPageProps) => props.theme.colors.black};
  padding: 20px 20px;
  color: ${(props: IStyledEndingPageProps) => props.theme.colors.white};
  height: 100%;
  display: flex;
  flex-direction: column;

`;

export const StyledStrategyBanner = styled.div`
  animation: ${getSlideUpKeyFrames} 0.75s forwards ease-in-out 0s;
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;

`;

export const StyledHeading = styled(Heading).attrs({ level: 'h1', primary: true })`
  font-weight: 400;
  font-size: 5rem;
  text-align: left;
  line-height: 0.7em;

  @media screen and (min-width: 375px) {
    font-size: 6.25rem;
  }
  @media screen and (min-width: 400px) {
    font-size: 6.875rem;
  }
`;

export const StyledText = styled(Heading).attrs({ level: 'h5' })`
  width: 90%;
  margin-top: 1.5rem;
  font-weight: 300;
  font-size: 17px;
  line-height: 1.5em;
  flex: 1;
  text-align: left;

  @media screen and (min-width: 375px) {
    width: 85%;
    font-size: 20px;
  }
  @media screen and (min-width: 400px) {
    width: 75%;
  }
`;

export const StyledIcon = styled(SvgIcon)`
  width: 55px;
  height: 65px;
  margin-bottom: 15px;

  @media screen and (min-width: 375px) {
    width: 65px;
    height: 80px;
  }
  @media screen and (min-width: 400px) {
    width: 70px;
    height: 80px;
  }

  svg {
    rect, polygon, path {
      fill: ${(props: IStyledEndingPageProps) => props.theme.colors.white};
    }
  }
`;

export const StyledButton = styled(StyledStrategyExercisesButton).attrs({ primary: true })`
  animation: ${getSlideUpKeyFrames} 1s forwards ease-in-out 0s;
  width: 100%;
  flex-shrink: 0;
  margin-top: 2rem;
`;

