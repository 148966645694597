/* eslint-disable @typescript-eslint/no-shadow */
import styled, { css } from 'styled-components';
import { IStyledProps } from '../../theme';

interface ITextFieldProps extends IStyledProps {
  empty?: boolean;
  error?: boolean;
  type?: string;
}

export const StyledIconContainer = styled.div`
  position: absolute;
  top: calc(25px - 0.5em);
  right: 10px;
  opacity: 0.3
`

export const StyledErrorMessage = styled.div`
  font-size: 0.8em;
  color: ${(props: ITextFieldProps) => props.theme.colors.accent.error};
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const StyledPlaceholder = styled.div`
  display: none;
  position: absolute;
  top: 5px;
  left: 10px;
  color: ${(props: ITextFieldProps) => props.theme.colors.greys.a};
  opacity: 0.6;
  font-size: 0.75em;
  font-family: Overpass;
`;


export const StyledTextField = styled.input.attrs((props: ITextFieldProps) => ({ type: props.type || 'text' }))`
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(36, 36, 36, 0.6);
  background-color: rgba(255, 255, 255, 0.9);
  height: 50px;
  padding: 0 10px;
  font-size: 1em;
  color: ${(props: ITextFieldProps) => props.theme.colors.greys.a};
  &::placeholder {
    color: ${(props: ITextFieldProps) => props.theme.colors.greys.a};
    opacity: 0.6;
    font-family: Overpass;
    font-size: 1em;
  }
  :focus {
    outline: none;
    border: solid 1px ${(props: ITextFieldProps) => props.theme.colors.primary.b};
    background-color: rgba(255, 255, 255, 0.9);
    color: ${(props: ITextFieldProps) => props.theme.colors.primary.b};
    + ${StyledPlaceholder} {
      color: ${(props: ITextFieldProps) => props.theme.colors.primary.b};
    }
  }
  ${(props: ITextFieldProps) => props.error && css`
    border: solid 1px ${(props: ITextFieldProps) => props.theme.colors.accent.error} !important;
  `}
`;

export const StyledTextFieldContainer = styled.div`
  position: relative;
  padding-bottom: 1.5em;
  margin-bottom: 0.8em;
  ${(props: ITextFieldProps) => !props.empty && css`
    ${StyledPlaceholder} {
      display: block;
    }
    ${StyledTextField} {
      padding-top: 1em;
    }
  `}
`;

export const StyledDropdownArrow = styled.i`
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg) translate(-50%);
`

