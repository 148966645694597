import React from 'react';
import { StyledPlayerAvatar, StyledPlayerImage, StyledPlayerResponse } from './PlayerAvatar.styled';
import IPlayerState from 'models/IGameState';

interface IPlayerAvatarProps {
  player: IPlayerState;
}

export const PlayerAvatar: React.FC<IPlayerAvatarProps> = ({ player }) => {
  return <StyledPlayerAvatar team={player.team}>
    <StyledPlayerImage src={player.avatar} />
    {player.response ? <StyledPlayerResponse team={player.team}>{player.response}</StyledPlayerResponse> : null}
  </StyledPlayerAvatar>;
};