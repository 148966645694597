/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useContext } from 'react';
import {
  StyledChooseBestPlanContainer,
  StyledPlanOption,
  StyledPlanSelectorContainer,
  StyledPlanSelector,
  StyledExpandedAdvancedCard,
  StyledChooseInstruction,
  StyledContinueButton,
  StyledChooseBestPlanCard,
  StyledInstructionText,
  StyledInstructionButton,
  StyledInstructionSubHeading,
  StyledInstructionContainer,
  StyledInstructionTextWrapper,
  StyledInstructionBodyTextWrapper,
  StyledInstructionHeading,
  StyledInstructionHeadingWrapper,
  StyledContainer,
  StyledDescription,
  StyledHeading,
  StyledStopButton,
} from './StrategyExercisePlan.styled';

import { Heading } from '../../Heading/Heading';
import { QuestionList } from '../../QuestionList/QuestionList';
import { ChapterInstruction } from '../../ChapterInstruction/ChapterInstruction';
import { IToastContext, ToastContext } from '../../Toast/ToastContext/ToastContext';
import DataLayer, { GA4ModuleCategoryEnum, GTM4Events, GA4ModuleTypeEnum } from '../../../services/DataLayer';
import { IQuestion } from '../../../models/IQuestion';
import { ReactComponent as IconThinking } from '../../../images/icons/thinking.svg';

import { IExercisePlan, IExercisePlanOption } from '../../../models/exercises/IExercisePlan';
import { ThemeContext } from 'styled-components';
import PlayerData from '../../../services/PlayerData';
import { COUNTRY_TYPE } from '../../../constants';

interface IStrategyExercisePlanProps {
  strategyExercisePlan: IExercisePlan
  onComplete: () => void
}

const PLANCONTENT = {
  title: 'GREAT WORK!',
  description: ['<b>Nice one</b><br>Option 1 sorted!<br>Now let\'s explore another plan', '<b>Ok done</b><br>Option 2 sorted!<br>Now let\'s weigh up the two plans'],
  next: 'Next',
}

// Container/state for the 'IChapterStepScoreboards' step type
export const StrategyExercisePlan: React.FC<IStrategyExercisePlanProps> = ({ strategyExercisePlan, onComplete }) => {
  const themeContext = useContext(ThemeContext);
  const { setToast }: IToastContext = useContext(ToastContext);

  //Holds all plans and their isSelected state and their isScored state
  const [plans, setPlans] = useState<IExercisePlanOption[]>(strategyExercisePlan.plans);

  const [activePlan, setActivePlan] = useState<IExercisePlanOption | null>(null); //Used to animate/style the most recently clicked plan

  // const [showInstructionScreen, setShowInstructionScreen] = useState(true);
  const [showContinueButton, setShowContinueButton] = useState(false);

  const activeChapter = DataLayer.getActiveChapter();

  const selectPlan = (selectedPlan: IExercisePlanOption) => {
    setActivePlan(selectedPlan);
    setTimeout(() => {
      setPlans(plans.map(plan => {
        return plan.text === selectedPlan.text
          ? {
            ...plan,
            colour: themeContext.colors.plans[selectedPlans.length],
            isSelected: true,
          }
          : plan
      }));
    }, 500);
    DataLayer.pushStrategyPlanSelected(selectedPlan.text);

    if (activeChapter) {
      const customEvent: GTM4Events['quizStart'] = {
        module: {
          module_category: GA4ModuleCategoryEnum.AOTG,
          module_id: activeChapter.id,
          module_name: selectedPlan.text,
          module_subcategory: `${activeChapter.ordinal}`,
          module_type: GA4ModuleTypeEnum.plan,
        },
      };
      DataLayer.pushQuizStart_GA4(customEvent);
    }
  };

  //Common variables for use below
  const selectedPlans = plans.filter(plan => plan.isSelected);
  const unscoredPlan = selectedPlans.find(plan => !plan.isScored);
  const hasSelectedEnoughPlans = selectedPlans.length === strategyExercisePlan.maxPlansToChoose;
  const hasSelectedMandatoryPlans = plans
    .filter(plan => plan.isMandatory)
    .every(plan => plan.isSelected);

  if ((!hasSelectedEnoughPlans || !hasSelectedMandatoryPlans) && !unscoredPlan) {
    //1. Show the plan select screen when no plan (or the previous plan) has been scored

    const instruction = !selectedPlans.length ? 'Let\'s explore a couple of options that he can try. Choose one.' : 'Choose one more plan to explore.';

    return <StyledPlanSelectorContainer>
      {!selectedPlans.length && <StyledHeading text={'Solutions'} />}
      <ChapterInstruction text={instruction} />
      <StyledPlanSelector>
        {plans.map((plan, planIndex) => {
          const isDisabled = plan.isSelected || (hasSelectedEnoughPlans && !plan.isMandatory);

          return <StyledPlanOption
            key={planIndex}
            index={planIndex}
            isActive={plan === activePlan}
            isDisabled={isDisabled}
            clickedColour={themeContext.colors.plans[selectedPlans.length]}
            onClick={() => !isDisabled && selectPlan(plan)}
          >
            {plan.text}
          </StyledPlanOption>
        })}
      </StyledPlanSelector>
    </StyledPlanSelectorContainer>;

  } else if (unscoredPlan) {
    //2. Score the that hasn't been scored

    const areAllQuestionsAnswered = unscoredPlan.questions.every((question: IQuestion) => !!question.points);

    const onQuestionAnswered = (answeredQuestion: IQuestion, assigningPoints: number) => {
      const wasAnsweredCorrectly = (answeredQuestion.isCorrect === true && assigningPoints > 0) || (answeredQuestion.isCorrect === false && assigningPoints < 0);

      if (wasAnsweredCorrectly) {
        setPlans(plans.map(plan => {
          return plan.text === unscoredPlan.text
            ? {
              ...unscoredPlan,
              questions: unscoredPlan.questions.map((question: IQuestion) => {
                return question === answeredQuestion
                  ? { ...answeredQuestion, points: assigningPoints } //Assign the points to the question
                  : question
              }),
            }
            : plan
        }));
      } else {
        setToast({
          icon: <IconThinking />,
          heading: 'Try again',
          body: answeredQuestion.feedback,
          buttonLabel: 'Ok',
        });
      }
      DataLayer.pushStrategyPlanQuestionAnswered(unscoredPlan.text, answeredQuestion.text, !!answeredQuestion.isCorrect, wasAnsweredCorrectly);

      if (activeChapter) {
        const customEvent: GTM4Events['quizAnswer'] = {
          answer: {
            answer_correct: !!answeredQuestion.isCorrect,
            answer_id: '',
            answer_text: answeredQuestion.text,
          },
          module: {
            module_category: GA4ModuleCategoryEnum.AOTG,
            module_id: activeChapter.id,
            module_name: unscoredPlan.text,
            module_subcategory: `${activeChapter.ordinal}`,
            module_type: GA4ModuleTypeEnum.plan,
          },
          question: {
            question_id: '',
            question_number: '',
            question_section: '',
            question_text: unscoredPlan.text,
            user_id: '',
          },
        };
        DataLayer.pushQuizAnswer_GA4(customEvent);
      }
    };

    const planIndex = plans.find(plan => plan.isScored) ? 1 : 0;

    // HACKY THING - We have a requirement for (https://movember.atlassian.net/browse/AOTG-761), it marks the challenge session as complete before it regularly would be
    // TODO: May need to modify this when sitemap is changed
    if (PlayerData.getCountry() === COUNTRY_TYPE.AU && planIndex === 1 && activeChapter && !PlayerData.hasCompletedChapter(activeChapter.id) ) {
      void (async ()=> {
        await PlayerData.setChapterCompleted(activeChapter.id);
      })();
    }
    return <>
      <StyledContainer className={areAllQuestionsAnswered ? 'flex-column' : 'non-flex-column'}>
        <div className='card-container'>
          {areAllQuestionsAnswered && <Heading className='title-text' level="h3" text={PLANCONTENT.title} />}
          <StyledExpandedAdvancedCard
            headerColour={unscoredPlan.colour}
            headerContent={<Heading level="h4" text={unscoredPlan.text} />}
            bodyContent={
              <>
                <QuestionList
                  questions={unscoredPlan.questions}
                  onYes={(question) => onQuestionAnswered(question, 1)}
                  onNo={(question) => onQuestionAnswered(question, -1)}
                />

              </>
            }
          />
        </div>
        {areAllQuestionsAnswered
          &&
          <>
            <StyledDescription className='plan-describe' level="h5" text={PLANCONTENT.description[planIndex]} />
            <StyledContinueButton primary label={PLANCONTENT.next} onClick={() => {
              setPlans(plans.map(plan => {
                return plan.text === unscoredPlan.text
                  ? { ...plan, isScored: true } //Set the plan as scored
                  : plan
              }));
              setActivePlan(null);
            }} />
          </>

        }
      </StyledContainer>
    </>;
  } else {
    setTimeout(() => {
      setShowContinueButton(true);
    }, 5000);
    return <>
      {/* the instruction screen is being hidden for now - will potentially be used for chapter 2 on replay instead */}
      {/* {showInstructionScreen && <StyledInstructionContainer>
        <StyledInstructionTextWrapper>
          <StyledInstructionHeadingWrapper>
            <StyledInstructionHeading>STEP 4</StyledInstructionHeading>
            <StyledInstructionSubHeading>CHOOSE THE OPTIMAL SOLUTION</StyledInstructionSubHeading>
          </StyledInstructionHeadingWrapper>
          <StyledInstructionBodyTextWrapper>
            <StyledInstructionText>Choose what you think is the most ideal solution to solve the problem.</StyledInstructionText>
            <StyledInstructionText>Or, if you're struggling to decide, ask for someone else's opinion on what the most ideal solution is.</StyledInstructionText>
          </StyledInstructionBodyTextWrapper>
        </StyledInstructionTextWrapper>
        <StyledInstructionButton primary onClick={() => { setShowInstructionScreen(false) }} label='FOR EXAMPLE...' />
      </StyledInstructionContainer>} */}

      <StyledChooseBestPlanContainer>
        <StyledChooseInstruction text="These are the two plans you selected" />
        {selectedPlans
          .map((selectedPlan, index) =>
            <>
              <StyledChooseBestPlanCard
                headerColour={selectedPlan.colour}
                key={index}
                headerContent={<Heading level="h4" text={selectedPlan.text} />}
                bodyContent={<QuestionList questions={selectedPlan.questions} />}
              />
            </>,
          )}
      </StyledChooseBestPlanContainer>

      { !showContinueButton && <StyledStopButton label='AWAIT INSTRUCTION' />}
      { showContinueButton && <StyledContinueButton primary onClick={onComplete} label="PROGRESS WHEN DIRECTED" />}
    </>
  } 
};