import React, { ReactNode, PropsWithChildren } from 'react';
import { StyledPage, StyledPageHeading, StyledPagePanel, StyledPageSection, StyledPageSectionParagragh, StyledPageSectionTitle } from './Page.styled';

//Used for consistent scrolling on each 'screen' of app
export const Page: React.FC<PropsWithChildren<{ children: ReactNode }>> = ({ children }) => {
  return <StyledPage>
    {children}
  </StyledPage>;
}

interface IPagePanelProps {
  className?: string
}

//Used for consistent padding on each 'screen' of the app
export const PagePanel: React.FC<PropsWithChildren<IPagePanelProps>> = ({ children, className }) => {
  return <StyledPagePanel className={className}>
    {children}
  </StyledPagePanel>
};

interface IStaticPageProps {
  pageIdentifier: string;
}

interface IPage {
  heading: string,
  sections: {
    title?: string
    paragraphs: string[]
  }[]
}

const PAGES: { [key: string]: IPage } = {
  'collection-statement': {
    'heading': 'COLLECTION STATEMENT',
    'sections': [
      {
        'paragraphs': [
          'By signing up for this app, you will be providing the Movember Group Pty Ltd as trustee for the Movember Foundation (Movember, we, us or our) with your personal information.  Our Privacy Policy includes important information about how we will collect, handle and hold this personal information.  It also includes our contact details if you have any questions or concerns, or would like to request access to or correction of your personal information.',
          'In addition to the purposes described in our Privacy Policy, we’ll use and disclose your personal information for the purpose of managing your involvement in the Ahead of the Game Program with the AFL, such as by driving completion rates, session management and tracking.  We may also use and disclose it for internal evaluation of our product delivery and effectiveness.  Importantly, this will involve our sharing your personal information collected via the App with the and some limited information (including your email address) to the AFL’s Ahead of the Game program facilitators and delivery partners and service providers (who may be located in the United States).',
          'We care about getting this stuff right, so please do review the privacy policy and reach out with any questions.',
        ],
      },
    ],
  },
  'terms-and-conditions': {
    'heading': 'Terms and Conditions',
    'sections': [
      {
        'paragraphs': [
          'Date of last revision: [25 June 2021]',
          'The Ahead of the Game mobile application (App) is provided to you by Movember Group Pty Ltd as trustee for the Movember Foundation (ABN 48 894 537 905).  These Terms and Conditions contain important information that apply to your use of the App, including warranty disclaimers and limitations of liability.  By using this App, you agree to be bound by these Terms and Conditions.',
        ],
      },
      {
        'title': '1. Your access to the App',
        'paragraphs': [
          'In order to use this App, you must be 16 years old or older, or have the consent of your parent or legal guardian to do so.  If you are under 16, you warrant that you have obtained consent from your parent or guardian to use this App and to accept these Terms and Conditions.',
          'Access to the App is also conditional on you complying with these Terms and Conditions.',
        ],
      },
      {
        'title': '2. Acceptable use and your obligations',
        'paragraphs': [
          'In accessing or using this App, you must ensure that you do not:',
          '(a) breach any laws, or encourage or assist the commission of any illegal act;',
          '(b) infringe any person’s rights, including intellectual property and proprietary rights, rights of confidentiality or contractual rights;',
          '(c) include any material that contains any virus or harmful code, or program that is designed to impair the performance of the App or any device accessing the App;',
          '(d) impersonate any other person;',
          '(e) negatively impact any other user’s ability to access and use the App;',
          '(f) publish or link to malicious content; or',
          '(g) engage in conduct that is unlawful, defamatory, obscene, threatening, harassing, abusive, slanderous, hateful or embarrassing to any other person, or which is reasonably likely to damage our reputation or our services.',
        ],
      },
      {
        'title': '3. Privacy',
        'paragraphs': [
          'By using this App, you acknowledge and agree that:',
          '(a) any personal information that you provide in relation to or when using the App will be collected and handled by Movember in accordance with our Privacy Policy; and',
          '(b) you will only provide us with personal information about another person if you have obtained their consent to do so.',
        ],
      },
      {
        'title': '4. Third party links',
        'paragraphs': [
          'This App may contain links to third party websites.  Movember does not control those websites and is not responsible for the content or accuracy of the material they contain.  Provision of these links does not mean that Movember sponsors, approves or endorses the content on the third party website.',
        ],
      },
      {
        'title': '5. Availability, suspension and termination of access',
        'paragraphs': [
          'Movember takes all reasonable steps to limit any interruptions to your use of this App.  However, access to this App may be prevented by issues outside of our control.  We do not promise continuous or error-free access to this App.',
          'Movember may, in our sole discretion:',
          '(a) revoke, suspend or end your access to use the App; or',
          '(b) cancel or otherwise terminate your account with immediate effect.',
          'We will generally advise you of any suspension, revocation or termination.  However, we are under no obligation to do so.',
          'If your account is cancelled or we permanently end your access to the App, then information we have collected about you may be either:',
          '(a) permanently deleted or de-identified; or',
          '(b) retained in accordance with any applicable regulatory requirements.',
          'Termination of your access to the App will not affect any accrued rights, or any other provision of these Terms and Conditions which are intended by their nature to survive termination.',
        ],
      },
      {
        'title': '6. Intellectual property',
        'paragraphs': [
          'Movember owns or licenses from third parties all of the trade marks, copyright and other intellectual property published on this App.  We are happy for you to download material from this App for personal and non-commercial purposes, or for purposes permitted by law, provided any reproduction is unaltered, dated and includes an attribution of source.',
          'You must not otherwise copy or reproduce in any form any of the material from this App, including Movember’s trade marks and logos, unless you have our prior written consent.',
          'Movember follows the provisions of the Digital Millennium Copyright Act and other relevant copyright legislation as applicable.  If you believe that someone else is using your copyright or trade mark without your permission, please contact us at generalcounsel@movember.com to report the infringement.',
        ],
      },
      {
        'title': '7. Disclaimer and limitation of liability',
        'paragraphs': [
          'Movember takes care to keep the content and functionality of this App accurate and error-free, but it does not make any representations or warranties that it will be fully functional at all times, that its content is correct, complete, up to date or adequate or that the information or functions will be useful for any particular purpose.',
          'The content on this App is for general informational purposes only.  It is not intended to be a substitute for advice from an appropriately qualified healthcare professional, who can determine your individual needs.  You must make your own assessment of the information contained on this App and you rely on it at your own risk.',
          'Movember does not represent that this App or any third party websites it links to are free from computer viruses or other defects.',
          'To the maximum extent permitted by law, Movember accepts no responsibility or liability for any loss or damage (including for any indirect or consequential loss) relating to access or use of this App, including the use or misuse of the information provided on this App.',
          'To the extent that any rights cannot be excluded by law, Movember’s liability in relation to this App is limited, at our election, to the supply of the service again or the cost of having the service supplied again.',
        ],
      },
      {
        'title': '8. Entities and governing law',
        'paragraphs': [
          'The Movember entity and relevant law governing these Terms and Conditions depends on the country in which you live:',
          '(a) If you live in the USA, these Terms and Conditions form an agreement between you and Movember Foundation (EIN 77-07114052) subject to the laws of California, USA;',
          '(b) If you live in Canada, these Terms and Conditions form an agreement between you and Movember Canada (Co. No. 767531-3), subject to the laws of Ontario, Canada;',
          '(c) If you live in the UK or a country that forms part of the EEA (except for Germany), these Terms and Conditions form an agreement between you and Movember Group Pty Ltd as trustee for the Movember Foundation (ABN 48 894 537 905);',
          '(d) If you live in New Zealand, these Terms and Conditions form an agreement between you and the Movember Foundation New Zealand (Co. No. 2616293), subject to the laws of New Zealand;',
          '(e) If you live in Germany, these Terms and Conditions form an agreement between you and Movember Foundation e.V, subject to the laws of Germany; and',
          '(f) If you live in Australia, or in any other place not covered by paragraphs a, b, c, d or e above, these Terms and Conditions form an agreement between you and The Movember Group Pty Ltd as trustee for the Movember Foundation (ABN 48 894 537 905), subject to the laws of Victoria, Australia.',
        ],
      },
      {
        'title': '9. Changes to these Terms and Conditions',
        'paragraphs': [
          'Movember may vary these Terms and Conditions at any time by publishing a revised version on this App.  Your continued use of this App following such variation will represent an agreement by you to be bound by the Terms and Conditions as amended.',
        ],
      },
    ],
  },
};

//Used for common static pages, e.g., collection notice, terms & conditions
export const StaticPage: React.FC<IStaticPageProps> = ({ pageIdentifier }) => {
  const page = PAGES[pageIdentifier];
  return (page ? <Page>
    <PagePanel>
      <StyledPageHeading> {page.heading} </StyledPageHeading>
      {page.sections.map((section, sIdx) => (
        <StyledPageSection key={sIdx}>
          {section.title && <StyledPageSectionTitle>{section.title}</StyledPageSectionTitle>}
          {section.paragraphs.map((paragraph, pIdx) => <StyledPageSectionParagragh key={pIdx} dangerouslySetInnerHTML={{ __html: paragraph }} />)}
        </StyledPageSection>))}
    </PagePanel>
  </Page> : null)
}