import React, { useContext, useRef, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { RadioButtonGroup } from '../RadioButtonGroup/RadioButtonGroup';
import { IToastContext, ToastContext } from '../Toast/ToastContext/ToastContext';
import { StyledDropdownArrow, StyledErrorMessage, StyledIconContainer, StyledPlaceholder, StyledTextField, StyledTextFieldContainer } from './TextField.styled';

interface ITextFieldProps {
  /** On change handler */
  onChange: (newValue: string) => void;
  /** If set, the field will be pre-populated */
  initialValue?: string;
  /** A placeholder for the field */
  placeholder: string;
  /** If the input is disabled */
  disabled?: boolean;
  /** A validation error message string */
  errorMessage?: string;
  /** The field type */
  type?: 'password' | 'uppercase' | 'email' | 'dropdown';
  /** A list of dropdown option values */
  dropdownList?: string[];
}

export const TextField: React.FC<ITextFieldProps> = ({ initialValue, placeholder = '', onChange, errorMessage, type, dropdownList = [], disabled = false, ...rest }) => {
  const [value, setValue] = useState(initialValue || '');
  const [isValueVisible, setIsValueVisible] = useState(false); // used for password input
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { setToast }: IToastContext = useContext(ToastContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let newValue = e.target.value;

    if (type === 'uppercase') newValue = newValue.toUpperCase();
    // update local state
    setValue(newValue);
    // emit change event
    onChange(newValue);
  };

  return (
    <StyledTextFieldContainer empty={!value} {...rest}>
      <StyledTextField
        error={!!errorMessage}
        type={type === 'password' && !isValueVisible ? 'password' : 'text'}
        value={value}
        inputMode={type === 'email' ? 'email' : 'text'}
        onChange={handleChange}
        placeholder={placeholder}
        ref={inputRef}
        disabled={!!disabled}
        readOnly={type === 'dropdown'}
        onClick={() =>
          type === 'dropdown' &&
          (dropdownList ?? []).length > 1 &&
          setToast({
            heading: placeholder,
            selectionList: dropdownList?.map((item) => ({ id: item, label: item, isSelected: item === value })),
            onChange: (selection: string): void => {
              setValue(selection);
              onChange(selection);
            },
          })
        }
      />
      <StyledPlaceholder>{placeholder}</StyledPlaceholder>
      <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
      {type === 'password' && (
        <StyledIconContainer
          onClick={() => {
            inputRef.current && inputRef.current.focus();
            setIsValueVisible(!isValueVisible);
          }}
        >
          {isValueVisible ? <FaEyeSlash /> : <FaEye />}
        </StyledIconContainer>
      )}
      {type === 'dropdown' && (dropdownList || []).length > 1 && (
        <StyledIconContainer>
          <StyledDropdownArrow />
        </StyledIconContainer>
      )}
    </StyledTextFieldContainer>
  );
};
