import { IStyledProps } from '../../theme';
import styled from 'styled-components';
import { Heading } from '../Heading/Heading';
import { Button } from '../Button/Button';

export const StyledSupportServicesContainer = styled.div`
  background: ${(props: IStyledProps) => props.theme.colors.defaultBackground};
  width: 100%;
  top: 0;
  left: 0;
  padding: 40px 30px;
`;

export const StyledSupportServicesHeading = styled(Heading).attrs({ primary: true, level: 'h3' })`
  margin-top: 5rem;
  font-weight: 500;
  font-size: 45px;
`;

export const StyledSupportServicesIconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 0.7rem;
`;

export const StyledSupportServicesSubHeading = styled(Heading).attrs({ primary: true, level: 'h4' })`
  text-align: left;
  font-size: 40px;
`;

export const StyledSupportServicesDescription = styled.div`
  width: 85%;
  text-align: left;
  line-height: 140%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  @media screen and (min-width: 375px) {
    width: 78%;
  }

  @media screen and (min-width: 400px) {
    width: 73%;
  }
`;

export const StyledSupportServicesLink = styled.div`
  text-decoration: underline;
  text-decoration-thickness: 2px;
  font-weight: 700;
`;

export const StyledSupportServicesButton = styled(Button)`
  width: 100%;
  margin-top: 6.5rem;
`;

export const StyledSupportServicesNumber = styled.div`
  font-weight: 700;
`;

export const StyledSupportServicesCloseIcon = styled.div`
  svg {
    width: 20px;
    height: 20px;
  }
  float: right;
`;