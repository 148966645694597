import styled, { css } from 'styled-components';
import { IStyledProps } from '../../../theme';
import { getScaleFrames, getFadeInKeyFrames, getSlowFadeInFrames, getPopUpFrames } from '../../../utils/animations.styled';
import { Heading } from '../../Heading/Heading';
import { Card } from '../../Card/Card';
import Content from '../../../services/Content';
import { SvgIcon } from '../../SvgIcon/SvgIcon';

interface IStyledFinaleProps extends IStyledProps {
  transitionStepIdentifier?: string | null
}

export const StyledFinaleChallengeText = styled.div`
  font-family: ${(props: IStyledProps) => props.theme.fonts.heading};
  font-style: normal;
  font-weight: 400;
  font-size: 5.3rem;
  line-height: 70%;
  margin-top:2rem;
  margin-bottom: -1rem;
`;

export const StyledFinale = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding: max(16%, 60px) 0 max(20%, 80px) 0;
  color: ${(props: IStyledFinaleProps) => props.theme.colors.white};  
`;

// Do not change the SVG used for this, it will break the animation
// I found out the hard way when I put this code back and forgot I had updated the SVG 
// Perry forgot to put a warning so blame him for my pain 
export const StyledFinaleCompletedText = styled(SvgIcon)`
  width: 68%;
  height: auto;
  
  @keyframes strokeOffset {
    to {
      stroke-dashoffset: 0;
    }
  }
  mask path {
    stroke-dasharray: 1000 !important;
    stroke-dashoffset: 1000;
    
    ${(props: IStyledFinaleProps) => ['dashboard-move', 'finale-slam', 'cards-popup', 'heading-fadein'].includes(props.transitionStepIdentifier as string) ? 
      css`animation: strokeOffset 11s 1.2s cubic-bezier(0.65, 0.53, 0.46, 0.64) forwards;` :
      css`stroke-dashoffset: 0;`
    }
  }
`;


export const StyledFinaleStamp = styled.img`
  margin-top: 10px;
  margin-bottom: 3rem;
  ${(props: IStyledFinaleProps) => props.transitionStepIdentifier === 'dashboard-move' && css`
    opacity: 0;
  `}
  ${(props: IStyledFinaleProps) => props.transitionStepIdentifier === 'finale-slam' && css`
    animation: ${getSlowFadeInFrames()} 0.3s ease-in forwards, ${getScaleFrames(2, 1)} 0.3s ease-in;
  `}
`;

export const StyledFinaleHeading = styled(Heading).attrs({ level: 'h4' })`
  text-align: center;
  opacity: 0.9;
  margin: 20px 0;
  ${(props: IStyledFinaleProps) => (props.transitionStepIdentifier === 'dashboard-move' || props.transitionStepIdentifier === 'finale-slam') && css`
    opacity: 0;
  `}
  ${(props: IStyledFinaleProps) => props.transitionStepIdentifier === 'heading-fadein' && css`
    animation: ${getFadeInKeyFrames()} 0.5s ease-in forwards;
  `}
`;

interface IStyledFinaleCardProps extends IStyledFinaleProps {
  index: number;
}

export const StyledFinaleCard = styled(Card)`
  width: 80px;
  height: 80px;
  margin: 7px;
  padding: 14px;
  background: ${(props: IStyledProps) => props.theme.colors.primary.c}; 

  ${(props: IStyledFinaleCardProps) => props.transitionStepIdentifier && css`
    opacity: 0;
  `}
  ${(props: IStyledFinaleCardProps) => {
    if (props.transitionStepIdentifier === 'cards-popup') {
      const totalTime = 2;
      const cardPopUpTime = 2 * totalTime / (Content.getAllChapters().length + 1);
      return css`
        opacity: 0;
        &:nth-child(${props.index + 1}) {
          animation: ${getPopUpFrames()} ${cardPopUpTime}s forwards ease-in-out ${props.index * cardPopUpTime / 2}s;
        }
      `
    }
  }}
  @media screen and (min-width: 360px) {
    width: 100px;
    height: 100px;
    margin: 8px;
    padding: 19px;
  }
`;

export const StyledFinaleCardList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5em;
`;

export const StyledFinaleReview = styled.div`
  width: 100%;
`;

export const StyledFeedbackCardContainer = styled.div`
  margin-top: 45px;
  flex: 1;

  @media screen and (max-height: 875px){
    position: relative;
    bottom: initial;
    margin-top: 20px;
  }
`;

export const StyledFinaleFeedbackCard = styled(Card)`
  border-radius: 0px;
  background: ${(props: IStyledFinaleProps) => props.theme.colors.greys.d};
  opacity: 1;

  ${(props) => {
    if (props.isActive) {
      return css`
        background: ${props.theme.colors.primary.c};
      `
    }
  }}

  div {
    display: flex;
    align-items: center;

    svg {
      transform: scale(0.75);
    }
  }
 
`;

export const StyledFinalFeedBackCardHeading = styled(Heading)`
  text-align: left;
  font-size: clamp(23px, 8vw, 33px);
  white-space: pre-wrap;
  font-size: 20px;
`

export const StyledServicesCardContainer = styled(StyledFeedbackCardContainer)`
  margin-top: 20px;
`;

export const StyledServicesFeedbackCard = styled(StyledFinaleFeedbackCard)`
  height: 104px;
  font-size: 20px;
  color: ${(props: IStyledProps) => props.theme.colors.white}; 
  background: ${(props: IStyledProps) => props.theme.colors.primary.c}; 
`;

export const StyledFinaleButtonWrapper = styled.div`
  width: 90%;
  height: 100%;
`;