/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import IPlayerState, { GAME_STAGE, IGameState } from 'models/IGameState';
import { ICohortData } from '../models/ICohortData';
import Auth from './Auth';
import Environment from './Environment';
import { IPlayerData } from './PlayerData';

export interface ITeamFeedbackTarget {
  Id: string;
  FirstName: string;
  LastName: string;
  IsFeedbackTarget: boolean;
}

export default {
  async getPlayerData(): Promise<IPlayerData> {
    //Returns an IPlayerData object from the database
    
    const res = await fetch(encodeURI(`${Environment.getCurrentEnvironment().apiUrl}/players?auth0Id=${Auth.getUserId() ?? ''}`), {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Auth.getAccessToken()}`,
      }),
    });

    if (res.status === 200) {
      const dbPlayerData = await res.json();
      
      if (typeof(dbPlayerData) !== 'object') {
        console.warn('Player_Data is the wrong type');

        if (typeof(dbPlayerData) !== 'string') {
          console.log('Player_Data is unrecoverable', dbPlayerData);

          throw new Error('Player_Data malformed');
        }
        
        // attempt to recover?
        try {
          return JSON.parse(dbPlayerData);
        } catch (error) {
          console.error('Unable to recover', dbPlayerData);

          throw new Error('Player_Data malformed');
        }
      }
      return dbPlayerData; // like: { archiveStatus: 'active' } or { archiveStatus: 'archived' } etc
    } else {
      console.log(res);
      //Without the player's user data we can't continue. Log out.
      Auth.logout();
      throw new Error(res.statusText);
    }
  },

  async savePlayerData(playerData: IPlayerData): Promise<void> {
    //Syncs the session's playerData bundle to the database - resolves on success, but no return value
    
    // check the playerDataType
    while (typeof(playerData) === 'string') {
      playerData = JSON.parse(playerData);
    }

    // don't even try to save non-objects
    if (typeof(playerData) !== 'object') {
      // just post up an error, and continue on
      console.error('Attempting to save a non-object or non-parsable string');
      return;
    }
    
    const res = await fetch(encodeURI(`${Environment.getCurrentEnvironment().apiUrl}/players?auth0Id=${Auth.getUserId() ?? ''}`), {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Auth.getAccessToken()}`,
      }), 
      body: JSON.stringify({
        Player_Data: playerData,
      }),
    });

    if (res.status !== 200) {
      console.log(res);
    }
  },

  async getCohortData(): Promise<ICohortData> {
    // Get's the users current cohort information.
    const res = await fetch(encodeURI(`${Environment.getCurrentEnvironment().apiUrl}/cohorts`), {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Auth.getAccessToken()}`,
      }),
    });

    if (res.status === 200) {
      return res.json();
    } else {
      throw new Error(res.statusText);
    }
  },

  async getCohortDataByAccessCode(accessCode: string): Promise<ICohortData> {
    const res = await fetch(encodeURI(`${Environment.getCurrentEnvironment().apiUrl}/get-teams-by-access-code?auth0Id=${Auth.getUserId() ?? ''}&accessCode=${accessCode}`), {
      method: 'GET',
    });

    if (res.status === 200) {
      return res.json();
    } else {
      throw new Error(res.statusText);
    }
  },

  async getGeneratedEmail(teamCode: string, firstName: string): Promise<string> {
    const res = await fetch(encodeURI(`${Environment.getCurrentEnvironment().apiUrl}/generated-email?accessCode=${teamCode}&firstName=${firstName}`), {
      method: 'GET',
    });

    if (res.status === 200) {
      return (await res.json())['Email'];
    } else {
      console.log(res);
      throw new Error(res.statusText);
    }
  },

  async getTeamMembers(): Promise<ITeamFeedbackTarget[]> {
    const res = await fetch(encodeURI(`${Environment.getCurrentEnvironment().apiUrl}/get-feedback-targets`), {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Auth.getAccessToken()}`,
      }),
    });

    if (res.status === 200) {
      return res.json();
    } else {
      throw new Error(res.statusText);
    }
  },

  async giveTeammatesFeedbacks(teammatesFeedbacks: any[]): Promise<void> {
    const res = await fetch(encodeURI(`${Environment.getCurrentEnvironment().apiUrl}/team-feedback`), {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Auth.getAccessToken()}`,
      }),
      body: JSON.stringify({
        feedbacks: teammatesFeedbacks,
      }),
    });

    if (res.status === 200) {
      return res.json();
    } else {
      throw new Error(res.statusText);
    }
  },

  async resetPassword(email: string): Promise<boolean | Response> {
    const res = await fetch(encodeURI(`${Environment.getCurrentEnvironment().apiUrl}/reset-password`), {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        email: email,
      }),
    });
    return res.json();
  },

  //Actual web sockets

  _wsConnect(accessCode: string, isFacilitator: boolean, onConnect: () => void, onNewState: (newGameState: any) => void) {
    if (accessCode !== 'JBOX') return null;

    const ws = new WebSocket(`wss://9og157zm5d.execute-api.ap-southeast-2.amazonaws.com/production/?accessCode=${accessCode}&isFacilitator=${isFacilitator.toString()}`);

    ws.addEventListener('open', (event) => {
      console.log('ws connected');
      onConnect();
    });

    ws.addEventListener('message', (event) => {
      console.log('ws message!!');
      const newState = event.data; //could be gamestate or playerstate
      onNewState(JSON.parse(newState));
      console.log(newState);
    });

    ws.addEventListener('close', () => {
      console.log('ws disconnected, attempting reconnect');
      this._wsConnect(accessCode, isFacilitator, onConnect, onNewState);
    });

    return ws;
  },

  wsConnectPlayer(accessCode: string, onConnect: () => void, onNewGameState: (newGameState: IGameState) => void) {
    return this._wsConnect(accessCode, false, onConnect, onNewGameState);
  },

  wsConnectFacilitator(accessCode: string, onConnect: () => void, onNewPlayerState: (newPlayerState: IPlayerState) => void) {
    return this._wsConnect(accessCode, true, onConnect, onNewPlayerState);
  },

  wsSetGameState(ws: WebSocket | null, newGameState: IGameState) {
    if (!ws) {
      console.log('wsSetGameState: Missing websocket');
      console.log(newGameState);
      throw new Error('wsSetGameState: Missing websocket');
    }

    console.log('sending game state');
    console.log(newGameState);
    ws.send(JSON.stringify({
      action: 'sendState',
      state: newGameState,
    }));
  },

  //Player set states

  wsSetPlayerState(ws: WebSocket | null, newState: IPlayerState) {
    if (!ws) {
      console.log('wsSetPlayerState: Missing websocket');
      console.log(newState);
      throw new Error('wsSetPlayerState: Missing websocket');
    }

    console.log('sending player state');
    console.log(newState);
    ws.send(JSON.stringify({
      action: 'sendState',
      state: newState,
    }));
  },
};