import styled from 'styled-components';
import { Heading } from '../Heading/Heading';
import { IStyledProps } from '../../theme';

interface StyledChapterInstructionProps extends IStyledProps {
  align?: string
}

export const StyledChapterInstruction = styled(Heading).attrs({ level: 'h4' })`
  margin-bottom: 30px;
  text-align: ${(props: StyledChapterInstructionProps) => props.align};
  color: ${(props: StyledChapterInstructionProps) => props.theme.colors.greys.a};
`;