import styled, { DefaultTheme } from 'styled-components';
import { IStyledProps } from '../../theme';
import { Z_INDEX } from '../../constants';

interface IStyleProps {
    theme: DefaultTheme;
}

interface IStyledVideoContainerProps extends IStyleProps {
    playerWidth: number;
    aspectRatio: number[];
    showControls: boolean;
    hasStartedPlaying: boolean;
}

export const StyledControlsContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Could be in theme but extremely unlikely to change sport to sport */
    background-color: ${(props: IStyleProps) => '#00000088'};
`;

export const StyledMainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.videoPlayer};
`;

export const StyledVideoContainer = styled.div<IStyledVideoContainerProps>`
    position: relative;
    height: 100%;
    max-height: 100%;
    width: ${(props: IStyledVideoContainerProps) => (window.innerWidth > window.innerHeight && `${props.playerWidth}px`) || 'inherit'};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props: IStyledVideoContainerProps) => props.theme.colors.black};


    & ${StyledControlsContainer} {
      visibility: ${(props: IStyledVideoContainerProps) => props.showControls ? 'visible' : 'hidden'};
    }

    /* Following is what makes the video responsive */
    padding-bottom: 100%;
    iframe {
        width: ${(props: IStyledVideoContainerProps) => (window.innerWidth > window.innerHeight && `${props.playerWidth}px`) || '100%'};
        max-width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
`;

export const StyledVideoClickCapture = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StyledControls = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 48px;
  left: 0;
  display: flex;
  justify-content: center;
`;

// INDIVIDUAL CONTROL CONTAINER
export const StyledControlContainer = styled.div`
    z-index: ${Z_INDEX.videoPlayer};
    position: relative;
    display: inline-block;

    vertical-align: middle;

    /* change controls color */
    & rect, polygon, path {
        fill: ${(props: IStyleProps) => props.theme.colors.white};
    }

    & svg {
        padding: 0;
    }

    & svg g[id^="Group"] rect:nth-of-type(1), &:hover svg g[id^="Group"] rect:nth-of-type(1) {
        fill: transparent;
    }

    &:hover rect, &:hover polygon, &:hover path {
        fill: ${(props: IStyleProps) => props.theme.colors.primary.b};
    }
`;

export const StyledRightControls = styled.div`


    & > div:last-child:not(:first-child) {
      position: absolute;
      right: 1rem;
    }
    & > div:first-child {
      position: absolute;
      right: 1rem;
    }

    & svg  {
        max-width: 34px;
        max-height: 34px;
    }

    display:relative;
    width:100%;
    margin-bottom: 4px;
`;

export const StyledCaptionsMenu = styled.div`
    position: absolute;
    bottom: calc(100% + 4px);
    max-width: 200px;
    right: 0;
    background: ${(props: IStyleProps) => props.theme.colors.greys.d};
    display: flex;
    flex-direction: column;
    padding: 5px 10px 5px 10px;
    align-items: stretch;

`;

interface IStyledCaptionsOptionProps extends IStyleProps {
  isSelected: boolean 
}

export const StyledCaptionsOption = styled.div`
    cursor: pointer;
    padding: 8px;
    font-size: 17px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: ${(props: IStyledCaptionsOptionProps) => props.isSelected ? '700' : '300'};
    color: ${(props: IStyledCaptionsOptionProps) => props.theme.colors.white };

    :before {
      display: inline-block;
      margin-right: 6px;
    }
    ${(props: IStyledCaptionsOptionProps) => props.isSelected 
      ? ':before { content: \'●\'; }' 
      : ':before { content: \'○\'; }'
    }
`;

export const StyledCCIcon = styled.div`
  background-color: ${(props: IStyledCaptionsOptionProps) => props.isSelected 
    ? props.theme.colors.white 
    : props.theme.colors.greys.d };  
  width: 43px;
  height: 30px;
  color: ${(props: IStyledCaptionsOptionProps) => props.isSelected 
    ? props.theme.colors.greys.d  
    : props.theme.colors.white };
  font-size: 23px;
  font-weight: 700;
  text-align: center;
  padding-top: 3px;
  margin-bottom:4px;
`;