import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IChapter } from '../../../models/IChapter';
import PlayerData from '../../../services/PlayerData';
import { TransitionStepType, useTransition } from '../../Transition/TransitionContext/TransitionContext';
import { StyledStrategyIcon, StyledStrategySlide, StyledSlideButton, StyledSlideButtonText, StyledChapterDetails, StyledOrdinalHeading, StyledChapterSlide, StyledChapterSlideContent } from '../PlayerDashboard.styled';
import { VideoController } from '../VideoController/VideoController';
import { Finale } from '../Finale/Finale';
import { StyledHeading } from '../../VideoOverlay/VideoOverlayStrategyBanner/VideoOverlayStrategyBanner.styled';
import { ChapterTransition } from '../../ChapterTransition/ChapterTransition';
interface Props {
  allChapters: IChapter[];
  currentChapterIndex: number;
  handleOnClick: (isUnlocked: boolean, chapter: IChapter, index: number) => void;
}

export const StrategySlides: React.FC<Props> = ({ allChapters, currentChapterIndex, handleOnClick }) => {
  const [showingFauxDashboardChapter, setShowingFauxDashboardChapter] = useState<number | null>(null);
  
  const { transitionStep } = useTransition();
  const transitionStepIdentifier = (transitionStep && transitionStep.identifier) ?? '';
  const navigate = useNavigate();
  const hasStartedChapter = (chapter: IChapter) => PlayerData.hasStartedChapter(chapter.chapterIdentifier);
  const hasCompletedChapter = (chapter: IChapter) => PlayerData.hasCompletedChapter(chapter.chapterIdentifier); //has this chapter been DONE
  const hasUnlockedChapter = (chapter: IChapter) => {
    const chapterIndex = allChapters.indexOf(chapter);
    return hasCompletedChapter(chapter) || chapterIndex === 0 || hasCompletedChapter(allChapters[chapterIndex - 1]); //has the previous chapter (if any) been DONE
  };

  useEffect(() => {
    if (transitionStep?.type) {
      switch (transitionStep?.type) {
        case TransitionStepType.ChapterTransition:
          // get the current chapter we need to do it to
          const currentChapter = PlayerData.getLatestChapterForUser();

          setShowingFauxDashboardChapter(currentChapter.ordinal);
          break;
        default:
          break;
      }
    }
  }, [transitionStep])

  // we need to show the faux dashboard and then stop caring about it after
  const hideFauxDashboardChapter = () => {
    setShowingFauxDashboardChapter(null);
  }

  const getStartChapterButton = ((chapter: IChapter, index: number) => {
    const isInactive = index !== 0 && !hasCompletedChapter(allChapters[index - 1]);

    // Still keeping these links to make it possible to select this element with
    // natural index order of the DOM, the container already able to navigate
    if (isInactive) {
      return {
        title: 'Chapter Locked',
        videoController: (
          <VideoController
            to={`/chapter/${chapter.chapterIdentifier}`}
            type={'lock'}
          />
        ),
      }
    } else if (hasCompletedChapter(chapter)) {
      return {
        title: 'Replay Chapter',
        videoController: (
          <VideoController
            to={`/chapter/${chapter.chapterIdentifier}`}
            type={'replay'}
          />
        ),
      }
    } else {
      return {
        title: hasStartedChapter(chapter) ? 'Resume Chapter' : 'Play Chapter',
        videoController: (
          <VideoController
            to={`/chapter/${chapter.chapterIdentifier}`}
            type={'play'}
            data-cy="playOrResumeButton"
          />
        ),
      }
    }
  });

  return (
    <>
      {allChapters.map((chapter, index) => {
        const { videoController, title } = getStartChapterButton(chapter, index);
        const isChapterCompleted = hasCompletedChapter(chapter);
        const isChapterUnlocked = hasUnlockedChapter(chapter);

        return (
          <StyledChapterSlide
            key={index}
            image={'../images/background.webp'}
          >
            {(showingFauxDashboardChapter == chapter.ordinal) && <ChapterTransition onComplete={hideFauxDashboardChapter} chapterOrdinal={showingFauxDashboardChapter} />}
          
            <StyledStrategySlide
              key={index}
              isUnlocked={isChapterCompleted} //strategy is unlocked only when completed
              isFocused={index === currentChapterIndex}
              isHidden={currentChapterIndex >= allChapters.length}
              transitionStepIdentifier={transitionStepIdentifier}
              index={index}
            >
              <StyledChapterDetails>
                <StyledStrategyIcon cmsSrc={chapter.icon} />
                <StyledOrdinalHeading text={`Chapter ${chapter.ordinal}`} />
                <StyledHeading text={chapter.strategy} />
              </StyledChapterDetails>
              <StyledSlideButton
                onClick={() => navigate(`/chapter/${chapter.chapterIdentifier}`)}
                isActive={isChapterUnlocked}
                isDisabled={!isChapterUnlocked}
                key={index}
              >
                {videoController}
                <StyledSlideButtonText>{title}</StyledSlideButtonText>
              </StyledSlideButton>
            </StyledStrategySlide>
          </StyledChapterSlide>
        );
      })}
      {PlayerData.hasCompletedAllChapters() && (
        <StyledChapterSlide
          key="finale"
          image={'../images/background.webp'}
        >
          <StyledChapterSlideContent>
            <Finale />
          </StyledChapterSlideContent>
        </StyledChapterSlide>
      )}
    </>
  );
};
