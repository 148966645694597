import styled from 'styled-components';
import { Heading } from '../Heading/Heading';

export const PAGE_PADDING_PX = 20;

export const StyledPageHeading = styled(Heading).attrs({ level: 'h3', primary: true })`
  opacity: 0.8;
  margin-bottom: 2em;
  text-transform: uppercase;
`;

export const StyledPageSectionParagragh = styled.div`
  font-size: 14px;
  opacity: 0.7;
  a {
    text-decoration: underline;
    color: inherit;
  }
`;

export const StyledPageSectionTitle = styled(StyledPageSectionParagragh)`
  font-weight: bold;
`;

export const StyledPageSection = styled.div`
  > ${StyledPageSectionParagragh}:not(:last-child) {
    margin-bottom: 1em;
  }
`;

export const StyledPagePanel = styled.div`
  padding: ${PAGE_PADDING_PX}px;
  flex: 1;
  > ${StyledPageSection}:not(:last-child) {
    margin-bottom: 3em;
  }
`;

export const StyledPage = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
