import React, { useState } from 'react';
import { StyledRadioButton, StyledLabel, StyledOption, StyledGroupContainer } from './RadioButtonGroup.styled';

export interface IRadioButtonOption {
  id: string;
  label: string;
  isSelected?: boolean
}

interface IRadioButtonGroup {
  name: string;
  options: IRadioButtonOption[];
  onChange: (radioButtonOption: IRadioButtonOption) => void;
}
  

export const RadioButtonGroup : React.FC<IRadioButtonGroup> = ({ name, options, onChange, ...rest }) => {
  const [selectedOption, setSelectedOption] = useState(options.find(option => option.isSelected));

  return <StyledGroupContainer {...rest}>
    {options.map((option, index) => (<StyledOption key={index}>
      <StyledRadioButton 
        checked={selectedOption && selectedOption.id === option.id} 
        name={name} 
        id={option.id} 
        data-cy={option.id}
        onChange={() => {
          setSelectedOption(option);
          onChange(option);
        }}
      />
      <StyledLabel htmlFor={option.id}>{option.label}</StyledLabel>
    </StyledOption>))}
  </StyledGroupContainer>
}