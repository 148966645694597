import styled from 'styled-components';
import { Z_INDEX } from '../../constants';

//This is used for inheritence for a LOT of components, for POSITIONING an overlay
//Avoid chanding rules (like overflow, background, flex etc) here
export const StyledVideoOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: ${Z_INDEX.videoOverlay};
`;