import styled, { keyframes } from 'styled-components';
import { IStyledProps } from '../../../theme';
import { Heading } from '../../Heading/Heading';
import { getFadeInKeyFrames } from '../../../utils/animations.styled';
import { Z_INDEX } from '../../../constants';
import { VideoOverlay } from '../VideoOverlay';
import { StyledStrategyExercisesButton } from 'components/StrategyExercises/StrategyExercises.styled';

export const StyledStopBannerContainer = styled(VideoOverlay)`  
  animation: ${getFadeInKeyFrames()} 0.25s forwards ease-in-out 0s;
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(2px);
  z-index: ${Z_INDEX.videoOverlayBanner};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface IStyledEndingPageProps extends IStyledProps {
  image?: string;
}

// the initial banner fly in from the bottom
const BANNER_DURATION = 0.5;

const TITLE_DURATION = 0.5;
const TITLE_DELAY = BANNER_DURATION;

// how long each text line takes to reveal
const SUBTITLE_DURATION = 0.5;
const SUBTITLE_DELAY = BANNER_DURATION + TITLE_DURATION;

// how long(IN TOTAL), before we start showing the call to action bottom button
const BUTTON_DURATION = 1;
const BUTTON_DELAY = BANNER_DURATION + TITLE_DURATION + SUBTITLE_DURATION;

// so... A lot of weird stuff happening in this animation:
//  we want the content to be "centered", no matter the frame of screen,
//  we are also dealing with scales
//  we are also dealing with imperfect text glyphs causing their containers to not match the actual size of text well

// we're sliding the frame in from the left: calc(${CONTAINER_WIDTH}px / 2 - 50% - 100vw)
// we use the container width to be able to approximate the center ot the frame(this appears to work well)
// for the y: calc(${CONTAINER_HEIGHT}px / 2 - 60% + 10px)
// we have a lot of adjustments for the imperfect text glyphs: ideally it would be the same as the x component.
const titleKeyframes = keyframes`
  0% {
    opacity: 1;
    transform: translate(-100%);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
`;

const subtitleKeyFrames = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const StyledHeadingTitleContainer = styled.div`
  position: relative;
  text-align: left;
  transform-origin: top left;
  transform: translate(-100vw, 20px) scale(1.2);
  opacity: 0;
  animation: ${titleKeyframes} ${TITLE_DURATION}s ${TITLE_DELAY}s forwards cubic-bezier(0.35,-0.1, 0.9, 1.16);
`;

export const StyledHeadingTitle = styled(Heading)`
  padding: 0px;
  text-align: left;
  font-size: 200px;
  font-weight: 400;
  color: ${(props: IStyledEndingPageProps) => props.theme.colors.white};
`;

export const StyledSubtitle = styled(Heading).attrs({ level: 'h4' })`
  text-align: left;
  position: relative;
  font-weight: 300;
  max-width: 190px;
  left: 8px;
  margin-bottom: 3px;
  padding: 0px;
  color: ${(props: IStyledEndingPageProps) => props.theme.colors.white};
  opacity: 0;
  animation: ${subtitleKeyFrames} ${SUBTITLE_DURATION}s ${SUBTITLE_DELAY}s forwards cubic-bezier(0.35,-0.1, 0.9, 1.16);
`;

const stopBannerKeyFrames = keyframes`
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(-50%);
  }
`;

export const StyledStopBanner = styled.div`
  animation: ${stopBannerKeyFrames} ${BANNER_DURATION}s forwards cubic-bezier(0.82, 0.61, 0.72, 1.1) 0s;
  position: relative;
  top: 10%;
  transform: translateY(-50%);

  svg {
    rect, polygon, path {
      fill: ${(props: IStyledEndingPageProps) => props.theme.colors.white};
    }
  }
`;

const buttonKeyframes = keyframes`
  0% {
    transform: translate(0px, 12px);
    opacity 0;
  }
  100% {
    transform: translate(0px, 0px);
    opacity 1;
  }
`;

export const StyledButton = styled(StyledStrategyExercisesButton)`
  animation: ${buttonKeyframes} ${BUTTON_DURATION}s ${BUTTON_DELAY}s forwards cubic-bezier(0.24, -0.09, 0.84, 0.53);
  transform: translateY(100vh);
  width: calc(100% - 40px);
  position: absolute;
  bottom: 5%;
`;
