/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useRef, useState, useContext } from 'react';
import { IVideoMomentStickerRemover, ISticker } from '../../../models/IVideoMoment';
import { addPanEvent } from '../../../utils/dom';
import { StyledRemovableSticker, StyledStickerRemoverContainer, StyledStickerRemoverInner, StyledFingerHelper } from './VideoOverlayStickerRemover.styled';
import { VideoOverlayStopBanner } from '../VideoOverlayStopBanner/VideoOverlayStopBanner';
import DataLayer, { GA4ModuleCategoryEnum, GA4ModuleTypeEnum, GTM4Events } from '../../../services/DataLayer';
import { ThemeContext } from 'styled-components'

interface IVideoOverlayStickerRemoverProps {
  stickers: ISticker[];
  videoMomentStickerRemover: IVideoMomentStickerRemover;
  onDurationEnd: () => void;
  onComplete: () => void;
}

//IRemovableSticker has positioning etc
interface IRemovableSticker {
  sticker: ISticker

  xPosition: number
  yPosition: number
  delay: number
  scale: number
  rotation: number

  swipeDirection?: number[]
}

export const VideoOverlayStickerRemover: React.FC<IVideoOverlayStickerRemoverProps> = ({ onComplete, stickers, videoMomentStickerRemover, onDurationEnd }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  //stickers start appearing immediately, STOP appears when duration is up
  const duration = (videoMomentStickerRemover.duration || 1);

  const STICKER_SCATTER_CONFIGS = [
    { xPosition: 25, yPosition: 20, delay: 0.10, scale: 1.0, rotation: -4.0 },
    { xPosition: 75, yPosition: 80, delay: 0.28, scale: 0.8, rotation: 5.0 },
    { xPosition: 70, yPosition: 30, delay: 0.84, scale: 1.6, rotation: 2.0 },
    { xPosition: 40, yPosition:  5, delay: 0.76, scale: 1.2, rotation: 4.0 },
    { xPosition: 30, yPosition: 70, delay: 0.88, scale: 1.8, rotation: 4.0 },
    { xPosition: 75, yPosition: 10, delay: 0.60, scale: 1.1, rotation: -6.0 },
    { xPosition: 25, yPosition: 90, delay: 0.70, scale: 1.0, rotation: 0.0 },
    { xPosition: 60, yPosition: 95, delay: 0.80, scale: 1.3, rotation: -1.0 },
    { xPosition: 50, yPosition: 50, delay: 0.90, scale: 2.2, rotation: 5.0 },
  ];

  //Should never happen
  if (!stickers || stickers.length === 0) stickers = [{ text: 'This sucks!', bgColour: '#' } ]

  const [isSwipeReady, setIsSwipeReady] = useState(false);
  const [isBannerShown, setIsBannerShown] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  //removable stickers must be accessed via a reference during the onMove event listener 
  const [removableStickers, setRemovableStickers] = useState<IRemovableSticker[]>(STICKER_SCATTER_CONFIGS.map((scatterConfig, index) => {
    return {
      sticker: stickers[index % stickers.length],
      ...scatterConfig,
    }
  }));
  const removableStickersRef = useRef(removableStickers);
  const _setRemovableStickers = (data: IRemovableSticker[]) => {
    removableStickersRef.current = data;
    setRemovableStickers(data);
  };

  const hasStartedSwiping = !!removableStickers.find(sticker => sticker.swipeDirection);

  useEffect(() => {
    //A duration is passed in, fire 'onDurationEnd' when it is up
    setTimeout(() => {
      onDurationEnd();
      setIsBannerShown(true);
    }, duration * 1000);
  }, [duration, onDurationEnd]);

  useEffect(() => {
    // if they've all been touched, then we can call a complete
    const allSwiped = removableStickers.every((removableSticker) => !!removableSticker.swipeDirection);
    if (!allSwiped) return;

    // set a timeout and exit!
    if (!isCompleted) {
      //Without a completed check this will fire several times with state update
      setIsCompleted(true);
      onComplete();
      DataLayer.pushStickersRemoved();
      const activeChapter = DataLayer.getActiveChapter();

      if (activeChapter) {
        const customEvent: GTM4Events['quizComplete'] = {
          module: {
            module_category: GA4ModuleCategoryEnum.AOTG,
            module_id: activeChapter.id,
            module_name: 'sticker removed',
            module_subcategory: `${activeChapter.ordinal}`,
            module_type: GA4ModuleTypeEnum.chapter,
            module_all_correct: '',
            module_step_count: '',
          },
        };
        DataLayer.pushQuizComplete_GA4(customEvent);
      }
    }

  }, [isCompleted, onComplete, removableStickers]);

  const onBannerComplete = () => {
    const containerEle = containerRef.current;
    if (!containerEle) return;
    addPanEvent(containerEle, {
      'onMove': (e: React.ChangeEvent<HTMLDivElement> | any) => {
        // check if the overlap is a sticker;
        const target = e.target as HTMLOrSVGImageElement;
        if (target && target.id && target.id.indexOf('sticker') === 0) {
          const stickerIndex = parseInt(target.id.replace('sticker-', ''));

          _setRemovableStickers(removableStickersRef.current.map((removableSticker, index) => {
            return index === stickerIndex && !removableSticker.swipeDirection
              ? {
                ...removableSticker,
                swipeDirection: [e.dx, e.dy],
              }
              : removableSticker;
          }));
        }

        return true;
      },
    });

    setIsBannerShown(false);
    setIsSwipeReady(true);
  };

  return <StyledStickerRemoverContainer>
    <StyledStickerRemoverInner ref={containerRef}>
      {isBannerShown && <VideoOverlayStopBanner subtitle={videoMomentStickerRemover.messageOnRemoved} onComplete={onBannerComplete} />}
      {isSwipeReady && !hasStartedSwiping && <StyledFingerHelper />}
      {
        // draw all the stickers.
        removableStickers.map((removableSticker: IRemovableSticker, i: number) => {
          const key = `sticker-${i}-${removableSticker.swipeDirection ? 'touched' : 'untouched'}`;
          const removableStickerProps = {
            ...removableSticker,
            id: `sticker-${i}`,
            delay: removableSticker.delay * duration, //delay is a decimal scale for duration
          };
          return <StyledRemovableSticker {...removableStickerProps} key={key} />
        })
      }
    </StyledStickerRemoverInner>
  </StyledStickerRemoverContainer>;
}