import { IContentBase } from '../models/IContentBase';
import { STRATEGY_EXERCISE_TYPE, VIDEO_MOMENT_TYPE, STRENGTH_TYPE, CHAPTER_IDENTIFIER } from '../constants';

const content: IContentBase = {
  welcomeScreenVideoUrl: 'videos/welcomeScreen/rugby/background.mp4',
  finaleBackgroundUrl: 'images/dashboard/rugby/finale.webp',
  videoOverlayBackgroundUrl: 'images/dashboard/rugby/overlay.webp',
  chapters: [
    {
      chapterIdentifier: CHAPTER_IDENTIFIER.ManagingYourThoughts,
      ordinal: 1,
      title: 'Showstopper',
      strategy: 'Choose your thoughts',
      strategyMessage: 'Understand how your brain<br />can work against you.',
      dashboardImage: 'images/dashboard/rugby/chapter-1.webp',
      icon: 'images/icons/strategy/rugby/thoughts',
      videoId: '580137132',
      isFacilitatorChapter: true,
      videoMoments: [{
        type: VIDEO_MOMENT_TYPE.PhonesDown,
        seconds: null,
        shouldPause: true,
        neverShowOnReplay: true,
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: "Our brains are built to find negatives. It helps us recognise when we've made mistakes, but can hold us back.",
        seconds: 106,
        duration: 10,
        showOnlyOnReplay: true,
        stickers: [{
          text: 'THIS SUCKS',
        }, {
          text: 'SCREW-UP 😩',
        }, {
          text: '$^#&%!',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'Our negative thoughts can be very dramatic. Sometimes this means we beat ourselves up, and make things worse.',
        seconds: 127,
        duration: 10,
        showOnlyOnReplay: true,
        stickers: [{
          text: '100% LOSER',
        }, {
          text: '😑 NUMPTY!',
        }, {
          text: "I'LL NEVER LIVE THIS DOWN",
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerRemover,
        messageOnRemoved: "Don't get caught up in negative thoughts.",
        seconds: 150,
        duration: 10,
        showOnlyOnReplay: true,
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyBanner,
        shouldPause: true,
        showOnlyOnReplay: true,
      }, {
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        seconds: null,
        shouldPause: true,
        exerciseType: STRATEGY_EXERCISE_TYPE.Choose,
        exercise: {
          cardsHeading: 'Is this a <b>positive</b> thought?',
          cards: [
            {
              questionText: 'I can do this',
              isCorrect: true,
              hint: "This is both positive and confident. It's a good example of a positive thought.",
            },
            {
              questionText: "I'm good at this",
              isCorrect: true,
              hint: "This is optimistic and confident. It's a positive thought.",
            },
            {
              questionText: 'I’m a total loser',
              isCorrect: false,
              hint: 'That doesn’t sound like a very positive or confident thought. How would it feel if someone said this to you?',
            },
            {
              questionText: 'I’ve done this before. I’ve got this.',
              isCorrect: true,
              hint: "This is a reassuring and positive thought. It's a good example of backing yourself.",
            },
            {
              questionText: "I'm an idiot",
              isCorrect: false,
              hint: "Nope not a positive thought. You wouldn't say this to a mate who was feeling down on themselves.",
            },
            {
              questionText: 'I’ll be alright. No big deal',
              isCorrect: true,
              hint: "It's a good start in positive thinking.",
            },
            {
              questionText: 'I’ll bounce back',
              isCorrect: true,
              hint: "Telling yourself that you'll bounce back is a resilient and positive thought.",
            },
            {
              questionText: 'I’m such a numpty',
              isCorrect: false,
              hint: "Doesn't sound very positive. Positive self-talk is what you want to aim for.",
            },
            {
              questionText: 'I totally suck!',
              isCorrect: false,
              hint: "You wouldn't say it to a mate to help them out. It's not positive or confident.",
            },
            {
              questionText: "I'm doing ok",
              isCorrect: true,
              hint: "I'm doing ok is a good start in positive thinking.",
            },
          ],
        },
      }, {
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        seconds: null,
        shouldPause: true,
        exerciseType: STRATEGY_EXERCISE_TYPE.Choose,
        exercise: {
          cardsHeading: 'Is this an <b>instructional</b> thought?',
          cards: [
            {
              questionText: "I've got to put more effort in during training",
              isCorrect: true,
              hint: "It's action based so it's an instructional thought.",
            },
            {
              questionText: 'I should maybe try NOT sucking',
              isCorrect: false,
              hint: "Instructional thoughts focus on things you SHOULD do rather than things you shouldn't do. Like Improving certain parts of your game.",
            },
            {
              questionText: 'I need to keep working on my passing technique',
              isCorrect: true,
              hint: "Nope. That’s an instructional thought. It's about taking action.",
            },
            {
              questionText: 'I suck',
              isCorrect: false,
              hint: 'That’s not a positive or instructional thought.',
            },
            {
              questionText: 'I need to stop missing so many goals',
              isCorrect: false,
              hint: "For instructional thoughts you want to focus on what you should do, rather than things you shouldn't be doing.",
            },
            {
              questionText: 'I should practice my kicking',
              isCorrect: true,
              hint: 'This is actually an instructional thought. It involves taking action and focusing on what you should do.',
            },
            {
              questionText: 'Time for a sausage roll break',
              isCorrect: false,
              hint: 'Good idea. But not very useful.',
            },
            {
              questionText: 'I need to keep working on my passing',
              isCorrect: true,
              hint: "This seems like a good option - it's an instructional thought.",
            },
            {
              questionText: "I've got a long way to go",
              isCorrect: false,
              hint: 'That’s not really instructional or very positive.',
            },
            {
              questionText: 'I need to work more on my catching',
              isCorrect: true,
              hint: "This is a plan of action so it's an instructional thought.",
            },
          ],
        },
      }, {
        type: VIDEO_MOMENT_TYPE.PhonesDown,
        seconds: null,
        shouldPause: true,
        neverShowOnReplay: true,
      }, {
        type: VIDEO_MOMENT_TYPE.SummaryPage,
        seconds: null,
        shouldPause: true,
        resumeAt: 161.5,
        },
      ],
      achievementScreen: {
        title: 'NICE ONE',
        description: "You've completed chapter 1. <br /><br /> Great start {firstName}.",
      },
      myLearningsDescription: "<ul><li>In challenging moments, we can have lots of different thoughts. </li> <li>Some are helpful others might be unhelpful. </li> <li>Remember, don't beat yourself up with unhelpful thoughts.</li><li>Choose helpful thoughts as they can help how we feel. </li> <li>Remember to lean on your team for support.</li></ul>",
    },
    {
      chapterIdentifier: CHAPTER_IDENTIFIER.ProblemSolving,
      ordinal: 2,
      title: "Scouts' game",
      strategy: 'Make a plan',
      strategyMessage: 'Use simple steps to help<br />you problem solve tricky situations.',
      dashboardImage: 'images/dashboard/rugby/chapter-2.webp',
      icon: 'images/icons/strategy/rugby/plan',
      videoId: '582733854',
      videoMoments: [{
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: "When things don't go our way, it's easy to react with anger.",
        seconds: 36.1,
        duration: 8.6,
        stickers: [{
          text: 'DISASTER 💣!',
        }, {
          text: 'UNBELIEVABLE!',
        }, {
          text: "YOU'VE GOTTA BE JOKING!",
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: "When things don't go our way, we might try and run away, or deny what's happening.",
        seconds: 53.6,
        duration: 8.7,
        stickers: [{
          text: 'This sucks!',
        }, {
          text: 'I’m outta here 🏃',
        }, {
          text: '$^#&% IT ALL!',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerRemover,
        messageOnRemoved: "Don't jump to conclusions.",
        seconds: 76.7,
        duration: 7.6,
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyBanner,
        shouldPause: true,
      }, {
        seconds: null,
        shouldPause: true,
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        exerciseType: STRATEGY_EXERCISE_TYPE.Plan,
        exercise: {
          maxPlansToChoose: 2,
          plans: [{
            text: 'Quit the team',
            questions: [{
              text: 'Will this help him stay connected to his team?',
              answeredText: "This won't help him stay connected to the team.",
              isCorrect: false,
              feedback: 'Quitting the team would likely make him lose social connection to his teammates.',
            }, {
              text: 'Will this help him maintain his fitness?',
              answeredText: "This won't help him maintain his fitness.",
              isCorrect: false,
              feedback: 'Quitting the team will likely cause him to lose his fitness.',
            }, {
              text: 'Will this reduce the risk of further injury?',
              answeredText: 'This will reduce the risk of further injury.',
              isCorrect: true,
              feedback: 'Quitting the team will definitely reduce the risk of further injury.',
            }, {
              text: 'Will this help him prepare for the scouts?',
              answeredText: 'This won’t help him prepare for the scouts.',
              isCorrect: false,
              feedback: 'Quitting the team means he’ll never get back to playing, and the scouts will never see him play.',
            }],
          }, {
            text: 'Map out a gradual return to training',
            questions: [{
              text: 'Will this help him stay connected to his team?',
              answeredText: 'This will help him stay connected to the team.',
              isCorrect: true,
              feedback: 'A gradual return to training is a great way to ensure he maintains social connection.',
            }, {
              text: 'Will this help him maintain his fitness?',
              answeredText: 'This will help him maintain his fitness.',
              isCorrect: true,
              feedback: 'A gradual return to training is a great way to help him maintain his fitness as best he can.',
            }, {
              text: 'Will this reduce the risk of further injury?',
              answeredText: 'This will reduce the risk of further injury.',
              isCorrect: true,
              feedback: 'A gradual, and well-supervised return to training is a great way to help reduce the risk of further injury.',
            }, {
              text: 'Will this help him prepare for the scouts?',
              answeredText: 'This will help him prepare for the scouts.',
              isCorrect: true,
              feedback: 'He might not be ready immediately, but a gradual return to training will ensure he is prepared as soon as possible.',
            }],
          }, {
            text: 'Help with coaching the team',
            questions: [{
              text: 'Will this help him stay connected to his team?',
              answeredText: 'This will help him stay connected to the team.',
              isCorrect: true,
              feedback: 'Helping to coach the team would be a great way to stay connected with the team.',
            }, {
              text: 'Will this help him maintain his fitness?',
              answeredText: "This won't help him maintain his fitness.",
              isCorrect: false,
              feedback: 'Helping to coach the team will keep him involved, but is unlikely to maintain his fitness.',
            }, {
              text: 'Will this reduce the risk of further injury?',
              answeredText: 'This will reduce the risk of further injury.',
              isCorrect: true,
              feedback: "Helping to coach the team is a great way to keep out of harm's way, and reduce the risk of further injury.",
            }, {
              text: 'Will this help him prepare for the scouts?',
              answeredText: 'This won’t help him prepare for the scouts.',
              isCorrect: false,
              feedback: 'Helping to coach the team will keep him involved, but won’t necessarily help him get back on his feet for the scouts.',
            }],
          }, {
            text: 'Shake it off and do your own training',
            questions: [{
              text: 'Will this help him stay connected to his team?',
              answeredText: "This won't help him stay connected to the team.",
              isCorrect: false,
              feedback: 'Training independently is unlikely to help him stay connected to his teammates.',
            }, {
              text: 'Will this help him maintain his fitness?',
              answeredText: 'This will help him maintain his fitness.',
              isCorrect: true,
              feedback: 'Doing your own training, while risky, may help him maintain his fitness.',
            }, {
              text: 'Will this reduce the risk of further injury?',
              answeredText: "This won't reduce the risk of further injury.",
              isCorrect: false,
              feedback: 'Training without proper advice or supervision could easily make his injury worse.',
            }, {
              text: 'Will this help him prepare for the scouts?',
              answeredText: 'This won’t help him prepare for the scouts.',
              isCorrect: false,
              feedback: 'Training with an injury is more likely to cause additional damage, and hold him back from returning to play.',
            }],
          }, {
            text: 'Keep playing',
            questions: [{
              text: 'Will this help him stay connected to his team?',
              answeredText: 'This will help him stay connected to the team.',
              isCorrect: true,
              feedback: 'Continuing to play is risky, but will definitely keep him connected to the team.',
            }, {
              text: 'Will this help him maintain his fitness?',
              answeredText: 'This will help him maintain his fitness.',
              isCorrect: true,
              feedback: 'Continuing to play is risky, but would probably help him maintain his fitness.',
            }, {
              text: 'Will this reduce the risk of further injury?',
              answeredText: "This won't reduce the risk of further injury.",
              isCorrect: false,
              feedback: 'Playing with an injury despite medical advice will likely make his injury worse.',
            }, {
              text: 'Will this help him prepare for the scouts?',
              answeredText: 'This won’t help him prepare for the scouts.',
              isCorrect: false,
              feedback: 'Playing with an injury is more likely to cause additional damage, and hold him back from performing long-term.',
            }],
          }],
        },
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.SummaryPage,
        shouldPause: true,
        resumeAt: 86,
      }],
      achievementScreen: {
        title: 'GOING STRONG',
        description: 'Chapter 2 all done. <br /><br /> Check out the next chapter.',
      },
      myLearningsDescription: '<ul><li>When faced with a challenge, sometimes we can fear the worst.</li><li>You could take a walk to clear your head.</li><li>Think about the problem and brainstorm lots of solutions.</li><li>Weigh up pros and cons.</li><li>Chose an option, plan the steps and give it a go.</li><li>If Plan A isn\'t working, there are lots of different options, give Plan B a go.</li><li>Remember to lean on your team when faced with a challenge.</li></ul>',
    },
    {
      chapterIdentifier: CHAPTER_IDENTIFIER.PlayingToYourStrengths,
      ordinal: 3,
      title: 'Training troubles',
      strategy: 'Play to your strengths',
      strategyMessage: 'Determine your own<br />strengths and focus on<br />them.',
      dashboardImage: 'images/dashboard/rugby/chapter-3.webp',
      icon: 'images/icons/strategy/rugby/strength',
      videoId: '580138364',
      videoMoments: [{
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: "When things aren't going to plan, it's easy to focus on our weaknesses as the only way out.",
        seconds: 15.1,
        duration: 9,
        stickers: [{
          text: 'NOT FEELING IT TODAY',
        }, {
          text: 'WHAT AM I DOING HERE?',
        }, {
          text: 'BLURGH 😖',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: "It's easy to be preoccupied with trying to fix our weaknesses.",
        seconds: 48.9,
        duration: 9,
        stickers: [{
          text: 'GET OFF MY CASE 😕',
        }, {
          text: "WHY CAN'T I DO THIS!?",
        }, {
          text: 'GOTTA BEAT THIS!',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerRemover,
        messageOnRemoved: "Don't get caught up in your weaknesses.",
        seconds: 73.3,
        duration: 5.3,
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyBanner,
        shouldPause: true,
      }, {
        seconds: null,
        shouldPause: true,
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        exerciseType: STRATEGY_EXERCISE_TYPE.Strengths,
        exercise: {
          promptSets: [{
            emptyText: 'Explore different ways to improve this strength.',
            prompts: [{
              strengthCardsType: STRENGTH_TYPE.OnField,
              instruction: 'Think about <b>your</b> own strengths.<br/>Select one of your <b>on-field</b> strengths.',
              instructionCustom: 'Name your <b>on-field</b> strength',
              questions: [{
                text: 'Can you look for opportunities to <b>use</b> this strength more?',
                answeredText: 'I can look for opportunities to use this strength more',
              }, {
                text: 'Can you <b>improve</b> on this strength through training?',
                answeredText: 'I can work on improving it',
              }, {
                text: 'Can you <b>help others</b> develop this strength?',
                answeredText: 'I can share it with others',
              }],
            },
            {
              strengthCardsType: STRENGTH_TYPE.OffField,
              instruction: 'Select one of your <b>off-field</b> strengths.',
              instructionCustom: 'Name your <b>off-field</b> strength',
              emptyText: 'Explore different ways to improve this strength.',
              questions: [{
                text: 'Can you look for opportunities to <b>use</b> this strength more?',
                answeredText: 'I can look for opportunities to use this strength more',
              }, {
                text: 'Can you <b>help others</b> with this strength?',
                answeredText: 'I can use it to help others',
              }, {
                text: 'Can you use this strength in your <b>sport</b>?',
                answeredText: 'I can use it in my sport',
              }],
            }],
          }, {
            emptyText: 'Explore different ways to improve this attribute.',
            prompts: [{
              strengthCardsType: STRENGTH_TYPE.OnField,
              instruction: 'Select one of your <b>on-field</b> attributes.',
              instructionCustom: 'Name your <b>on-field</b> attribute',
              questions: [{
                text: 'Can you look for opportunities to <b>use</b> this attribute more?',
                answeredText: 'I can look for opportunities to use this attribute more',
              }, {
                text: 'Can you <b>improve</b> on this attribute through training?',
                answeredText: 'I can work on improving it',
              }, {
                text: 'Can you <b>help others</b> develop this attribute?',
                answeredText: 'I can share it with others',
              }],
            },
            {
              strengthCardsType: STRENGTH_TYPE.OffField,
              instruction: 'Select one of your <b>off-field</b> attributes.',
              instructionCustom: 'Name your <b>off-field</b> attribute',
              questions: [{
                text: 'Can you look for opportunities to <b>use</b> this attribute more?',
                answeredText: 'I can look for opportunities to use this attribute more',
              }, {
                text: 'Can you <b>help others</b> with this attribute?',
                answeredText: 'I can use it to help others',
              }, {
                text: 'Can you use this attribute in your <b>sport</b>?',
                answeredText: 'I can use it in my sport',
              }],
            }],
          }],
        },
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        shouldPause: true,
        neverShowOnReplay: true,
        exerciseType: STRATEGY_EXERCISE_TYPE.StrengthsTeammates,
        exercise: {},
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.SummaryPage,
        shouldPause: true,
        resumeAt: 79.5,
      }],
      achievementScreen: {
        title: "YOU'VE GOT THIS",
        description: 'Over half-way there. <br /><br /> Chapter 4 coming up.',
      },
      myLearningsDescription: '<ul><li>Think about your strengths ON and OFF the field and how to play to these strengths.</li><li>Use your strengths more</li><li>Improve through training</li><li>Help others develop their strengths</li><li>Your team (ON and OFF the field) can help you understand your strengths.</li><li>Remember to remind your team of their strengths.</li></ul>',
    },
    {
      chapterIdentifier: CHAPTER_IDENTIFIER.ControllingTheControllables,
      ordinal: 4,
      title: 'False start',
      strategy: 'Control the controll&shy;ables',
      strategyMessage: 'Some things we can’t<br />control. Let’s focus on what<br />we can.',
      dashboardImage: 'images/dashboard/rugby/chapter-4.webp',
      icon: 'images/icons/strategy/rugby/control',
      videoId: '580138431',
      videoMoments: [{
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: "It's easy to get upset with forces beyond our control. Especially when they hold us back.",
        seconds: 12.4,
        duration: 8.4,
        stickers: [{
          text: 'THIS IS STUPID',
        }, {
          text: '🤨 UNFAIR',
        }, {
          text: 'WHAT THE HELL!?',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'We might blame everything and everyone else for our misfortune, and ignore the things we can control.',
        seconds: 34,
        duration: 10.1,
        stickers: [{
          text: "IT'S NOT MY FAULT 😡",
        }, {
          text: 'WHAT ATTITUDE!? 🤬',
        }, {
          text: "THEY DON'T UNDERSTAND!",
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerRemover,
        messageOnRemoved: "Don't get hung up on things you can't control.",
        seconds: 50.4,
        duration: 10.8,
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyBanner,
        shouldPause: true,
      }, {
        seconds: null,
        shouldPause: true,
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        exerciseType: STRATEGY_EXERCISE_TYPE.Control,
        exercise: {
          numberOfCardsToChoose: 4,
          controlCards: [{
            text: 'The ref',
            icon: 'images/icons/control/rugby/ref',
            isControllable: false,
            controlFeedback: 'The ref is not within his control. No matter how much he kicks and screams, he can’t change the ref’s decision.',
            isImmediatelyControllable: false,
          }, {
            text: 'His opponents',
            icon: 'images/icons/control/rugby/opponents',
            isControllable: false,
            controlFeedback: 'He has no control over his opponents.',
            isImmediatelyControllable: false,
          }, {
            text: 'His teammates',
            icon: 'images/icons/control/rugby/teammates',
            isControllable: false,
            controlFeedback: 'The only player in his team he has control over is himself.',
            isImmediatelyControllable: false,
          }, {
            text: 'The weather',
            icon: 'images/icons/control/rugby/weather',
            isControllable: false,
            controlFeedback: 'That would be handy but no he has no control over the weather.',
            isImmediatelyControllable: false,
          }, {
            text: 'His performance',
            icon: 'images/icons/control/rugby/performance',
            isControllable: false,
            controlFeedback: "He can't control his overall performance. But he might be able to control parts of it like his pre-kick routine.",
            isImmediatelyControllable: false,
          }, {
            text: 'His language',
            icon: 'images/icons/control/rugby/language',
            isControllable: true,
            controlFeedback: 'His language is within his control. He needs to focus on things he can control.',
            isImmediatelyControllable: false,
            immediateControlFeedback: 'He should definitely watch his language. But there is something else he should focus on right now.',
          }, {
            text: 'The coach',
            icon: 'images/icons/control/rugby/coach',
            isControllable: false,
            controlFeedback: 'Nope. The coach is not something within his control.',
            isImmediatelyControllable: false,
          }, {
            text: 'His effort',
            icon: 'images/icons/control/rugby/effort',
            isControllable: true,
            controlFeedback: 'You got it. His effort is something he can control.',
            isImmediatelyControllable: false,
            immediateControlFeedback: 'He should definitely keep putting effort in. But there is something else he should focus on right now.',
          }, {
            text: 'His attitude',
            icon: 'images/icons/control/rugby/reactions',
            isControllable: true,
            controlFeedback: 'His attitude is within his control, even if it feels tough sometimes.',
            isImmediatelyControllable: true,
            immediateControlFeedback: "His coach, and others, have made it clear that his attitude is the problem, and it's something he can think about taking control of straight away.",
          }, {
            text: 'The pitch',
            icon: 'images/icons/control/rugby/pitch',
            isControllable: false,
            controlFeedback: "That's out of his control.",
            isImmediatelyControllable: false,
          }, {
            text: 'His fitness',
            icon: 'images/icons/control/rugby/fitness',
            isControllable: true,
            controlFeedback: 'His fitness is something he can work on and have some control over.',
            isImmediatelyControllable: false,
            immediateControlFeedback: 'His fitness is not something he can fix right away. Another option might be more important in this moment.',
          }, {
            text: 'The wind',
            icon: 'images/icons/control/rugby/wind',
            isControllable: false,
            controlFeedback: "He can't control how the wind effects the ball when he kicks it.",
            isImmediatelyControllable: false,
          }],
        },
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.SummaryPage,
        shouldPause: true,
        resumeAt: 62.5,
      }],
      achievementScreen: {
        title: 'YOU NAILED IT',
        description: "Almost done! <br /><br /> You're up to the last chapter.",
      },
      myLearningsDescription: "<ul><li>When in a challenging situation there are things that you CAN control and things you can't control.</li><li>Look at what you CAN control.</li><li>Focus on acting on things you CAN control.</li><li>Also focus on appreciating your support team (ON and OFF the field).</li></ul>",
    },
    {
      chapterIdentifier: CHAPTER_IDENTIFIER.KeepingYourCool,
      ordinal: 5,
      title: 'Second chance',
      strategy: 'Keep your cool',
      strategyMessage: '<b>Remember to focus on your breathing.</b> <br/><br/>Controlled breathing is a quick hack to calm our body in tense moments. <br/>Find a quiet place and take a few moments to complete this exercise. <br/>It will take less than a minute.',
      dashboardImage: 'images/dashboard/rugby/chapter-5.webp',
      icon: 'images/icons/strategy/rugby/breathe',
      videoId: '580138519',
      videoMoments: [{
        seconds: 44.9,
        type: VIDEO_MOMENT_TYPE.StrategyBanner,
        shouldPause: true,
      }, 
      {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        shouldPause: true,
        exerciseType: STRATEGY_EXERCISE_TYPE.Breathe,
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.SummaryPage,
        shouldPause: true,
        resumeAt: 47,
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'Staying focused, keeping calm, and taking a deep breath is a perfect way to settle the nerves.',
        seconds: 49.3,
        duration: 6,
        stickers: [{
          text: 'just me and the ball 🏉',
        }, {
          text: '😌 keep calm',
        }, {
          text: 'BREATHE 💨',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'With smart, calm choices about how we approach our challenges, we give ourselves the best chance at success.',
        seconds: 64.9,
        duration: 6.3,
        stickers: [{
          text: 'I can do this ⭐',
        }, {
          text: 'I can do this 🙂',
        }, {
          text: 'I can do this 💪',
        }],
      }],
      myLearningsDescription: 'It’s easy to get fired up, or anxious in high-pressure moments. But pausing for a moment to take a few breaths can help keep us cool, calm, and in control. <br><br>Remember:<br><ul><li>Breathe in for 4 seconds</li><li>Hold for 4 seconds</li><li>Breathe out for 4 seconds</li></ul>',
    },
  ],
  strengthCards: {
    [STRENGTH_TYPE.OnField]: [
      {
        text: 'Passing',
        slug: 'passing',
        icon: 'images/icons/strength/rugby/on-field/passing',
      }, {
        text: 'Running',
        slug: 'running',
        icon: 'images/icons/strength/rugby/on-field/running',
      }, {
        text: 'Support play',
        slug: 'support-play',
        icon: 'images/icons/strength/rugby/on-field/support-play',
      }, {
        text: 'Tackling',
        slug: 'tackling',
        icon: 'images/icons/strength/rugby/on-field/tackling',
      }, {
        text: 'Decision making',
        slug: 'decision-making',
        icon: 'images/icons/strength/rugby/on-field/decision-making',
      }, {
        text: 'Fair play',
        slug: 'fair-play',
        icon: 'images/icons/strength/rugby/on-field/fair-play',
      }, {
        text: 'Team player',
        slug: 'team-player',
        icon: 'images/icons/strength/rugby/on-field/team-player',
      }, {
        text: 'Leadership',
        slug: 'leadership',
        icon: 'images/icons/strength/rugby/on-field/leadership',
      },
    ],
    [STRENGTH_TYPE.OffField]: [
      {
        text: 'Sense of humour',
        slug: 'sense-of-humour',
        icon: 'images/icons/strength/rugby/off-field/humour',
      }, {
        text: 'Adaptable',
        slug: 'adaptable',
        icon: 'images/icons/strength/rugby/off-field/adaptable',
      }, {
        text: 'Kind',
        slug: 'kind',
        icon: 'images/icons/strength/rugby/off-field/kind',
      }, {
        text: 'Motivated',
        slug: 'motivated',
        icon: 'images/icons/strength/rugby/off-field/motivated',
      }, {
        text: 'Creative',
        slug: 'creative',
        icon: 'images/icons/strength/rugby/off-field/creative',
      }, {
        text: 'Determined',
        slug: 'determined',
        icon: 'images/icons/strength/rugby/off-field/determined',
      }, {
        text: 'Dedicated',
        slug: 'dedicated',
        icon: 'images/icons/strength/rugby/off-field/dedicated',
      }, {
        text: 'Patient',
        slug: 'patient',
        icon: 'images/icons/strength/rugby/off-field/patient',
      }, {
        text: 'Trustworthy',
        slug: 'trustworthy',
        icon: 'images/icons/strength/rugby/off-field/trustworthy',
      }, {
        text: 'Friendly',
        slug: 'friendly',
        icon: 'images/icons/strength/rugby/off-field/friendly',
      },
    ],
  },
};

export default content;