/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useLayoutEffect, useState, useRef } from 'react';
import { IChapter } from '../../models/IChapter';
import { IVideoMoment, IVideoMomentStickerSelector, IVideoMomentStickerRemover, ISticker, IVideoMomentStrategy } from '../../models/IVideoMoment';
import { StyledChapterVideo } from './ChapterVideo.styled';
import { VideoOverlayStrategyBanner } from '../VideoOverlay/VideoOverlayStrategyBanner/VideoOverlayStrategyBanner';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';
import { TRANSITION_TYPES, VIDEO_MOMENT_TYPE, TRANSITION_CONFIGS, STRATEGY_EXERCISE_TYPE, SPORT_TYPE } from '../../constants';
import { VideoOverlayStickerSelector } from '../VideoOverlay/VideoOverlayStickerSelector/VideoOverlayStickerSelector';
import { VideoOverlayStickerRemover } from '../VideoOverlay/VideoOverlayStickerRemover/VideoOverlayStickerRemover';
import { VideoOverlayStrategyExercise } from '../VideoOverlay/VideoOverlayStrategyExercise/VideoOverlayStrategyExercise';
import { NavButton } from '../NavButton/NavButton';
import { useTransition } from '../Transition/TransitionContext/TransitionContext';
import PlayerData from '../../services/PlayerData';
import { PhonesDown } from '../PhonesDown/PhonesDown';
import { StrategySummary } from '../StrategySummary/StrategySummary';
import DataLayer, { GA4ModuleCategoryEnum, GA4ModuleTypeEnum, GTM4Events } from '../../services/DataLayer';
import Content from '../../services/Content';
import Api from '../../services/Api';
import { AchievementScreen } from '../AchievementScreen/AchievementScreen';
import { useNavigate } from 'react-router-dom';
import content from '../../content/gb_rugby';
import CohortData from 'services/CohortData';

interface IChapterVideoProps {
  chapter: IChapter
}

export const ChapterVideo: React.FC<IChapterVideoProps> = ({ chapter }) => {
  const FADE_OUT_SECONDS = TRANSITION_CONFIGS[TRANSITION_TYPES.ChapterComplete][0].durationSeconds;
  const VIDEO_SETTIMEOUT_DELAY_FACTOR = 100;

  const navigate = useNavigate();
  const { setTransition } = useTransition();

  // Video pause/play (set upon video ready)
  const _playFunction = useRef<(() => void) | null>(null);
  const _pauseFunction = useRef<(() => void) | null>(null);
  const _seekFunction = useRef<((seconds: number) => void) | null>(null);
  const pauseVideo = () => _pauseFunction.current && _pauseFunction.current();
  const playVideo = () => _playFunction.current && _playFunction.current();
  const seekVideo = (seconds: number) => _seekFunction.current && _seekFunction.current(seconds);

  const [activeVideoMoment, setActiveVideoMoment] = useState<IVideoMoment | null>(null);
  const [achievementScreenShown, setAchievementScreenShown] = useState<boolean>(false);

  const isEndSequenceOccurring = useRef<boolean>(false);
  const encounteredVideoMoments = useRef<IVideoMoment[]>([]); // @todo - this should be useState

  const [isTeamFeedbackEnabled, setIsTeamFeedbackEnabled] = useState<boolean>(false);

  const isChapterReplay = useRef<boolean | null>(null);

  useLayoutEffect(() => {
    DataLayer.pushChapterStart(chapter);
    const newModule: GTM4Events['chapterStart'] = {
      module: {
        module_category: GA4ModuleCategoryEnum.AOTG,
        module_id: chapter.chapterIdentifier,
        module_name: chapter.title,
        module_subcategory: `${chapter.ordinal}`,
        module_type: GA4ModuleTypeEnum.chapter,
      },
    };
    DataLayer.pushChapterStart_GA4(newModule);
    const now = new Date().getTime();
    DataLayer.setActiveChapter({ id: chapter.chapterIdentifier, title: chapter.title, ordinal: chapter.ordinal, startTime: now });
    PlayerData.setChapterStartedAt(chapter.chapterIdentifier);

    //On first render, check if the first moment is at zero seconds- show it immediately if so.
    //This is an edge case for facilitator chapters which have no video and only moments
    if (isFirstMomentAtZero) handleVideoMoment(firstVideoMoment);

    const cohortData = CohortData.getCohortData();
    setIsTeamFeedbackEnabled(cohortData.IsTeamFeedbackEnabled);
  }, []);

  const selectedStickers = useRef<ISticker[]>([]);

  // prevent the replay being recalculated.
  if (isChapterReplay.current === null) {
    isChapterReplay.current = PlayerData.hasCompletedChapter(chapter.chapterIdentifier);
  }
  const isFirstPlaythroughOfFacilitatorChapter = chapter.isFacilitatorChapter && !isChapterReplay.current;
  // certain video moments show on first play and replay 
  const chapterVideoMoments = isChapterReplay.current
    ? chapter.videoMoments.filter(videoMoment => !videoMoment.neverShowOnReplay)
    : chapter.videoMoments.filter(videoMoment => !videoMoment.showOnlyOnReplay);

  const firstVideoMoment = chapterVideoMoments[0];
  const isFirstMomentAtZero = firstVideoMoment && firstVideoMoment.seconds === null;

  const markChapterAsCompleted = () => {
    PlayerData.setChapterCompleted(chapter.chapterIdentifier)
      // eslint-disable-next-line no-console
      .catch(console.log);
  }

  const recordChapterCompletionEvent = () => {
    DataLayer.pushChapterEnd();
    const newModule: GTM4Events['chapterStart'] = {
      module: {
        module_category: GA4ModuleCategoryEnum.AOTG,
        module_id: chapter.chapterIdentifier,
        module_name: chapter.title,
        module_subcategory: `${chapter.ordinal}`,
        module_type: GA4ModuleTypeEnum.chapter,
      },
    };
    DataLayer.pushChapterComplete_GA4(newModule);
  }

  const onTimeUpdate = (currentSeconds: number, durationSeconds: number) => {
    DataLayer.pushChapterVideoTimeUpdate(currentSeconds);

    //Check video moment
    if (activeVideoMoment) return; // Ignore timeupdate if current video moment is set)

    // Find next video moment
    const nextVideoMoment = chapterVideoMoments[encounteredVideoMoments.current.length];

    if (nextVideoMoment
      && nextVideoMoment.seconds
      && currentSeconds >= nextVideoMoment.seconds
      && !encounteredVideoMoments.current.includes(nextVideoMoment)) {
      //We have a video moment at this point in time to show! Handle it!
      handleVideoMoment(nextVideoMoment);

    } else if (durationSeconds - currentSeconds <= FADE_OUT_SECONDS && !isEndSequenceOccurring.current) {
      handleEndSequence();
    }
  };

  const handleEndSequence = () => {
    //The end SEQUENCE is the place where we mark stuff as done, and we optionally show the achievement screen,
    // before heading to the end TRANSITION (the actual fade out and move back to dashboard).
    if (!isEndSequenceOccurring.current) {
      //We check this ref to make sure this function isn't called multiple times, as it is triggered by onTimeUpdate which will continue to hit it after the first call
      isEndSequenceOccurring.current = true;

      //Before the achievment screen is shown or the end transition begins
      // we mark the chapter as completed in case someone exits the app (they're essentially done at this point)
      markChapterAsCompleted();
      recordChapterCompletionEvent(); //TODO LUKE: this was in setttimeout below but now it's not... okay?

      if (chapter.achievementScreen && !isChapterReplay.current) {
        setAchievementScreenShown(true);
      } else {
        doEndTransition();
      }
    }
  }

  const doEndTransition = () => {
    const allChapters = Content.getAllChapters();
    const isLastChapter = allChapters.indexOf(chapter) === allChapters.length - 1;
    const isFirstChapter = allChapters.indexOf(chapter) === 0;

    const currentChapterIndex = allChapters.indexOf(chapter);
    const nextChapterIndex = isLastChapter ? currentChapterIndex : currentChapterIndex + 1;
    const nextChapter = allChapters[nextChapterIndex];

    if (!isChapterReplay.current) {
      const getTransitionType = () => {
        if (isLastChapter) return TRANSITION_TYPES.FinalChapterFirstComplete;

        return TRANSITION_TYPES.ChapterFirstComplete;
      };
      const nextTransitionType = getTransitionType();

      setTransition(nextTransitionType, chapter);
    } else {
      //Usual chapter exit transition, with no user progress update
      setTransition(TRANSITION_TYPES.ChapterComplete, chapter);
    }

    const isNextChapterFacilitator = !isLastChapter && nextChapter.isFacilitatorChapter; //checking if next chapter is a facilitator chapter

    setTimeout(() => {
      if (isFirstPlaythroughOfFacilitatorChapter) {
        if (isNextChapterFacilitator) {
          navigate(`/chapter/${nextChapter.chapterIdentifier}`)

          return;
        }
      }
      navigate(`/dashboard?cid=${chapter.chapterIdentifier}`);
    }, FADE_OUT_SECONDS * 1000);
  };

  const handleVideoMoment = (videoMoment: IVideoMoment) => {
    // Set current video moment
    setActiveVideoMoment(videoMoment);
    encounteredVideoMoments.current = [...encounteredVideoMoments.current, videoMoment];
    DataLayer.pushChapterVideoMoment(videoMoment.type);
    if (videoMoment.shouldPause) pauseVideo(); //LUKE: async await?

    //Sometimes we want to skip a bit of black screen/fluff in the video when resuming after a video moment (ie. seek to a certain position in the player)
    //So we jump to that position IN ADVANCE immediately upon hitting the video moment, so that when the moment is completed it's ready to resume from the right spot.
    //As Perry advised previously: it's not worth trying to do this at a later point (eg. as soon as the moment is done), the video player is too tempermental
    if (seekVideo && videoMoment.resumeAt) seekVideo(videoMoment.resumeAt);
  };

  const onVideoMomentComplete = () => {
    if (!activeVideoMoment) return; //Ignore this complete if no current video moment set
    const videoMoments = chapterVideoMoments;
    const currentMoment = videoMoments.indexOf(activeVideoMoment);
    const nextVideoMoment = videoMoments[currentMoment + 1];

    // If the next video moment seconds is null directly jump to it directly
    if (nextVideoMoment && nextVideoMoment.seconds === null) return handleVideoMoment(nextVideoMoment);

    // There is no video on the first playthrough of a facilitator chapter (it's on the big screen in person)
    if (isFirstPlaythroughOfFacilitatorChapter) return handleEndSequence();

    // If the next video moment is further along, resume the video and clear the active video moment
    playVideo();
    setTimeout(() => setActiveVideoMoment(null), VIDEO_SETTIMEOUT_DELAY_FACTOR); //Slight delay to prevent flash of player controls
  };

  const getActiveVideoMomentComponent = () => {
    if (!activeVideoMoment) return null;

    switch (activeVideoMoment.type) {
      case VIDEO_MOMENT_TYPE.StickerSelector:
        return <VideoOverlayStickerSelector
          videoMomentStickerSelector={activeVideoMoment as IVideoMomentStickerSelector}
          onStickerSelected={(sticker: ISticker) => {
            selectedStickers.current = [...selectedStickers.current, sticker];
          }}
          onDurationEnd={() => pauseVideo()}
          onComplete={onVideoMomentComplete} />;

      case VIDEO_MOMENT_TYPE.StickerRemover:
        //Replace
        return <VideoOverlayStickerRemover
          stickers={selectedStickers.current}
          videoMomentStickerRemover={activeVideoMoment as IVideoMomentStickerRemover}
          onDurationEnd={() => pauseVideo()}
          onComplete={() => {
            selectedStickers.current = [];
            onVideoMomentComplete();
          }} />;

      case VIDEO_MOMENT_TYPE.StrategyBanner:
        return <VideoOverlayStrategyBanner onComplete={onVideoMomentComplete} chapter={chapter} />;

      case VIDEO_MOMENT_TYPE.SummaryPage:
        return <StrategySummary onComplete={onVideoMomentComplete} chapter={chapter} />;

      case VIDEO_MOMENT_TYPE.PhonesDown:
        return <PhonesDown onComplete={onVideoMomentComplete} />;

      case VIDEO_MOMENT_TYPE.StrategyExercise:
        const asVideoMomentStrategy = activeVideoMoment as IVideoMomentStrategy;
        if (!isTeamFeedbackEnabled && asVideoMomentStrategy.exerciseType === STRATEGY_EXERCISE_TYPE.StrengthsTeammates) {
          //Do not allow team feedback exercise if feature not enabled
          onVideoMomentComplete();
          break;
        } else {
          return <VideoOverlayStrategyExercise
            key={chapterVideoMoments.indexOf(activeVideoMoment)}
            chapter={chapter}
            onComplete={onVideoMomentComplete}
            exerciseType={asVideoMomentStrategy.exerciseType}
            exercise={asVideoMomentStrategy.exercise} />;
        }

      default:
        return <span>Unknown videoMoment type: {activeVideoMoment.type}</span>;
    }
  };

  const activeVideoMomentComponent = getActiveVideoMomentComponent();

  return <StyledChapterVideo image={chapter.dashboardImage}>
    {!activeVideoMoment && isChapterReplay.current && <NavButton chapterIdentifier={chapter.chapterIdentifier} />}
    {achievementScreenShown
      ? <AchievementScreen chapter={chapter} onComplete={doEndTransition} />
      : isFirstPlaythroughOfFacilitatorChapter
        ? activeVideoMomentComponent
        : <VideoPlayer
          videoMoments={chapter.videoMoments}
          autoplay={!isFirstMomentAtZero}
          videoId={chapter.videoId}
          onTimeUpdate={onTimeUpdate}
          isForwardSeekingDisabled={false}
          onReady={(playerPlay: () => void, playerPause: () => void, playerSeek: (seconds: number) => void) => {
            _playFunction.current = playerPlay;
            _pauseFunction.current = playerPause;
            _seekFunction.current = playerSeek;
          }}>
          {activeVideoMomentComponent}
        </VideoPlayer>
    }
  </StyledChapterVideo>
};
