import styled from 'styled-components';
import { IStyledProps } from '../../theme';

interface StyledTextAreaProps extends IStyledProps {
  lineHeight: number;
}

export const StyledTextArea = styled.textarea`
	box-sizing: border-box;
	border: none;
	resize: none;
	font-size: 20px;
	font-family: ${(props: StyledTextAreaProps) => `${props.theme.fonts.body}`};
	line-height: ${(props: StyledTextAreaProps) => `${props.lineHeight}px`};
	overflow: auto;
	height: auto;
	padding: 8px;
	text-align: center;
	&:focus {
    	outline: none;
  	}
`;
