import styled, { css } from 'styled-components';
import { Heading } from '../../Heading/Heading';
import { IStyledProps } from '../../../theme';
import { SvgIcon } from '../../SvgIcon/SvgIcon';

export const StyledQuestionListItem = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2.5rem 1fr;
  grid-template-rows: minmax(min-content, 2.1rem);
`;

interface IStyledQuestionListItemProps extends IStyledProps {
  isActive?: boolean
}

export const StyledQuestionListItemText = styled(Heading)`
  text-align: left;
  margin-top: 4px;
  ${(props: IStyledQuestionListItemProps) => props.isActive 
    ? css`color: ${props.theme.colors.primary.a}`
    : ''}
`;

const StyledQuestionIcon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-top: 2px;
  margin-right: 20px;
  flex-shrink: 0;

  > svg {
    height: 14px;
    width: 14px;

    path {
      stroke-width: 4px;
    }
  }
`;

export const StyledQuestionIconQuestion = styled(StyledQuestionIcon)`
  background-color: transparent;
  border: 2px solid ${(props: IStyledProps) => props.theme.colors.primary.a};

  svg {
    path {
      fill: ${(props: IStyledProps) => props.theme.colors.primary.a};
    }
  }
`;

export const StyledQuestionIconTick = styled(StyledQuestionIcon)`
  background-color: ${(props: IStyledProps) => props.theme.colors.primary.a};
`;

export const StyledQuestionIconCross = styled(StyledQuestionIcon)`
  background-color: ${(props: IStyledProps) => props.theme.colors.primary.a};

  > svg {
    height: 10px;
    width: 10px;
  }
`;