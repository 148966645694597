import styled from 'styled-components';

export const StyledPlayerBoard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  height: 100%;
`;

export const StyledPlayersTeamContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  max-height: 100%;
`;

export const StyledTeamSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 64px;
`;

export const StyledAvatarContainer = styled.div<{ size: number }>`
  overflow: visible;
  height: ${({ size }) => size.toString()}%;
`;

export const StyledScore = styled.div`
  background: black;
  color: white;
  font-size: 2rem;
  padding: 2px 4px;
  margin: 5px 0;
  font: 400 2rem/2rem 'Roboto', sans-serif;
`;