import styled, { css } from 'styled-components';
import { getTimerKeyFrames } from '../../../utils/animations.styled';
import { Z_INDEX } from '../../../constants';
import { Heading } from '../../Heading/Heading';
import { IStyledProps } from 'theme';

interface StyledTimerPanelProps extends IStyledProps {
  isShown: boolean;
}

const LETTERBOX_ANIMATION_EASE_TIME = 1;
const TIMER_PANEL_HEIGHT_PX = 100;
const TIMER_BAR_HEIGHT_PX = 6;

export const StyledTimerHeading = styled(Heading).attrs({ level: 'h3', primary: true })`
  position: absolute;
  bottom: 0;
  padding-bottom: 10px;
  width: 100%;
  text-align: center;

  color: white;
  opacity: 0.75;
`;

const StyledTimerPanel = styled.div`
  background: black;
  position: absolute;
  z-index: ${Z_INDEX.videoOverlayTimer};
  left: 0;
  height: ${TIMER_PANEL_HEIGHT_PX}px;
  width: 100%;
  transition: transform ${LETTERBOX_ANIMATION_EASE_TIME}s cubic-bezier(0.25, 0.47, 0.45, 0.94);

  transform-origin: center center;
`;

export const StyledTimerPanelUpper = styled(StyledTimerPanel)`
  top: 0;
  transform: ${(props: StyledTimerPanelProps) => props.isShown 
    ? css`translate(0, 0)`
    : css`translate(0, -${TIMER_PANEL_HEIGHT_PX}px)`}
`;

export const StyledTimerPanelLower = styled(StyledTimerPanel)`
  bottom: 0;
  transform: ${(props: StyledTimerPanelProps) => props.isShown
    ? css`translate(0, 0)`
    : css`translate(0, ${TIMER_PANEL_HEIGHT_PX}px)`}
`;

export interface IStyledTimerBarProps extends IStyledProps {
  duration: number;
}

export const StyledTimerBar = styled.div`
position: absolute;
left: 0px;
bottom: ${TIMER_PANEL_HEIGHT_PX - TIMER_BAR_HEIGHT_PX}px;
width: 100%;
height: ${TIMER_BAR_HEIGHT_PX}px;
z-index: ${Z_INDEX.videoOverlayTimer};

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: white;
    transform-origin: center center;
    animation: ${(props: IStyledTimerBarProps) => css`${getTimerKeyFrames()} ${props.duration}s linear forwards`};
  }
`;