import styled, { css } from 'styled-components';
import { IStyledProps } from '../../../../theme';
import { getScaleFrames, getSlideUpAndFadeIn, getSlideUpAndFadeOut } from '../../../../utils/animations.styled';
import { BreathingState } from './BreatheCircle';
import { Heading } from '../../../Heading/Heading';

export const StyledBreatheCircle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

interface IStyledBreatheCircleInnerProps extends IStyledProps {
  status?: string;
  textAlign?: string;
  breathingState: BreathingState
}

const getAnimationForCircle = (largestScale: number, smallestScale: number, entryScalar = 1) => {
  return css`animation: ${getScaleFrames(largestScale, smallestScale)} 2s ease-in-out, ${getScaleFrames(smallestScale, largestScale)} 2s infinite alternate ease-in-out 2s;
    ${(props: IStyledBreatheCircleInnerProps) => {
      switch (props.breathingState) {
        case BreathingState.IntroExpand:
          return css`animation: ${getScaleFrames(largestScale, largestScale * entryScalar)} 1s ease-out; opacity: ${entryScalar !== 1 ? 0.5 : 1};`;
        case BreathingState.IntroRetract:
          return css`animation: ${getScaleFrames(largestScale * entryScalar, largestScale * 0.99)} 1.5s ease-in; opacity: 1;`;
        case BreathingState.IdleOut:
          return css`animation: ${getScaleFrames(largestScale, smallestScale)} 2s ease-in-out;`;
        case BreathingState.IdleIn:
          return css`animation: ${getScaleFrames(smallestScale, largestScale)} 2s ease-in-out;`;
        case BreathingState.IntroOut:
          return css` animation: ${getScaleFrames(largestScale, smallestScale)} 4s ease-in-out;`;
        case BreathingState.IntroOutHold:
          return css`animation: ${getScaleFrames(smallestScale, smallestScale)} 0.2s;`;
        case BreathingState.In:
          return css`animation: ${getScaleFrames(smallestScale, largestScale)} 4s ease-in-out;`;
        case BreathingState.InHold:
          return css`animation: ${getScaleFrames(largestScale, largestScale)} 4s;`;
        case BreathingState.Out:
          return css`animation: ${getScaleFrames(largestScale, smallestScale)} 4s ease-in-out;`;
        case BreathingState.OutHold:
          return css`animation: ${getScaleFrames(smallestScale, smallestScale)} 4s;`;
      }
    }}`;
}

const staticBreathingStyle = (status: string) => {
  switch (status) {
    case 'Inhale':
      return css`transform: scale(0.95)`
    case 'Exhale':
      return css`transform: scale(0.9)`
    default:
      return css`transform: scale(1.0)`
  }
};

export const StyledBreatheSwipeOuterWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

// 30% comes from the circle container having padding-top 30%
// min(60vw, 260px) is the height & width of the outer circle 
export const StyledBreatheSwipeWrapper = styled.div`
  position: absolute;
  top: calc(30% + min(60vw, 260px) * 0.5);
  left: 50%;
  width: 2500px;
  height: 2500px;
  overflow: hidden;
  pointer-events: none;
  transform: translate(-50%, -50%);
`;

export const StyledBreatheSwipe = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%) rotate(-90deg);
  
  svg {
    height: 100%;
    width: 100%;
    display: block;
  }

  circle:not(.animate){
    display: none;
  }
  
  circle.animate {
    stroke: white;
    opacity: 0.2;
    fill: none;
    stroke-dasharray: 314;
    animation: swipe 4s linear infinite;
    stroke-width: 6rem;
  }

  @keyframes swipe {
    0% {
      stroke-dashoffset: 314;
    }
    100% {
      stroke-dashoffset: 0;
    }
  } 
`;

export const StyledBreatheText = styled.div`
  position: absolute;
  top: 40%;
  width: 40%;
`;

export const StyledBreatheCircleInnerOne = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${(props: IStyledBreatheCircleInnerProps) => props.theme.colors.breathingRings[3]};

  ${(props: IStyledBreatheCircleInnerProps) => props.status && staticBreathingStyle(props.status)};

  ${getAnimationForCircle(0.95, 0.9)};
`;

export const StyledBreatheCircleInnerTwo = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${(props: IStyledBreatheCircleInnerProps) => props.theme.colors.breathingRings[2]};

  ${(props: IStyledBreatheCircleInnerProps) => props.status && staticBreathingStyle(props.status)};

  ${getAnimationForCircle(0.9, 0.87)};
`;

export const StyledBreatheCircleInnerThree = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${(props: IStyledBreatheCircleInnerProps) => props.theme.colors.breathingRings[1]};
  
  ${(props: IStyledBreatheCircleInnerProps) => props.status && staticBreathingStyle(props.status)}

  ${getAnimationForCircle(0.87, 0.8)};
`;

export const StyledBreatheCircleOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: min(60vw, 260px);
  height: min(60vw, 260px);
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 30px;
  background-color: ${(props: IStyledBreatheCircleInnerProps) => props.theme.colors.breathingRings[4]};
  transition: opacity 0.3s;

  ${(props: IStyledBreatheCircleInnerProps) => props.status && staticBreathingStyle(props.status)}

  ${getAnimationForCircle(1.1, 0.95, 5)};
`;

interface IStyledInstructionProps {
  isPrevious?: boolean
  noAnimation?: boolean;
}

const INSTRUCTION_SPACING_PX = 30;

export const StyledInstructionHeading = styled(Heading).attrs({ level: 'h5' })`
  position: absolute;
  text-align: center;
  width: 100%;
  top: calc(100% + ${INSTRUCTION_SPACING_PX}px);
  left: 0;
  font-size: 0.8rem;

  @media screen and (min-width: 360px) {
    font-size: 1rem;
  }
  @media screen and (min-width: 400px) {
    font-size: 1.1rem;
  }

  animation: ${(props: IStyledInstructionProps) => props.noAnimation
    ? css`none`
    : props.isPrevious
      ? css`${getSlideUpAndFadeOut('-30px')} 0.5s ease-in-out forwards`
      : css`${getSlideUpAndFadeIn('30px')} 0.5s ease-in-out forwards`
  };
`;