import React, { PropsWithChildren } from 'react';
import { StyledStripButton } from './StripButton.styled';

//A base component used for consistent styling
//Avoid putting lots of optional props onto this

interface IStripButtonProps {
  onClick?: () => void
  isActive?: boolean //Makes the button a bright blue
  isDisabled?: boolean //Makes the button translucent and unclickable

  className?: string //Used to inherit any other styles
}

export const StripButton: React.FC<PropsWithChildren<IStripButtonProps>> = ({ className, children, onClick, isActive, isDisabled }) => {
  const buttonProps = {
    className,
    isDisabled,
    onClick: () => !isDisabled && onClick && onClick(),
    isActive,
  }

  return <StyledStripButton {...buttonProps}>
    {children}
  </StyledStripButton>
};