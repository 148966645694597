import styled, { keyframes, css } from 'styled-components';
import { IStyledProps } from '../../theme';
import { Card } from '../Card/Card';
import { StyledFormButton } from '../Forms/Form.styled';
import { Heading } from '../Heading/Heading';

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`

export const StyledNotificationModal = styled(Card) `
    width: 359px;
    height: 536px;
    align-items: center;
    position: fixed;
    border-radius: 25px;
    box-shadow: 0px 0px 80px rgba(0, 0, 0, 15%);
    top: calc((100% - 536px) / 2);
`;

export const StyledNotificationModalContainer = styled.div<{ animate: boolean }> `
    width: 100%;
    height: 100vh;
    z-index: 2;
    display: flex;
    justify-content: center;
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
    animation: ${props => (props.animate && fadeOut)} 1s 1;
`;

export const StyledNotificationModalUpperContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2.6em;
`;

export const StyledNotificationModalLowerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledNotificationModalParagraph = styled.div `
    text-align: center;
    color: ${(props: IStyledProps) => props.theme.colors.greys.a};
    font-size: 18px;
    margin: 30px 12px 10px 12px;
`;

export const StyledNotificationModalTitle = styled(Heading).attrs({ level: 'h4', primary: true }) `
    color: ${(props: IStyledProps) => props.theme.colors.greys.a};
    font-size: 40px;
    width: 200px;
    font-weight: 400;
`;

export const StyledNotificationModalButton = styled(StyledFormButton) `
    width: 90%;
    margin-top: 18px;
    margin-bottom: 18px;
`;

export const StyledNotificationModalArchiveIcon = styled.div`
    transform: scale(1.2);
`;