import React, { useLayoutEffect, useRef, useState, useContext } from 'react';
import { StyledInstruction, StyledStrategyExerciseChoose, StyledSwipeCardContainer } from './StrategyExerciseChoose.styled';
import { IExerciseChoose, IExerciseChooseCard } from '../../../models/exercises/IExerciseChoose';

import { ISwipeCardState, SwipeCard } from './SwipeCard';
import { IToastContext, ToastContext } from '../../Toast/ToastContext/ToastContext';
import DataLayer, { GA4ModuleCategoryEnum, GA4ModuleTypeEnum, GTM4Events } from '../../../services/DataLayer';
import { ThemeContext } from 'styled-components';
import { ReactComponent as IconThinking } from '../../../images/icons/thinking.svg';

interface IStrategyExerciseChooseProps {
  strategyExerciseChoose: IExerciseChoose
  onComplete: () => void
}

const COMPLETE_PAUSE_DURATION = 800;
const FLING_AWAY_DURATION = 500;
const SHAKE_DURATION = 2000;
const ENTRY_DURATION = 1800;
const DEFAULT_HINT = 'Have another read.';

export const StrategyExerciseChoose: React.FC<IStrategyExerciseChooseProps> = ({ strategyExerciseChoose, onComplete }) => {
  const themeContext = useContext(ThemeContext);
  const { setToast }: IToastContext = useContext(ToastContext);

  const { cards } = strategyExerciseChoose;

  const initialCards: ISwipeCardState[] = cards.map(card => 'pre-enter');
  const [cardState, setCardState] = useState(initialCards);
  const [isCompleted, setIsCompleted] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);

  const cardStateRef = useRef<ISwipeCardState[]>(cardState);

  useLayoutEffect(() => {
    // prevent infinite render loops
    if (hasInitialized) return;
    setHasInitialized(true);

    setTimeout(() => {
      // once the cards have flown in, set the states of each to be entering
      const newState = cardState.map(c => c === 'pre-enter' ? 'entering' : c)
      cardStateRef.current = newState;
    }, ENTRY_DURATION);
  }, []);

  const updateCardState = (cardIndex: number, nextState: ISwipeCardState) => {
    const newCardState = cardStateRef.current.map((v, i) => (cardIndex === i) ? nextState : v);
    cardStateRef.current = newCardState;
    setCardState(newCardState);
  }

  const onAttemptCardResult = (cardIndex: number, checkValue: boolean) => {
    // check the cards current state
    const thisCardState = cardStateRef.current[cardIndex];

    if (thisCardState === 'done' || thisCardState.indexOf('fling') !== -1) return;

    const card: IExerciseChooseCard = cards[cardIndex];
    DataLayer.pushStrategyChooseCardSwiped(card.questionText, card.isCorrect, checkValue);

    const activeChapter = DataLayer.getActiveChapter();
    if (activeChapter) {
      const customEvent: GTM4Events['quizAnswer'] = {
        answer: {
          answer_correct: checkValue === card.isCorrect,
          answer_id: '',
          answer_text: '',
        },
        module: {
          module_category: GA4ModuleCategoryEnum.AOTG,
          module_id: activeChapter.id,
          module_name: activeChapter.title,
          module_subcategory: `${activeChapter.ordinal}`,
          module_type: GA4ModuleTypeEnum.chapter,
        },
        question: {
          question_id: '',
          question_number: cardIndex.toString(),
          question_section: '',
          question_text: card.questionText,
          user_id: '',
        },
      };
      DataLayer.pushQuizAnswer_GA4(customEvent);
    }

    if (card.isCorrect === checkValue) {
      updateCardState(cardIndex, card.isCorrect ? 'fling-right' : 'fling-left');

      // check if we're swiping on the last card(then complete if we are);
      if (cardIndex === 0) {
        setIsCompleted(true);
        setTimeout(onComplete, COMPLETE_PAUSE_DURATION);
      }
      setTimeout(() => {
        updateCardState(cardIndex, 'done');
      }, FLING_AWAY_DURATION);
    } else {
      const indexCardState = cardStateRef.current[cardIndex];

      if (['fling-right', 'fling-left', 'done'].indexOf(indexCardState) !== -1) {
        return;
      }
      // have to clear the card animation.
      updateCardState(cardIndex, 'shake');

      setTimeout(() => {
        updateCardState(cardIndex, 'standing');
      }, SHAKE_DURATION);

      setToast({
        icon: <IconThinking />,
        heading: 'Try Again',
        body: card.hint ?? DEFAULT_HINT,
        buttonLabel: 'Ok',
      });
    }
  };

  return <StyledStrategyExerciseChoose>
    <StyledInstruction isCompleted={isCompleted} text={strategyExerciseChoose.cardsHeading} />
    <StyledSwipeCardContainer data-cy-cards={JSON.stringify(cards)}>
      {
        cards.map((card, cardIndex) => {
          return <SwipeCard
            key={`swipe-card-${cardIndex}`}
            state={cardStateRef.current[cardIndex]}
            accentColor={themeContext.colors.chooseCards[cardIndex % themeContext.colors.chooseCards.length]}
            questionText={'“' + card.questionText + '”'}
            stackCount={
              cardState.filter(state => state !== 'done').length}
            stackIndex={cardIndex}
            onCheckClick={() => {
              onAttemptCardResult(cardIndex, true);
            }}
            onCrossClick={() => {
              onAttemptCardResult(cardIndex, false);
            }} />;
        })
      }
    </StyledSwipeCardContainer>
  </StyledStrategyExerciseChoose>;
}
