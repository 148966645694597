import { IContentBase } from '../models/IContentBase';
import { STRATEGY_EXERCISE_TYPE, VIDEO_MOMENT_TYPE, STRENGTH_TYPE, CHAPTER_IDENTIFIER } from '../constants';

const content: IContentBase = {
  welcomeScreenVideoUrl: 'videos/welcomeScreen/afl/background.mp4',
  finaleBackgroundUrl: 'images/dashboard/afl/finale.webp',
  videoOverlayBackgroundUrl: 'images/dashboard/afl/overlay.webp',
  chapters: [
    {
      chapterIdentifier: CHAPTER_IDENTIFIER.KeepingYourCool,
      ordinal: 1,
      title: 'Full Circle',
      strategy: 'Keep your cool',
      strategyMessage: '<b>Remember to focus on your breathing.</b> <br/><br/>Controlled breathing is a quick hack to calm our body in tense moments. <br/>Find a quiet place and take a few moments to complete this exercise. <br/>It will take less than a minute.',
      dashboardImage: 'images/dashboard/afl/chapter-5.webp',
      icon: 'images/icons/strategy/afl/breathe',
      videoId: '798637191',
      isFacilitatorChapter: true,
      videoMoments: [{
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'Our unhelpful  thoughts can be very dramatic. Sometimes this means we beat ourselves up, and make things worse.',
        seconds: 109,
        duration: 10,
        showOnlyOnReplay: true,
        stickers: [{
          text: 'IT’S GOING TO HAPPEN AGAIN 😤',
        }, {
          text: 'I SUCK 😩',
        }, {
          text: 'I CAN’T DO THIS!',
        }],
      },
      {
        type: VIDEO_MOMENT_TYPE.PhonesDown,
        seconds: null,
        shouldPause: true,
        neverShowOnReplay: true,
      },
      {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyBanner,
        shouldPause: true,
        showOnlyOnReplay: true,
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        shouldPause: true,
        exerciseType: STRATEGY_EXERCISE_TYPE.Breathe,
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.SummaryPage,
        shouldPause: true,
        showOnlyOnReplay: true,
      }],
      myLearningsDescription: 'It’s easy to get fired up, or anxious in high-pressure moments. But pausing for a moment to take a few breaths can help keep us cool, calm, and in control. <br><br>Remember:<br><ul><li>Breathe in for 4 seconds</li><li>Hold for 4 seconds</li><li>Breathe out for 4 seconds</li></ul>',
    },
    {
      chapterIdentifier: CHAPTER_IDENTIFIER.ManagingYourThoughts,
      ordinal: 2,
      title: 'Crunch Moment',
      strategy: 'Choose your thoughts',
      strategyMessage: 'Understand how your brain<br />can work against you.',
      dashboardImage: 'images/dashboard/afl/chapter-1.webp',
      icon: 'images/icons/strategy/afl/thoughts',
      videoId: '798633439',
      isFacilitatorChapter: true,
      videoMoments: [{
        type: VIDEO_MOMENT_TYPE.PhonesDown,
        seconds: null,
        shouldPause: true,
        neverShowOnReplay: true,
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'Our brains are built to focus on the negative. It can help us recognise danger.  But sometimes this can lead to unhelpful thoughts, especially after we make a mistake.',
        seconds: 92,
        duration: 17,
        showOnlyOnReplay: true,
        stickers: [{
          text: 'I SUCK 😩',
        }, {
          text: 'I LOST US THE GAME 😤',
        }, {
          text: '$^#&%!',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'Our unhelpful thoughts aren’t balanced and tend to focus on the negative. Sometimes this means we can beat ourselves up, and make things worse.',
        seconds: 152,
        duration: 11,
        showOnlyOnReplay: true,
        stickers: [{
          text: 'EASY FOR HIM TO SAY 😤',
        }, {
          text: 'IT WAS MY FAULT 😩',
        }, {
          text: 'BUT I’M INJURED!',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerRemover,
        messageOnRemoved: "Don't get caught up in negative thoughts.",
        seconds: 191,
        duration: 5,
        showOnlyOnReplay: true,
      },
      {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyBanner,
        shouldPause: true,
        showOnlyOnReplay: true,
      }, {
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        seconds: null,
        shouldPause: true,
        exerciseType: STRATEGY_EXERCISE_TYPE.Choose,
        exercise: {
          cardsHeading: 'Is this a <b>helpful</b> thought?',
          cards: [
            {
              questionText: 'I’ve done this before. I’ve got this.',
              isCorrect: true,
              hint: 'This is a reassuring and helpful thought. It’s a good example of backing yourself.',
            },
            {
              questionText: 'I’m a total loser',
              isCorrect: false,
              hint: 'That doesn’t sound like a very helpful or confident thought. How would it feel if someone said this to you?',
            },
            {
              questionText: 'I’m an idiot',
              isCorrect: false,
              hint: 'Nope not a helpful thought. You wouldn’t say this to a mate who was feeling down on themselves.',
            },
            {
              questionText: 'I’ll be alright. No big deal',
              isCorrect: true,
              hint: 'It’s a good start in helpful thinking.',
            },
            {
              questionText: 'I’ve got a long way to go, but whatever',
              isCorrect: false,
              hint: 'Hmmm. Probably not a very helpful thought.',
            },
            {
              questionText: 'Time for a sausage roll break',
              isCorrect: false,
              hint: 'Good idea. But not super helpful maybe?',
            },
            {
              questionText: 'I’ll bounce back',
              isCorrect: true,
              hint: 'Telling yourself that you’ll bounce back is a resilient and helpful thought.',
            },
            {
              questionText: 'I totally suck!',
              isCorrect: false,
              hint: 'You wouldn’t say it to a mate to help them out. It’s not helpful, motivating or kind.',
            },
            {
              questionText: 'I’m doing my best',
              isCorrect: true,
              hint: 'I’m doing my best is a good start in helpful thinking and shows some self-kindness.',
            },
            {
              questionText: 'I should practice my kicking',
              isCorrect: true,
              hint: 'This is actually helpful. It involves taking action and focusing on what you should do.',
            },
            {
              questionText: 'I need to stop missing so many goals',
              isCorrect: false,
              hint: 'For helpful thoughts you want to focus on what you should do, rather than things you shouldn’t be doing.',
            },
            {
              questionText: 'I’m injured so no point going to training',
              isCorrect: false,
              hint: 'Not necessarily. There might be some things you can still work on safely. So might not be a helpful thought?',
            },
            {
              questionText: 'I’m good at this',
              isCorrect: true,
              hint: 'This is optimistic and confident. It’s a helpful thought.',
            },
          ],
        },
      }, {
        type: VIDEO_MOMENT_TYPE.PhonesDown,
        seconds: null,
        shouldPause: true,
        neverShowOnReplay: true,
      }, {
        type: VIDEO_MOMENT_TYPE.SummaryPage,
        seconds: null,
        shouldPause: true,
        showOnlyOnReplay: true,
      }],
      myLearningsDescription: "<ul><li>In challenging moments, we can have lots of different thoughts. </li> <li>Some are helpful others might be unhelpful. </li> <li>Remember, don't beat yourself up with unhelpful thoughts.</li><li>Choose helpful thoughts as they can help how we feel. </li> <li>Remember to lean on your team for support.</li></ul>",

    },
    {
      chapterIdentifier: CHAPTER_IDENTIFIER.ProblemSolving,
      ordinal: 3,
      title: 'High Pressure',
      strategy: 'Problem solving',
      strategyMessage: 'Use simple steps to help<br />you problem solve tricky situations.',
      dashboardImage: 'images/dashboard/afl/chapter-2.webp',
      icon: 'images/icons/strategy/afl/plan',
      videoId: '798635175',
      isFacilitatorChapter: true,
      videoMoments: [{
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'When we’re under pressure, we can sometimes focus on the worst case scenario.',
        seconds: 40,
        duration: 12,
        showOnlyOnReplay: true,
        stickers: [{
          text: 'THIS IS GOING TO BE HORRIBLE 😤',
        }, {
          text: 'WE’RE GOING TO LOSE 😩',
        }, {
          text: 'THE WHOLE FAMILY?!',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'When we’re under pressure we might feel stuck and like giving up.',
        seconds: 64,
        duration: 4,
        showOnlyOnReplay: true,
        stickers: [{
          text: 'THAT WON’T HELP 😤',
        }, {
          text: 'I DON’T WANT THEM THERE 😩',
        }, {
          text: 'UGHHH!',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerRemover,
        messageOnRemoved: "Don't jump to conclusions.",
        seconds: 125,
        duration: 6,
        showOnlyOnReplay: true,
      },
      {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyBanner,
        shouldPause: true,
        showOnlyOnReplay: true,
      }, {
        seconds: null,
        shouldPause: true,
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        exerciseType: STRATEGY_EXERCISE_TYPE.Plan,
        exercise: {
          maxPlansToChoose: 2,
          plans: [{
            text: 'Give up footy forever',
            questions: [{
              text: 'Will this help him stay connected to his team?',
              answeredText: "This won't help him stay connected to the team.",
              isCorrect: false,
              feedback: 'Giving up footy would likely make him lose social connection to his teammates.',
            }, {
              text: 'Will this help him maintain his fitness?',
              answeredText: "This won't help him maintain his fitness.",
              isCorrect: false,
              feedback: 'Giving up footy will likely cause him to lose his fitness.',
            }, {
              text: 'Will this reduce the risk of further injury?',
              answeredText: 'This will reduce the risk of further injury.',
              isCorrect: true,
              feedback: 'Giving up footy will reduce the risk of further injury.',
            }, {
              text: 'Will this help him get back to playing footy sooner?',
              answeredText: 'This won’t help him return to play ASAP.',
              isCorrect: false,
              feedback: 'Giving up footy means he’ll never get back to playing.',
            }],
          }, {
            text: 'Find a way to keep fit until it heals',
            questions: [{
              text: 'Will this help him stay connected to his team?',
              answeredText: 'This will help him stay connected to the team.',
              isCorrect: true,
              feedback: 'Finding a way to keep fit with the team is a great way to ensure he maintains social connection.',
            }, {
              text: 'Will this help him maintain his fitness?',
              answeredText: 'This will help him maintain his fitness.',
              isCorrect: true,
              feedback: 'Finding a way to keep fit until it heals is a great way to help him maintain his fitness as best he can.',
            }, {
              text: 'Will this reduce the risk of further injury?',
              answeredText: 'This will reduce the risk of further injury.',
              isCorrect: true,
              feedback: 'Finding a gradual, and well-supervised way to keep fit is a great way to help reduce the risk of further injury.',
            }, {
              text: 'Will this help him get back to playing footy sooner?',
              answeredText: 'This will help him return to play ASAP.',
              isCorrect: true,
              feedback: 'He might not be ready immediately, but keeping fit in a safe way will ensure he is prepared as soon as possible.',
            }],
          }, {
            text: 'Try and keep involved with the team',
            questions: [{
              text: 'Will this help him stay connected to his team?',
              answeredText: 'This will help him stay connected to the team.',
              isCorrect: true,
              feedback: 'Doing what he can to stay involved would be a great way to stay connected with the team.',
            }, {
              text: 'Will this help him maintain his fitness?',
              answeredText: "This won't help him maintain his fitness.",
              isCorrect: false,
              feedback: 'It will keep him involved, but is unlikely to maintain his fitness.',
            }, {
              text: 'Will this reduce the risk of further injury?',
              answeredText: 'This will reduce the risk of further injury.',
              isCorrect: true,
              feedback: "Staying involved with the team but keeping out of harm's way, will reduce the risk of further injury.",
            }, {
              text: 'Will this help him get back to playing footy sooner?',
              answeredText: 'This won’t help him return to play ASAP.',
              isCorrect: false,
              feedback: 'It will keep him involved, but won’t necessarily help him get back to playing footy sooner.',
            }],
          }, {
            text: 'Shake it off and do his own training',
            questions: [{
              text: 'Will this help him stay connected to his team?',
              answeredText: "This won't help him stay connected to the team.",
              isCorrect: false,
              feedback: 'Training independently is unlikely to help him stay connected to his teammates.',
            }, {
              text: 'Will this help him maintain his fitness?',
              answeredText: 'This will help him maintain his fitness.',
              isCorrect: true,
              feedback: 'Doing your own training, while risky, may help him maintain his fitness.',
            }, {
              text: 'Will this reduce the risk of further injury?',
              answeredText: "This won't reduce the risk of further injury.",
              isCorrect: false,
              feedback: 'Training without proper advice or supervision could easily make his injury worse.',
            }, {
              text: 'Will this help him get back to playing footy sooner?',
              answeredText: 'This won’t help him return to play ASAP.',
              isCorrect: false,
              feedback: 'Training with an injury is more likely to cause additional damage, and hold him back from returning to play.',
            }],
          }, {
            text: 'Keep playing',
            questions: [{
              text: 'Will this help him stay connected to his team?',
              answeredText: 'This will help him stay connected to the team.',
              isCorrect: true,
              feedback: 'Continuing to play is risky, but will definitely keep him connected to the team.',
            }, {
              text: 'Will this help him maintain his fitness?',
              answeredText: 'This will help him maintain his fitness.',
              isCorrect: true,
              feedback: 'Continuing to play is risky, but would probably help him maintain his fitness.',
            }, {
              text: 'Will this reduce the risk of further injury?',
              answeredText: "This won't reduce the risk of further injury.",
              isCorrect: false,
              feedback: 'Playing with an injury despite medical advice will likely make his injury worse.',
            }, {
              text: 'Will this help him get back to playing footy sooner?',
              answeredText: 'This won’t help him return to play ASAP.',
              isCorrect: false,
              feedback: 'Playing with an injury is more likely to cause additional damage, and hold him back from performing long-term.',
            }],
          }],
        },
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.SummaryPage,
        shouldPause: true,
        showOnlyOnReplay: true,
      }, {
        type: VIDEO_MOMENT_TYPE.PhonesDown,
        seconds: null,
        shouldPause: true,
        neverShowOnReplay: true,
      }],
      myLearningsDescription: '<ul><li>When faced with a challenge, sometimes we can fear the worst.</li><li>You could take a walk to clear your head.</li><li>Think about the problem and brainstorm lots of solutions.</li><li>Weigh up pros and cons.</li><li>Chose an option, plan the steps and give it a go.</li><li>If Plan A isn\'t working, there are lots of different options, give Plan B a go.</li><li>Remember to lean on your team when faced with a challenge.</li></ul>',
    },
    {
      chapterIdentifier: CHAPTER_IDENTIFIER.PlayingToYourStrengths,
      ordinal: 4,
      title: 'Training Troubles',
      strategy: 'Play to your strengths',
      strategyMessage: 'Determine your own<br />strengths and focus on<br />them.',
      dashboardImage: 'images/dashboard/afl/chapter-3.webp',
      icon: 'images/icons/strategy/afl/strength',
      videoId: '798564584',
      isFacilitatorChapter: true,
      videoMoments: [{
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'Our minds often go straight to focusing on what we don’t do so well. The aim is to catch those thoughts and focus on our strengths instead.',
        seconds: 16,
        duration: 9,
        showOnlyOnReplay: true,
        stickers: [{
          text: 'I can\'t even kick',
        }, {
          text: 'What\'s happening?',
        }, {
          text: 'The team think I suck',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'Our thoughts can spiral and get stuck on our weaknesses. But by focusing on our strengths instead, we can improve our performance and resilience.',
        seconds: 38,
        duration: 6,
        showOnlyOnReplay: true,
        stickers: [{
          text: 'What\'s wrong with me?',
        }, {
          text: 'I\'m getting worse',
        }, {
          text: 'No chance I\'ll score in a game',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerRemover,
        messageOnRemoved: "Don't focus on your weaknesses.",
        seconds: 56,
        duration: 8,
        showOnlyOnReplay: true,
      },
      {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyBanner,
        shouldPause: true,
        showOnlyOnReplay: true,
      },
      {
        seconds: null,
        shouldPause: true,
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        exerciseType: STRATEGY_EXERCISE_TYPE.Strengths,
        exercise: {
          promptSets: [{
            emptyText: 'Explore different ways to improve this strength.',
            prompts: [{
              strengthCardsType: STRENGTH_TYPE.OnField,
              instruction: 'Select one of your <b>ON-field</b> strengths.',
              instructionCustom: 'Name your <b>on-field</b> strength',
              questions: [{
                text: 'Can you <b>use</b> this strength more?',
                answeredText: 'I can look for opportunities to use this strength more',
              }, {
                text: 'Can you <b>improve</b> on this strength through training?',
                answeredText: 'I can work on improving it',
              }, {
                text: 'Can you <b>help others</b> develop this strength?',
                answeredText: 'I can share it with others',
              }],
            },
            {
              strengthCardsType: STRENGTH_TYPE.OffField,
              instruction: 'Select one of your <b>OFF-field</b> strengths.',
              instructionCustom: 'Name your <b>off-field</b> strength',
              emptyText: 'Explore different ways to improve this strength.',
              questions: [{
                text: 'Can you <b>use</b> this strength more?',
                answeredText: 'I can look for opportunities to use this strength more',
              }, {
                text: 'Can you <b>help others</b> with this strength?',
                answeredText: 'I can use it to help others',
              }, {
                text: 'Can you use this strength in your <b>sport</b>?',
                answeredText: 'I can use it in my sport',
              }],
            }],
          }],
        },
      },
      {
        showOnlyOnReplay: true,
        seconds: null,
        type: VIDEO_MOMENT_TYPE.SummaryPage,
        shouldPause: true,
      }],
      myLearningsDescription: '<ul><li>Think about your strengths ON and OFF the field and how to play to these strengths.</li><li>Use your strengths more</li><li>Improve through training</li><li>Help others develop their strengths</li><li>Your team (ON and OFF the field) can help you understand your strengths.</li><li>Remember to remind your team of their strengths.</li></ul>',
    },
    {
      chapterIdentifier: CHAPTER_IDENTIFIER.ControllingTheControllables,
      ordinal: 5,
      isFacilitatorChapter: true,
      title: 'Game Day',
      strategy: 'Control the controll&shy;ables',
      strategyMessage: 'Some things we can’t<br />control. Let’s focus on what<br />we can.',
      dashboardImage: 'images/dashboard/afl/chapter-4.webp',
      icon: 'images/icons/strategy/afl/control',
      videoId: '798636049',
      videoMoments: [{
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'It’s easy to slip into feeling out of control when faced with a challenging moment. The aim is to focus on things you can control.',
        seconds: 12,
        duration: 9,
        showOnlyOnReplay: true,
        stickers: [{
          text: 'AWW C’MON',
        }, {
          text: 'MY SHOULDER',
        }, {
          text: 'PULL IT TOGETHER',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'When our brains focus on things we can’t control, it can hold us back. If we focus on what we can control in the moment we stay strong, motivated and solutions focussed.',
        seconds: 40,
        duration: 10,
        showOnlyOnReplay: true,
        stickers: [{
          text: 'THESE PLAYING CONDITIONS SUCK!!!!',
        }, {
          text: 'MY SHOULDER!!!',
        }, {
          text: 'THEY’RE TARGETING ME!!',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerRemover,
        messageOnRemoved: 'Focus on things you can control',
        seconds: 54,
        duration: 7,
        showOnlyOnReplay: true,
      },
      {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyBanner,
        shouldPause: true,
        showOnlyOnReplay: true,
      },
      {
        seconds: null,
        shouldPause: true,
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        exerciseType: STRATEGY_EXERCISE_TYPE.Control,
        exercise: {
          numberOfCardsToChoose: 4,
          controlCards: [{
            text: 'The umpire',
            icon: 'images/icons/control/afl/umpire',
            isControllable: false,
            controlFeedback: 'The umpire is not within his control. No matter how much he kicks and screams, he can’t change the umpire’s decision. Behaving in that way to an umpire isn’t part of our game.',
            isImmediatelyControllable: false,
          }, {
            text: 'The opposition',
            icon: 'images/icons/control/afl/opponents',
            isControllable: false,
            controlFeedback: 'He has no control over his opponents.',
            isImmediatelyControllable: false,
          }, {
            text: 'His teammates',
            icon: 'images/icons/control/afl/teammates',
            isControllable: false,
            controlFeedback: 'The only player in his team he has control over is himself.',
            isImmediatelyControllable: false,
          }, {
            text: 'The weather',
            icon: 'images/icons/control/afl/weather',
            isControllable: false,
            controlFeedback: 'That would be handy but no he has no control over the weather.',
            isImmediatelyControllable: false,
          }, {
            text: 'His performance',
            icon: 'images/icons/control/afl/performance',
            isControllable: false,
            controlFeedback: "He can't control his overall performance. But he might be able to control parts of it like his pre-kick routine.",
            isImmediatelyControllable: false,
          }, {
            text: 'His language',
            icon: 'images/icons/control/afl/language',
            isControllable: true,
            controlFeedback: 'His language is within his control. He needs to focus on things he can control.',
            isImmediatelyControllable: true,
            immediateControlFeedback: 'That’s a great place to start. It’s something he can control straight away.',
          }, {
            text: 'The coach',
            icon: 'images/icons/control/afl/coach',
            isControllable: false,
            controlFeedback: 'Nope. The coach is not something within his control.',
            isImmediatelyControllable: false,
          }, {
            text: 'His effort',
            icon: 'images/icons/control/afl/effort',
            isControllable: true,
            controlFeedback: 'You got it. His effort is something he can control.',
            isImmediatelyControllable: true,
            immediateControlFeedback: 'It’s easy to let it affect your play when you’re upset. But he can re-take control by focussing on his effort in the moment.',
          }, {
            text: 'His attitude',
            icon: 'images/icons/control/afl/reactions',
            isControllable: true,
            controlFeedback: 'His attitude is within his control, even if it feels tough sometimes.',
            isImmediatelyControllable: true,
            immediateControlFeedback: 'It might seem tough but this is something he can start trying to control right away.',
          }, {
            text: 'The ground conditions',
            icon: 'images/icons/control/afl/pitch',
            isControllable: false,
            controlFeedback: "That's out of his control.",
            isImmediatelyControllable: false,
          }, {
            text: 'His fitness',
            icon: 'images/icons/control/afl/fitness',
            isControllable: true,
            controlFeedback: 'His fitness is something he can work on and have some control over.',
            isImmediatelyControllable: false,
            immediateControlFeedback: 'His fitness is not something he can fix right away. Another option might be more important in this moment.',
          }, {
            text: 'The crowd',
            icon: 'images/icons/control/afl/crowd',
            isControllable: false,
            controlFeedback: 'Nope. Not something he can control.',
            isImmediatelyControllable: false,
          }],
        },
      },
      {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.SummaryPage,
        shouldPause: true,
        showOnlyOnReplay: true,
      },
      {
        type: VIDEO_MOMENT_TYPE.PhonesDown,
        seconds: null,
        shouldPause: true,
        neverShowOnReplay: true,
      },
      {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        shouldPause: true,
        neverShowOnReplay: true,
        exerciseType: STRATEGY_EXERCISE_TYPE.StrengthsTeammates,
        exercise: {},
      },
      {
        type: VIDEO_MOMENT_TYPE.PhonesDown,
        seconds: null,
        shouldPause: true,
        neverShowOnReplay: true,
      }],
      myLearningsDescription: "<ul><li>When in a challenging situation there are things that you CAN control and things you can't control.</li><li>Look at what you CAN control.</li><li>Focus on acting on things you CAN control.</li><li>Also focus on appreciating your support team (ON and OFF the field).</li></ul>",
    },
  ],
  strengthCards: {
    [STRENGTH_TYPE.OnField]: [
      {
        text: 'Kicking',
        slug: 'kicking',
        icon: 'images/icons/strength/afl/on-field/kicking',
      }, {
        text: 'Running',
        slug: 'running',
        icon: 'images/icons/strength/afl/on-field/running',
      }, {
        text: 'Marking',
        slug: 'marking',
        icon: 'images/icons/strength/afl/on-field/marking',
      }, {
        text: 'Tackling',
        slug: 'tackling',
        icon: 'images/icons/strength/afl/on-field/tackling',
      }, {
        text: 'Fair play',
        slug: 'fair-play',
        icon: 'images/icons/strength/afl/on-field/fair-play',
      }, {
        text: 'Leadership',
        slug: 'leadership',
        icon: 'images/icons/strength/afl/on-field/leadership',
      }, {
        text: 'Team work',
        slug: 'team-work',
        icon: 'images/icons/strength/afl/on-field/team-work',
      }, {
        text: 'Decision making',
        slug: 'decision-making',
        icon: 'images/icons/strength/afl/on-field/decision-making',
      },
    ],
    [STRENGTH_TYPE.OffField]: [
      {
        text: 'Sense of humour',
        slug: 'sense-of-humour',
        icon: 'images/icons/strength/afl/off-field/humour',
      }, {
        text: 'Adaptable',
        slug: 'adaptable',
        icon: 'images/icons/strength/afl/off-field/adaptable',
      }, {
        text: 'Kind',
        slug: 'kind',
        icon: 'images/icons/strength/afl/off-field/kind',
      }, {
        text: 'Motivated',
        slug: 'motivated',
        icon: 'images/icons/strength/afl/off-field/motivated',
      }, {
        text: 'Creative',
        slug: 'creative',
        icon: 'images/icons/strength/afl/off-field/creative',
      }, {
        text: 'Determined',
        slug: 'determined',
        icon: 'images/icons/strength/afl/off-field/determined',
      }, {
        text: 'Dedicated',
        slug: 'dedicated',
        icon: 'images/icons/strength/afl/off-field/dedicated',
      }, {
        text: 'Hard working',
        slug: 'hard-working',
        icon: 'images/icons/strength/afl/off-field/patient',
      }, {
        text: 'Trustworthy',
        slug: 'trustworthy',
        icon: 'images/icons/strength/afl/off-field/trustworthy',
      }, {
        text: 'Friendly',
        slug: 'friendly',
        icon: 'images/icons/strength/afl/off-field/friendly',
      },
    ],
  },
};

export default content;