import React from 'react';
import { StyledChapterHeader, StyledHeading, StyledChapterInfo, StyledChapterIcon } from './ChapterHeader.styled';
import { IChapter } from '../../models/IChapter';
import PlayerData from '../../services/PlayerData';

import { NavButton } from '../NavButton/NavButton';

interface IReflectionHeaderProps {
  chapter: IChapter
}

//This component should only be used as an inline CHAPTER header, not as a general purpose header
export const ChapterHeader: React.FC<IReflectionHeaderProps> = ({ chapter }) => {
  return <StyledChapterHeader>
    {PlayerData.hasCompletedChapter(chapter.chapterIdentifier) && <NavButton chapterIdentifier={chapter.chapterIdentifier}/>}
    <StyledChapterInfo>
      <StyledHeading level="h5" primary text={chapter.strategy} />
      <StyledChapterIcon cmsSrc={chapter.icon} />
    </StyledChapterInfo>
  </StyledChapterHeader>
}

