
import Api, { ITeamFeedbackTarget } from './Api';
import { ICohortData } from '../models/ICohortData';

const DEMO_MODE_TEAM_MEMBERS = [
  {
    Id: 'demoUser0',
    FirstName: 'Luke',
    LastName: 'H',
    IsFeedbackTarget: true,
  },
  {
    Id: 'demoUser1',
    FirstName: 'Jess',
    LastName: 'R',
    IsFeedbackTarget: true,
  },
  {
    Id: 'demoUser2',
    FirstName: 'Martin',
    LastName: 'L',
    IsFeedbackTarget: true,
  },
  {
    Id: 'demoUser3',
    FirstName: 'Jay',
    LastName: 'C',
    IsFeedbackTarget: true,
  },
  {
    Id: 'demoUser4',
    FirstName: 'Tim',
    LastName: 'L',
    IsFeedbackTarget: true,
  },
];

//The cohort the player is a part of - DOES NOT get updated with player data, for reference/reading only
let CohortData: ICohortData;

/*
  Normal way - Players

  Short Term Store -  Variable in CohortDataService(file) - CohortData
  Long Term Store - Database - CohortDataService(file) - CohortData

  Demo way - Facilitator/DeliveryPartner/Partner

  Short Term Store -  Variable in CohortDataService(file) - CohortData
  Long Term Store - Local Storage - 'cohortData'

  Demo will never make another 'get' call to Cohorts, because it can't.
*/

export default {
  // set and get demo state is applied on registration.
  setDemoState(value: boolean): void {
    localStorage.setItem('demoMode', value ? 'true' : 'false');
  },
  getDemoState(): boolean {
    return localStorage.getItem('demoMode') === 'true';
  },
  async loadCohortData(): Promise<ICohortData> {
    if (this.getDemoState()) {
      const localCohortData = localStorage.getItem('cohortData');
      const currentCohortData = JSON.parse(localCohortData || '{}') as ICohortData;

      CohortData = currentCohortData;

      return currentCohortData;
    }

    const newCohortData = await Api.getCohortData();
    
    if (CohortData) {
      CohortData.DeliveryDate = newCohortData.DeliveryDate;
    } else {
      CohortData = newCohortData
    }

    return CohortData;
  },
  setCohortData(cohortData: ICohortData) {
    CohortData = cohortData;

    if (this.getDemoState()) {
      localStorage.setItem('cohortData', JSON.stringify(cohortData));

      return;
    }
  },
  getCohortData(): ICohortData {
    return CohortData;
  },
  async getTeamMembers(): Promise<ITeamFeedbackTarget[]> {
    // check if we're in demo mode
    if (this.getDemoState()) {
      return DEMO_MODE_TEAM_MEMBERS;
    }

    return Api.getTeamMembers();
  },
  async giveTeammatesFeedbacks(teammatesFeedbacks: any[]): Promise<void> {
    // check if we're in demo mode
    if (this.getDemoState()) {
      return;
    }

    return Api.giveTeammatesFeedbacks(teammatesFeedbacks);
  },
};
