import auth0, { Auth0Callback, Auth0DecodedHash, Auth0Result, Auth0UserProfile, AuthOptions, WebAuth } from 'auth0-js';
import jwt_decode from 'jwt-decode';
import Environment from './Environment';

type DomainUser<T extends string = ''> = Record<string, T>;

//LUKE WHAT THIS??
export const AUTH0_USER_CUSTOM_NAMESPACE = 'https://user.aotg.movember.com';

interface IAuthData<D = DomainUser> {
  accessToken: string; //access token for the API specified by `audience`
  expiresAt: number; // access token's expiration time
  user: null | (Auth0UserProfile & D);
}

let AUTH_CLIENT: auth0.WebAuth;

const initialAuthData: IAuthData = {
  accessToken: '',
  expiresAt: 0,
  user: null,
};

let AUTH_DATA: IAuthData = {
  ...initialAuthData,
};

export default {
  resetAuthData(): void {
    AUTH_DATA = {
      ...initialAuthData,
    };
  },

  isValidToken(): boolean {
    return !!AUTH_DATA.accessToken && !!AUTH_DATA.expiresAt && new Date().getTime() < AUTH_DATA.expiresAt;
  },

  getAccessToken(): string {
    return this.isValidToken() ? AUTH_DATA.accessToken : '';
  },

  getUser(): IAuthData['user'] {
    return this.isValidToken() ? AUTH_DATA.user : null;
  },

  getUserId(): string | null {
    const user = this.getUser();
    return user ? user.sub : null;
  },

  getTeamCode(): string {
    const user = this.getUser();
    if (!user) return '';
    return user[`${AUTH0_USER_CUSTOM_NAMESPACE}/teamCode`];
  },

  getFirstName(): string {
    const user = this.getUser();
    return user ? user[`${AUTH0_USER_CUSTOM_NAMESPACE}/firstName`] : '';
  },

  logout(): void {
    this.resetAuthData();
    this.getWebAuthClient().logout({
      returnTo: window.location.origin,
      clientID: Environment.getCurrentEnvironment().auth0ClientId,
    });
  },

  login(authResult: Auth0Result): void {
    window.location.hash = '';
    AUTH_DATA.accessToken = authResult.accessToken ?? '';
    AUTH_DATA.expiresAt = (authResult.expiresIn ?? -1) * 1000 + new Date().getTime();
    try {
      AUTH_DATA.user = jwt_decode(authResult.idToken ?? '');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },

  async tryRenewToken(): Promise<Auth0Result | void> {
    return new Promise((resolve, reject) => {
      return this.getWebAuthClient().checkSession({}, (err: any, authResult: Auth0Result) => {
          if (authResult && authResult.accessToken && authResult.idToken && authResult.expiresIn) {
            this.login(authResult);
            resolve(authResult);
          } else {
            this.resetAuthData();
            reject(err || authResult);
          }
        },
      );
    });
  },

  async handleAuthentication(): Promise<Auth0DecodedHash | null> {
    return new Promise((resolve, reject) => {
      this.getWebAuthClient().parseHash({ hash: window.location.hash }, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken && authResult.expiresIn) {
          this.login(authResult);
          resolve(authResult);
        } else if (err) {
          // eslint-disable-next-line no-console
          console.log('Parse auth res failed', err);
          reject(err);
        } else {
          // No token found
          resolve(null);
        }
      });
    });
  },

  async isAuthenticated(): Promise<boolean> {
    if (this.isValidToken()) return true;

    try {
      //If there is no valid token, try to renew one
      await this.tryRenewToken();
      return true;
    } catch (err) {
      return false;
    }
  },

  getWebAuthClient(): WebAuth {
    if (!AUTH_CLIENT) {
      const env = Environment.getCurrentEnvironment();
      const authOptions: AuthOptions = {
        domain: env.auth0CustomDomain,
        clientID: env.auth0ClientId,
        redirectUri: `${window.location.origin}`,
        responseType: 'token id_token',
        scope: 'read:current_user update:current_user_metadata openid profile email',
        audience: `https://${env.auth0Domain}/api/v2/`,
      };
      AUTH_CLIENT = new auth0.WebAuth(authOptions);
    }

    return AUTH_CLIENT;
  },

  loginWithEmailPassword(email: string, password: string, callback: (err: any, res: any) => void): void {
    this.getWebAuthClient().login(
      {
        realm: 'Username-Password-Authentication',
        username: email,
        password: password,
      },
      callback,
    );
  },

  registerWithEmailPassword(registerInfo: { email: string; password: string; firstName: string; lastName: string; accessCode: string; team: string; callback: (err: any, res: any) => void }) {
    this.getWebAuthClient().redirect.signupAndLogin(
      {
        connection: 'Username-Password-Authentication',
        email: registerInfo.email,
        password: registerInfo.password,
        userMetadata: {
          firstName: registerInfo.firstName,
          lastName: registerInfo.lastName,
          accessCode: registerInfo.accessCode,
          team: registerInfo.team,
        },
      },
      registerInfo.callback,
    );
  },

  // resetPassword(email: string, callback: Auth0Callback<any>) {
  //   this.getWebAuthClient().changePassword(
  //     {
  //       connection: 'Username-Password-Authentication',
  //       email: email,
  //     },
  //     callback,
  //   );
  // },
};
