import styled, { css, AnyStyledComponent } from 'styled-components';
import { IMAGES_URL, Z_INDEX } from '../../constants';
import { IStyledProps } from '../../theme';
import { Card } from '../Card/Card';
import { Heading } from '../Heading/Heading';
import { StyledIcon } from '../VideoOverlay/VideoOverlayStrategyBanner/VideoOverlayStrategyBanner.styled';

//Dashboard
interface IStyledDashboardProps extends IStyledProps {
  sport?: string | null;
  transitionStepIdentifier?: string | null
}

export const StyledPlayerDashboard = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${(props: IStyledDashboardProps) => props.theme.colors.black};
`;
StyledPlayerDashboard.displayName = 'StyledPlayerDashboard';

//Chapter carousel
export const StyledChapterCarousel = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;

	scroll-snap-type: x mandatory;
	-webkit-overflow-scrolling: touch;
  overflow-x: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
StyledChapterCarousel.displayName = 'StyledChapterCarousel';

interface IStyledChapterSlideProps extends IStyledProps {
  image: string;
}

export const StyledChapterSlide: AnyStyledComponent = styled.div`
  position: relative;
  height: 100%;
  flex: 0 0 100%;
  scroll-snap-align: start;
  overflow: hidden;
  color: ${(props: IStyledProps) => props.theme.colors.white};

  :before {
    content:'';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: ${(props: IStyledChapterSlideProps) => `url(${IMAGES_URL}${props.image})`};
    background-position: 50%;
    background-size: cover;
 
  }

`;
StyledChapterSlide.displayName = 'StyledChapterSlide';

export const StyledChapterSlideContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
`;
StyledChapterSlideContent.displayName = 'StyledChapterSlideContent';

export const StyledStrategyIcon = styled(StyledIcon)`
`;

//Strategy carousel
export const StyledStrategyCarousel = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
StyledStrategyCarousel.displayName = 'StyledStrategyCarousel';

interface IStyledStrategySlideProps extends IStyledProps {
  /** The zero-index for the chapter slide */
  index: number;
  /** True if the user is focussed on this chapter index */
  isFocused: boolean;
  /** True if the chapter is available to be played */
  isUnlocked: boolean;
  isHidden: boolean;
  /** Used to add conditional css, ex. slam, fade in... */
  transitionStepIdentifier?: string | null;
}

export const StyledStrategySlide = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: calc(35%);
  
  opacity: ${(props: IStyledStrategySlideProps) => props.isHidden ? '0' : '0.7'};
  will-change: transform, opacity;
  transition: transform 0.3s, opacity 0.3s;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  > svg {
    width: 32px;
    height: 32px;
  }

  ${(props: IStyledStrategySlideProps) => props.isFocused && css`
    opacity: 1;
    transform: scale(1);
  `}

  ${(props: IStyledStrategySlideProps) => props.transitionStepIdentifier === 'dashboard-fadein' && css`
    opacity: 0;
  `}

`;
StyledStrategySlide.displayName = 'StyledStrategySlide';

export const StyledChapterDetails = styled.div`
  position: relative;
  padding: 30px 20px;
`;
StyledChapterDetails.displayName = 'StyledChapterDetails';

export const StyledOrdinalHeading = styled(Heading).attrs({ level: 'h4', primary: true })`
  margin-bottom: 8px;
  text-align: left;
  font-size: 34px;
`;

StyledOrdinalHeading.displayName = 'StyledOrdinalHeading';

export const StyledSlideButton = styled(Card)`
  width: 85%;
  height: 55px;
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  background: ${(props: IStyledProps) => props.theme.colors.greys.d};
  border-radius: 0px;
  bottom: 10%;
  position: absolute;
  gap: 5px;
  margin-bottom: 10px;
  
  ${(props) => {
      if (props.isDisabled) {
        return css`
          opacity: 0.65;
          background: ${props.theme.colors.greys.a};
          border: 0.5px solid ${props.theme.colors.white};
        `
      }
    }
  }
`
StyledSlideButton.displayName = 'StyledSlideIconAndTextContainer'

export const StyledSlideButtonText = styled.span`
  font-size: 20px;
  text-align: right;
  padding-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`
StyledSlideButtonText.displayName = 'StyledSlideButtonText'

//Navigation
export const StyledCarouselNav = styled.div`
  position: absolute;
  width: 100%;
  bottom: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
StyledCarouselNav.displayName = 'StyledCarouselNav';

interface IStyledCarouselNavButtonProps extends IStyledProps {
  isActive: boolean
}

export const StyledCarouselNavButton = styled.div`
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0 8px;
  border-radius: 50%;
  opacity: ${(props: IStyledCarouselNavButtonProps) => props.isActive ? '1' : '0.7'};
  background: ${(props: IStyledProps) => props.theme.colors.greys.d};
`;
StyledCarouselNavButton.displayName = 'StyledCarouselNavButton';

export const LoadingOverlay = styled.div`
  position: absolute;
  z-index: ${Z_INDEX.loadingOverlay};
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: linear-gradient(to bottom, rgba(216, 216, 216), rgba(255, 255, 255));
`;
