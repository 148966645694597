import React from 'react';
import { StyledAppLogo } from './AppLogo.styled';
import PlayerData from '../../services/PlayerData';

import { ReactComponent as LogoBlack } from '../../images/logo-black.svg';
import { ReactComponent as LogoWhite } from '../../images/logo-white.svg';
import { ReactComponent as LogoAfl } from '../../images/logo-afl-lockup.svg';
import { SPORT_TYPE } from '../../constants';

interface IAppLogoProps {
  isLight?: boolean
  isSportLockup?: boolean
  className?: string
}

export const AppLogo: React.FC<IAppLogoProps> = ({ className, isLight, isSportLockup }) => {
  const getLogo = () => {
    //Add other logos as needed
    const sport = PlayerData.getSport();
    if (isSportLockup && (sport && [SPORT_TYPE.AustralianRules, SPORT_TYPE.AustralianRulesOneSession].includes(sport))) {
      return <LogoAfl />; //todo add light/dark?
    } else {
      return isLight ? <LogoWhite /> : <LogoBlack />;
    }
  };

  return <StyledAppLogo className={className}>{getLogo()}</StyledAppLogo>;
}