import styled, { css } from 'styled-components';
import { IStyledProps } from '../../theme';

interface IAdvancedCardProps extends IStyledProps {
  headerColour?: string
  bodyColour?: string
  hasBodyContent?: boolean
  isClickable?: boolean
}

const CARD_PADDING_PX = 20;
const POINTY_ARROW_HEIGHT = CARD_PADDING_PX + CARD_PADDING_PX / 2;
//TODO: should we move this to theme?
const BODY_BG_COLOUR = '#ececec';

//The :before :after's are to create a RESPONSIVE point on the bottom of the div

export const StyledAdvancedCardHeader = styled.div`
  background-color: ${(props: IAdvancedCardProps) => props.headerColour || props.theme.colors.primary.a};
  color: ${(props: IStyledProps) => props.theme.colors.white};
  padding-bottom: ${CARD_PADDING_PX * 0.125}px;
  margin-bottom: ${CARD_PADDING_PX * 2}px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90px;

  /* this displays the cheveron.
   * it malforms a div to always match the width, rotate to a diamond, and the stretch out.
   */
  &:before {
    content:'';
    position: absolute;
    bottom: 0%;
    left:50%;
    transform: translate(-50%, -7.23%) scaleX(4) rotate(45deg);
    width: 100%;
    padding-top: 100%;

    background: ${(props: IAdvancedCardProps) => props.headerColour || props.theme.colors.primary.a};
  }
`;

export const StyledAdvancedCardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background-color: ${(props: IAdvancedCardProps) => props.bodyColour || BODY_BG_COLOUR};
`;

export const StyledAdvancedCardFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props: IStyledProps) => props.theme.colors.greys.a};
`;

export const StyledAdvancedCard = styled.div`
  background-color: ${(props: IAdvancedCardProps) => props.hasBodyContent 
  ? ( props.bodyColour || BODY_BG_COLOUR )
  : props.theme.colors.greys.a};

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.29);
  border-radius: 17.5px;
  overflow: hidden;
  cursor: ${(props: IAdvancedCardProps) => props.isClickable ? 'pointer' : 'default'};

  display: flex;
  flex-direction: column;
`;

const StyledCardInnerContent = styled.div`
  padding: ${CARD_PADDING_PX}px;
  margin: 0 auto;
  max-width: 340px;
  width: 100%;
`;

//Bottom of header has pointy arrow which creates padding, so we remove excess here
export const StyledCardInnerContentHeader = styled(StyledCardInnerContent)`
  position: relative;
  padding-bottom: 0;
`;

//To balance out the upper heading from the pointy arrow, we add some bottom padding here
export const StyledCardInnerContentBody = styled(StyledCardInnerContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-bottom: ${POINTY_ARROW_HEIGHT}px;
`;

export const StyledCardInnerContentFooter = styled(StyledCardInnerContent)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props: IAdvancedCardProps) => props.hasBodyContent 
    ? ''
    : css`flex: 1;`}
`;