import styled, { css } from 'styled-components';
import { IStyledProps } from '../../theme';
import { Heading } from '../Heading/Heading';

interface StyledButtonProps extends IStyledProps {
  primary?: boolean;
  hollow?: boolean;

  isDisabled?: boolean;
  withArrow?: boolean;
}

export const StyledButton = styled.button`
  position: relative;
  padding: 15px;
  border: 0;
  border-radius: 5px;
  &:focus {
    outline: none;
  }
  
  cursor: ${(props: StyledButtonProps) => props.isDisabled ? 'default' : 'pointer'};
  background-color: ${(props: StyledButtonProps) => !props.hollow 
    ? props.isDisabled
      ? props.theme.colors.greys.b
      : props.theme.colors.greys.d
    : 'transparent'};
  color: ${(props: StyledButtonProps) => !props.hollow 
    ? props.theme.colors.white 
    : props.isDisabled
      ? props.theme.colors.greys.b
      : props.theme.colors.greys.d};

  ${(props: StyledButtonProps) => props.withArrow && css`
    :after {
      content: "→";
      right: 18px;
      position: absolute;
    }
  `}
`;

export const StyledButtonHeading = styled(Heading).attrs({ level: 'h6', primary: true })`
  letter-spacing: 0.1em;
  text-transform: none;
  font-family: 'Overpass';
`;