import styled, { css, keyframes } from 'styled-components';
import { getFadeInKeyFrames, getFadeOutKeyFrames } from '../../../utils/animations.styled';
import { ITransitionStep, TransitionStepType } from '../TransitionContext/TransitionContext';
import { Z_INDEX } from '../../../constants';
import { IStyledProps } from '../../../theme';

import overlayImage from './OverlayScroll.webp';

export interface IStyledTransitionMaskProps extends IStyledProps {
  currentStep: ITransitionStep | null;
}

const fadeOutStyle = (currentStep: ITransitionStep) => {
  return css`
    opacity: 1;
    transition: opacity ${currentStep.animationSeconds || currentStep.durationSeconds}s ease-in-out forwards;
  `;
};

const fadeInStyle = (currentStep: ITransitionStep) => {
  return css`
    transition: opacity ${currentStep.animationSeconds || currentStep.durationSeconds}s ease-in-out forwards;
  `;
};

const noInteractionStyle = (currentStep?: ITransitionStep) => {
  return css`
    pointer-events: none;
  `;
};

const allowInteractionStyle = (currentStep?: ITransitionStep) => {
  return css`
    width: 0;
    height: 0;
    pointer-events: none;
  `;
};

const handleTransition = (props: IStyledTransitionMaskProps) => {
  if (props.currentStep === null) return allowInteractionStyle();

  switch (props.currentStep.type) {
    case TransitionStepType.FadeOut:
      return fadeOutStyle(props.currentStep)
    case TransitionStepType.FadeIn:
      return fadeInStyle(props.currentStep)
    case TransitionStepType.WaitNoInteraction:
      return noInteractionStyle(props.currentStep)
    case TransitionStepType.WaitWithInteraction:
    default:
      return allowInteractionStyle(props.currentStep)
  }
};

export const StyledTransitionMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  transition: opacity 1s ease-in-out;
  opacity: 0;
  z-index: ${Z_INDEX.tansitionMask};
  ${(props: IStyledTransitionMaskProps) => handleTransition(props)}
`;

const fade = keyframes`
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  89% {
    opacity: 0;
  }
`;
const mainFade = keyframes`
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
const slide = keyframes`
  0% {
    background-position: 50% 112.5vh;
    opacity: 1;
  }
  78% {
    opacity: 1;
  }
  83% {
    opacity: 0;
  }
  100% {
    background-position: 50% 3.125vh;
    opacity: 0;
  }
`;


export const StyledScrollingChevrons = styled.div<IStyledProps & { currentStep: ITransitionStep | null }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: ${Z_INDEX.transitionOverlayMask};
  animation: ${mainFade} 2s cubic-bezier(0.64, 0.95, 0.43, 0.06) forwards;
  ${props => {
    return css`
      animation-duration: ${props.currentStep?.durationSeconds ?? 2}s;
    `
  }}

  ::after {
    background-image: url(${overlayImage});
    background-position: 50% 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    transform: scaleY(-1);
    
    position: absolute;

    animation: ${slide} ${props => props.currentStep?.durationSeconds ?? 2}s cubic-bezier(0.49, 0.81, 0.34, 0.86) forwards;
  }
  ::before {
    background-color: #090909;

    content: '';
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    
    position: absolute;

    opacity: 0;
    animation: ${fade} ${props => props.currentStep?.durationSeconds ?? 2}s ease-out forwards;
  }
`;