import { USER_DATA_TYPES, SAVE_USER_DATA_RATE_LIMIT, USER_DATA_REFRESH_INTERVAL, SPORT_TYPE, COUNTRY_TYPE } from '../constants';

const IS_QUIZ_DISABLED = true;

import Content from './Content';
import { IChapter } from '../models/IChapter';
import Api from './Api';
import Eloqua from './Eloqua';
import { NavigateFunction } from 'react-router-dom';
import CohortData from './CohortData';

export interface IChapterProgress {
  firstStartedAt: Date; //UNIX timestamp
  firstCompletedAt?: Date;
}

export interface IUserProgressData {
  chapterProgress: {
    [chapterIdentifier: string]: IChapterProgress;
  };
}
export interface ITeamFeedbackDictionary {
  [id: string]: ITeammatesStrengths;
}

export interface ITeammatesStrengths {
  FirstName: string;
  LastName: string;
  StrengthsOnField: string[];
  StrengthsOffField: string[];
}

interface ISetToast {
  body: string;
}

export interface IPlayerData {
  [USER_DATA_TYPES.notificationSendingAllowed]?: boolean;
    // keeping these two fields, although they won't be accessed again.
  // the purpose is not to send notifications to users who overlap the release
  [USER_DATA_TYPES.welcomeNotificationSentAt]?: number;
  [USER_DATA_TYPES.finalQuizNotificationQueuedFor]?: number;

  [USER_DATA_TYPES.videoCaptionsLanguagePreference]: string;
  [USER_DATA_TYPES.videoVolumePreference]: number;

  [USER_DATA_TYPES.entryQuizComplete]: boolean;
  [USER_DATA_TYPES.finalQuizComplete]: boolean;
  [USER_DATA_TYPES.quizConsent]?: boolean;

  [USER_DATA_TYPES.personalFeedback]?: string[];

  [USER_DATA_TYPES.deviceToken]?: string;
  [USER_DATA_TYPES.timeZone]?: string;

  [USER_DATA_TYPES.userProgress]: IUserProgressData;

  [USER_DATA_TYPES.archiveStatus]: string;

  //team-feedbacks
  [USER_DATA_TYPES.teamFeedback]?: ITeamFeedbackDictionary;
  
  [USER_DATA_TYPES.sport]?: string;
  [USER_DATA_TYPES.country]?: string;
}

let PlayerData: IPlayerData;
let PlayerDataSyncTimeout: string | number | NodeJS.Timeout | undefined;

const initialPlayerDataState: IPlayerData = {
  archiveStatus: '',
  entryQuizComplete: false,
  finalQuizComplete: false,
  userProgress: {
    chapterProgress: {},
  },
  videoCaptionsLanguagePreference: '',
  videoVolumePreference: 1,
  teamFeedback: {},
}

export default {
  savePlayerData(updatedPlayerData: IPlayerData): void {
    //First, instantly save to this session's Player_Data variable
    PlayerData = updatedPlayerData;

    if (CohortData.getDemoState()) {
      localStorage.setItem('playerData', JSON.stringify(updatedPlayerData));

      return;
    }

    //Don't allow savePlayerData requests to be fired more regularly than every 2 seconds.
    //Considering this is updated on volume change, it could be spammed pretty hard.
    if (PlayerDataSyncTimeout) clearTimeout(PlayerDataSyncTimeout);
    const save = async () => updatedPlayerData && Api.savePlayerData(updatedPlayerData);
    PlayerDataSyncTimeout = setTimeout(() => void save(), SAVE_USER_DATA_RATE_LIMIT);
  },

  async getPlayerData(): Promise<IPlayerData> {
    if (CohortData.getDemoState()) {
      const localUserData = localStorage.getItem('playerData');
      const currentUserData = JSON.parse(localUserData || '{}') as IPlayerData;

      return currentUserData;
    }
    return Api.getPlayerData();
  },
  async loadPlayerData() {
    let playerData;
    try {
      playerData = await this.getPlayerData();

      const defaultPlayerData: IPlayerData = {
        ...initialPlayerDataState,
        ...playerData,
      };

      PlayerData = defaultPlayerData;

      this.savePlayerData(defaultPlayerData);
    } catch (e) {
      console.error('Unable to recover, player data malformed', playerData, e);
      //  playerData does not work from the server, lets try to keep the app working
      PlayerData = { ...initialPlayerDataState };

      this.savePlayerData(PlayerData);
    }
    
    this.initPlayerDataRefreshes();
  },

  initPlayerDataRefreshes() {
    const doSync = async () => {
      try {
        const playerData = await this.getPlayerData();

        PlayerData = {
          ...PlayerData,
          [USER_DATA_TYPES.archiveStatus]: playerData.archiveStatus,
          [USER_DATA_TYPES.teamFeedback]: playerData.teamFeedback,
        }; //only update the PlayerData that the server is in charge of
      } catch (e) {
        console.error('Player_Data refresh failure');
      }
      // @todo - fix this!
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(doSync, USER_DATA_REFRESH_INTERVAL);
    };
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(doSync, USER_DATA_REFRESH_INTERVAL);
  },

  _getUserProp<T = any>(key: keyof typeof USER_DATA_TYPES): T {
    return PlayerData 
      ? (PlayerData[key]) as unknown as T 
      : null as unknown as T;
  },

  _setUserProp<T = any>(key: string, value: T): void {
    this.savePlayerData({
      ...PlayerData,
      [key]: value,
    });
  },

  getSport(): SPORT_TYPE | undefined {
    return this._getUserProp(USER_DATA_TYPES.sport);
  },

  getCountry(): COUNTRY_TYPE | undefined {
    return this._getUserProp(USER_DATA_TYPES.country);
  },

  getArchiveStatus(): string {
    return this._getUserProp<string>(USER_DATA_TYPES.archiveStatus);
  },

  getNotificationSendingPermission(): boolean {
    return this._getUserProp<boolean>(USER_DATA_TYPES.notificationSendingAllowed);
  },

  setNotificationSendingPermission(allowed: boolean): void {
    this._setUserProp(USER_DATA_TYPES.notificationSendingAllowed, allowed);
  },

  setQuizConsent(skip: boolean): void {
    this._setUserProp(USER_DATA_TYPES.quizConsent, skip);
  },

  getChapterProgress(chapterIdentifier: string): IChapterProgress | null {
    const userProgress = this._getUserProp<IUserProgressData>(USER_DATA_TYPES.userProgress);
    return userProgress ? userProgress.chapterProgress[chapterIdentifier] ?? null : null;
  },

  getUserStrengths() {
    return PlayerData.teamFeedback ? PlayerData.teamFeedback : {};
  },

  getUserStrengthContributorsLength(): number {
    return Object.keys(this.getUserStrengths()).length;
  },

  hasReceivedFeedbacks(): boolean {
    return this.getUserStrengthContributorsLength() > 0;
  },

  setUserDeviceToken(value: string): void {
    return this._setUserProp(USER_DATA_TYPES.deviceToken, value);
  },

  updateUserTimeZone(): void {
    // get the users time zone and compare it to their current
    // if it's updated send to the api our new device token
    const date = new Date();
    const currentTimeZone = date.getTimezoneOffset();
    const previousTimeZone = this._getUserProp<string | number | undefined>(USER_DATA_TYPES.timeZone);
    /** @todo - Now the types are fixed, there seems to be a problem here which needs fixing as we probably comparing a number to a string */
    if (currentTimeZone !== previousTimeZone) {
      return this._setUserProp(USER_DATA_TYPES.timeZone, currentTimeZone);
    }
  },

  setPersonalFeedback(value: string[]) {
    return this._setUserProp(USER_DATA_TYPES.personalFeedback, value);
  },
  getPersonalFeedback(): string[] {
    return this._getUserProp<string[]>(USER_DATA_TYPES.personalFeedback) || [];
  },

  hasCompletedEntryQuiz(): boolean {
    // Returns true if quiz disabled
    if (IS_QUIZ_DISABLED) return true;

    return this._getUserProp(USER_DATA_TYPES.entryQuizComplete);
  },
  getQuizConsent(): boolean {
    // Returns FALSE if quiz disabled - if this is UNDEFINED the popup consent shows, so returning 'false' is a little more accurate
    if (IS_QUIZ_DISABLED) return false;

    return this._getUserProp(USER_DATA_TYPES.quizConsent);
  },
  hasCompletedFinalQuiz(): boolean {
    // Returns true if quiz disabled
    if (IS_QUIZ_DISABLED) return true;

    return this._getUserProp(USER_DATA_TYPES.finalQuizComplete);
  },

  setEntryQuizComplete(value: boolean): void {
    return this._setUserProp(USER_DATA_TYPES.entryQuizComplete, value);
  },
  setFinalQuizComplete(value: boolean): void {
    return this._setUserProp(USER_DATA_TYPES.finalQuizComplete, value);
  },

  //Returns the latest unlocked chapter for the user
  getLatestChapterForUser(): IChapter {
    const allChapters = Content.getAllChapters();
    const latestChapter = allChapters.find((chapter) => !this.hasCompletedChapter(chapter.chapterIdentifier));
    return latestChapter || allChapters[allChapters.length - 1];
  },

  hasCompletedChapter(chapterIdentifier: string): boolean {
    const progressForChapter = this.getChapterProgress(chapterIdentifier);
    if (!!(progressForChapter && progressForChapter.firstCompletedAt)) return true;

    //If the chapter is a facilitator chapter (one shown in session) we auto- "complete" it at the end of the delivery date
    //This does NOT update the database saying the players completed those chapters (coz they didn't)
    //It just returns a fake 'completion' for the frontend, so the players can proceed with the remaining chapters
    
    // TODO: hide unlock feature when confirmed.  
    // PF Leaving this in code, as this buisness rule has seems to always keep on popping back up.
    const chapter = Content.getChapterByIdentifier(chapterIdentifier);
    if (chapter?.isFacilitatorChapter) {
      const cohortData = CohortData.getCohortData();
      if (cohortData && cohortData.DeliveryDate) {
        const deliveryDate = new Date(cohortData.DeliveryDate);
        const updatedDate = new Date(deliveryDate.getTime());
        // set the updated date to be the same day as the delivery date but 11:59pm (23:59)
        updatedDate.setHours(23, 59, 0, 0);
        const now = new Date();
        
        if (now > updatedDate) {
          return true;
        }
      }
    } 
    return false;
  },

  hasStartedChapter(chapterIdentifier: string): boolean {
    return !!this.getChapterStartedAt(chapterIdentifier);
  },

  hasCompletedAllChapters(): boolean {
    const allChapters = Content.getAllChapters();
    return allChapters.every((chapter) => this.hasCompletedChapter(chapter.chapterIdentifier));
  },

  // adding this function to stop the welcome screen popping up if chapter 1 has been autocompleted in a cohort with a delivery date < now
  // without this, the welcome screen pops up after every chapter that comes after the facilitator chapters
  hasStartedAtLeastOneChapter(): boolean {
    const allChapters = Content.getAllChapters();
    return allChapters.some((chapter) => this.hasStartedChapter(chapter.chapterIdentifier));
  },

  async setChapterCompleted(chapterIdentifier: string): Promise<void> {
    const progressForChapter = this.getChapterProgress(chapterIdentifier);
    if (!(progressForChapter && progressForChapter.firstCompletedAt)) {
      const existingUserProgress = PlayerData ? PlayerData[USER_DATA_TYPES.userProgress] : {} as IUserProgressData;
      // we don't care if it completes or not, it can run synchronously to other processes
      
      this._setUserProp(USER_DATA_TYPES.userProgress, {
        ...existingUserProgress,
        chapterProgress: {
          ...existingUserProgress.chapterProgress,
          [chapterIdentifier]: {
            ...existingUserProgress.chapterProgress[chapterIdentifier],
            firstCompletedAt: new Date(),
          },
        },
      });
      await Eloqua.chapterComplete(chapterIdentifier);
    }
  },

  getChapterStartedAt(chapterIdentifier: string): Date | null {
    const progressForChapter = this.getChapterProgress(chapterIdentifier);
    return progressForChapter ? progressForChapter.firstStartedAt : null;
  },

  setChapterStartedAt(chapterIdentifier: string): void {
    if (!this.hasStartedChapter(chapterIdentifier)) {
      const existingUserProgress = PlayerData[USER_DATA_TYPES.userProgress];

      this._setUserProp(USER_DATA_TYPES.userProgress, {
        ...existingUserProgress,
        chapterProgress: {
          ...(existingUserProgress.chapterProgress ?? {}),
          [chapterIdentifier]: {
            ...existingUserProgress.chapterProgress[chapterIdentifier],
            firstStartedAt: new Date(),
          },
        },
      });
    }
  },

  dev_SimulateSignup(): void {
    this._setUserProp(USER_DATA_TYPES.userProgress, {
      chapterProgress: {},
    });
    this._setUserProp(USER_DATA_TYPES.notificationSendingAllowed, undefined);
    this._setUserProp(USER_DATA_TYPES.welcomeNotificationSentAt, undefined);
    // await this._setUserProp(USER_DATA_TYPES.entryQuizComplete, undefined);

    setTimeout(() => window.location.reload(), SAVE_USER_DATA_RATE_LIMIT * 2);
  },

  async dev_GetUserData(setToast: (content: ISetToast) => void) {
    const playerData = JSON.stringify(PlayerData);
    await navigator.clipboard.writeText(playerData);
    
    setToast({
      body: 'PlayerData copied to clipboard',
    });
  },
  dev_SetUserData(setToast: (content: ISetToast) => void): void {
    // display a prompt, input userData
    try {
      const playerDataText = prompt('PlayerData JSON.');

      if (!playerDataText) return;

      const playerDataJSON = JSON.parse(playerDataText) as IPlayerData;

      this.savePlayerData(playerDataJSON);
      setToast({
        body: 'PlayerData rewritten, reloading',
      });

      setTimeout(() => window.location.reload(), SAVE_USER_DATA_RATE_LIMIT * 2);
    } catch (e) {
      // nothing written
      setToast({
        body: 'PlayerData incorrect, nothing written',
      });
    }
  },
  dev_GoToChapter(setToast: (content: ISetToast) => void, navigate: NavigateFunction): void {
    const allChapters = Content.getAllChapters();

    const chapterCompleteProgress: IUserProgressData = {
      chapterProgress: {},
    };

    const inputChapterOrdinalOrId = prompt('What chapter do you want to go to?');

    if (!inputChapterOrdinalOrId) {
      // no input, ignore
      return;
    }

    const inputChapter = allChapters.find(chapter => (inputChapterOrdinalOrId === `${chapter.ordinal}`) || chapter.chapterIdentifier === inputChapterOrdinalOrId);

    if (!inputChapter) {
      setToast({
        body: 'Invalid input, use the ordinal or chapterIdentifier',
      });

      return;
    }

    const chaptersBeforeOrdinal = allChapters.filter(chapter => chapter.ordinal < inputChapter.ordinal);

    chaptersBeforeOrdinal.forEach((chapter) => {
      chapterCompleteProgress.chapterProgress[chapter.chapterIdentifier] = {
        firstStartedAt: this.getChapterStartedAt(chapter.chapterIdentifier) || new Date(),
        firstCompletedAt: new Date(),
      };
    });

    this._setUserProp(USER_DATA_TYPES.userProgress, {
      ...PlayerData[USER_DATA_TYPES.userProgress],
      chapterProgress: chapterCompleteProgress.chapterProgress,
    });

    // if the user inputs an ordinal of 3 or less, we should clear the feedback data too
    if (inputChapter.ordinal <= 3) {
      this._setUserProp(USER_DATA_TYPES.teamFeedback, {});
    }

    setTimeout(() => {
      navigate(`/chapter/${inputChapter.chapterIdentifier}`);
    }, SAVE_USER_DATA_RATE_LIMIT * 2);
  },
  dev_ClearRateMates(): void {
    this._setUserProp(USER_DATA_TYPES.teamFeedback, {});

    // Wait until the API call is successful
    setTimeout(() => window.location.reload(), SAVE_USER_DATA_RATE_LIMIT * 2);
  },
  dev_CompleteAllChapters(): void {
    const allChapters = Content.getAllChapters();

    const allChaptersDoneProgress: IUserProgressData = {
      chapterProgress: {},
    };

    allChapters.forEach((chapter) => {
      allChaptersDoneProgress.chapterProgress[chapter.chapterIdentifier] = {
        firstStartedAt: this.getChapterStartedAt(chapter.chapterIdentifier) || new Date(),
        firstCompletedAt: new Date(),
      };
    });

    this._setUserProp(USER_DATA_TYPES.userProgress, {
      ...PlayerData[USER_DATA_TYPES.userProgress],
      chapterProgress: allChaptersDoneProgress.chapterProgress,
    });

    // Wait until the API call is successful
    setTimeout(() => window.location.reload(), SAVE_USER_DATA_RATE_LIMIT * 2);
  },

  dev_IncompleteAllChapters(): void {
    this._setUserProp(USER_DATA_TYPES.userProgress, {
      ...PlayerData[USER_DATA_TYPES.userProgress],
      chapterProgress: {},
    });

    // Wait until the API call is successful
    setTimeout(() => window.location.reload(), SAVE_USER_DATA_RATE_LIMIT * 2);
  },

  getVideoCaptionsLanguagePreference(): string {
    return this._getUserProp(USER_DATA_TYPES.videoCaptionsLanguagePreference);
  },

  setVideoCaptionsLanguagePreference(language: string): void {
    this._setUserProp(USER_DATA_TYPES.videoCaptionsLanguagePreference, language);
  },

  getVideoVolumePreference(): number {
    return this._getUserProp(USER_DATA_TYPES.videoVolumePreference);
  },

  setVideoVolumePreference(volume: number): void {
    this._setUserProp(USER_DATA_TYPES.videoVolumePreference, volume);
  },
};
