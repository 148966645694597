import styled from 'styled-components';
import Native from '../../services/Native';
import { Heading } from '../Heading/Heading';
import { IStyledProps } from '../../theme';
import { SvgIcon } from '../SvgIcon/SvgIcon';
import { PAGE_PADDING_PX } from '../Page/Page.styled';

export const StyledChapterHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: ${PAGE_PADDING_PX}px;
  margin-top: ${Native.getPhoneTopInsetPx()}px;
  top: 10px;
  position: relative;
`;

export const StyledHeading = styled(Heading)`
  opacity: 0.5;
  margin-right: 10px;
  color: ${(props: IStyledProps) => props.theme.colors.greys.a};
`;

export const StyledChapterInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledChapterIcon = styled(SvgIcon)`
  height: 32px;
  width: 32px;
  opacity: 0.5;

  > svg {
    path {
      fill: ${(props: IStyledProps) => props.theme.colors.greys.a};
    }
  }
`;