import React, { useState, useEffect } from 'react';
import { BreatheCircle } from './BreatheCircle/BreatheCircle';
import { StyledContainer, StyledFinishedOverlayButton, StyledFinishedOverlayContainer, StyledFinishedOverlayContent, StyledFinishedOverlayHeader, StyledFinishedOverlayInner } from './StrategyExerciseBreathe.styled';
import DataLayer, { GA4ModuleCategoryEnum, GA4ModuleTypeEnum, GTM4Events } from '../../../services/DataLayer';
import { IChapter } from '../../../models/IChapter';
import { Button } from 'components/Button/Button';

interface IStrategyExerciseBreatheProps {
  chapter: IChapter;
  onComplete: () => void;
}

export const StrategyExerciseBreathe: React.FC<IStrategyExerciseBreatheProps> = ({ chapter, onComplete }) => {
  const [hasBegun, setHasBegun] = useState(false);
  const [hasFinished, setHasFinished] = useState(false);

  const activeChapter = DataLayer.getActiveChapter();
  const BUTTON_READY_TEXT = 'Ok, I\'m ready';
  const BUTTON_DONE_TEXT = 'Ok, I\'m done';

  useEffect(() => {
    if (activeChapter && hasBegun) {
      DataLayer.pushStrategyBreatheStart();
      const customEvent: GTM4Events['quizAnswer'] = {
        answer: {
          answer_correct: true,
          answer_id: '',
          answer_text: BUTTON_READY_TEXT,
        },
        module: {
          module_category: GA4ModuleCategoryEnum.AOTG,
          module_id: activeChapter.id,
          module_name: activeChapter.title,
          module_subcategory: `${activeChapter.ordinal}`,
          module_type: GA4ModuleTypeEnum.chapter,
        },
        question: {
          question_id: '',
          question_number: '',
          question_section: '',
          question_text: 'breathing exercise',
          user_id: '',
        },
      };
      DataLayer.pushQuizAnswer_GA4(customEvent);
    }
  }, [hasBegun]);

  const recordExerciseEndEvent = () => {
    if (activeChapter) {
      DataLayer.pushStrategyBreatheEnd();
      const customEvent: GTM4Events['quizAnswer'] = {
        answer: {
          answer_correct: true,
          answer_id: '',
          answer_text: BUTTON_DONE_TEXT,
        },
        module: {
          module_category: GA4ModuleCategoryEnum.AOTG,
          module_id: activeChapter.id,
          module_name: activeChapter.title,
          module_subcategory: `${activeChapter.ordinal}`,
          module_type: GA4ModuleTypeEnum.chapter,
        },
        question: {
          question_id: '',
          question_number: '',
          question_section: '',
          question_text: 'breathing exercise',
          user_id: '',
        },
      };
      DataLayer.pushQuizAnswer_GA4(customEvent);
    }
  }
  
  // if the chapter is a facilitator chapter, we'll use another flow
  if (chapter.isFacilitatorChapter) {

  }

  return <>
      {hasFinished ?
      <StyledFinishedOverlayContainer>
        <StyledFinishedOverlayInner>
          <StyledFinishedOverlayHeader text="That’s it"/>
          <StyledFinishedOverlayContent>
            You can continue doing this exercise for as long as you like.
            <br/>
            <br/>
            <b>Remember</b>
            <br/>
            Breathe in for 4 seconds<br/>
            Hold for 4 seconds<br/>
            Breathe out for 4 seconds<br/>
          </StyledFinishedOverlayContent>
        </StyledFinishedOverlayInner>

        <StyledFinishedOverlayButton primary label="Ok, I’m done" onClick={() => {
          onComplete();
        }} />
      </StyledFinishedOverlayContainer> :
      <StyledContainer>
        <BreatheCircle key={2} isActive={true} onActiveStepsFinished={() => {
          recordExerciseEndEvent();

          if (!chapter.isFacilitatorChapter) {
            onComplete();
            return;
          }

          setHasFinished(true);
        }} />
      </StyledContainer>
    }
  </>;
}