import React from 'react';
import { IChapter } from '../../../models/IChapter';
import { StyledStrategyBannerContainer, StyledStrategyBanner, StyledButton, StyledIcon, StyledHeading, StyledText } from './VideoOverlayStrategyBanner.styled';
interface IVideoOverlayStrategyBannerProps {
  chapter: IChapter;
  onComplete: () => void;
}

export const VideoOverlayStrategyBanner: React.FC<IVideoOverlayStrategyBannerProps> = ({ chapter, onComplete }) => {

  return <StyledStrategyBannerContainer>
    <StyledStrategyBanner>
      <StyledIcon cmsSrc={chapter.icon} />
      <StyledHeading text={chapter.strategy} />
      <StyledText text={chapter.strategyMessage}/>
    </StyledStrategyBanner>
    <StyledButton onClick={onComplete} label="Let's go!" />

  </StyledStrategyBannerContainer>;
}