import React, { useRef, useState, useEffect } from 'react';
import { StyledTextArea } from './ResizableTextarea.styled';

interface IResizableTextareaProps {
  minRows: number;
  maxRows: number;
  maxLength?: number;
  lineHeight?: number;
  placeholder?: string;
  onChange?: (value: string) => void;
  isAutoFocus?: boolean;

  className?: string;
}

export const ResizableTextarea: React.FC<IResizableTextareaProps> = ({ className, minRows, maxRows, maxLength, lineHeight, placeholder, onChange, isAutoFocus }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [value, setValue] = useState('');
  const [rows, setRows] = useState(minRows);

  const textareaLineHeight = lineHeight || 24;

  useEffect(() => {
    if (isAutoFocus && textareaRef && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = event.target;

    const previousRows = textarea.rows;
    textarea.rows = minRows; // reset number of rows in textarea

    const currentRows = Math.floor(textarea.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      textarea.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      textarea.rows = maxRows;
      textarea.scrollTop = textarea.scrollHeight;
    }

    setRows(currentRows < maxRows ? currentRows : maxRows);

    const newValue = textarea.value && maxLength ? textarea.value.substr(0, maxLength) : textarea.value;

    setValue(newValue);

    if (onChange) onChange(newValue);
  };

  return (
    <StyledTextArea
      ref={textareaRef}
      className={className}
      lineHeight={textareaLineHeight}
      rows={rows}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
    />
  );
};
