import { IChapter } from '../models/IChapter';
import { IPage } from '../models/IPage';
import { IStrengthCard } from '../models/IStrengthCard';
import { IContentBase } from '../models/IContentBase';
import { STRENGTH_TYPE, SPORT_TYPE, COUNTRY_TYPE } from '../constants';

import gb_rugby from '../content/gb_rugby';
import au_wow from '../content/au_wow';
import au_aussierules from '../content/au_aussierules';
import au_aussierules_onesession from '../content/au_aussierules_onesession';
import PlayerData from './PlayerData';

interface IContentMap {
  sports: {
    [key in SPORT_TYPE]: {
      [X in COUNTRY_TYPE]?: IContentBase;
    }
  }
}

const CONTENT_MAP : IContentMap = {
  sports: {
    [SPORT_TYPE.Rugby]: {
      [COUNTRY_TYPE.GB]: gb_rugby,
    },
    [SPORT_TYPE.AustralianRules]: {
      [COUNTRY_TYPE.AU]: au_aussierules,
    },
    [SPORT_TYPE.AustralianRulesOneSession]: {
      [COUNTRY_TYPE.AU]: au_aussierules_onesession,
    },
    [SPORT_TYPE.WoWTest]: {
      [COUNTRY_TYPE.AU]: au_wow,
    },
  },
};
const DEFAULT_CONTENT = gb_rugby;

let CONTENT_BASE : IContentBase | undefined;

export default {
  setContentBase() {

    if (!CONTENT_BASE) {
      const getContentBase = () => {
        const sport = PlayerData.getSport();
        const country = PlayerData.getCountry();

        const sportOption = sport 
          ? CONTENT_MAP.sports[sport] 
          : Object.values(CONTENT_MAP.sports)[0];
          
        if (!sportOption) return DEFAULT_CONTENT;

        const countryOption = country 
          ? sportOption[country] 
          : Object.values(sportOption)[0];

        //Return first country for the sport if no match found (or DEFAULT_CONTENT if for some reason the map has a sport with no countries)
        if (!countryOption) return DEFAULT_CONTENT;

        return countryOption;
      }

      CONTENT_BASE = getContentBase();
    }
  },

  getAllChapters(): IChapter[] {
    if (!CONTENT_BASE || !CONTENT_BASE.chapters) return [];

    return CONTENT_BASE.chapters;
  },

  getChapterByIdentifier(identifier: string): IChapter | undefined {
    if (!CONTENT_BASE || !CONTENT_BASE.chapters) return;

    return this.getAllChapters().find(chapter => chapter.chapterIdentifier === identifier);
  },

  getStrengthCards(strengthType?: STRENGTH_TYPE): IStrengthCard[] {
    if (!CONTENT_BASE || !CONTENT_BASE.strengthCards) return [];

    const strengths = CONTENT_BASE.strengthCards;

    if (strengthType) return strengths[strengthType];

    return ([] as IStrengthCard[])
      .concat(...Object.keys(strengths)
      .map(key => strengths[key as STRENGTH_TYPE]));
  },

  getWelcomeScreenBackgroundVideo() {
    if (!CONTENT_BASE) return '';
    return CONTENT_BASE.welcomeScreenVideoUrl;
  },

  getFinaleBackgroundImage() {
    if (!CONTENT_BASE) return '';
    return CONTENT_BASE.finaleBackgroundUrl;
  },

  getVideoOverlayBackgroundImage() {
    if (!CONTENT_BASE) return '';
    return CONTENT_BASE.videoOverlayBackgroundUrl;
  },
}