import React, { useState, useContext } from 'react';
import Content from '../../services/Content';
import NotificationModal from './NotificationModal';
import { ReactComponent as TeamFeedbackIcon } from '../../images/icons/player-feedback.svg';
import {
    StyledMyLearningsIntro,
    StyledMyLearningsAdvancedCard,
    StyledMyLearningsCardContainer, 
    StyledMyLearningsChapterIcon, 
    StyledMyLearningsChapterNumber, 
    StyledMyLearningsHeading, 
    StyledMyLearningsFeedbackTitle,
    StyledMyLearningsList,
    StyledMyLearningsFeedbackCount,
    StyledMyLearningsListItem,
    StyledMyLearningsHeader,
    StyledMyLearningsListItemName,
    StyledMyLearningsContainer,
    StyledMyLearningsH1,
} from './MyLearnings.styled';
import { getUserFeedback } from '../../services/UserFeedback';
import PlayerData from '../../services/PlayerData';
import { ThemeContext } from 'styled-components'

export const MyLearnings: React.FC = () => {

    const allChapters = Content.getAllChapters();
    const [notificationModalIsTrue, setNotificationModalIsTrue] = useState<boolean>(true);
    const [teamHasGivenFeedback] = useState<boolean>(PlayerData.hasReceivedFeedbacks());
    const themeContext = useContext(ThemeContext);

    const feedbackBodyContent = () => {
        const UserStrengths = getUserFeedback();

        return (
        <StyledMyLearningsCardContainer>
            <StyledMyLearningsFeedbackTitle text={'YOUR STRENGTHS <u>ON</u> THE FIELD'}/>
            <StyledMyLearningsList>
                {UserStrengths.onFieldFeedback.map((strength, key) => {
                    return ( 
                    <StyledMyLearningsListItem key={key}>
                        <StyledMyLearningsListItemName>{strength.text}</StyledMyLearningsListItemName>
                        <StyledMyLearningsFeedbackCount>{strength.badgeText}</StyledMyLearningsFeedbackCount>
                    </StyledMyLearningsListItem>
                    );
                })}
            </StyledMyLearningsList>
            <StyledMyLearningsFeedbackTitle text={'YOUR STRENGTHS <u>OFF</u> THE FIELD'}/>
            <StyledMyLearningsList>
                {UserStrengths.offFieldFeedback.map((strength, key) => {
                   return ( 
                    <StyledMyLearningsListItem key={key}>
                        <StyledMyLearningsListItemName>{strength.text}</StyledMyLearningsListItemName>
                        <StyledMyLearningsFeedbackCount>{strength.badgeText}</StyledMyLearningsFeedbackCount>
                    </StyledMyLearningsListItem>
                    );
                })}
            </StyledMyLearningsList>
            <StyledMyLearningsFeedbackTitle text={'SKILLS YOU WANT TO WORK ON'} />
            <StyledMyLearningsList>
                {UserStrengths.personalCookedFeedback.map((strength, key) => {
                    return <StyledMyLearningsListItem key={key}>{strength.text}</StyledMyLearningsListItem>
                })}
            </StyledMyLearningsList>
        </StyledMyLearningsCardContainer>
        );
    }

    return (
        <StyledMyLearningsContainer>
        {notificationModalIsTrue && <NotificationModal setNotificationModalIsTrue={setNotificationModalIsTrue}/>}
            <StyledMyLearningsHeader>
                The app is in <u>archive mode</u>
            </StyledMyLearningsHeader>
            <StyledMyLearningsIntro>
            Thank you for using the <b>Ahead of the Game app</b>. We hope it has helped you and that you are using some of the things you learned, on and off the field.
            </StyledMyLearningsIntro>
            <StyledMyLearningsH1>Review your strategies</StyledMyLearningsH1>
            <StyledMyLearningsCardContainer>
                {allChapters.map((chapter, index) => {
                    return (
                        <StyledMyLearningsAdvancedCard
                            key={index}
                            headerColour={themeContext.colors.primary.a}
                            bodyColour={themeContext.colors.white}
                            headerContent={
                                <StyledMyLearningsCardContainer>
                                    <StyledMyLearningsChapterIcon chapterIdentifier={chapter.chapterIdentifier} />
                                    <StyledMyLearningsHeading primary text={chapter.strategy} />
                                    <StyledMyLearningsChapterNumber>Chapter {chapter.ordinal}</StyledMyLearningsChapterNumber>
                                </StyledMyLearningsCardContainer>
                            }
                            bodyContent={<div dangerouslySetInnerHTML={{ __html: chapter.myLearningsDescription }}></div>} 
                        />
                    )
                })}
                {teamHasGivenFeedback &&
                    <StyledMyLearningsAdvancedCard
                        headerColour={themeContext.colors.primary.a}
                        bodyColour={themeContext.colors.white}
                        headerContent={
                            <StyledMyLearningsCardContainer>
                                <TeamFeedbackIcon/>
                                <StyledMyLearningsHeading primary text={'Your Team Feedback'} />
                            </StyledMyLearningsCardContainer>
                        }
                        bodyContent={feedbackBodyContent()}
                    />
                }
            </StyledMyLearningsCardContainer>
        </StyledMyLearningsContainer>
    );
}

