/* eslint-disable @typescript-eslint/no-floating-promises */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import _languageEN from './translations/en.json';

const resources = {
  en: _languageEN,
};

i18n.use(initReactI18next).init({
  lng: 'en', // if you're using a language detector, do not define the lng option
  debug: true,
  resources,
  fallbackLng: 'en',
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    // wait: true,
    bindI18n: 'languageChanged loaded',
    nsMode: 'default',
  },
});
export default i18n;
