import React from 'react';
import { Page, PagePanel, StaticPage } from './components/Page/Page';
import { PlayerDashboard } from './components/PlayerDashboard/PlayerDashboard';
import { StrategySummary } from './components/StrategySummary/StrategySummary';
import LoginForm from './components/Forms/LoginForm/LoginForm';
import RegisterForm from './components/Forms/RegisterForm/RegisterForm';
import ResetPasswordForm from './components/Forms/ResetPasswordForm/ResetPasswordForm';
import ContactUsForm from './components/Forms/ContactUsForm/ContactUsForm';
import { DevHeader } from './components/DevHeader/DevHeader';
import StrengthPopUp from './components/TeamFeedback/StrengthPopUp/StrengthPopUp';
import { ChapterVideo } from './components/ChapterVideo/ChapterVideo';


import Content from './services/Content';
import { Navigate } from 'react-router-dom';
import { MyLearnings } from './components/MyLearnings/MyLearnings';
import { TeamFeedback } from './components/TeamFeedback/TeamFeedback';
import { SupportServices } from './components/SupportServices/SupportServices';
import Environment, { ENVIRONMENT_TYPE } from './services/Environment';
import { GameViewPlayer } from 'components/GameView/GameViewPlayer/GameViewPlayer';
import { GameViewFacilitator } from 'components/GameView/GameViewFacilitator/GameViewFacilitator';

export interface IRoute {
  index?: boolean;
  path?: string;
  // render?: ({ match }: any) => JSX.Element;
  elem: JSX.Element;
}

/********** Route setup (based on authenticated status) ********/
export const getRoutes = (isAuthenticated: boolean, isArchived: boolean): IRoute[] => {
  // All users can access these routes
  const routes: IRoute[] = [
    { path: '/terms-and-conditions', elem: <StaticPage pageIdentifier="terms-and-conditions" /> },
    { path: '/collection-notice', elem: <StaticPage pageIdentifier="collection-statement" /> },
    { path: '/collection-statement', elem: <StaticPage pageIdentifier="collection-statement" /> },
    {
      path: '/contact-us',
      elem: (
        <Page>
          <ContactUsForm />
        </Page>
      ),
    },
  ];

  //Debug environment only routes
  if (Environment.getCurrentEnvironment().isForDebugging) {
    //Add websocket P.O.C. routes

    routes.push(
      //Facilitator view of session
      { path: '/session-facilitator/:accessCode?', elem: <GameViewFacilitator /> },
      //Player view of session
      { path: '/session', elem: <GameViewPlayer /> },
    );
  }

  if (isAuthenticated && !isArchived) {
    // Only logged-in users can access these routes
    routes.push(
      {
        // index: true,
        path: '*',
        elem: <Navigate to="/dashboard" />,
      },
      {
        // index: true,
        path: '/dashboard',
        elem: (
          <Page>
            {!Environment.isEnvironment(ENVIRONMENT_TYPE.PROD) && <DevHeader />}
            <PlayerDashboard />
          </Page>
        ),
      },

      // { path: '/', render: () => <Redirect to="/dashboard" /> },
      { path: '/', elem: <Navigate to="/dashboard" /> },

      // Excercises
      ...Content.getAllChapters().map((chapter, index) => {
        return {
          path: `/chapter/${chapter.chapterIdentifier}`,
          elem: (
            <Page key={index}>
              <ChapterVideo chapter={chapter} />
            </Page>
          ),
        };
      }),
      // Summaries
      ...Content.getAllChapters().map((chapter) => {
        return {
          path: `/chapter/${chapter.chapterIdentifier}/summary`,
          elem: (
            <Page>
              <StrategySummary chapter={chapter} />
            </Page>
          ),
        };
      }),
      // access the support services from the dash
      {
        path: '/support-services',
        elem: (
          <Page>
            <SupportServices />
          </Page>
        ),
      },
      {
        path: '/team-feedback',
        elem: (
          <Page>
            <TeamFeedback />
          </Page>
        ),
      },

      ...Content.getStrengthCards().map((teamStrength) => {
        return {
          path: `/team-feedback/${teamStrength.slug.toLowerCase()}`,
          elem: (
            <Page>
              <StrengthPopUp feedbackName={teamStrength.text} />
            </Page>
          ),
        };
      }),
    );
  } else if (isAuthenticated && isArchived) {
    routes.push({
      path: '/my-learnings',
      elem: (
        <Page>
          {!Environment.isEnvironment(ENVIRONMENT_TYPE.PROD) && <DevHeader />}
          <MyLearnings />
        </Page>
      ),
    })
  } else {
    // Only logged-out users can access these routes
    routes.push(
      // { path: '/', elem: <Redirect to="/register" /> }, // "/" to login
      { path: '/', elem: <Navigate to="/register" /> }, // "/" to login
      {
        path: '/login',
        elem: (
          <Page>
            <LoginForm />
          </Page>
        ),
      },
      {
        path: '/register',
        // path: '/',
        elem: (
          <Page>
            <RegisterForm />
          </Page>
        ),
      },

      {
        path: '/reset-password',
        elem: (
          <Page>
            <ResetPasswordForm />
          </Page>
        ),
      },
      {
        // index: true,
        path: '*',
        elem: <Navigate to="/register" />,
      },
    );
  }

  // routes.push(
  //   // Catch all other routes and push to /
  //   {
  //     // index: true,
  //     path: '*',
  //     elem: <Navigate to="/register" />,
  //   },
  // );

  // eslint-disable-next-line no-console
  console.log('Routes: ', routes);

  return routes;
};
