import React, { useState } from 'react';
import { StyledBreatheCircle, StyledBreatheCircleInnerOne, StyledBreatheCircleInnerTwo, StyledBreatheCircleInnerThree, StyledBreatheSwipe, StyledBreatheSwipeWrapper, StyledBreatheText, StyledBreatheCircleOuter, StyledInstructionHeading, StyledBreatheSwipeOuterWrapper } from './BreatheCircle.styled';

// the states of a breather during the Breathing stage, used to switch between animations
export enum BreathingState {
  IdleOut,
  IdleIn,

  IntroExpand,
  IntroRetract,
  IntroOut,
  IntroOutHold,

  In,
  InHold,
  Out,
  OutHold,
}

interface BreathingStep {
  state: BreathingState
  instruction?: string
}

//Shown if idle
const LOOPED_IDLE_BREATHING_STEPS: BreathingStep[] = [
  { state: BreathingState.IdleOut, instruction: '<b>Pay attention to your breathing.</b>' },
  { state: BreathingState.IdleIn, instruction: '<b>Pay attention to your breathing.</b>' },
];

//Shown when isActive = true
const ACTIVE_BREATHING_STEPS: BreathingStep[] = [
  { state: BreathingState.IntroExpand },
  { state: BreathingState.IntroRetract },
  { state: BreathingState.IdleOut, instruction: 'Get ready' },
  { state: BreathingState.IdleIn, instruction: 'Get ready' },
  { state: BreathingState.IntroOut, instruction: 'Breathe out' },
  { state: BreathingState.IntroOutHold },

  { state: BreathingState.In, instruction: 'Breathe in <br /> for 4 seconds' },
  { state: BreathingState.InHold, instruction: 'Hold for 4 seconds' },
  { state: BreathingState.Out, instruction: 'Breathe out <br /> for 4 seconds' },
  { state: BreathingState.OutHold, instruction: 'Hold the breath out for 4 seconds' },

  { state: BreathingState.In, instruction: 'Breathe in again' },
  { state: BreathingState.InHold, instruction: 'Hold for 4 seconds' },
  { state: BreathingState.Out, instruction: 'Breathe out <br /> for 4 seconds' },
  { state: BreathingState.OutHold, instruction: 'Hold the breath out for 4 seconds' },

  { state: BreathingState.In, instruction: 'One more time' },
  { state: BreathingState.InHold, instruction: 'Hold for 4 seconds' },
  { state: BreathingState.Out, instruction: 'Breathe out <br /> for 4 seconds' },
];
//Shown when isActive = true, AFTER the ACTIVE_BREATHING_STEPS have finished
const LOOPED_ACTIVE_BREATHING_STEPS: BreathingStep[] = [
  { state: BreathingState.In, instruction: "That's it" },
  { state: BreathingState.InHold, instruction: "That's it" },
  { state: BreathingState.Out, instruction: "That's it" },
  { state: BreathingState.OutHold, instruction: "That's it" },
];

interface IBreatheCircleProps {
  isActive?: boolean //If false, does a relaxed idle pattern, not the actual exercise
  onActiveStepsFinished?: () => void //Called once the active steps are finished
}

export const BreatheCircle: React.FC<IBreatheCircleProps> = ({ isActive, onActiveStepsFinished }) => {
  const [breathingSteps, setBreathingSteps] = useState<BreathingStep[]>(isActive ? ACTIVE_BREATHING_STEPS : LOOPED_IDLE_BREATHING_STEPS);

  const [currentBreathingStep, setCurrentBreathingStep] = useState<BreathingStep>(breathingSteps[0]);
  const [previousBreathingStep, setPreviousBreathingStep] = useState<BreathingStep | undefined>(undefined);

  const areActiveStepsFinished = isActive && breathingSteps === LOOPED_ACTIVE_BREATHING_STEPS; //The looped steps are used once the active breathing steps are done

  // get the dynamic text for breather
  const getInstruction = () => {
    const previousInstruction = previousBreathingStep && previousBreathingStep.instruction;
    const currentInstruction = currentBreathingStep && currentBreathingStep.instruction;

    if (!previousInstruction && !currentInstruction) return;

    if (previousInstruction === currentInstruction) {
      //If the previous + current instructions are the same, don't animate a change
      return <StyledInstructionHeading noAnimation={true} text={currentInstruction || ''} />;
    } else {
      // keep the previous description on screen while animating to next
      return <>
        {previousInstruction && <StyledInstructionHeading key={previousInstruction} isPrevious={true} text={previousInstruction} />}
        {currentInstruction && <StyledInstructionHeading key={currentInstruction} text={currentInstruction} />}
      </>
    }
  }

  const onBreathingAnimationEnd = (e: any) => {
    const breathingStepIndex = breathingSteps.indexOf(currentBreathingStep);
    const newBreathingStep = breathingSteps[breathingStepIndex + 1];

    setPreviousBreathingStep(currentBreathingStep);

    if (newBreathingStep) {
      setCurrentBreathingStep(newBreathingStep);

    } else {

      if (isActive && !areActiveStepsFinished) {
        onActiveStepsFinished && onActiveStepsFinished();
        setCurrentBreathingStep(LOOPED_ACTIVE_BREATHING_STEPS[0]);
        setBreathingSteps(LOOPED_ACTIVE_BREATHING_STEPS);
      } else {
        setCurrentBreathingStep(breathingSteps[0]);
      }
    }
  }

  const GetBreathingClass = () => {
    return (!areActiveStepsFinished && [BreathingState.In, BreathingState.Out].includes(currentBreathingStep.state)) ? 'animate' : ''
  }
  return <>  
    <StyledBreatheSwipeOuterWrapper>
      <StyledBreatheSwipeWrapper>
        <StyledBreatheSwipe>
          <svg viewBox="0 0 100 100">
            <circle className={GetBreathingClass()} cx="50" cy="50" r="50" />
          </svg>
        </StyledBreatheSwipe>
      </StyledBreatheSwipeWrapper>
    </StyledBreatheSwipeOuterWrapper>
    <StyledBreatheCircle>
      <StyledBreatheCircleOuter breathingState={currentBreathingStep.state}>
        <StyledBreatheCircleInnerOne breathingState={currentBreathingStep.state}>
          <StyledBreatheCircleInnerTwo breathingState={currentBreathingStep.state}>
            <StyledBreatheCircleInnerThree breathingState={currentBreathingStep.state} onAnimationEnd={onBreathingAnimationEnd} />
          </StyledBreatheCircleInnerTwo>
        </StyledBreatheCircleInnerOne>
      </StyledBreatheCircleOuter>
      <StyledBreatheText>
        {/* instructions are only shown if isActive */}
        {getInstruction()}
      </StyledBreatheText>
    </StyledBreatheCircle>
  </>
};