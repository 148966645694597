import { createContext } from 'react';
import { IRadioButtonOption } from '../../RadioButtonGroup/RadioButtonGroup';

export interface IToast {
  icon?: JSX.Element
  heading?: string
  body?: string
  buttonLabel?: string
  onDismissed?: () => void

  // for dropdown type
  selectionList?: IRadioButtonOption[]
  onChange?: (value: any) => void
}

export interface IToastContext {
  toast?: IToast
  setToast: (toast?: IToast) => void
}

export const ToastContext = createContext<IToastContext>({} as IToastContext);

export const ToastContextConsumer = ToastContext.Consumer;
export const ToastContextProvider = ToastContext.Provider;