/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import { IChapter } from '../../models/IChapter';
import {
  StyledAchievementScreenTextWrapper,
  StyledAchievementScreenTitle,
  StyledAchievementScreenIcons,
  StyledAchievementScreenDescription,
  StyledAchievementScreenContainer,
  StyledAchievementScreenButton,
  StyledAchievementScreenSuperWrapper,
  StyledAchievementProgressContainer,
  StyledAchievementScreenTrackIcon,
} from './AchievementScreen.styled';
import Content from '../../services/Content';
import { VIDEO_MOMENT_TYPE, CHAPTER_IDENTIFIER } from '../../constants';
import Auth from '../../services/Auth';
import { replaceLabels } from '../../utils/helper';
import { SvgIcon } from '../SvgIcon/SvgIcon';

interface IAchievementScreenProps {
  chapter: IChapter;
  onComplete: () => void;
}

export const AchievementScreen: React.FC<IAchievementScreenProps> = ({ chapter, onComplete }) => {
  const [locked, setLocked] = useState(false);
  
  const handleComplete = () => {
    // we lock everything because we have to wait 100MS for the video moment to unload,
    // it's taking far longer than 100ms to unload, I believe because we're at the end of the video.
    setLocked(true);
    onComplete();
  }

  const achievementContent = chapter.achievementScreen;
  if (!achievementContent) {
    handleComplete();
    return null;
  }

  const chapters = Content.getAllChapters();
  const chapterCount = chapters.length;

  // Safe to use on strings without dynamic variables
  // In such case rawString is returned as-is
  const description = replaceLabels(achievementContent.description, [['firstName', Auth.getFirstName()]]);

  return <>
    <StyledAchievementScreenContainer locked={locked} key={`achievement-screen-${chapter.ordinal}`}>
      <StyledAchievementProgressContainer>
        <StyledAchievementScreenIcons chapter={chapter} chapterCount={chapterCount}>
          {chapters.map((iconChapter, index) => <StyledAchievementScreenTrackIcon key={`chapter-icon-${index}`} cmsSrc={iconChapter.icon} active={iconChapter.ordinal <= chapter.ordinal} />)}
        </StyledAchievementScreenIcons>
        <StyledAchievementScreenTextWrapper>
          <StyledAchievementScreenTitle text={achievementContent.title} />
          <StyledAchievementScreenDescription dangerouslySetInnerHTML={{ __html: description }} />
        </StyledAchievementScreenTextWrapper>
      </StyledAchievementProgressContainer>
      <StyledAchievementScreenButton primary onClick={() => handleComplete()} label={('Next chapter')} />
    </StyledAchievementScreenContainer>
  </>;
};
AchievementScreen.displayName = 'AchievementScreen';