import styled from 'styled-components';
import { Button } from '../Button/Button';
import { Heading } from '../Heading/Heading';
import { Z_INDEX } from '../../constants';
import { IStyledProps } from '../../theme';
import { StyledStrategyExercisesButton } from 'components/StrategyExercises/StrategyExercises.styled';

interface IStyledToastProps extends IStyledProps {
  isShown: boolean
}

export const StyledToastOverlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  z-index: ${Z_INDEX.toast};
  
  pointer-events: ${(props: IStyledToastProps) => props.isShown ? 'all' : 'none'};
  opacity: ${(props: IStyledToastProps) => props.isShown ? '1' : '0'};
  transition: opacity 0.3s;
`;

export const StyledToastImage = styled.div`
  height: 64px;
  width: 64px;
  margin-bottom: 20px;

  > svg {
    path {
      fill: ${(props: IStyledProps) => props.theme.colors.primary.a};
    }
  }
`;

export const StyledToast = styled.div`
  position: absolute;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  padding: 38px 24px;

  background: ${(props: IStyledProps) => props.theme.colors.greys.c};
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.24);
  border-radius: 4px 4px 0px 0px;

  transform-origin: bottom;

  transition: transform 0.4s, opacity 0.3s;  

  transform: translateY(${(props: IStyledToastProps) => props.isShown ? '0' : '100'}%);
`;
export const StyledToastHeading = styled(Heading)`
  font-family: ${(props: IStyledProps) => props.theme.fonts.heading};
`;

export const StyledToastBody = styled.div`
  margin: 16px 0px;
  font-family: Overpass;
  font-size: 18px;
  font-weight: 300;
  color: ${(props: IStyledProps) => props.theme.colors.greys.a};
  text-align: center;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 48px;
  }
`;
export const StyledToastButton = styled(StyledStrategyExercisesButton)`
  display: block;
  width: 100%;
`;