import React from 'react';

import { StyledNavButton } from './NavButton.styled';

import { ReactComponent as IconChevron } from '../../images/icons/chevron.svg';
import { getQueryParam } from '../../utils/helper';

interface INavButtonInterface {
  chapterIdentifier: string
}

export const NavButton: React.FC<INavButtonInterface> = ({ chapterIdentifier }) => {
  return <StyledNavButton to={`/dashboard?cid=${getQueryParam('from') ?? chapterIdentifier}`} data-testid="nav-button"><IconChevron /></StyledNavButton>;
}