import React from 'react';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { Heading } from '../Heading/Heading';
import { StyledStrengthCard } from '../StrategyExercises/StrategyExerciseStrength/StrategyExerciseStrength.styled';
import { SvgIcon } from '../SvgIcon/SvgIcon';
import { CardList } from '../CardList/CardList';
import { getUserFeedback, ITeamFeedback } from '../../services/UserFeedback';
import {
  StyledCloseIcon,
  StyledNotificationText,
  StyledTeamFeedbackCircleContainer,
  StyledTeamFeedbackCardList,
  StyledTeamFeedbackContainer,
  StyledTeamFeedbackContent,
  StyledTeamFeedbackSubTitle,
  StyledTeamFeedbackTitle,
} from './TeamFeedback.styled';
import { useNavigate } from 'react-router-dom';
import Content from '../../services/Content';

export const TeamFeedback: React.FC = () => {

  const navigate = useNavigate();

  const UserFeedbackCards = getUserFeedback();

  const finaleBackground = Content.getFinaleBackgroundImage();

  const renderCard = (card: ITeamFeedback) => {
    return (
      <StyledStrengthCard
        onClick={() =>
          navigate(`/team-feedback/${card.slug}`, {
            state: card.contributors,
          })
        }
        upperContent={<SvgIcon cmsSrc={card.icon} />}
        lowerContent={
          <Heading
            level="h4"
            text={card.text}
          />
        }
      >
        <StyledTeamFeedbackCircleContainer>
          <StyledNotificationText>{card.badgeText}</StyledNotificationText>
        </StyledTeamFeedbackCircleContainer>
      </StyledStrengthCard>
    );
  };

  const renderSelfCard = (card: ITeamFeedback) => {
    return (
      <StyledStrengthCard
        upperContent={<SvgIcon cmsSrc={card.icon} />}
        lowerContent={
          <Heading
            level="h4"
            text={card.text}
          />
        }
      />
    );
  }

  return (
    <StyledTeamFeedbackContainer image={finaleBackground}>
      <StyledTeamFeedbackContent>
        <StyledCloseIcon to="/dashboard">
          <CloseIcon />
        </StyledCloseIcon>

        <StyledTeamFeedbackTitle
          primary
          text={'Your Team Feedback'}
        />

        {UserFeedbackCards.onFieldFeedback.length !== 0 && (
          <>
            <StyledTeamFeedbackSubTitle text={'Your strengths on the field'} />
            <StyledTeamFeedbackCardList>
              <CardList
                fadeIn={false}
                cardComponents={UserFeedbackCards.onFieldFeedback.map((v) => renderCard(v))}
              />
            </StyledTeamFeedbackCardList>
          </>
        )}

        {UserFeedbackCards.offFieldFeedback.length !== 0 && (
          <>
            <StyledTeamFeedbackSubTitle text={'Your strengths off the field'} />
            <StyledTeamFeedbackCardList>
              <CardList
                fadeIn={false}
                cardComponents={UserFeedbackCards.offFieldFeedback.map((v) => renderCard(v))}
              />
            </StyledTeamFeedbackCardList>
          </>
        )}

        {UserFeedbackCards.personalCookedFeedback.length !== 0 && (
          <>
            <StyledTeamFeedbackSubTitle text={'Skills you want to work on'} />
            <StyledTeamFeedbackCardList>
              <CardList
                fadeIn={false}
                cardComponents={UserFeedbackCards.personalCookedFeedback.map((v) => renderSelfCard(v))}
              />
            </StyledTeamFeedbackCardList>
          </>
        )}
      </StyledTeamFeedbackContent>
    </StyledTeamFeedbackContainer>
  );
};
