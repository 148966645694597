import React, { PropsWithChildren, useContext, useState } from 'react';
import { StyledCheckbox, StyledCheckboxContainer, StyledCheckboxClickableContainer, StyledCheckboxLabel, StyledErrorMessage } from './Checkbox.styled';
import { ThemeContext } from 'styled-components';

interface ICheckboxProps {
  isCheckedInitially?: boolean;
  onChange: (newStatus: boolean) => void;
  errorMessage?: string;
  hasError?: boolean;
}

export const Checkbox: React.FC<PropsWithChildren<ICheckboxProps>> = ({ onChange, isCheckedInitially, errorMessage, hasError, children }) => {
  const [isChecked, setIsChecked] = useState(!!isCheckedInitially);
  const themeContext = useContext(ThemeContext);

  const handleClick = (e: any) => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    onChange && onChange(newValue);
  };

  return <StyledCheckboxContainer>
    <StyledCheckboxClickableContainer onClick={handleClick}>
      <StyledCheckbox checked={isChecked} style={hasError && !isChecked ? { border: ` solid 2px ${themeContext.colors.accent.error}` } : {} } />
      <StyledCheckboxLabel>{children}</StyledCheckboxLabel>
    </StyledCheckboxClickableContainer>
    <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
  </StyledCheckboxContainer>
}