import styled, { css } from 'styled-components';
import { Z_INDEX } from '../../constants';
import { IStyledProps } from '../../theme';
import { Button } from '../Button/Button';
import { Heading } from '../Heading/Heading';

import { ReactComponent as LogoBlack } from '../../images/logo-black.svg';
import { ReactComponent as IconTickCirlce } from '../../images/icons/circled-tick.svg';
import { AppLogo } from '../AppLogo/AppLogo';

export const NotificationChoiceOverlayContainer = styled.div`
  background:  ${(props: IStyledProps) => props.theme.colors.greys.c};
  
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  transition: opacity 0.3s;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-top: 24px;

  z-index: ${() => Z_INDEX.applicationOverlay};
`;

export const StyledAppLogo = styled(AppLogo)`
  height: 50%;
`;

export const NotificationChoiceSubTitle = styled(Heading)`
  font-family: ${(props: IStyledProps) => props.theme.fonts.heading};
  letter-spacing: 12px;
  font-size: 32px;
  padding-top: 6px;
  padding-left: 8px;

  @media screen and (max-width: ${(props: IStyledProps) => props.theme.breakpoints.xxs}) {
    font-size: 26px;
  }
`;

export const NotificationChoiceBody = styled(Heading)`
  font-size: 18px;
  line-height: 22px;
`;

export const NotificationChoiceButtonColumn = styled.div`
  display: block;
  width: 100%;
  padding:22px;
`;

export const NotificationChoiceButton = styled(Button)`
  font-family: ${(props: IStyledProps) => props.theme.fonts.heading};
  width: 100%;
  margin-top: 20px;
  
  ${(props) => {
    if (props.primary) {
      return css`
        background-color: ${props.theme.colors.black}; 
      `;
    }
  }}

  h5 {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 1px;
  }
`;

export const NotificationSignupSuccessful = styled(IconTickCirlce)`
  width: 50px;
  height: 50px;

  path {
    fill: ${(props: IStyledProps) => props.theme.colors.primary.a};
  }
`

export const NotificationSignupSuccessfulContainer = styled.div`
  position: relative;

  text-align: center;

  :after {
    content: 'Sign up successful';
    position: relative;
    display: block;

    color:  ${(props: IStyledProps) => props.theme.colors.greys.a};

    font-size: 22px;
    font-weight: bold;

    margin-top: 4px;
  }
`;
export const NotificationFormLogo = styled(LogoBlack)`
  height: 193px;
  width: calc(100% - 32px);
  margin: 0px 16px;
`;