import React from 'react';
import { CypressTestable } from '../../@types/cypress';
import { StyledButton, StyledButtonHeading } from './Button.styled';
// CypressTestable
export interface IButtonProps extends React.RefAttributes<any> {
  type?: 'button' | 'submit' | undefined;
  isDisabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any;
  label: string;
  withArrow?: boolean;
  className?: string;

  primary?: boolean; //true if CTA
  hollow?: boolean
}

export const Button: React.FC<IButtonProps> = React.forwardRef((props, ref) => {
  const { type, withArrow, onClick, label, className, isDisabled, primary = false, hollow = false, ...rest } = props;
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick && !isDisabled) {
      onClick(event);
    }
  };

  return (
    <StyledButton
      ref={ref}
      type={type}
      primary={primary}
      hollow={hollow}
      isDisabled={isDisabled}
      withArrow={withArrow}
      onClick={handleClick}
      className={className}
      {...rest}
    >
      <StyledButtonHeading text={label} />
    </StyledButton>
  );
})

Button.displayName = 'Button'