import React, { useState, useContext } from 'react';
import { StyledControlCard, 
  StyledCardHeading,
  StyledControlCardIcon,
  StyledIcon,
  StyledInstruction,
  StyledControlCardTick } from './StrategyExerciseControl.styled';
import { ReactComponent as IconTick } from '../../../images/icons/tick.svg';
import { CardList } from '../../CardList/CardList';
import { IToastContext, ToastContext } from '../../Toast/ToastContext/ToastContext';
import { ICard } from '../../../models/ICard';
import DataLayer, { GTM4Events, GA4ModuleCategoryEnum, GA4ModuleTypeEnum } from '../../../services/DataLayer';
import { IExerciseControl, IExerciseControlCard } from '../../../models/exercises/IExerciseControl';
import { ReactComponent as IconThumbsUp } from '../../../images/icons/thumbsup.svg';
import { ReactComponent as IconThinking } from '../../../images/icons/thinking.svg';

interface IStrategyExerciseControlProps {
  strategyExerciseControl: IExerciseControl;
  onComplete: () => void;
}

export enum ControlState {
  SelectingControlCards,
  SelectingImmediateControlCards,
}

export const StrategyExerciseControl: React.FC<IStrategyExerciseControlProps> = ({ strategyExerciseControl, onComplete }) => {
  const { setToast }: IToastContext = useContext(ToastContext);
  
  const [selectedControlCards, setSelectedControlCards] = useState<IExerciseControlCard[]>([]);
  const [selectedImmediateControlCards, setSelectedImmediateControlCards] = useState<IExerciseControlCard[]>([]);

  const [activeState, setActiveState] = useState<ControlState>(ControlState.SelectingControlCards);

  const isSelectingControlCards = activeState === ControlState.SelectingControlCards;

  const activeChapter = DataLayer.getActiveChapter();

  const showToast = (isCorrect: boolean, body: string, onDismissed: () => void) => {
    setToast({
      icon: isCorrect
        ? <StyledIcon><IconThumbsUp /></ StyledIcon>
        : <StyledIcon><IconThinking /></StyledIcon>,
      heading: isCorrect 
        ? 'Good work!' 
        : 'Try again',
      buttonLabel: isCorrect
        ? 'Continue'
        : 'Got it',
      body: body,
      onDismissed: onDismissed,
    })
  };
  
  if (isSelectingControlCards) {
    //1. Select 3 things he can control

    const onControlSelected = (selectedCard: IExerciseControlCard) => {
      if (selectedControlCards.includes(selectedCard)) return;
      setSelectedControlCards(prevCards => [...prevCards, selectedCard]);
      DataLayer.pushStrategyControlCardSelected(selectedCard.text, false, selectedCard.isControllable);
      if (activeChapter ) {
        const customEvent: GTM4Events['quizAnswer'] = {
          answer: {
            answer_correct: selectedCard.isControllable,
            answer_id: '',
            answer_text: selectedCard.text,
          },
          module: {
            module_category: GA4ModuleCategoryEnum.AOTG,
            module_id: activeChapter.id,
            module_name: activeChapter.title,
            module_subcategory: `${activeChapter.ordinal}`,
            module_type: GA4ModuleTypeEnum.chapter,
          },
          question: {
            question_id: '',
            question_number: '',
            question_section: '',
            question_text: `Select ${strategyExerciseControl.numberOfCardsToChoose} things he CAN control`,
            user_id: '',
          },
        };
        DataLayer.pushQuizAnswer_GA4(customEvent);
      }
      showToast(selectedCard.isControllable, selectedCard.controlFeedback, () => {
        // This does NOT change the state, just used for getting the up-to-date value of "prevCards"
        setSelectedControlCards(prevCards => {
          // True if the user has selected the required amount of controllables, but not an immediate controllable
          const hasSelectedControllables = prevCards.filter(card => card.isControllable).length === strategyExerciseControl.numberOfCardsToChoose;
          if (hasSelectedControllables) setTimeout(() => setActiveState(ControlState.SelectingImmediateControlCards), 500);
          return prevCards;
        });
      });
    };
    
    return <div>
      <StyledInstruction key='controllables-instruction' text={`Select ${strategyExerciseControl.numberOfCardsToChoose} things he <b>CAN</b> control`} />
      <CardList key='controllables-cards' cardComponents={strategyExerciseControl.controlCards.map((card, key) => {
        const isActive = card.isControllable && selectedControlCards.includes(card);
        return <StyledControlCard
          key={key}
          isActive={isActive} 
          isDisabled={!card.isControllable && selectedControlCards.includes(card)} 
          onClick={() => onControlSelected(card)}
          upperContent={<StyledControlCardIcon cmsSrc={card.icon} />}
          lowerContent={<StyledCardHeading text={card.text} />}
        >
            {isActive ? <StyledControlCardTick><IconTick/></StyledControlCardTick> : null}
        </StyledControlCard>
})} 
      />
    </div>
  } else {
    //2. Select the immediate controllable
    
    const onImmediateControlSelected = (selectedCard: IExerciseControlCard) => {
      if (selectedImmediateControlCards.includes(selectedCard)) return;
      setSelectedImmediateControlCards(prevCards => [...prevCards, selectedCard]);
      DataLayer.pushStrategyControlCardSelected(selectedCard.text, true, selectedCard.isImmediatelyControllable);
  
      if (activeChapter ) {
        const customEvent: GTM4Events['quizAnswer'] = {
          answer: {
            answer_correct: selectedCard.isControllable,
            answer_id: '',
            answer_text: selectedCard.text,
          },
          module: {
            module_category: GA4ModuleCategoryEnum.AOTG,
            module_id: activeChapter.id,
            module_name: activeChapter.title,
            module_subcategory: `${activeChapter.ordinal}`,
            module_type: GA4ModuleTypeEnum.chapter,
          },
          question: {
            question_id: '',
            question_number: '',
            question_section: '',
            question_text: `Select ${strategyExerciseControl.numberOfCardsToChoose} things he CAN control`,
            user_id: '',
          },
        };
        DataLayer.pushQuizAnswer_GA4(customEvent);
      }
      
      showToast(selectedCard.isImmediatelyControllable, selectedCard.immediateControlFeedback, () => {
        if (selectedCard.isImmediatelyControllable) setTimeout(() => onComplete(), 500);
      });
    };

    return <div>
      <StyledInstruction key='immediate-controllables-instruction' text="Choose something he can act on right away." />
      <CardList key='immediate-controllables-cards' cardComponents={selectedControlCards.filter(card => card.isControllable).map((card, key) => {
        const isActive = card.isImmediatelyControllable && selectedImmediateControlCards.includes(card);
        return <StyledControlCard
          key={key}
          isActive={isActive}
          isDisabled={!card.isImmediatelyControllable && selectedImmediateControlCards.includes(card)} 
          onClick={() => onImmediateControlSelected(card)}
          upperContent={<StyledControlCardIcon cmsSrc={card.icon} />}
          lowerContent={<StyledCardHeading text={card.text}
          />}
        >
          {isActive ? <StyledControlCardTick><IconTick/></StyledControlCardTick> : null}
        </StyledControlCard>
})}
      />
    </div>
  }
}