export const isValidEmail = (email: string) => {
  if (email && email.length > 0) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }
  return false;
}

export const isValidPassword = (password: string) => {
  return !!(password && password.length >= 8);
};

export const isValidTeamCode = (teamCode: string) => {
  //Team code must be all uppercase letters + numbers, and 4 characters long
  const re = /^[A-Z0-9_]*$/;
  return !!teamCode && re.test(teamCode) && teamCode.length > 0;
}