import IPlayerState, { IGameState } from 'models/IGameState';
import React from 'react';
import { PlayerAvatar } from '../PlayerAvatar/PlayerAvatar';
import { StyledPlayerBoard, StyledPlayersTeamContainer, StyledAvatarContainer, StyledTeamSide, StyledScore } from './PlayerBoard.styled';

interface IPlayerBoardProps {
  gameState: IGameState
}

export const PlayerBoard: React.FC<IPlayerBoardProps> = ({ gameState }) => {
  return <StyledPlayerBoard>
    {gameState.teams.map((team, idx) => {
      const teamPlayers = gameState.playerStates.filter(player => player.team === idx);

      return <StyledTeamSide>
        <StyledScore>{team.points <= 9 || team.points >= 0 ? `0${team.points}` : team.points}</StyledScore>
        <StyledPlayersTeamContainer>
          {teamPlayers.map((teamPlayer: IPlayerState) => <StyledAvatarContainer size={100 / teamPlayers.length}>
            <PlayerAvatar player={teamPlayer} />
          </StyledAvatarContainer>)}
      </StyledPlayersTeamContainer>
    </StyledTeamSide>
    })}
  </StyledPlayerBoard>;
}