import React, { DOMAttributes, SVGAttributes, useLayoutEffect, useRef, useState } from 'react';
import { addPanEvent } from '../../../utils/dom';
import { FLING_DISTANCE, FLING_ROTATION, StyledAdvancedCard, StyledSwipeCardDummy, StyledSwipeCardButton, StyledSwipeCardOverlay, StyledSwipeCardQuestionText, StyledSwipeCardScalar } from './StrategyExerciseChoose.styled';

const INNERTIA_STORE_TEST_VALUE = 100;
const INNERTIA_STORE_DURATION = 100;
const DRAG_OVERLAY_THRESHOLD = 16;
const DRAG_OVERLAY_DISTANCE = 180;

const DRAG_TRANSITION_RULE = 'none';
// safari fix. Safari expands whatever transition rules are input into transition sub-categories.
const NO_DRAG_TRANSITION_RULE = 'transform 0.3s';

export type ISwipeCardState = 'pre-enter' | 'entering' | 'standing' | 'shake' | 'fling-left' | 'fling-right' | 'done';

export interface ISwipeCard {
  accentColor: string;
  questionText: string;

  state: ISwipeCardState;

  stackCount: number;
  stackIndex: number;

  onCheckClick: () => void;
  onCrossClick: () => void;
}

export const SwipeCard: React.FC<ISwipeCard> = ({ state, accentColor, questionText, stackCount, stackIndex, onCheckClick, onCrossClick }) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const cardOverlayTickRef = useRef<HTMLDivElement>(null);
  const cardOverlayCrossRef = useRef<HTMLDivElement>(null);

  const overlayOpacityRef = useRef<number>(0);

  useLayoutEffect(() => {
    const dragState = {
      x: 0,
      y: 0,
      lastInnertiaTestDirection: -1,
      lastInnertiaTestPass: -1,
    };
    
    if (!cardRef) return;
    const cardEle = cardRef.current;

    if (!cardEle) return;
    
    cardEle.addEventListener('animationend', (e: any) => {
      cardEle.style.transition = NO_DRAG_TRANSITION_RULE;
      cardEle.style.transform = '';
    });
    cardEle.addEventListener('animationcancel', (e: any) => {
      cardEle.style.transition = NO_DRAG_TRANSITION_RULE;
      cardEle.style.transform = '';
    });

    const updateDragOverlay = (forceX?: number) => {
      const testX = forceX ?? dragState.x;
      // get how far left or right it is by the drag store
      let opacity = 0;

      if (Math.abs(testX) > DRAG_OVERLAY_THRESHOLD) {
        opacity = Math.sign(testX) * (Math.abs(testX) - DRAG_OVERLAY_THRESHOLD) / DRAG_OVERLAY_DISTANCE;
      }

      overlayOpacityRef.current = opacity;

      if (!cardOverlayTickRef.current || !cardOverlayCrossRef.current) return;

      cardOverlayTickRef.current.style.setProperty('opacity', `${overlayOpacityRef.current}`);
      cardOverlayCrossRef.current.style.setProperty('opacity', `${-overlayOpacityRef.current}`);
    }

    // set up the scrolling
    addPanEvent(cardEle, {
      'onStart': (e: any) => {
        dragState.x = 0;
        dragState.y = 0;
        // check if it's the right card
        return true;
      },
      'onMove': (e: SVGAttributes<any>): boolean => {
        dragState.x += (e.dx as number ?? 0);
        dragState.y += (e.dy as number ?? 0);

        // here's some dumb stuff... We want to know if the card has been flung.
        // so we record the last time there was significant movement.
        // if there's significant movement recently, then we say the card is flung.
        const moveDistanceX = (dragState.x * dragState.x);

        if (moveDistanceX > INNERTIA_STORE_TEST_VALUE) {
          dragState.lastInnertiaTestDirection = parseInt(`${e.dx || ''}`);
          dragState.lastInnertiaTestPass = Date.now();
        }
        
        const cardElem = cardRef.current;

        if (cardElem) {
          const flingProgress = dragState.x / FLING_DISTANCE;
          const rot = flingProgress * FLING_ROTATION;

          // move the card. Note the drag transition rule is to make the card fling,
          // a little bit quicker than if pushing the buttons.
          cardElem.style.transition = DRAG_TRANSITION_RULE;
          cardElem.style.transform = `translate(calc(-50% + ${dragState.x}px), calc(-50% + ${dragState.y}px)) rotate(${rot}deg)`;
        }

        updateDragOverlay();

        return true;
      },
      'onEnd': (e: any) => {
        const currentTime = Date.now();
        const timeToLastInnertiaFlick = currentTime - dragState.lastInnertiaTestPass;

        const cardElem = cardRef.current;
        if (!cardElem) return;

        if (timeToLastInnertiaFlick < INNERTIA_STORE_DURATION) {

          if (dragState.lastInnertiaTestDirection > 0) {
            onCheckClick();
          } else {
            onCrossClick();
          }
          
          cardElem.style.transition = NO_DRAG_TRANSITION_RULE;
          cardElem.style.transform = '';
        } else {
          cardElem.style.transition = NO_DRAG_TRANSITION_RULE;
          cardElem.style.transform = '';
        }

        updateDragOverlay(dragState.x % 1);

        dragState.x = 0;
        dragState.y = 0;
      },
      'preventDefault': false,
    });
  }, [cardRef, onCheckClick, onCrossClick]);

  return (stackIndex < stackCount + 1) ? 
    <StyledSwipeCardScalar
      state={state}
      stackIndex={stackIndex}
      stackCount={stackCount}>

        <StyledSwipeCardDummy
          ref={cardRef}
          state={state}
          stackIndex={stackIndex}
          stackCount={stackCount}>

          <StyledAdvancedCard
            headerColour={accentColor}
            headerContent={<StyledSwipeCardQuestionText dangerouslySetInnerHTML={{ __html: questionText }} />}
            bodyContent={
              <>
                <StyledSwipeCardButton data-cy="cross" type={'cross'} onClick={onCrossClick} />
                <StyledSwipeCardButton data-cy="tick" type={'tick'} onClick={onCheckClick} />
              </>
            }
          ></StyledAdvancedCard>
          

          {
            (stackIndex == stackCount - 1) && <>
              <StyledSwipeCardOverlay ref={cardOverlayCrossRef} opacity={overlayOpacityRef.current} />

              <StyledSwipeCardOverlay ref={cardOverlayTickRef} className={'tick'} opacity={-overlayOpacityRef.current} />
            </>
          }

        </StyledSwipeCardDummy>
    </StyledSwipeCardScalar>
    : <></>;
};
