import React, { useState, useRef, useEffect, useContext } from 'react';
import { ISticker, IVideoMomentStickerSelector } from '../../../models/IVideoMoment';

import { StyledSelectableSticker, 
  StyledStickerContainer, 
  StyledStickerSelectorContainer, 
  StyledSelectedSticker,
  StyledStickerFeedbackContainer,
  StyledStickerFeedback,
  StyledStickerFeedbackInner,
  StyledContinueButton,
  FEEDBACK_OVERLAY_SECONDS,
} from './VideoOverlayStickerSelector.styled';
import { VideoOverlayTimer } from '../VideoOverlayTimer/VideoOverlayTimer';
import DataLayer, { GA4ModuleCategoryEnum, GA4ModuleTypeEnum, GTM4Events } from '../../../services/DataLayer';
import { ThemeContext } from 'styled-components';

interface IVideoOverlayStickerProps {
  videoMomentStickerSelector: IVideoMomentStickerSelector;
  onStickerSelected: (sticker: ISticker) => void; //Fires when a sticker is selected (dont confuse with onComplete)
  onDurationEnd: () => void; //Fires when the duration is up (whether or not a sticker has/hasn't been selected)
  onComplete: () => void; //Fires when the sticker has been selected AND the feedback CTA is clicked (does not care about duration)
}

// Ideally there is a resume callback and a clean up callback.
// But for now, we just use the one callback.

const PROMPT_HEADING = "What's he thinking?";

export const VideoOverlayStickerSelector: React.FC<IVideoOverlayStickerProps> = ({ onStickerSelected, onComplete, onDurationEnd, videoMomentStickerSelector }) => {
  const themeContext = useContext(ThemeContext);
  
  const selectedStickerWrapperRef = useRef<HTMLDivElement>(null);

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [hasClickedButton, setHasClickedButton] = useState(false);
  const [selectedStickerBaseWidth, setSelectedStickerBaseWidth] = useState(0); //Used to scale up the selected sticker to almost full view width

  const hasSelectedSticker = selectedIndex >= 0;
  
  const stickers = videoMomentStickerSelector.stickers.map((sticker, index) => {
    const stickerThemeColours = themeContext.colors.videoStickers[index % themeContext.colors.videoStickers.length];
    return {
      ...sticker,
      bgColour: stickerThemeColours.color,
      textColour: stickerThemeColours.text,
    }
  });

  useEffect(() => {
    const selectedStickerEl = selectedStickerWrapperRef.current;
    if (!selectedStickerEl) return;
    setSelectedStickerBaseWidth(selectedStickerEl.clientWidth);
  }, [selectedIndex])

  const selectSticker = (stickerIndex: number) => {
    setSelectedIndex(stickerIndex);
    const sticker = stickers[stickerIndex];
    DataLayer.pushStickerSelected(PROMPT_HEADING, (sticker.text || ''));
    const activeChapter = DataLayer.getActiveChapter();

    if (activeChapter) {
      // Push GTM Event
      const customEvent: GTM4Events['quizAnswer'] = {
        answer: {
          answer_correct: true,
          answer_id: '',
          answer_text:  sticker.text,
        },
        module: {
          module_category: GA4ModuleCategoryEnum.AOTG,
          module_id: activeChapter.id,
          module_name: activeChapter.title,
          module_subcategory: `${activeChapter.ordinal}`,
          module_type: GA4ModuleTypeEnum.chapter,
        },
        question: {
          question_id: '',
          question_number: '',
          question_section: '',
          question_text: PROMPT_HEADING,
          user_id: '',
        },
      };
      DataLayer.pushQuizAnswer_GA4(customEvent);
    }
    onStickerSelected(sticker);
  };

  const onContinueClick = () => {
    if (hasClickedButton) return;
    setHasClickedButton(true);

    //Wait for fade out 
    setTimeout(() => onComplete(), FEEDBACK_OVERLAY_SECONDS * 1000);
  };

  const onTimerFinish = () => {
    //Only pause the video if we haven't clicked our CTA button (because if we have, it's finishing up anyway)
    if (!hasClickedButton) onDurationEnd();
  };

  return (
    <StyledStickerSelectorContainer>
        <StyledStickerContainer>
        {hasSelectedSticker
            ? <StyledStickerFeedbackContainer isFading={hasClickedButton}>
              <StyledStickerFeedbackInner>
                <div ref={selectedStickerWrapperRef}>
                  <StyledSelectedSticker baseWidth={selectedStickerBaseWidth} sticker={stickers[selectedIndex]} />
                </div>
                <StyledStickerFeedback>{videoMomentStickerSelector.feedback}</StyledStickerFeedback>
                <StyledContinueButton onClick={onContinueClick} label='Next' />
              </StyledStickerFeedbackInner>
            </StyledStickerFeedbackContainer>
            : stickers.map((sticker: ISticker, i: number) => {
              // generate all the stickers.
              const key = `sticker-${i}`;
              const stickerProps = {
                sticker: sticker,
                rotation: Math.random() * 6 * (Math.round(Math.random()) ? 1 : -1),
                
                onClick: () => selectSticker(i),
              }
  
              return <StyledSelectableSticker {...stickerProps} key={i} />;
            })
        }
        </StyledStickerContainer>
      <VideoOverlayTimer 
        isVisible={!hasSelectedSticker} 
        duration={videoMomentStickerSelector.duration} 
        onFinish={() => onTimerFinish()}
        heading={PROMPT_HEADING}
      />
    </StyledStickerSelectorContainer>
  );
}