import styled from 'styled-components';

import { ISticker } from '../../models/IVideoMoment';
import { Heading } from '../Heading/Heading';
import { IStyledProps } from '../../theme';

interface StyledStickerProps extends IStyledProps {
  sticker: ISticker
}

const StyledSticker = styled.div`
  user-select: none;
  background-color: ${(props: StyledStickerProps) => props.sticker.bgColour || 'transparent'};
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.27);
`;

export const StyledTextSticker = styled(StyledSticker)`
  border: 4px solid white;
  border-radius: 8px;
  padding: 8px 8px 4px 8px;
`;

export const StyledStickerText = styled(Heading).attrs({ level: 'h3', primary: true })`
  color: ${(props: StyledStickerProps) => props.sticker.textColour || props.theme.colors.white};
  white-space: nowrap;
  pointer-events: none;
  touch-action: none;
  letter-spacing: 0.4;
  letter-spacing: normal;
`;