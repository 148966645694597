/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useContext } from 'react';
import Auth from '../../services/Auth';
import PlayerData from '../../services/PlayerData';
import { IToastContext, ToastContext } from '../Toast/ToastContext/ToastContext';
import { useNavigate } from 'react-router-dom';
import { Z_INDEX } from '../../constants';

export const DevHeader : React.FC = () => {
  const { setToast }: IToastContext = useContext(ToastContext);
  const navigate = useNavigate();

  const actions = [{
      name: 'Logout',
      fn: () => Auth.logout(),
    }, {
      name: 'UNLOCK all',
      fn: () => PlayerData.dev_CompleteAllChapters(),
    }, {
      name: 'LOCK all',
      fn: () => PlayerData.dev_IncompleteAllChapters(),
    }, {
      name: 'GOTO chapter...',
      fn: () => PlayerData.dev_GoToChapter(setToast, navigate),
    }, {
      name: 'GET PlayerData',
      fn: () => PlayerData.dev_GetUserData(setToast),
    }, {
      name: 'SET PlayerData',
      fn: () => PlayerData.dev_SetUserData(setToast),
    },
  ];

  return <header style={{ zIndex: Z_INDEX.testTools, position: 'absolute', top: 0, right: 0 }}>
      <span>Test tools:</span>
      <select style={{ fontSize: '20px' }} value='' onChange={e => {
        const action = actions.find(a => a.name === e.target.value)
        if (action) {
          action.fn();
          e.target.value = '';
        }
      }}>
      <option value=''></option>
      {actions.map(action => <option>{action.name}</option>)}
    </select>

  </header>;
}