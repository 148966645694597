import styled, { keyframes } from 'styled-components';
import { AdvancedCard } from '../AdvancedCard/AdvancedCard';
import { Heading } from '../Heading/Heading';
import { ChapterIcon } from '../SvgIcon/SvgIcon';
import { IStyledProps } from '../../theme';


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const StyledMyLearningsContainer = styled.div`
  animation: ${fadeIn} 2s 1;
`;

export const StyledMyLearningsHeader = styled.header `
  background: ${(props: IStyledProps) => props.theme.colors.greys.a};
  color: ${(props: IStyledProps) => props.theme.colors.white};
  font-size: 20px;
  text-align: center;
  padding: 15px 0;
`;

export const StyledMyLearningsIntro = styled.p `
  padding: 10px 50px;
  margin-top: 32px;
  margin-bottom: 0;
  text-align: left;
`;

export const StyledMyLearningsH1 = styled.h1`
  font-size: 20px;
  text-align: center;
`;

export const StyledMyLearningsAdvancedCard = styled(AdvancedCard) `
  width: 90%;
  margin: 1.5em 100%;
  box-shadow: none;
  padding: 10px 0 0 0; 

  & ul {
    padding-inline-start: 20px;
    line-height: 1.25rem;
    font-size: 16px;
  }
`;

export const StyledMyLearningsChapterIcon = styled(ChapterIcon)`
  width: 20%;
  height: 100%;
`;

export const StyledMyLearningsCardContainer = styled.div `
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const StyledMyLearningsHeading = styled(Heading).attrs({ level: 'h4', primary: true }) `
  margin-top: 10px;
  font-size: 37px;
`;

export const StyledMyLearningsChapterNumber = styled.p `
  margin-top: 5px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 12px;
`;

export const StyledMyLearningsFeedbackTitle = styled(Heading).attrs({ level: 'h4', primary: true }) `
  font-size: 30px;
  font-weight: 400;
`;

export const StyledMyLearningsList = styled.ul `
  list-style: none;
  padding: 0;
  text-align: center;
`

export const StyledMyLearningsListItem = styled.li `
  margin-bottom: 4px;
`;

export const StyledMyLearningsListItemName = styled.span``;

export const StyledMyLearningsFeedbackCount = styled.span `
  background-color: ${(props: IStyledProps) => props.theme.colors.accent.count};
  padding: 0 9px;
  border-radius: 12px;
  color: ${(props: IStyledProps) => props.theme.colors.white};
  margin-left: 9px;
`;
