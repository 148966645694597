import { keyframes } from 'styled-components';

//Mud
export const getSplatKeyFrames = () => {
  return keyframes`
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  `;
}
export const getSlideUpAndRotateKeyFrames = (startPosition: string, endPosition: string, degree: string) => {
  return keyframes`
    0% {
      top: ${startPosition};
      opacity: 0;
      transform: rotate(0deg) translateX(-50%);
    }
    100% {
      top: ${endPosition};
      opacity: 1;
      transform: rotate(${degree}) translateX(-50%);
    }
  `;
}

export const getSlideUpKeyFrames = () => {
  return keyframes`
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateY(0);
    }
  `;
}

export const getFadeOutKeyFrames = () => {
  return keyframes`
    0% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0
    }
  `;
}

export const getFadingKeyFrames = (from: number, to: number) => {
  return keyframes`
    0% {
      opacity: ${from};
    }
    80% {
      opacity: ${to};
    }
    100% {
      opacity: ${to}
    }
  `;
}


export const getFadeInKeyFrames = () => {
  return keyframes`
    0% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  `;
}

export const getScaleFrames = (from: number, to: number) => {
  return keyframes`
    from {
      transform: scale(${from})
    }
    to {
      transform: scale(${to})
    }
  `
}

//Breathing exercise text
export const getSlideUpAndFadeIn = (from: string) => {
  return keyframes`
    from {
      opacity: 0;
      transform: translateY(${from});
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;
}

//Breathing exercise text
export const getSlideUpAndFadeOut = (to: string) => {
  return keyframes`
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(${to});
    }
  `;
}

export const getPopKeyFrames = () => {
  return keyframes`
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
    }
    20% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(0.15);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  `;
}
export const getPopLeaveKeyFrames = () => {
  return keyframes`
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    80% {
      transform: translate(-50%, -50%) scale(0.15);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
    }
  `;
}

export const getFlyLeaveKeyFrames = () => {
  return keyframes`
    0% {
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      transform: translate(-50%, calc(-50% + 100vh)) scale(1);
    }
  `;
}

export const getTimerKeyFrames = () => {
  return keyframes`
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  `;
}

export const getProcessRingFrames = (ringRadius?: number) => {
  return keyframes`
    0% {
      stroke-dashoffset: ${ringRadius};
    }
    100% {
      stroke-dashoffset: 0;
    }
  `;
}

export const getSlowFadeInFrames = () => {
  return keyframes`
    0% {
      opacity: 0;
    }
    80% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  `;
}

export const getFlashKeyFrames = () => {
  return keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;
}

export const getFingerSwipeFrames = () => {
  return keyframes`
    0% {
      opacity: 0;
      left: 20%;
      top: 20%;
      transform: rotate(-15deg);
    }
    10% {
      opacity: 1;
      left: 20%;
      top: 20%;
      transform: rotate(-15deg);
    }
    30% {
      left: 70%;
      top: 30%;
      transform: rotate(15deg);
    }
    60% {
      left: 30%;
      top: 40%;
      transform: rotate(-15deg);
    }
    90% {
      left: 80%;
      top: 50%;
      transform: rotate(25deg);
      opacity: 1;
    }
    100% {
      left: 80%;
      top: 50%;
      transform: rotate(25deg);
      opacity: 0;
    }
  `;
}

export const getPopUpFrames = () => {
  return keyframes`
    0% {
      opacity: 0;
      transform: scale(0);
    }
    33% {
      opacity: 1;
      transform: scale(1.1);
    }
    66% {
      opacity: 1;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  `;
}
