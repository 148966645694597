import styled from 'styled-components';
import { IStyledProps } from '../../../theme';
import { Button } from '../../Button/Button';

interface ITeamCodeInputProps extends IStyledProps {
  showTip?: boolean;
}

export const StyledTipHeading = styled.div`
  font-weight: bold;
`;

export const StyledTipBody = styled.div`
  opacity: 0.8;
  margin-top: 10px;
  font-weight: 300;
`;

export const StyledTipButton = styled(Button)`
  width: 35%;
  border: 1px solid white;
  font-size: 1.2em;
  color: white;
  line-height: 0.8;
  margin-top: 20px;
  background: ${(props: ITeamCodeInputProps) => props.theme.colors.greys.a};
`;

export const StyledTip = styled.div`
  position: fixed;
  width: 100%;
  height: 205px;
  top: 0;
  left: 0;
  background: ${(props: ITeamCodeInputProps) => props.theme.colors.greys.a};
  padding: 0 20px;
  color: white;
  font-family: 'Overpass';
  display: flex;
  flex-direction: column;
  justify-content: center;

  :after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    bottom: -20px;
    right: 20px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${(props: ITeamCodeInputProps) => props.theme.colors.greys.a};
    border-bottom: 10px solid transparent;
    
  } 
`

export const StyledTeamCodeContainer = styled.div`
  position: relative;
  padding-top: 1.5em;
  > svg {
    position: absolute;
    top: 0;
    right: 0;
    opacity: ${(props: ITeamCodeInputProps) => props.showTip ? 1 : 0.6};
  }
`;