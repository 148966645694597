/* eslint-disable @typescript-eslint/no-shadow */
import styled, { css } from 'styled-components';
import { IStyledProps } from '../../theme';

interface IStyledStripButtonProps extends IStyledProps {
  isDisabled?: boolean
  isActive?: boolean
}

const getActiveStyles = (props: IStyledStripButtonProps) => {
  return css`
    border: none;
    background-color: ${props.theme.colors.primary.a};
    color: ${props.theme.colors.white};
    svg {
      path {
        fill: ${props.theme.colors.white};
      }
    }

  :after {
    border-top-color: ${(props: IStyledStripButtonProps) => props.theme.colors.white};
    border-right-color: ${(props: IStyledStripButtonProps) => props.theme.colors.white};
  }
  `;
}

export const StyledStripButton = styled.button`
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
  padding: 12px;
  padding-right: 32px;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  border: none;
  outline: none;

  ${(props: IStyledStripButtonProps) => {
    if (props.isActive) {
      return getActiveStyles(props);
    } else {
      return css`
        background-color: ${props.theme.colors.white};
        color: ${props.theme.colors.primary.a};
        svg {
          path {
            fill: ${props.theme.colors.primary.a};
          }
        }

        ${props.isDisabled ? css`
          opacity: 0.5;
          cursor: default;
        ` : css`
          opacity: 1;
          cursor: pointer;

          &:active {
            ${(props: IStyledStripButtonProps) => getActiveStyles(props)};
          }
        `}
      `;
    }
  }};

  :after {
    content: '';
    position: absolute;
    display: block;
    right: 12px;
    top:50%;
    transform:translate(-50%, -50%) rotate(45deg);
    width: 6px;
    height: 6px;
    border-top: 3px solid ${(props: IStyledStripButtonProps) => props.theme.colors.greys.d};
    border-right: 3px solid ${(props: IStyledStripButtonProps) => props.theme.colors.greys.d};
  }
  &:focus {
    border: none;
    outline: none;
  }
`;