import styled from 'styled-components';
import { Button } from '../../Button/Button';
import { Heading } from 'components/Heading/Heading';

export const StyledTitle = styled.h2`
  font-family: ${props => props.theme.fonts.heading};
  font-weight: 400;
  font-size: 40px;
  line-height: 36px;
  text-align: center;
  padding: 0px 30px;
  color: ${props => props.theme.colors.black};
  margin: 0px;
  text-transform: uppercase;
`;

export const StyledParagraphContainer = styled.div`
  width: 100%;
  padding: 0px 18px;
`;

export const StyledParagraph = styled.p`
  font-size: 17px;
  opacity: 0.85;
  text-align: center;

  &:first-of-type {
    margin-top: 30px;
  }
`;

export const StyledButton = styled(Button)`
  position: relative;
  width: 100%;
  margin-top: 5rem;
`;

export const StyledContainer = styled.div`
  max-height: 100vh;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 60%;
  padding-top: 30%;
`;

export const StyledFinishedOverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100vh;
  overflow: hidden;
  flex: 1;
`;


export const StyledFinishedOverlayInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  justify-content: center;
  flex-grow:1;
`;


export const StyledFinishedOverlayHeader = styled(Heading).attrs({ level: 'h3', primary: true })`
  padding: 0px 16px;
`;


export const StyledFinishedOverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-height: 100vh;
  padding: 30px 16px;
  overflow: hidden;
`;

export const StyledFinishedOverlayButton = styled(Button)`
width: 100%;
`;
