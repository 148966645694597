import styled, { css } from 'styled-components';
import Native from '../../services/Native';
import { IStyledProps } from '../../theme';
import { Button } from '../Button/Button';
import { PAGE_PADDING_PX } from '../Page/Page.styled';

interface IStyledFormProps extends IStyledProps {
  isLoading?: boolean
}

export const StyledForm = styled.form`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: ${PAGE_PADDING_PX}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${Native.getPhoneTopInsetPx()}px;
  
  > * {
    width: 100%;
  }

  ${(props: IStyledFormProps) => props.isLoading && css`
    > * {
      opacity: 0.5;
      pointer-events: none;
    }
  `}
`;

export const StyledHeadingContainer = styled.div`
  margin: 15px 0 30px;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    width: 100%;
  }
`;

export const StyledDescContainer = styled.div`
  margin-bottom: 35px;
  > div:not(:last-child) {
    margin-bottom: 3px;
  }
`;

interface ITextProps extends IStyledProps {
  primary?: boolean //undefined, true = 1em, false = 0.75em. 
}

export const StyledFormText = styled.div`
  color: ${(props: ITextProps) => props.theme.colors.greys.a};
  font-size: ${(props: ITextProps) => props.primary === false ? '0.75em' : '1em'}
`;

export const StyledFormTerms = styled(StyledFormText).attrs({ primary: false })`
  opacity: 0.7;
  margin-bottom: 1.5em;
  a {
    text-decoration: underline;
    color: inherit;
  }
`;

interface IButtonProps extends IStyledProps {
  primary?: boolean
  hollow?: boolean
}

export const StyledFormButton = styled(Button).attrs((props: IButtonProps) => ({ primary: props.primary === undefined ? true : props.primary }))`
  margin: 0 0 20px;
  padding: 15px;

  ${(props: IButtonProps) => {
    if (props.primary) {
      return css`
        background: ${props.theme.colors.black};
      `;
    }
  }}
`;