import { StyledStrategyExercisesButton } from 'components/StrategyExercises/StrategyExercises.styled';
import { IStyledProps } from '../../theme';
import { Button } from '../Button/Button';
import { Heading } from '../Heading/Heading';
import { SvgIcon } from '../SvgIcon/SvgIcon';

import styled from 'styled-components';

export const StyledSummaryContainer = styled.div`
  background: ${(props: IStyledProps) => props.theme.colors.black};
  color: ${(props: IStyledProps) => props.theme.colors.white};
  display: flex;
  align-content: start;
  flex-direction: column;
`;


export const StyledNavWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  padding-right: 20px;
`;

export const StyledSummaryHeaderButton = styled(Button)`
  background: ${(props: IStyledProps) => props.theme.colors.greys.d};
  color: ${(props: IStyledProps) => props.theme.colors.white};
  margin-top: 15.5px;
  margin-bottom: 2rem;
  width: 125px;
  padding: 8px;

  span {
    letter-spacing: 0em;
    font-size: 14px;
  }
`;

export const StyledSummaryHeader = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  padding: 20px 20px;
`;

export const StyledSummaryTitle = styled(Heading).attrs({ primary: true, level: 'h1' })`
  text-align: left;
  font-size: 90px;
  line-height: 0.7em;
  width: 90%;

  @media screen and (min-width: 375px) {
    font-size: 100px;
  }

  @media screen and (min-width: 400px) {
    font-size: 120px;
  }
`;

export const StyledSummarySubTitle = styled(Heading).attrs({ primary: true, level: 'h4' })`
  opacity: 0.6;
  letter-spacing: 1px;
  margin-top: 20px;
  text-align: left;
  font-size: 32px;
`;

export const StyledSummaryHeaderIcon = styled(SvgIcon)`
  height: 55px;
  width: 55px;
  margin-bottom: 24px;

  path, rect {
    fill: ${(props: IStyledProps) => props.theme.colors.white};
  }
`;

export const StyledSummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  padding: 20px 20px;
  
  svg {
    margin-bottom: 20px;
  }
`;

export const StyledSummaryContentHeadingText = styled(Heading).attrs({ primary: true, level: 'h5' })`
  text-align: left;
  font-size: 40px;
`;

export const StyledSummaryContentText = styled.div`
  opacity: 0.8;
  font-family: Overpass;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 3rem;
  width: 90%;

  @media screen and (min-width: 375px) {
    width: 80%;
  }

  ul {
    padding-left: 28px;

    li {
      padding-left: 6px;
      margin-bottom: 10px;
    }
  }
`;

export const StyledSummaryContentTextList = styled(StyledSummaryContentText)`
  &&& {
    width: 90%;
    margin-top: -1rem;
    margin-bottom: 0rem;
  }
`;

export const StyledSummaryContentImage = styled.img`
  margin: 0px auto;
  max-width: 100%;
  margin-bottom: 3rem;
`;

export const StyledIconContentContainer = styled.div`
  display: block;
  position: relative;
  white-space: nowrap;
  margin-bottom: 32px;
  margin-top: 3rem;
`;
export const StyledIconContent = styled.div`
  position: relative;
  height: 100%;
  vertical-align: top;
  width: calc(100% - 38px);

`;
export const StyledIcon = styled.img`
  display: inline-block;
  position: relative;
  width: 65px;
  vertical-align: top;
  margin-bottom: 10px;
`;

export const StyledIconTitle = styled(Heading).attrs({ primary: true, level: 'h4' })`  
  text-align: left;
`;

export const StyledIconQuote = styled(Heading).attrs({ level: 'h5' })`
  text-align: left;
  font-style: italic;
  white-space: normal;
  font-size: 20px;
  margin-top: 5px;

  @media screen and (min-width: 400px) {
    width: 80%;
  }
`;

export const StyledButton = styled(StyledStrategyExercisesButton)`
  margin-top: 64px;
`;
