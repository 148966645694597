import styled, { css } from 'styled-components';
import { CHAPTER_TRANSITION } from '../../constants';
import { IStyledProps } from '../../theme';
import { getFadeInKeyFrames } from '../../utils/animations.styled';

const CARD_SPACING_PX = 10;

export const StyledCardList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -${CARD_SPACING_PX}px;
`;

interface IStyledCardContainerProps extends IStyledProps {
  isFullWidth: boolean;
  fadeIn: boolean;
  index: number;
}

export const StyledCardContainer = styled.div`
  padding: ${CARD_SPACING_PX}px;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;

  &:nth-child(2n) {
    justify-content: flex-start;
  }

  ${(props: IStyledCardContainerProps) => props.isFullWidth ? css`
    width: 100%;
    justify-content: center;
  ` : ''}

  ${(props: IStyledCardContainerProps) => props.fadeIn && css`
    opacity: 0;
    &:nth-child(${props.index + 1}) {
      animation: ${getFadeInKeyFrames()} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out ${CHAPTER_TRANSITION.delayTime + props.index * CHAPTER_TRANSITION.fadingTime / 2}s;
    }
  `}
`;