import styled, { css } from 'styled-components';
import { CHAPTER_TRANSITION } from '../../constants';
import { IStyledProps } from '../../theme';
import { getFadeInKeyFrames, getFadingKeyFrames } from '../../utils/animations.styled';
import { QuestionListItem } from './QuestionListItem/QuestionListItem';

export const StyledQuestionList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  flex: 1;
`;

export const StyledQuestionsContainer = styled.div`
  > * {
    margin-bottom: 10px;
  }
`;

interface IAnswerButtonProps extends IStyledProps {
  isInitialised: boolean;
}

export const StyledAnswerButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  opacity: 0;
  animation: ${getFadeInKeyFrames()} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out ${CHAPTER_TRANSITION.delayTime}s;
  ${(props: IAnswerButtonProps) => props.isInitialised && css`
    opacity: 1;
    animation: ${getFadingKeyFrames(1, 0.5)} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out, ${getFadingKeyFrames(0.5, 1)} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out ${CHAPTER_TRANSITION.delayTime}s;
  `}
`;

const StyledAnswerButton = styled.div`
  cursor: pointer;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > svg {
    display: block;
    margin: auto;
  }
`;

export const StyledAnswerButtonTick = styled(StyledAnswerButton)`
  background-color: ${(props: IStyledProps) => props.theme.colors.black};

  > svg {
    height: 32px;
    width: 32px;
  }
`;

export const StyledAnswerButtonCross = styled(StyledAnswerButton)`
  background-color: ${(props: IStyledProps) => props.theme.colors.black};

  svg {
    width: 26px;
    height: 26px;
  }
`;

export const StyledActiveQuestion = styled(QuestionListItem).attrs({ isActive: true })`
  opacity: 0;
  animation: ${getFadeInKeyFrames()} ${CHAPTER_TRANSITION.fadingTime}s forwards ease-in-out ${CHAPTER_TRANSITION.delayTime}s;
`;
