import styled, { css, keyframes } from 'styled-components';
import { Button } from '../Button/Button';
import { IStyledProps } from 'theme';

export const StyledStrategyExercisesButton = styled(Button)`
    & h6{
        font-family: ${(props: IStyledProps) => props.theme.fonts.heading} !important;
        font-weight: 400;
        font-size: 24px;
        letter-spacing: 2.5px !important;
    }
`;
