/* eslint-disable no-console */
import React, { useState } from 'react';
import { isValidEmail } from '../../../utils/validation';
import { useTranslation } from 'react-i18next';
import { FormFunctionProps, withForm } from '../Form';
import { TextField } from '../../TextField/TextField';
import { StyledFormButton, StyledHeadingContainer, StyledDescContainer, StyledFormText } from '../Form.styled';
import { FormLogo } from '../FormLogo/FormLogo';
import { Heading } from '../../Heading/Heading';
import { CONTACT_US_URL } from '../../../constants';
import { getQueryParam } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import i18n from '../../../i18n';

const ContactUsForm: React.FC<FormFunctionProps> = ({ getFormData, setIsLoading, setFormFieldValue, setFormFieldError, validateFormFields, clearFormErrors }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formData = getFormData();

  const [isSubmitted, setIsSubmitted] = useState(false);
  
  const onFormSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e && e.preventDefault();

    if (formData.isLoading || !validateFormFields(['newEmail', 'firstName', 'lastName', 'team'])) return;
    
    setIsLoading(true);

    // call Api to send emails to specific addresses, which could be found in infra repo
    fetch(CONTACT_US_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify({
        /** @todo - improve typings of form fields to use generics or set values as strings */
        email: formData.fields.newEmail.value as string, 
        firstName: formData.fields.firstName.value as string,
        lastName: formData.fields.lastName.value as string, 
        team: formData.fields.team.value as string,
      }),
    }).then((res: Response) => {
      if (res.status !== 200) {
        console.error('Contact us failed, response code: ', res.status);
      } else {
        console.log('Contact us succeed');
        setIsSubmitted(true);
      }
    }).catch(error => {
      console.error('Contact us failed, ', error);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const onBackToLoginClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate('/login');
  }

  const heading = getQueryParam('type') === 'password-reset'
    ? 'Reset password'
    : 'Forgot your email?';
  
  return <>
    <FormLogo/>
    {isSubmitted
      ? <>
        <StyledHeadingContainer>
        <Heading level="h4" primary text="We'll be in touch" />
        </StyledHeadingContainer>
        <StyledDescContainer>
          <StyledFormText>
            We’ll contact you soon with instructions on what to do next.
          </StyledFormText>
        </StyledDescContainer>
        <StyledFormButton type="submit" onClick={onBackToLoginClick} label="Back to log in" hollow={false} />
      </>
      : <>
        <StyledHeadingContainer>
        <Heading level="h4" primary text={heading} />
        </StyledHeadingContainer>
        <StyledDescContainer>
          <StyledFormText>
            Provide a new email address so we can contact you with instructions to access your account.
          </StyledFormText>
          <StyledFormText primary={true}>
            (This could be a friend or parent’s email address)
          </StyledFormText>
        </StyledDescContainer>

        <TextField
          placeholder={t('form.newEmail.placeholder')}
          errorMessage={formData.fields.newEmail.error}
          onChange={(newValue) => setFormFieldValue('newEmail', newValue)}
        />
        <TextField
          placeholder={t('form.firstName.placeholder')}
          errorMessage={formData.fields.firstName.error}
          onChange={(newValue) => setFormFieldValue('firstName', newValue)}
        />
        <TextField
          placeholder={t('form.lastName.placeholder')}
          errorMessage={formData.fields.lastName.error}
          onChange={(newValue) => setFormFieldValue('lastName', newValue)}
        />
        <TextField
          placeholder={t('form.team.placeholder')}
          errorMessage={formData.fields.team.error}
          onChange={(newValue) => setFormFieldValue('team', newValue)}
        />
        <StyledFormButton type="submit" onClick={onFormSubmit} label="Next" />
      </>  
    }
  </>
};

export default withForm(ContactUsForm, {
  fields: {
    newEmail: {
      value: '',
      validate: (value: string) => isValidEmail(value) ? '' : i18n.t('form.newEmail.invalidError'),
    },
    firstName: {
      value: '',
      validate: (value: string) => value ? '' : i18n.t('form.firstName.invalidError'),
    },
    lastName: {
      value: '',
      validate: (value: string) => value ? '' : i18n.t('form.lastName.invalidError'),
    },
    team: {
      value: '',
      validate: (value: string) => value ? '' : i18n.t('form.team.invalidError'),
    },
  },
})