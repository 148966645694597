/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { TouchEventHandler, useEffect, useRef, useState } from 'react';
import { bindViewportListeners, getQueryParam } from '../../utils/helper';
import PlayerData from '../../services/PlayerData';
import { useTransition } from '../Transition/TransitionContext/TransitionContext';
import { IChapter } from '../../models/IChapter';
import Content from '../../services/Content';
import {
  StyledPlayerDashboard,
  StyledStrategyCarousel,
  StyledCarouselNav,
  StyledCarouselNavButton,
  LoadingOverlay,
} from './PlayerDashboard.styled';
import { isNotificationChoiceUnset, NotificationChoiceOverlay, NOTIFICATION_FADE_OUT_DURATION } from '../NotificationChoiceOverlay/NotificationChoiceOverlay';
import { FINAL_QUIZ_URL, START_QUIZ_URL, CHAPTER_IDENTIFIER } from '../../constants';
import { addIFrame } from '../../utils/dom';
import Auth from '../../services/Auth';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import Native from '../../services/Native';
import { WelcomeScreen } from '../WelcomeScreen/WelcomeScreen';
import { useNavigate } from 'react-router-dom';
import { StrategySlides } from './StrategySlides/StrategySlides';
import { QuizConsentPopup } from '../QuizConsentPopup/QuizConsentPopup';

export const PLAYER_DASHBOARD_STATES = {
  signupEntry: 'signupEntry',
  notificationOverlay: 'notificationOverlay',
  entryQuiz: 'entryQuiz',
  welcomeScreen: 'welcomeScreen',
  default: 'default',
  finalQuiz: 'finalQuiz',
  loading: 'loading',
  quizConsentPopup: 'quizConsentPopup',
} as const;
let DelayedChapterIndex: number;
let DelayedChapterIndexTimeout: string | number | NodeJS.Timeout | null | undefined = null;
let DashboardTransitionTimeout: string | number | NodeJS.Timeout | null | undefined = null;
export const PlayerDashboard: React.FC = () => {
  const navigate = useNavigate();

  const { transition, setTransition, setTransitionPaused, transitionStep } = useTransition();
  const transitionStepIdentifier = transitionStep && transitionStep.identifier;
  const allChapters = Content.getAllChapters();

  const [playerState, setPlayerState] = useState<keyof typeof PLAYER_DASHBOARD_STATES>(PLAYER_DASHBOARD_STATES.loading);
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
  const [hasShownWelcomeScreen, setHasShownWelcomeScreen] = useState(false);
  const [quizIFrameEle, setQuizIFrameEle] = useState<HTMLElement>();

  const focusScrollLockRef = useRef(false);

  // let DelayedChapterIndex: number;
  // const [DelayedChapterIndex, setDelayedChapterIndex] = useState(0);
  // let DelayedChapterIndexTimeout: string | number | NodeJS.Timeout | null | undefined = null;
  // const [DelayedChapterIndexTimeout, setDelayedChapterIndexTimeout] = useState<NodeJS.Timeout | number | null>(null);
  // let DashboardTransitionTimeout: string | number | NodeJS.Timeout | null | undefined = null;
  // const [DashboardTransitionTimeout, setDashboardTransitionTimeout] = useState<NodeJS.Timeout | number | null>(null);

  const [initialSlideIndex, setInitialSlideIndex] = useState<number>(allChapters.findIndex((chapter) => chapter.chapterIdentifier === getQueryParam('cid')));

  // const chapterStatus = allChapters.reduce((res: Partial<IChapter>, chapter: IChapter) =>
  //   Object.assign(res, {
  //     [chapter.chapterIdentifier]: {
  //     hasStarted: PlayerData.hasStartedChapter(chapter.chapterIdentifier),
  //       hasCompleted: PlayerData.hasCompletedChapter(chapter.chapterIdentifier),
  //     },
  //   }), {});

  const dashboardRef: React.RefObject<HTMLDivElement> = useRef(null);
  const strategyCarouselRef: React.RefObject<HTMLDivElement> = useRef(null);

  /**
   * Triggered when user clicks on the square chapter icons in the slider
   */
  const handleOnClick = (isUnlocked: boolean, chapter: IChapter, index: number) => {
    const isFocused = index === currentChapterIndex;

    if (isFocused && isUnlocked) {
      navigate(`/chapter/${chapter.chapterIdentifier}/summary`);
    } else {
      scrollToChapterIndex(index);
    }
  };

  const renderNavButtons = () => {
    const buttonsNum = allChapters.length + (PlayerData.hasCompletedAllChapters() ? 1 : 0);
    const arr = Array<number>(buttonsNum).map((_, i) => i);
    return [...arr].map((_, index) => (
      <StyledCarouselNavButton
        key={index}
        isActive={currentChapterIndex === index}
        onClick={() => scrollToChapterIndex(index)}
      />
    ));
  };

  const onDashboardMove = (event?: any) => {
    if (focusScrollLockRef.current) return;
    if (!(dashboardRef.current && strategyCarouselRef.current)) return;

    // The width of a single dashboard tile
    const dashboardSlideWidth = dashboardRef.current.clientWidth;

    // get current scroll left position of the carousel
    const { scrollLeft } = strategyCarouselRef.current;

    //Now we have to calculate WHICH chapter is "active" based on our new scroll position.
    //Find the new index based on the scrollLeft divided by  dashboard slide width
    const newIndex = Math.round(scrollLeft / dashboardSlideWidth);
    if (newIndex !== DelayedChapterIndex) {
      DelayedChapterIndex = newIndex;
      // setDelayedChapterIndex(newIndex);

      const lastChapterIndexNumberBeforeFinale = 4
      const finaleChapterIndex = 5
      // If navigating to finale screen, make strategy slide disappear faster
      // also appear faster if navigating back to strategy slides
      const delayInMs = newIndex > lastChapterIndexNumberBeforeFinale ||
        currentChapterIndex === finaleChapterIndex ?
        0 : 500

      //We slightly delay the state update so it's not firing constantly on a broad scroll
      if (DelayedChapterIndexTimeout) clearTimeout(DelayedChapterIndexTimeout);
      DelayedChapterIndexTimeout = setTimeout(() => setCurrentChapterIndex(newIndex), delayInMs);
      // setDelayedChapterIndexTimeout(setTimeout(() => setCurrentChapterIndex(newIndex), 500));
    }
  };

  const scrollToChapterIndex = (chapterIndex: number, noAnimation = false) => {
    if (dashboardRef.current && strategyCarouselRef.current) {
      const scrollLeft = chapterIndex * dashboardRef.current.clientWidth;

      focusScrollLockRef.current = true;
      if (noAnimation) {
        strategyCarouselRef.current.scrollLeft = scrollLeft;
      } else {
        // smooth scrolling not working :S.
        // eslint-disable-next-line
        (async () => {
          if (!strategyCarouselRef.current) return;
          // disable the snap type, to enable smooth scrolling
          strategyCarouselRef.current.style.scrollSnapType = 'none';

          const duration = 600;
          const startTime = Date.now();

          const startLeft = strategyCarouselRef.current.scrollLeft;

          let deltaTime = 0;

          while (deltaTime < duration) {
            const scalar = deltaTime / duration;
            // smoothing function is just quad in
            const easedScalar = Math.pow(scalar, 2);

            const deltaScrollLeft = startLeft + (scrollLeft - startLeft) * easedScalar;

            strategyCarouselRef.current.scrollLeft = deltaScrollLeft;

            deltaTime = Date.now() - startTime;
            await new Promise((resolve) => requestAnimationFrame(resolve));
          }
          // reenable the snap type, to enable smooth scrolling
          strategyCarouselRef.current.style.scrollSnapType = '';

          strategyCarouselRef.current.scrollLeft = scrollLeft;
        })();
      }
      // update the nav dots to match the chapter index
      // @todo - fix this!
      setTimeout(() => {
        setCurrentChapterIndex(chapterIndex);

        focusScrollLockRef.current = false;
        onDashboardMove();
      }, 800);
    }
  };

  const checkPlayerState = () => {
    const lastChapter = CHAPTER_IDENTIFIER.KeepingYourCool;
    // work out what state we should be in. 
    const hasStartedAnEpisode = allChapters.find((iChapter: IChapter) => PlayerData.hasStartedChapter(iChapter.chapterIdentifier));
    const hasNotSetNotificationOptionYet = isNotificationChoiceUnset();
    const hasQuizConsent = PlayerData.getQuizConsent();

    if (hasNotSetNotificationOptionYet) {
      // Check if notification has not set, if true, show notification overlay
      setTransitionPaused(true);

      setPlayerState(PLAYER_DASHBOARD_STATES.notificationOverlay);

    } else if (hasQuizConsent === undefined && !hasStartedAnEpisode) {
      // check if QuizConsent has not set, and not start an epsidoe. if true, show quiz consent popup
      setTransitionPaused(true);

      // preload the quiz
      if (!PlayerData.hasCompletedEntryQuiz() && !hasStartedAnEpisode) {
        showEntryQuiz(false);
      }

      setPlayerState(PLAYER_DASHBOARD_STATES.quizConsentPopup);
    } else if (!PlayerData.hasCompletedEntryQuiz() && !hasStartedAnEpisode && hasQuizConsent) {
      //  check if not complete entry quiz, not start an epsidoe and QuizConsent is true. if true, show entry quiz
      setTransitionPaused(true);

      showEntryQuiz();

      setTimeout(() => {
        setPlayerState(PLAYER_DASHBOARD_STATES.entryQuiz);
      }, NOTIFICATION_FADE_OUT_DURATION / 2);
    } else if ((PlayerData.hasCompletedEntryQuiz() || !hasQuizConsent) && !PlayerData.hasStartedAtLeastOneChapter() && !hasShownWelcomeScreen) {
      // this is now checking if at least one chapter has been started rather than just if chapter 1 has as this causes a bug for cohorts with delivery dates < now
      // check if complete entry quiz, QuizConsent is false, not start an epsidoe and not show welcome screen. if true, show welcome screen
      setTransitionPaused(true);

      setPlayerState(PLAYER_DASHBOARD_STATES.welcomeScreen);
      setHasShownWelcomeScreen(true);

    } else if (PlayerData.hasCompletedChapter(lastChapter) && !PlayerData.hasCompletedFinalQuiz() && hasQuizConsent) {
      // check if completed all chapter, not complete final quiz and QuizConsent is true. if true, show final quiz
      setTransitionPaused(true);

      setPlayerState(PLAYER_DASHBOARD_STATES.finalQuiz);

      showFinalQuiz();
    } else if (!checkSignUpEntry()) {
      // check if we should send the final quiz notification
      setPlayerState(PLAYER_DASHBOARD_STATES.default);

      setTransitionPaused(false);
    }
  };

  const showEntryQuiz = (showQuiz = true) => {
    if (quizIFrameEle) {
      // we can just change the opacity of this one.

      if (showQuiz) {
        quizIFrameEle.style.setProperty('opacity', '1');
        quizIFrameEle.style.setProperty('pointer-events', 'all');
      }

      return;
    }
    const user = Auth.getUser();
    if (!user) return;

    const email = user.email ?? '';
    const accessCode = Auth.getTeamCode() ?? '';
    const country = PlayerData.getCountry() ?? '';
    const sport = PlayerData.getSport() ?? '';

    const quizUrl = new URL(START_QUIZ_URL);
    quizUrl.searchParams.set('Email', email);
    quizUrl.searchParams.set('AccessCode', accessCode);
    quizUrl.searchParams.set('Country', country);
    quizUrl.searchParams.set('Sport', sport);

    // show the overlay.
    const iFrame = addIFrame(document.body, quizUrl.toString(), 5000, () => {
      // set that we've completed the quiz
      PlayerData.setEntryQuizComplete(true);

      checkPlayerState();
    }, 'entryQuizIframe');

    setQuizIFrameEle(iFrame);

    if (!showQuiz) {
      iFrame.style.setProperty('opacity', '0');
      iFrame.style.setProperty('pointer-events', 'none');
    }
  };

  const showFinalQuiz = () => {
    const user = Auth.getUser();
    if (!user) return;

    const email = user.email ?? '';
    const sport = PlayerData.getSport() ?? '';

    const quizUrl = new URL(FINAL_QUIZ_URL);
    quizUrl.searchParams.set('Email', email);
    quizUrl.searchParams.set('Sport', sport);

    // show the overlay.
    addIFrame(document.body, quizUrl.toString(), 5000, () => {
      // set that we've completed the quiz
      PlayerData.setFinalQuizComplete(true);

      checkPlayerState();
    }, 'finalQuizIframe');
  };

  // returns true if it detects the user has not entered a chapter yet.
  const checkSignUpEntry = () => {
    // Check the user current states,
    const latestChapter = PlayerData.getLatestChapterForUser();

    if (latestChapter && latestChapter.ordinal === 1) {
      const chapterProgress = PlayerData.getChapterProgress(latestChapter.chapterIdentifier);

      if (chapterProgress === null) {
        // the user has not made any progress toward the first chapter, show the first chapter

        navigate(`/chapter/${latestChapter.chapterIdentifier}`);
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const newSlideIndex =
      initialSlideIndex === -1
        ? PlayerData.hasCompletedAllChapters()
          ? allChapters.length // show finale if all chapters have been completed
          : allChapters.indexOf(PlayerData.getLatestChapterForUser())
        : initialSlideIndex;

    setInitialSlideIndex(newSlideIndex);

    DelayedChapterIndexTimeout = initialSlideIndex;
    // setDelayedChapterIndexTimeout(initialSlideIndex);

    setCurrentChapterIndex(newSlideIndex);

    scrollToChapterIndex(newSlideIndex, true);

    checkPlayerState();

    bindViewportListeners(onDashboardMove, true);
  }, []);

  //Fires when the transitionStep changes (ie. immediately as the step occurs)
  useEffect(() => {
    const nextStep = transition ? transition.steps[transition.currentStepIndex + 1] : null;
    if (!transitionStep || !nextStep) return;

    if (nextStep && nextStep.identifier === 'dashboard-move') {
      // Pan over to next chapter after the tile being slammed
      DashboardTransitionTimeout = setTimeout(() => scrollToChapterIndex(currentChapterIndex + 1), 1000 * transitionStep.durationSeconds);
      // setDashboardTransitionTimeout(setTimeout(() => scrollToChapterIndex(currentChapterIndex + 1), 1000 * transitionStep.durationSeconds));
    } else if (nextStep && nextStep.identifier === 'dashboard-fadeout' && allChapters[currentChapterIndex]) {
      // After the play button flashing, fade out and jump to the next chapter
      DashboardTransitionTimeout = setTimeout(() => {
        navigate(`/chapter/${allChapters[currentChapterIndex].chapterIdentifier}`);
        // Wait for the fade out
      }, 1000 * (transitionStep.durationSeconds + nextStep.durationSeconds));

      // const newTimeout = setTimeout(() => {
      //   navigate(`/chapter/${allChapters[currentChapterIndex].chapterIdentifier}`);
      //   // Wait for the fade out
      // }, 1000 * (transitionStep.durationSeconds + nextStep.durationSeconds));
      // setDashboardTransitionTimeout(newTimeout);
    }
  }, [allChapters, currentChapterIndex, transition, transitionStep]);

  // if (playerState === PLAYER_DASHBOARD_STATES.loading) {
  //   return (
  //     <LoadingOverlay>
  //       <LoadingSpinner />
  //     </LoadingOverlay>
  //   )
  // }

  // if (playerState === PLAYER_DASHBOARD_STATES.welcomeScreen) {
  //   return <WelcomeScreen onComplete={checkPlayerState} />
  // }

  // if (playerState === PLAYER_DASHBOARD_STATES.notificationOverlay) {
  //   return <NotificationChoiceOverlay onComplete={checkPlayerState} />
  // }

  return (
    <StyledPlayerDashboard
      ref={dashboardRef}
    >
      {
        playerState !== PLAYER_DASHBOARD_STATES.welcomeScreen && <StyledStrategyCarousel data-find="stratcar" ref={strategyCarouselRef} onScroll={onDashboardMove}>
          <StrategySlides
            handleOnClick={handleOnClick}
            allChapters={allChapters}
            currentChapterIndex={currentChapterIndex}
          ></StrategySlides>
        </StyledStrategyCarousel>
      }
      <StyledCarouselNav>{renderNavButtons()}</StyledCarouselNav>
      {playerState === PLAYER_DASHBOARD_STATES.loading && (
        <LoadingSpinner />
      )}
      {playerState === PLAYER_DASHBOARD_STATES.welcomeScreen && <WelcomeScreen onComplete={checkPlayerState} />}
      {playerState === PLAYER_DASHBOARD_STATES.notificationOverlay && <NotificationChoiceOverlay onComplete={checkPlayerState} />}
      {playerState === PLAYER_DASHBOARD_STATES.quizConsentPopup && <QuizConsentPopup onComplete={checkPlayerState} />}
    </StyledPlayerDashboard>
  );
};
