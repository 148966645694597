import { IContentBase } from '../models/IContentBase';
import { STRATEGY_EXERCISE_TYPE, VIDEO_MOMENT_TYPE, STRENGTH_TYPE, CHAPTER_IDENTIFIER } from '../constants';

const content: IContentBase = {
  welcomeScreenVideoUrl: 'videos/welcomeScreen/rugby/background.mp4',
  finaleBackgroundUrl: 'images/dashboard/wow/ulduar.webp',
  videoOverlayBackgroundUrl: 'images/dashboard/rugby/overlay.webp',
  chapters: [
    {
      ordinal: 1,
      chapterIdentifier: CHAPTER_IDENTIFIER.ManagingYourThoughts,
      title: 'Staying calm in a raid',
      strategy: 'Select your thoughts',
      strategyMessage: 'Understand how your brain<br />can work against you.',
      dashboardImage: 'images/dashboard/wow/icecrown.webp',
      icon: 'images/icons/strategy/wow/gears',
      videoId: '9067882',
      isFacilitatorChapter: true,
      videoMoments: [
        {
          type: VIDEO_MOMENT_TYPE.PhonesDown,
          seconds: null,
          shouldPause: true,
          neverShowOnReplay: true,
        }, {
          type: VIDEO_MOMENT_TYPE.StickerSelector,
          feedback: "Sometimes a gaming session doesn't go as planned. It's tempting to blame other factors.",
          seconds: 10,
          duration: 6,
          showOnlyOnReplay: true,
          stickers: [{
            text: '💥 BAD MOVE',
          }, {
            text: 'MY KEYBOARD SUCKS',
          }, {
            text: 'WHY DID NO ONE HEAL ME?',
          }],
        }, {
          type: VIDEO_MOMENT_TYPE.StickerSelector,
          feedback: 'Our negative thoughts can be very drastic. Sometimes this means we focus too heavily on the bad, and make things worse.',
          seconds: 23,
          duration: 4,
          showOnlyOnReplay: true,
          stickers: [{
            text: 'SUCH A LOSER',
          }, {
            text: '😑 MUPPET!',
          }, {
            text: "I'LL NEVER LIVE THIS DOWN",
          }],
        }, {
          type: VIDEO_MOMENT_TYPE.StickerRemover,
          messageOnRemoved: "Don't get trapped by negative thoughts.",
          seconds: 35,
          duration: 10,
          showOnlyOnReplay: true,
        }, {
          seconds: null,
          type: VIDEO_MOMENT_TYPE.StrategyBanner,
          shouldPause: true,
          showOnlyOnReplay: true,
        }, {
          type: VIDEO_MOMENT_TYPE.StrategyExercise,
          seconds: null,
          shouldPause: true,
          exerciseType: STRATEGY_EXERCISE_TYPE.Choose,
          exercise: {
            heading: 'Is this a <b>positive</b> thought?',
            cards: [
              {
                questionText: "I've got this",
                isCorrect: true,
                hint: "This is both positive and confident. It's a good example of a positive thought.",
              },
              {
                questionText: "I'm good at this",
                isCorrect: true,
                hint: "This is optimistic and confident. It's a positive thought.",
              },
              {
                questionText: 'I’m a complete loser',
                isCorrect: false,
                hint: 'That doesn’t sound like a very positive or confident thought. How would it feel if someone said this to you?',
              },
              {
                questionText: 'I’ve done this before. I can do it again.',
                isCorrect: true,
                hint: "This is a reassuring and positive thought. It's a good example of backing yourself.",
              },
              {
                questionText: "I'm so stupid",
                isCorrect: false,
                hint: "Nope not a positive thought. You wouldn't say this to a mate who was feeling down on themselves.",
              },
              {
                questionText: 'I’ll be alright. No big deal',
                isCorrect: true,
                hint: "It's a good start in positive thinking.",
              },
              {
                questionText: 'Next time for sure',
                isCorrect: true,
                hint: "Telling yourself that you'll bounce back is a resilient and positive thought.",
              },
              {
                questionText: 'I’m such a muppet',
                isCorrect: false,
                hint: "Doesn't sound very positive. Positive self-talk is what you want to aim for.",
              },
              {
                questionText: 'I totally suck!',
                isCorrect: false,
                hint: "You wouldn't say it to a mate to help them out. It's not positive or confident.",
              },
              {
                questionText: "I'm doing ok",
                isCorrect: true,
                hint: "I'm doing ok is a good start in positive thinking.",
              },
            ],
          },
        }, {
          type: VIDEO_MOMENT_TYPE.StrategyExercise,
          seconds: null,
          shouldPause: true,
          exerciseType: STRATEGY_EXERCISE_TYPE.Choose,
          exercise: {
            heading: 'Is this an <b>instructional</b> thought?',
            cards: [
              {
                questionText: "I've got to put more effort in during dungeons",
                isCorrect: true,
                hint: "It's action based so it's an instructional thought.",
              },
              {
                questionText: 'I should maybe try NOT sucking',
                isCorrect: false,
                hint: "Instructional thoughts focus on things you SHOULD do rather than things you shouldn't do. Like Improving certain parts of your game.",
              },
              {
                questionText: 'I need to keep working on my target swapping',
                isCorrect: true,
                hint: "Nope. That’s an instructional thought. It's about taking action.",
              },
              {
                questionText: 'I suck',
                isCorrect: false,
                hint: 'That’s not a positive or instructional thought.',
              },
              {
                questionText: 'I need to stop letting so many people die',
                isCorrect: false,
                hint: "For instructional thoughts you want to focus on what you should do, rather than things you shouldn't be doing.",
              },
              {
                questionText: 'I should practice my rotation',
                isCorrect: true,
                hint: 'This is actually an instructional thought. It involves taking action and focusing on what you should do.',
              },
              {
                questionText: 'Time for a mountain dew break',
                isCorrect: false,
                hint: 'Good idea. But not very useful.',
              },
              {
                questionText: 'I need to improve my communication',
                isCorrect: true,
                hint: "This seems like a good option - it's an instructional thought.",
              },
              {
                questionText: "I've got a long way to go",
                isCorrect: false,
                hint: 'That’s not really instructional or very positive.',
              },
              {
                questionText: 'I need to practice working as a group more',
                isCorrect: true,
                hint: "This is a plan of action so it's an instructional thought.",
              },
            ],
          },
        }, {
          type: VIDEO_MOMENT_TYPE.StrategyExercise,
          seconds: null,
          shouldPause: true,
          exerciseType: STRATEGY_EXERCISE_TYPE.Choose,
          exercise: {
            heading: '<b>new cards</b> here (e.g. motivational/helpful/supportive)',
            cards: [
              {
                questionText: 'Q10 false',
                isCorrect: false,
                hint: 'Hint for Q10 here.',
              },
              {
                questionText: 'Q9 false',
                isCorrect: false,
                hint: 'Hint for Q9 here.',
              },
              {
                questionText: 'Q8 true',
                isCorrect: true,
                hint: 'Hint for Q8 here.',
              },
              {
                questionText: 'Q7 true',
                isCorrect: true,
                hint: 'Hint for Q7 here.',
              },
              {
                questionText: 'Q6 true',
                isCorrect: true,
                hint: 'Hint for Q6 here.',
              },
              {
                questionText: 'Q5 true',
                isCorrect: true,
                hint: 'Hint for Q5 here.',
              },
              {
                questionText: 'Q4 true',
                isCorrect: true,
                hint: 'Hint for Q4 here.',
              },
              {
                questionText: 'Q3 true',
                isCorrect: true,
                hint: 'Hint for Q3 here.',
              },
              {
                questionText: 'Q2 true',
                isCorrect: true,
                hint: 'Hint for Q2 here.',
              },
              {
                questionText: 'Q1 true',
                isCorrect: true,
                hint: 'Hint for Q1 here.',
              },
            ],
          },
        }, {
          type: VIDEO_MOMENT_TYPE.PhonesDown,
          seconds: null,
          shouldPause: true,
          neverShowOnReplay: true,
        },
        {
          seconds: null,
          type: VIDEO_MOMENT_TYPE.SummaryPage,
          shouldPause: true,
        },
      ],
      achievementScreen: {
        title: 'CONGRATULATIONS',
        description: 'Chapter one is complete. <br /><br /> Great start {firstName}.',
      },
      myLearningsDescription: "Remember: Don't beat yourself up with negative thoughts. <br/><br/>Choose thoughts that are <b>positive</b>, <b>instructional</b> and <b>new card set aim here</b>.",
    },
    {
      ordinal: 2,
      chapterIdentifier: CHAPTER_IDENTIFIER.ProblemSolving,
      title: 'Avoiding anger',
      strategy: 'Time for wowhead',
      strategyMessage: 'Use simple steps to help<br />you problem solve tricky situations.',
      dashboardImage: 'images/dashboard/wow/tempestKeep.webp',
      icon: 'images/icons/strategy/wow/scroll',
      videoId: '628171',
      videoMoments: [{
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: "When things don't go our way, it's easy to react with anger.",
        seconds: 10,
        duration: 5,
        stickers: [{
          text: 'UNBELIEVABLE!',
        }, {
          text: "YOU'VE GOTTA BE KIDDING!",
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: "When things don't go our way, we might try and run away, or deny what's happening.",
        seconds: 25,
        duration: 5,
        stickers: [{
          text: 'DISASTER 💣!',
        }, {
          text: 'This sucks!',
        }, {
          text: "I've had it 😤",
        }, {
          text: '$^#&% IT ALL!',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerRemover,
        messageOnRemoved: "Don't jump to conclusions.",
        seconds: 40,
        duration: 5,
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyBanner,
        shouldPause: true,
      }, {
        seconds: null,
        shouldPause: true,
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        exerciseType: STRATEGY_EXERCISE_TYPE.Plan,
        exercise: {
          minPlansToChoose: 2,
          plans: [{
            text: 'Quit the guild',
            questions: [{
              text: 'Will this help him stay connected to his guild?',
              answeredText: "This won't help him stay connected to the guild.",
              isCorrect: false,
              feedback: 'Quitting the guild would likely make him lose social connection to his guild.',
            }, {
              text: 'Will this help him maintain his knowledge/skills?',
              answeredText: "This won't help him maintain his knowledge/skills.",
              isCorrect: false,
              feedback: 'Quitting the guild will likely cause him to lose his ability to play well during a raid.',
            }, {
              text: 'Will this reduce the risk of needing to repair his armour',
              answeredText: 'This will reduce the risk of armour repairs.',
              isCorrect: true,
              feedback: 'Quitting the guild will definitely reduce the risk of armour repairs.',
            }, {
              text: 'Will this help him prepare for heroic level raids?',
              answeredText: 'This won’t help him prepare for heroic raids.',
              isCorrect: false,
              feedback: "Quitting the guild means he’ll no longer have a active raiding party who knows what they're doing, so heroic level is eh.",
            }],
          }, {
            text: 'Map out a gradual return to raiding form',
            questions: [{
              text: 'Will this help him stay connected to his guild?',
              answeredText: 'This will help him stay connected to the guild.',
              isCorrect: true,
              feedback: 'A gradual return to raiding is a great way to ensure he maintains social connection.',
            }, {
              text: 'Will this help him maintain his knowledge/skills?',
              answeredText: 'This will help him maintain his knowledge/skills.',
              isCorrect: true,
              feedback: 'A gradual return to raiding is a great way to help him maintain his knowledge/skills as best he can.',
            }, {
              text: 'Will this reduce the risk of needing to repair his armour',
              answeredText: 'This will reduce the risk of armour repairs.',
              isCorrect: true,
              feedback: 'A gradual return will help him reduce the risk of wiping and thus armour repairs.',
            }, {
              text: 'Will this help him prepare for heroic level raids?',
              answeredText: 'This will help him prepare for heroic raids.',
              isCorrect: true,
              feedback: 'He might not be ready immediately, but a gradual return to raiding will ensure he is prepared as soon as possible.',
            }],
          }, {
            text: 'Help with planning the raid roster',
            questions: [{
              text: 'Will this help him stay connected to his guild?',
              answeredText: 'This will help him stay connected to the guild.',
              isCorrect: true,
              feedback: 'Helping to plan the roster would be a great way to stay connected with the guild.',
            }, {
              text: 'Will this help him maintain his knowledge/skills?',
              answeredText: "This won't help him maintain his knowledge/skills.",
              isCorrect: false,
              feedback: 'Helping to plan the roster will keep him involved, but is unlikely to maintain his knowledge/skills.',
            }, {
              text: 'Will this reduce the risk of needing to repair his armour?',
              answeredText: 'This will reduce the risk of armour repairs.',
              isCorrect: true,
              feedback: "Helping to plan the roster is a great way to keep out of harm's way, and reduce the risk of armour repairs",
            }, {
              text: 'Will this help him prepare for heroic level raids?',
              answeredText: 'This won’t help him prepare for heroic raids.',
              isCorrect: false,
              feedback: 'Helping to plan the roster will keep him involved, but won’t allow him to get practical experience.',
            }],
          }, {
            text: 'Keep raiding',
            questions: [{
              text: 'Will this help him stay connected to his guild?',
              answeredText: 'This will help him stay connected to the guild.',
              isCorrect: true,
              feedback: 'Continuing to raid is risky, but will definitely keep him connected to the guild.',
            }, {
              text: 'Will this help him maintain his knowledge/skills?',
              answeredText: 'This will help him maintain his knowledge/skills.',
              isCorrect: true,
              feedback: 'Continuing to play is risky, but would probably help him maintain his knowledge/skills.',
            }, {
              text: 'Will this reduce the risk of needing to repair his armour?',
              answeredText: "This won't reduce the risk of armour repairs.",
              isCorrect: false,
              feedback: 'Raiding without taking the time to properly understand mechanics will likely cause wipes and thus armour repairs.',
            }, {
              text: 'Will this help him prepare for heroic level raids?',
              answeredText: 'This won’t help him prepare for heroic raids.',
              isCorrect: false,
              feedback: 'Raiding without taking time to properly understand mechanics will likely cause wipes and enough of those will result in a potential guild kick.',
            }],
          }, {
            text: 'new mandatory plan here',
            isMandatory: true,
            questions: [{
              text: 'question 1 here (true)',
              answeredText: 'answer 1 here.',
              isCorrect: true,
              feedback: 'feedback here.',
            }, {
              text: 'question 2 here (true)',
              answeredText: 'answer 2 here.',
              isCorrect: true,
              feedback: 'feedback here.',
            }, {
              text: 'question 3 here (true)',
              answeredText: 'answer 3 here.',
              isCorrect: true,
              feedback: 'feedback here.',
            }, {
              text: 'question 4 here (true)',
              answeredText: 'answer 4 here.',
              isCorrect: true,
              feedback: 'feedback here.',
            }],
          }, {
            text: 'extra blank plan to show no limit cap',
            questions: [{
              text: 'question 1 here (true)',
              answeredText: 'answer 1 here.',
              isCorrect: true,
              feedback: 'feedback here.',
            }, {
              text: 'question 2 here (true)',
              answeredText: 'answer 2 here.',
              isCorrect: true,
              feedback: 'feedback here.',
            }, {
              text: 'question 3 here (false)',
              answeredText: 'answer 3 here.',
              isCorrect: false,
              feedback: 'feedback here.',
            }, {
              text: 'question 4 here (false)',
              answeredText: 'answer 4 here.',
              isCorrect: false,
              feedback: 'feedback here.',
            }],
          }],
        },
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.SummaryPage,
        shouldPause: true,
        resumeAt: 46,
      }],
      achievementScreen: {
        title: 'WELL DONE',
        description: 'You have now finished chapter 2. <br /><br /> Check out the next chapter.',
      },
      myLearningsDescription: 'Remember: Take your time to properly think about potential plans and work out which has the best approach and outcomes.',
    },
    {
      ordinal: 3,
      chapterIdentifier: CHAPTER_IDENTIFIER.PlayingToYourStrengths,
      title: 'Healing for friendship',
      strategy: 'Focus on your positives',
      strategyMessage: 'Determine your own<br />strengths and focus on<br />them.',
      dashboardImage: 'images/dashboard/wow/karazhan.webp',
      icon: 'images/icons/strategy/wow/team',
      videoId: '439784326',
      videoMoments: [{
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: "When things aren't going to plan, it's easy to focus on our weaknesses as the only way out.",
        seconds: 20,
        duration: 5,
        stickers: [{
          text: 'NOT IN THE MOOD',
        }, {
          text: 'WHY AM I HERE?',
        }, {
          text: 'BLURGH 😖',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: "It's easy to be preoccupied with trying to fix our weaknesses.",
        seconds: 40,
        duration: 5,
        stickers: [{
          text: 'LEAVE ME ALONE 😕',
        }, {
          text: "WHY CAN'T I DO THIS!?",
        }, {
          text: "I'M BETTER THAN THIS!",
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerRemover,
        messageOnRemoved: "Don't get caught up in your weaknesses.",
        seconds: 65,
        duration: 5,
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyBanner,
        shouldPause: true,
      }, {
        seconds: null,
        shouldPause: true,
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        exerciseType: STRATEGY_EXERCISE_TYPE.Strengths,
        exercise: {
          promptSets: [{
            emptyText: 'Explore new ways to improve this strength',
            prompts: [{
              type: 'strength',
              instruction: 'Think about <b>your</b> own strengths.<br/>Select one of your <b>on-field</b> strengths.',
              instructionCustom: 'Name your <b>on-field</b> strength',
              strengthCardsType: STRENGTH_TYPE.OnField,
              questions: [{
                text: 'Can you look for opportunities to <b>use</b> this strength more?',
                answeredText: 'I can look for opportunities to use this strength more',
              }, {
                text: 'Can you <b>improve</b> on this strength through playing?',
                answeredText: 'I can work on improving it',
              }, {
                text: 'Can you <b>help others</b> develop this strength?',
                answeredText: 'I can share it with others',
              }],
            },
            {
              type: 'strength',
              instruction: 'Select one of your <b>off-field</b> strengths.',
              instructionCustom: 'Name your <b>off-field</b> strength',
              strengthCardsType: STRENGTH_TYPE.OffField,
              questions: [{
                text: 'Can you look for opportunities to <b>use</b> this strength more?',
                answeredText: 'I can look for opportunities to use this strength more',
              }, {
                text: 'Can you <b>help others</b> with this strength?',
                answeredText: 'I can use it to help others',
              }, {
                text: 'Can you use this strength in your <b>game</b>?',
                answeredText: 'I can use it in my game',
              }],
            }],
          }, {
            emptyText: 'Explore new ways to improve this strength',
            prompts: [{
              type: 'strength',
              instruction: 'New extra on-field choice.',
              instructionCustom: 'Name your extra <b>on-field</b> strength',
              strengthCardsType: STRENGTH_TYPE.OnField,
              questions: [{
                text: 'text 1 here',
                answeredText: 'response 1 here',
              }, {
                text: 'text 2 here',
                answeredText: 'response 2 here',
              }, {
                text: 'text 3 here',
                answeredText: 'response 3 here',
              }],
            },
            {
              type: 'strength',
              instruction: 'New extra off-field choice.',
              instructionCustom: 'Name your extra <b>off-field</b> strength',
              strengthCardsType: STRENGTH_TYPE.OffField,
              questions: [{
                text: 'text 1 here',
                answeredText: 'response 1 here',
              }, {
                text: 'text 2 here',
                answeredText: 'response 2 here',
              }, {
                text: 'text 3 here',
                answeredText: 'response 3 here',
              }],
            }],
          }, {
            emptyText: 'Explore new ways to improve this strength',
            prompts: [{
              type: 'attribute',
              instruction: 'Select one of your <b>on-field</b> attributes.',
              instructionCustom: 'Name your <b>on-field</b> attribute',
              strengthCardsType: STRENGTH_TYPE.OnField,
              questions: [{
                text: 'Can you look for opportunities to <b>use</b> this attribute more?',
                answeredText: 'I can look for opportunities to use this attribute more',
              }, {
                text: 'Can you <b>improve</b> on this attribute through playing?',
                answeredText: 'I can work on improving it',
              }, {
                text: 'Can you <b>help others</b> develop this attribute?',
                answeredText: 'I can share it with others',
              }],
            },
            {
              type: 'attribute',
              instruction: 'Select one of your <b>off-field</b> attributes.',
              instructionCustom: 'Name your <b>off-field</b> attribute',
              strengthCardsType: STRENGTH_TYPE.OffField,
              questions: [{
                text: 'Can you look for opportunities to <b>use</b> this attribute more?',
                answeredText: 'I can look for opportunities to use this attribute more',
              }, {
                text: 'Can you <b>help others</b> with this attribute?',
                answeredText: 'I can use it to help others',
              }, {
                text: 'Can you use this attribute in your <b>game</b>?',
                answeredText: 'I can use it in my game',
              }],
            }],
          }],
        },
      }, {
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        seconds: null,
        shouldPause: true,
        neverShowOnReplay: true,
        exerciseType: STRATEGY_EXERCISE_TYPE.StrengthsTeammates,
        exercise: { },
      }, {
        type: VIDEO_MOMENT_TYPE.SummaryPage,
        seconds: null,
        shouldPause: true,
        resumeAt: 71,
      }],
      achievementScreen: {
        title: 'WAY TO GO',
        description: 'Over half-way there. <br /><br /> Chapter 4 coming up.',
      },
      myLearningsDescription: 'Think about your strengths ON and OFF the field and how to utilise them. <br/><ul><li>Use your strength more</li><li>Improve through training</li><li>Help others develop their strengths</li></ul>',
    },
    {
      ordinal: 4,
      chapterIdentifier: CHAPTER_IDENTIFIER.ControllingTheControllables,
      title: 'Control the team',
      strategy: 'Not everything is controll&shy;able',
      strategyMessage: 'Some things we can’t<br />control. Let’s focus on what<br />we can.',
      dashboardImage: 'images/dashboard/wow/sunwell.webp',
      icon: 'images/icons/strategy/wow/dice',
      videoId: '15482944',
      videoMoments: [{
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: "It's easy to get upset with forces beyond our control. Especially when they keep us from performing at our best.",
        seconds: 50,
        duration: 10,
        stickers: [{
          text: 'THIS IS DUMB',
        }, {
          text: '🤨 UNFAIR',
        }, {
          text: 'WHAT THE HELL!?',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'We might choose to shift the blame for our issues onto others instead of focussing on what is in our control.',
        seconds: 90,
        duration: 10,
        stickers: [{
          text: "DON'T BLAME ME 😡",
        }, {
          text: "I DON'T HAVE AN ATTITUDE! 🤬",
        }, {
          text: "THEY DON'T UNDERSTAND!",
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerRemover,
        messageOnRemoved: "Don't obsess about what you can't control.",
        seconds: 130,
        duration: 10,
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyBanner,
        shouldPause: true,
      }, {
        seconds: null,
        shouldPause: true,
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        exerciseType: STRATEGY_EXERCISE_TYPE.Control,
        exercise: {
          numberOfCardsToChoose: 5,
          controlCards: [{
            text: 'People needing to leave',
            icon: 'images/icons/control/wow/leave',
            isControllable: false,
            controlFeedback: 'People needing to leave is not within his control. No matter how much he tries, he can’t change their decision.',
            isImmediatelyControllable: false,
          }, {
            text: 'Enemy NPCs',
            icon: 'images/icons/control/wow/enemy',
            isControllable: false,
            controlFeedback: 'He has no control over his enemy NPCs.',
            isImmediatelyControllable: false,
          }, {
            text: 'The performance of the raid',
            icon: 'images/icons/control/rugby/teammates',
            isControllable: false,
            controlFeedback: 'The only player in his raid he has control over is himself.',
            isImmediatelyControllable: false,
          }, {
            text: 'Lag',
            icon: 'images/icons/control/wow/connection',
            isControllable: false,
            controlFeedback: 'That would be handy but no he has no control over lag.',
            isImmediatelyControllable: false,
          }, {
            text: 'His performance',
            icon: 'images/icons/control/rugby/performance',
            isControllable: false,
            controlFeedback: "He can't control his overall performance. But he might be able to control parts of it like his rotation.",
            isImmediatelyControllable: false,
          }, {
            text: 'His language',
            icon: 'images/icons/control/rugby/language',
            isControllable: true,
            controlFeedback: 'His language is within his control. He needs to focus on things he can control.',
            isImmediatelyControllable: false,
            immediateControlFeedback: 'He should definitely watch his language. But there is something else he should focus on right now.',
          }, {
            text: 'The raid leader',
            icon: 'images/icons/control/wow/leader',
            isControllable: false,
            controlFeedback: 'Nope. The raid leader is not something within his control.',
            isImmediatelyControllable: false,
          }, {
            text: 'His effort',
            icon: 'images/icons/control/rugby/effort',
            isControllable: true,
            controlFeedback: 'You got it. His effort is something he can control.',
            isImmediatelyControllable: false,
            immediateControlFeedback: 'He should definitely keep putting effort in. But there is something else he should focus on right now.',
          }, {
            text: 'His attitude',
            icon: 'images/icons/control/rugby/reactions',
            isControllable: true,
            controlFeedback: 'His attitude is within his control, even if it feels tough sometimes.',
            isImmediatelyControllable: false,
            immediateControlFeedback: 'His raid leader, and others, have made it clear that his attitude is the problem. But there is something else he should focus on right now.',
          }, {
            text: 'The raid',
            icon: 'images/icons/control/wow/raid',
            isControllable: false,
            controlFeedback: "That's out of his control.",
            isImmediatelyControllable: false,
          }, {
            text: 'His skill',
            icon: 'images/icons/control/wow/keyboard',
            isControllable: true,
            controlFeedback: 'His skill is something he can work on and have some control over.',
            isImmediatelyControllable: false,
            immediateControlFeedback: 'His skill is not something he can fix right away. Another option might be more important in this moment.',
          }, {
            text: 'The time',
            icon: 'images/icons/control/wow/time',
            isControllable: false,
            controlFeedback: "He can't control how fast time passes.",
            isImmediatelyControllable: false,
          }, {
            text: 'Immediately controllable option (true)',
            icon: 'images/icons/control/wow/loading',
            isControllable: true,
            controlFeedback: 'control feedback here.',
            isImmediatelyControllable: true,
            immediateControlFeedback: 'immediate control feedback here.',
          }],
        },
      }, {
        type: VIDEO_MOMENT_TYPE.SummaryPage,
        seconds: null,
        shouldPause: true,
        resumeAt: 141,
      }],
      achievementScreen: {
        title: 'YOU ACED IT',
        description: "Almost there! <br /><br /> You're up to the last chapter now.",
      },
      myLearningsDescription: "Put your energy into things you CAN control, not the things you can't. <br/><br/>Act on the FIRST THING you can control.",
    },
    {
      ordinal: 5,
      chapterIdentifier: CHAPTER_IDENTIFIER.KeepingYourCool,
      title: 'Breathe it out',
      strategy: 'There is no aggro reset',
      strategyMessage: '<b>Remember to focus on your breathing.</b> <br/><br/>Controlled breathing is a quick hack to calm our body in tense moments. <br/>Find a quiet place and take a few moments to complete this exercise. <br/>It will take less than a minute.',
      dashboardImage: 'images/dashboard/wow/blackTemple.webp',
      icon: 'images/icons/strategy/wow/heart',
      videoId: '24027845',
      videoMoments: [{
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'New stickers for chapter 5.',
        seconds: 10,
        duration: 2,
        stickers: [{
          text: 'New',
        }, {
          text: 'Stickers',
        }, {
          text: 'Here',
        }],
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyBanner,
        shouldPause: true,
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        shouldPause: true,
        exerciseType: STRATEGY_EXERCISE_TYPE.Breathe,
        exercise: {},
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.SummaryPage,
        shouldPause: true,
        resumeAt: 12,
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'Staying focused, keeping calm, and taking a deep breath is a perfect way to settle the nerves.',
        seconds: 15,
        duration: 5,
        stickers: [{
          text: 'just me and the boss',
        }, {
          text: '😌 keep calm',
        }, {
          text: 'BREATHE 💨',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'With smart, calm choices about how we approach our challenges, we give ourselves the best chance at success.',
        seconds: 30,
        duration: 5,
        stickers: [{
          text: 'This is doable ⭐',
        }, {
          text: 'This is doable 🙂',
        }, {
          text: 'This is doable 💪',
        }],
      }],
      myLearningsDescription: "It's easy to spiral due to stress or anxiety but the key is to recognise what is happening, try to pause and take deep breaths.<br/><br/>Remember:<br/><ul><li>Breathe in for 5 seconds</li><li>Hold for 2 seconds</li><li>Exhale for 5 seconds</li></ul>",
    }, {
      ordinal: 6,
      chapterIdentifier: CHAPTER_IDENTIFIER.WowTestChapter,
      title: 'Odd groups go to left (6th chapter example)',
      strategy: 'icon title',
      strategyMessage: 'Controlled breathing is a quick hack to calm our body in tense moments. <br/>Find a quiet place and take a few moments to complete this exercise. <br/>It will take less than a minute.',
      dashboardImage: 'images/dashboard/wow/nax.webp',
      icon: 'images/icons/strategy/wow/loading',
      videoId: '580138519',
      videoMoments: [{
        type: VIDEO_MOMENT_TYPE.StrategyBanner,
        seconds: 44.9,
        shouldPause: true,
      }, {
        type: VIDEO_MOMENT_TYPE.StrategyExercise,
        seconds: null,
        shouldPause: true,
      }, {
        seconds: null,
        type: VIDEO_MOMENT_TYPE.SummaryPage,
        shouldPause: true,
        resumeAt: 47,
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'Sticker 1 feedback here.',
        seconds: 49.3,
        duration: 6,
        stickers: [{
          text: '🏉',
        }, {
          text: '😌',
        }, {
          text: '💨',
        }],
      }, {
        type: VIDEO_MOMENT_TYPE.StickerSelector,
        feedback: 'Sticker 2 feedback here.',
        seconds: 64.9,
        duration: 6.3,
        stickers: [{
          text: '⭐',
        }, {
          text: '🙂',
        }, {
          text: '💪',
        }],
      }],
      myLearningsDescription: 'My learnings: content goes here for the new chapter',
    },
  ],
  strengthCards:
  {
    [STRENGTH_TYPE.OnField]: [
      {
        text: 'Damage',
        slug: 'damage',
        icon: 'images/icons/strength/wow/on-field/bomb',
      }, {
        text: 'Reaction time',
        slug: 'reaction-time',
        icon: 'images/icons/strength/wow/on-field/time',
      }, {
        text: 'Support play',
        slug: 'support-play',
        icon: 'images/icons/strength/rugby/on-field/support-play',
      }, {
        text: 'Good rotation',
        slug: 'good-rotation',
        icon: 'images/icons/strength/wow/on-field/rotation',
      }, {
        text: 'Decision making',
        slug: 'decision-making',
        icon: 'images/icons/strength/rugby/on-field/decision-making',
      }, {
        text: 'Fair play',
        slug: 'fair-play',
        icon: 'images/icons/strength/rugby/on-field/fair-play',
      }, {
        text: 'Team player',
        slug: 'team-player',
        icon: 'images/icons/strength/rugby/on-field/team-player',
      }, {
        text: 'Leadership',
        slug: 'leadership',
        icon: 'images/icons/strength/rugby/on-field/leadership',
      }, {
        text: 'New on field choice',
        slug: 'new-on-field',
        icon: 'images/icons/strength/wow/on-field/loading',
      },
    ],
    [STRENGTH_TYPE.OffField]: [
      {
        text: 'Sense of humour',
        slug: 'sense-of-humour',
        icon: 'images/icons/strength/rugby/off-field/humour',
      }, {
        text: 'Adaptable',
        slug: 'adaptable',
        icon: 'images/icons/strength/rugby/off-field/adaptable',
      }, {
        text: 'Welcoming',
        slug: 'welcoming',
        icon: 'images/icons/strength/wow/off-field/welcoming',
      }, {
        text: 'Motivated',
        slug: 'motivated',
        icon: 'images/icons/strength/rugby/off-field/motivated',
      }, {
        text: 'Creative',
        slug: 'creative',
        icon: 'images/icons/strength/rugby/off-field/creative',
      }, {
        text: 'Determined',
        slug: 'determined',
        icon: 'images/icons/strength/rugby/off-field/determined',
      }, {
        text: 'Dedicated',
        slug: 'dedicated',
        icon: 'images/icons/strength/rugby/off-field/dedicated',
      }, {
        text: 'Patient',
        slug: 'patient',
        icon: 'images/icons/strength/rugby/off-field/patient',
      }, {
        text: 'Trustworthy',
        slug: 'trustworthy',
        icon: 'images/icons/strength/rugby/off-field/trustworthy',
      }, {
        text: 'Friendly',
        slug: 'friendly',
        icon: 'images/icons/strength/rugby/off-field/friendly',
      }, {
        text: 'New off field choice',
        slug: 'new-off-field',
        icon: 'images/icons/strength/wow/off-field/loading',
      },
    ],
  },
};

export default content;