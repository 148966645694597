/* eslint-disable no-console */
/* eslint-disable prefer-rest-params */
/* eslint-disable max-statements-per-line */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import Environment from './Environment';

declare global {
    interface Window {
      hj: any;
    }
}

export const HOTJAR_SV = 6;

let isInitialised = false;

export default {
  init() {
    if (isInitialised) return;

    const environment = Environment.getCurrentEnvironment();
    const id = environment.hotjarId;

    if (id) {
        (function (h: any, o: any, t: any, j: any, a: any, r: any) {
          h.hj = h.hj || function () {(h.hj.q = h.hj.q || []).push(arguments)};
          h._hjSettings = { hjid:id, hjsv:HOTJAR_SV };
          a = o.getElementsByTagName('head')[0];
          r = o.createElement('script');r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=', '', '');

      console.log('HOTJAR: Active');
    } else {
      console.log('HOTJAR: No ID for this environment');
    }

    isInitialised = true;
  },
};