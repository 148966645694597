import { DefaultTheme } from 'styled-components';
import { ThemeDefault } from './ThemeDefault';

// CHANGING OR ADDING A NEW COLOUR TO A THEME?
// See instructions in ThemeDefault.ts

const colors = {  
  defaultBackground: '#dedede',
  white: '#ffffff',
  black: '#090909',

  primary: {
    a: '#173ed7',
    b: '#173ed7',
    c: '#173ed7',
  },
  greys: {
    a: '#434240',
    b: '#7d7d7d', //used for DISABLED cards/things and radio button border
    c: '#f1f1f1', //used for cards
    d: '#3E3E3E', //used for buttons
  },
  accent: {
    error: '#D40000',
    success: '#678ABD',
    count: '#EE0001',
  },
  
  //populated below to use refs to this obj
  videoStickers: [{ color: '#408fb0' }, { color: '#a851b8' }, { color: '#b16240' }],

  chooseCards: ['#008396', '#006a96', '#ae021b', '#972e01', '#7b4601', '#02694f', '#8b17a1'],
  plans: ['#02694f', '#972e01', '#8B17A1'],
  breathingRings: { 1: '#83EF9D', 2: '#8EF6A6', 3: '#9FFCB5', 4: '#BDFCCB' },
};

export const ThemeRugby: DefaultTheme = {
  dark: false,
  name: 'AOTG Rugby Theme',
  colors,
  breakpoints: ThemeDefault.breakpoints,
  containerMaxWidth: ThemeDefault.containerMaxWidth,
  fonts: ThemeDefault.fonts,
}