import React from 'react';
import { 
  StyledAdvancedCard,
  StyledAdvancedCardHeader,
  StyledAdvancedCardBody,
  StyledAdvancedCardFooter,
  StyledCardInnerContentHeader,
  StyledCardInnerContentBody,
  StyledCardInnerContentFooter,
 } from './AdvancedCard.styled';

//A base component used for consistent styling
//Avoid putting lots of optional props onto this

interface ICardProps {
  headerContent?: JSX.Element,
  bodyContent?: JSX.Element,
  footerContent?: JSX.Element,

  onClick?: () => void,

  headerColour?: string,
  bodyColour?: string,

  className?: string //Used to inherit any other styles
}


export const AdvancedCard: React.FC<ICardProps> = ({ className, onClick, headerColour, bodyColour, headerContent, bodyContent, footerContent }) => {
  const cardInnerProps = {
    headerColour,
    bodyColour,
    hasBodyContent: !!bodyContent,
    isClickable: !!onClick,
  };

  const cardProps = {
    className,
    ...cardInnerProps,
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    e && e.stopPropagation();
    onClick && onClick();
  };

  return <StyledAdvancedCard onClick={handleClick} {...cardProps}>
    {headerContent && <StyledAdvancedCardHeader {...cardInnerProps}>
      <StyledCardInnerContentHeader {...cardInnerProps}>{headerContent}</StyledCardInnerContentHeader>
    </StyledAdvancedCardHeader>}
    {bodyContent && <StyledAdvancedCardBody {...cardInnerProps}>
      <StyledCardInnerContentBody {...cardInnerProps}>{bodyContent}</StyledCardInnerContentBody>
    </StyledAdvancedCardBody>}
    {footerContent && <StyledAdvancedCardFooter {...cardInnerProps}>
      <StyledCardInnerContentFooter {...cardInnerProps}>{footerContent}</StyledCardInnerContentFooter>
    </StyledAdvancedCardFooter>}
  </StyledAdvancedCard>
};