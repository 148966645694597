import styled, { css } from 'styled-components';
import { IStyledProps } from '../../../../theme';
import { Button } from '../../../Button/Button';
import { Heading } from '../../../Heading/Heading';
import { StyledStrengthCard } from '../StrategyExerciseStrength.styled';
import { SvgIcon } from '../../../SvgIcon/SvgIcon';
import { getFadeInKeyFrames, getSlideUpKeyFrames } from '../../../../utils/animations.styled';
import { StyledStrategyExercisesButton } from 'components/StrategyExercises/StrategyExercises.styled';

interface IStyledSelectionContainerProps extends IStyledProps {
  disableScroll: boolean;
}

export const FADE_OUT_TIME = 0.4;
export const FADE_IN_TIME = 0.8;

export const StyledSelectionContainer = styled.div<IStyledSelectionContainerProps>`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  min-width: 100%;

  ${(props: IStyledSelectionContainerProps) => props.disableScroll && `
    position: fixed;
    top: 0px;
    left: 0px;
  `}
`;

interface IStyledStrengthSelectionProps extends IStyledProps {
  isActive: boolean;
}

export const StyledStrengthSelection = styled.div`
  grid-row-start: 1;
  grid-column-start: 1;
  opacity: ${(props: IStyledStrengthSelectionProps) => props.isActive ? '1' : '0'};
  pointer-events: ${(props: IStyledStrengthSelectionProps) => props.isActive ? 'all' : 'none'};
  // 0.3s is the duration of the transition
  transition: opacity 0.3s ${(props: IStyledStrengthSelectionProps) => props.isActive ? `${FADE_IN_TIME}s` : `${FADE_OUT_TIME}s`};
  * {
    animation-play-state: ${(props: IStyledStrengthSelectionProps) => props.isActive ? '' : 'paused !important'};
  }
`;

export const StyledTeammateSelection = styled(StyledStrengthSelection)`
  display: flex;
  flex-direction: column;
  align-items: center;  
  height: 100%;
  ${(props: IStyledStrengthSelectionProps) => !props.isActive && 'height: 90vh; overflow:hidden;'}
`;

export const StyledHeading = styled(Heading)`
  width: 240px;
`;

export interface IStyledButtonProps extends IStyledProps {
  isCompleted?: boolean;
}

export const StyledTeammateList = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;  
  width: 100%;
`;

export const StyledButton = styled(Button)`
  width: 90%;
  font-size: 1.5rem;
  line-height: 1.5rem;
  letter-spacing: 2.5px;
  margin-top: 20px;
  
  ${(props: IStyledButtonProps) => props.isCompleted && css`
    background-color: transparent;
    color: ${props.theme.colors.greys.b};
    border: 1px solid ${props.theme.colors.greys.b};
    border-radius: 4px;

    :before {
      content: "";
      left: 18px;
      position: absolute;
      height: 14px;
      width: 7px;
      border-bottom: 3px solid ${props.theme.colors.greys.d};
      border-right: 3px solid ${props.theme.colors.greys.d};
      transform: rotate(35deg);
    }
  `}
`;

// TODO: find a way to use StyledStrategyExercisesButton and also inherit styles from StyledButton.
export const StyledFinishButton = styled(StyledButton)`
  margin-top: auto;
  margin-bottom: 20px;
  & h6{
        font-family: ${(props: IStyledProps) => props.theme.fonts.heading} !important;
        font-weight: 400;
        font-size: 24px;
        letter-spacing: 2.5px !important;
    }
`;

export const StyledTeammateStrengthCard = styled(StyledStrengthCard)`

`;

export const StyledAddMore = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`;

export const StyledMatesContainer = styled.div`
  animation: ${getFadeInKeyFrames()} 0.25s forwards ease-in-out 0s;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledMatesPage = styled.div`
  animation: ${getSlideUpKeyFrames} 0.75s forwards ease-in-out 0s;
  height: 100%;
  width: 100%;
  background: ${(props: IStyledProps) => props.theme.colors.black};
`;

export const StyledMatesStrategyIcon = styled(SvgIcon)`
  svg {
    width: 60px;
    height: 60px;
    left: 0;
    margin-bottom: 30px;
    margin: 44.95px 8.83px 31.95px 28.83px;
  }
  path {
    fill: ${(props: IStyledProps) => props.theme.colors.white};
    }
`;

export const StyledMatesHeading = styled(Heading)`
  color: ${(props: IStyledProps) => props.theme.colors.white};
  font-size: 45px;
  padding: 0px;
  font-weight: 400;
  font-size: 45px;
  line-height: 90%;
  margin-left: 40px;
  text-align: left;

  @media screen and (min-width: 280px) {
    font-size: 60px;
  }
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xxs}) {
    font-size: 80px; 
  }
  @media screen and (max-height: 680px) {
    font-size: 60px;
  }
`;

export const StyledMatesList = styled.ul`
  color: ${(props: IStyledProps) => props.theme.colors.white};
  width: 70%;
  font-size: 16px;
  line-height: 28px;
  list-style-position: outside;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin: 0 26px;
  padding-left: 25px;
  font-weight: 300;

  @media screen and (min-width: 280px) {
    width: 80%;
    font-size: 17px;
  }
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xxs}) {
    font-size: 18px;
  }
`;

export const StyledMatesClockContainer = styled.div`
  margin-top: 35px;
  margin-left: 40px;
  display: flex;
  align-items: end; 
  color: ${(props: IStyledProps) => props.theme.colors.white};
`;

export const StyledMatesClockIcon = styled(SvgIcon)`
  width: 20px;
  height: 20px;
  margin-right: 7px;
  svg {
    path {
      fill: ${(props: IStyledProps) => props.theme.colors.white};
    }
  }
`;

export const StyledMatesClockText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  font-weight: 100;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StyledOverlayButton = styled(StyledStrategyExercisesButton)`
  position: absolute;
  width: 85%;
  bottom: 4%;
`;