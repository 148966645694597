/* eslint-disable react/display-name */
import React, { useState } from 'react';
import { StyledForm } from './Form.styled';

interface FormField {
  value: string | number | boolean | undefined | null;
  validate: (value: any) => string;
  error?: string;
}

interface CustomFormData {
  fields: {
    [Key: string]: FormField;
  }
  formError?: string; //overall form error
  isLoading: boolean;
}

interface IFormProps {
  onSubmit?: (e: any) => void
  fields: {
    [Key: string]: FormField
  }
}

export interface FormFunctionProps {
  getFormData: () => CustomFormData,
  setFormFieldValue: (fieldName: string, newValue: any) => void,
  setFormFieldError: (fieldName: string, error: string) => void,
  setFormError: (error: string) => void,
  validateFormFields: (fields?: string[]) => boolean,
  clearFormErrors: () => void,
  setIsLoading: (isLoading: boolean) => void
}

export const withForm = (WrappedComponent: React.FC<FormFunctionProps>, { fields }: IFormProps): React.FC => () => {
  const [formData, setFormData] = useState<CustomFormData>({
    fields: fields,
    formError: '',
    isLoading: false,
  });

  const getFormData = () => formData;

  const setFormFieldValue = (fieldName: string, newValue: FormField['value']) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      formError: '',
      fields: {
        ...prevFormData.fields,
        [fieldName]: {
          ...prevFormData.fields[fieldName],
          error: '',
          value: newValue,
        },
      },
    }));
  };

  const setFormFieldError = (fieldName: string, error: string) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      fields: {
        ...prevFormData.fields,
        [fieldName]: {
          ...prevFormData.fields[fieldName],
          error: error,
        },
      },
    }));
  };
  
  const clearFormErrors = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      formError: '',
      fields: Object.keys(prevFormData.fields).reduce((newFields: CustomFormData['fields'], fieldName: string) => {
        return {
          ...newFields,
          [fieldName]: {
            ...prevFormData.fields[fieldName],
            error: '',
          },
        };
      }, {}),
    }));
  };

  const setFormError = (error: string) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      formError: error,
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const validateFormFields = (fields?: string[]) => {
    const updatedFormData = formData;

    const isValid = !formData.formError && (fields || Object.keys(formData.fields))
      .map(fieldName => {
        const field = formData.fields[fieldName];
        const error = field.validate(field.value);
        updatedFormData.fields[fieldName].error = error;
        return !error; //Returns true if valid
      })
      .every(valid => valid);

    setFormData({
      ...updatedFormData,
    });

    return isValid;
  };

  const setIsLoading = (isLoading: boolean) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      isLoading: isLoading,
    }));
  }

  const formFunctions = {
    getFormData,
    setIsLoading,
    setFormFieldValue,
    setFormFieldError,
    setFormError,
    validateFormFields,
    clearFormErrors,
  }

  return <StyledForm isLoading={formData.isLoading}>
    <WrappedComponent {...formFunctions} />
  </StyledForm>;
};
