import React from 'react';
import { StyledQuestionListItem,
  StyledQuestionListItemText,
  StyledQuestionIconQuestion,
  StyledQuestionIconTick,
  StyledQuestionIconCross,
 } from './QuestionListItem.styled';

import { ReactComponent as IconTick } from '../../../images/icons/tick.svg';
import { ReactComponent as IconCross } from '../../../images/icons/cross.svg';
import { ReactComponent as IconQuestion } from '../../../images/icons/question.svg';

interface IQuestionListItemProps {
  text: string
  isActive?: boolean //Makes the text blue
  ticked?: boolean //false = cross, true = tick, (?) = undefined

  className?: string //Used to inherit any other styles
}

export const QuestionListItem: React.FC<IQuestionListItemProps> = ({ className, text, isActive, ticked }) => {
  let icon = <StyledQuestionIconQuestion><IconQuestion/></StyledQuestionIconQuestion>; //Default to (?)
  if (ticked === true) icon = <StyledQuestionIconTick><IconTick/></StyledQuestionIconTick>;
  if (ticked === false) icon = <StyledQuestionIconCross><IconCross/></StyledQuestionIconCross>;

  return <StyledQuestionListItem className={className}>
    {icon}
    <StyledQuestionListItemText isActive={isActive} level="h5" text={text} />
  </StyledQuestionListItem>;
};