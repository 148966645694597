import { ITransitionStep, TransitionStepType } from './components/Transition/TransitionContext/TransitionContext';
import Environment from './services/Environment';

/********************* Environment *********************/

export const CONTACT_US_URL = `${Environment.getCurrentEnvironment().domain || window.location.origin}/contact-us`;
export const PRIVACY_POLICY_URL = "https://au.movember.com/privacy#:~:text=Movember%20is%20committed%20to%20protecting,we'll%20do%20with%20it";
export const COLLECTIONS_URL = `${Environment.getCurrentEnvironment().domain || window.location.origin}/collection-notice`;
export const TERMS_URL = `${Environment.getCurrentEnvironment().domain || window.location.origin}/terms-and-conditions`;

export const REACT_APP_GTM_ID = 'GTM-NGT3CS4';
export const REACT_APP_GTM_ID_GA4 = 'GTM-KKFPTZN';

/********************* APIS *********************/

export const SAVE_USER_DATA_RATE_LIMIT = 200;
export const USER_DATA_REFRESH_INTERVAL = 30000;
export const FRONTEND_TEAM_FEEDBACK_REFRESHMENT = 1000;

/********************* User data (+ local data) *********************/

export const USER_DATA_TYPES = {
  welcomeNotificationSentAt: 'welcomeNotificationSentAt',
  finalQuizNotificationQueuedFor: 'finalQuizNotificationQueuedFor',
  notificationSendingAllowed: 'notificationSendingAllowed',

  //Video player
  videoCaptionsLanguagePreference: 'videoCaptionsLanguagePreference',
  videoVolumePreference: 'videoVolumePreference',

  entryQuizComplete: 'entryQuizComplete',
  finalQuizComplete: 'finalQuizComplete',
  quizConsent: 'quizConsent',

  userProgress: 'userProgress',

  personalFeedback: 'personalFeedback',

  deviceToken: 'deviceToken',
  timeZone: 'timeZone',
  archiveStatus: 'archiveStatus',
  teamFeedback: 'teamFeedback',

  sport: 'sport',
  country: 'country',
} as const;

/********************* Enums *********************/

//HOLD UP: Changing the SPORT_TYPE enums? They're on the frontend/admin portal/backend too! Make sure you change them all
export enum SPORT_TYPE {
  Rugby = 'rugby-league',
  AustralianRules = 'australian-rules',
  AustralianRulesOneSession = 'australian-rules-one-session',

  WoWTest = 'wow-test',
}

//HOLD UP: Changing the COUNTRY_TYPE enums? They're on the frontend/admin portal/backend too! Make sure you change them all
export enum COUNTRY_TYPE {
  AU = 'australia',
  GB = 'england',
}

export enum VIDEO_MOMENT_TYPE {
  PhonesDown = 'phonesDown',

  StickerSelector = 'sticker-selector',
  StickerRemover = 'sticker-remover',

  // StrategyBanner is always shown after StickerRemover, it could almost be merged into it... BUT:
  // In at least one chapter is shown on its own (without a StickerRemover), so it must be treated as it's own video moment
  StrategyBanner = 'strategy-banner',
  StrategyExercise = 'strategy-exercise', //various strategy exercises

  SummaryPage = 'summary-page',
}

export enum STRATEGY_EXERCISE_TYPE {
  Choose = 'choose', //Chapter 1
  Plan = 'plan', //Chapter 2

  Strengths = 'strengths', //Chapter 3
  StrengthsTeammates = 'teammates-strengths', //Chapter 3

  Control = 'control', //Chapter 4
  Breathe = 'breathe', //Chapter 5
}

export enum PLAYER_STATUS {
  Archived = 'archived',
}

export enum STRENGTH_TYPE {
  OffField = 'off-field',
  OnField = 'on-field',
}

//Used for UserData/progress tracking
export enum CHAPTER_IDENTIFIER {
  //Although these enum values might not continue to make sense with titles/content,
  // they are used to track user progress so please don't casually change them

  //Typically CH1
  ManagingYourThoughts = 'managing-your-thoughts',

  //Typically CH2
  ProblemSolving = 'problem-solving',

  //Typically CH3
  PlayingToYourStrengths = 'playing-to-your-strengths',

  //Typically CH4
  ControllingTheControllables = 'controlling-the-controllables',

  //Typically CH5
  KeepingYourCool = 'keeping-your-cool',


  //Purely used to demonstrate extra chapter ability in WoW version, remove if causing issues
  WowTestChapter = 'wow-test-chapter',
}

/********************* Images *********************/
export const IMAGES_URL = `${window.location.origin}/`;

/********************* Transition *********************/

export enum TRANSITION_TYPES {
  ChapterComplete = 'chapter-complete',

  ChapterFirstComplete = 'chapter-first-complete',

  InitalChapterFirstComplete = 'inital-chapter-first-complete',
  FinalChapterFirstComplete = 'final-chapter-first-complete',
  PhonesDownEntry = 'PhonesDownEntry',
}

const CHAPTER_FIRST_COMPLETE_STEPS = [
  {
    type: TransitionStepType.FadeOut,
    durationSeconds: 1,
    animationSeconds: 0.5,
  },
  {
    type: TransitionStepType.ChapterTransition,
    durationSeconds: 0,
  },
  {
    type: TransitionStepType.FadeIn,
    durationSeconds: 1,
    animationSeconds: 0.5,
  },
  {
    type: TransitionStepType.WaitNoInteraction,
    durationSeconds: 0.5,
  },
  {
    type: TransitionStepType.WaitNoInteraction,
    durationSeconds: 1,
    identifier: 'dashboard-move',
  },
];

export const TRANSITION_CONFIGS: { [key: string]: ITransitionStep[] } = {

  [TRANSITION_TYPES.ChapterComplete]: [
    {
      type: TransitionStepType.FadeOut,
      durationSeconds: 1,
      animationSeconds: 0.5,
    },
    {
      type: TransitionStepType.FadeIn,
      durationSeconds: 1,
    },
  ],

  [TRANSITION_TYPES.PhonesDownEntry]: [
    {
      type: TransitionStepType.EntryTransition,
      durationSeconds: 4.5,
      animationSeconds: 4.5,
    },
  ],

  [TRANSITION_TYPES.ChapterFirstComplete]: CHAPTER_FIRST_COMPLETE_STEPS,

  [TRANSITION_TYPES.InitalChapterFirstComplete]: [
    ...CHAPTER_FIRST_COMPLETE_STEPS,
    // {
    //   type: TransitionStepType.WaitWithInteraction,
    //   durationSeconds: 6,
    //   identifier: 'autoplay-timer'
    // },
    // {
    //   type: TransitionStepType.FadeOut,
    //   durationSeconds: 2,
    //   identifier: 'dashboard-fadeout'
    // },
    // {
    //   type: TransitionStepType.FadeIn,
    //   durationSeconds: 3
    // }
  ],

  [TRANSITION_TYPES.FinalChapterFirstComplete]: [
    {
      type: TransitionStepType.FadeOut,
      durationSeconds: 1,
      animationSeconds: 0.5,
    },
    {
      type: TransitionStepType.FadeIn,
      durationSeconds: 1,
      animationSeconds: 0.5,
    },
    {
      type: TransitionStepType.WaitNoInteraction,
      durationSeconds: 1,
      identifier: 'dashboard-move',
    },
    {
      type: TransitionStepType.WaitNoInteraction,
      durationSeconds: 1,
      identifier: 'finale-slam',
    },
    {
      type: TransitionStepType.WaitNoInteraction,
      durationSeconds: 0.5,
      identifier: 'heading-fadein',
    },
    {
      type: TransitionStepType.WaitNoInteraction,
      durationSeconds: 2,
      identifier: 'cards-popup',
    },
  ],
}

/********************* Other *********************/
export const FAUX_EMAIL_SUFFIX = '@aotgmhr.com';

//The max width of the app area, makes desktop/wide screens still portrait
export const APP_MAX_WIDTH_PX = 428;

//Z indexes- keep in order ascending to work out what has priority over what
//If you make one something particularly high, please leave a note as to why :)
export const Z_INDEX = {
  strategyCarousel: 1,

  selectedSticker: 2,

  videoPlayer: 3,

  videoOverlayTimer: 4,

  videoOverlay: 6,
  skipButton: 7,
  navIcon: 9,

  loadingOverlay: 10,

  tansitionMask: 11,

  removableSticker: 12,
  removableStickerFinger: 13,

  videoOverlayBanner: 14,

  sideDrawer: 15,

  applicationOverlay: 16,

  transitionOverlayMask: 17,

  toast: 17,

  environmentIndicator: 30,
  testTools: 31,
} as const;


/********************* Timing of chapter transitions & animations *********************/
export const CHAPTER_TRANSITION = {
  fadingTime: 0.5,
  delayTime: 1,
} as const;

/********************* Start and end quiz urls *********************/

export const START_QUIZ_URL = 'https://movember.qualtrics.com/jfe/form/SV_0GnROZu0rVQPISa';
export const FINAL_QUIZ_URL = 'https://movember.qualtrics.com/jfe/form/SV_eWhy4plMTmH9FFs';


/********************* Native Interface *********************/

export const NATIVE_SET_USER_KEY = '__nativeSetUserToken';