import styled, { css, keyframes } from 'styled-components';
import { IChapter } from '../../models/IChapter';
import { IStyledProps } from '../../theme';
import { Button } from '../Button/Button';
import { Heading } from '../Heading/Heading';
import { PagePanel } from '../Page/Page';
import { SvgIcon } from '../SvgIcon/SvgIcon';

interface IStyledAchievementScreenProps extends IStyledProps {
  chapter: IChapter;
  chapterCount: number;
}

interface IStyledAchievementScreenContainerProps extends IStyledProps {
  locked: boolean;
}
interface IStyledAchievementScreenTrackIcon extends IStyledProps {
  active: boolean;
}

export const StyledAchievementScreenContainer = styled.div<IStyledAchievementScreenContainerProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${(props: IStyledProps) => props.theme.colors.black};
  color: ${(props: IStyledProps) => props.theme.colors.white};
  pointer-events: ${(props: IStyledAchievementScreenContainerProps) => props.locked ? 'none' : ''};
  z-index: 10; // above the nav icon
`;

export const StyledAchievementProgressContainer = styled(PagePanel)`
`

export const StyledAchievementScreenSuperWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-align: right;
  padding: 10px 15px;
  top: 5px; // This is to avoid the iOS camera space
`;

export const StyledAchievementScreenTextWrapper = styled.div`
  position: absolute;
  top: 15%;
`;

export const StyledAchievementScreenTitle = styled(Heading).attrs({ primary: true, level: 'h1' })`
  font-size: 180px;
  width: 80%;
  line-height: 0.7em;
  text-align: left;

  @media screen and (min-width: 375px) {
    font-size: 220px;
    width: 90%;
  }
  @media screen and (min-width: 400px) {
    font-size: 240px;
  }
`;

export const StyledAchievementScreenDescription = styled.div`
  font-size: 1.125rem;
  opacity: 0.8;
`;

const getBarFillAnimation = (count: number, i: number) => {
  return keyframes`
    from {
      width: ${100 / count * (i - 1)}%;
    }
    to {
      width: ${100 / count * i}%;
    }
  `;
}

export const StyledAchievementScreenIcons = styled.div`
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  height: 60px;
  background-color: ${(props: IStyledProps) => props.theme.colors.greys.d};
  overflow: hidden;
  justify-content: space-around;
  
  ::before { 
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    animation: ${(props: IStyledAchievementScreenProps) => getBarFillAnimation(props.chapterCount, props.chapter.ordinal)} 0.5s ease forwards;
    height: 100%;
    background-color: ${(props: IStyledProps) => props.theme.colors.primary.a};
  }
`;

export const StyledAchievementScreenTrackIcon = styled(SvgIcon)`
  height: 30px;
  position: relative;

  * {
    fill: ${(props: IStyledAchievementScreenTrackIcon) => props.active ? props.theme.colors.white : props.theme.colors.greys.b };
  }
`;

export const StyledAchievementScreenButton = styled(Button)`
  width: 90%;
  position: absolute;
  bottom: 5%;
  left: 5%;
`;
StyledAchievementScreenButton.displayName = 'StyledAchievementScreenButton';
