import styled from 'styled-components';
import Native from '../../services/Native';
import { Link } from 'react-router-dom';
import { Z_INDEX } from '../../constants';
import { PAGE_PADDING_PX } from '../Page/Page.styled';

export const StyledNavButton = styled(Link)`
  position: absolute;
  z-index: ${Z_INDEX.navIcon};
  top: 0;
  left: 0;
  padding: ${PAGE_PADDING_PX}px;
  margin-top: ${Native.getPhoneTopInsetPx()}px;

  > svg {
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .5));
    margin: -2px 0;
    width: 32px;
    height: 32px;
  }
`;