import styled, { css, keyframes } from 'styled-components';
import finger from './finger.webp';
import { Sticker } from '../../Sticker/Sticker';
import { ISticker } from '../../../models/IVideoMoment';
import { Z_INDEX } from '../../../constants';
import { getFingerSwipeFrames } from '../../../utils/animations.styled';
import { VideoOverlay } from '../VideoOverlay';
import { IStyledProps } from 'theme';

export const StyledStickerRemoverContainer = styled(VideoOverlay)`
  overflow: hidden;
`;

interface IStyledRemovableStickerProps extends IStyledProps {
  sticker: ISticker;

  xPosition: number; // x in percent
  yPosition: number; // y in percent
  delay: number; //Delay in animation before being shown
  scale: number;
  rotation: number; //Rotation in degrees

  swipeDirection?: number[];
}

export const StyledFingerHelper = styled.div`
  position: absolute;
  z-index: ${Z_INDEX.removableStickerFinger};
  left: 20%;
  top: 20%;
  width: 0px;
  height: 0px;
  animation: ${getFingerSwipeFrames()};
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  pointer-events: none;

  :after {
    content: '';
    width: 100px;
    height: 100px;
    background-image: url(${finger});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: -50px;
    top: 0px;
    display: block;
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
    pointer-events: none;
  }
`;

const getCommonTransform = (rotation: number, scale: number) => {
  return `rotate(${rotation}deg)  scale(${scale}, ${scale}) translate(-50%, -50%)`;
};

export const getStickerAppearFrames = ({ delay, rotation, scale }: IStyledRemovableStickerProps) => {
  const duration = delay + 0.5;
  const delayedFirstFramePercent = (delay / duration) * 100;

  return keyframes`
    0% {
      transform: ${getCommonTransform(rotation, scale)} scale(0, 0) ;
      opacity: 0;
    }
    ${delayedFirstFramePercent}% {
      transform: ${getCommonTransform(rotation, scale)} scale(0, 0) ;
      opacity: 0;
    }
    100% {
      transform: ${getCommonTransform(rotation, scale)} scale(1, 1) ;
      opacity: 1;
    }
  `;
};

export const getStickerRemoveFrames = ({ scale, rotation, swipeDirection }: IStyledRemovableStickerProps) => {
  const BOUNCE_AT_PERCENT = 10;
  const FALL_ROTATION_MULTIPLIER = 10;

  if (!swipeDirection || swipeDirection.length < 2) return '';

  let swipeDirectionX = swipeDirection[0];
  let swipeDirectionY = swipeDirection[1];

  //Amp the values up a bit
  swipeDirectionX = swipeDirectionX * 30;
  swipeDirectionY = swipeDirectionY < 0 ? swipeDirectionY * 4 : 0;

  //Apply maximums
  const SWIPE_MAX = 50;
  if (swipeDirectionX > SWIPE_MAX) swipeDirectionX = SWIPE_MAX;
  if (swipeDirectionX < -SWIPE_MAX) swipeDirectionX = -SWIPE_MAX;
  if (swipeDirectionY > SWIPE_MAX) swipeDirectionY = SWIPE_MAX;
  if (swipeDirectionY < -SWIPE_MAX) swipeDirectionY = -SWIPE_MAX;

  return keyframes`
    0% {
      transform: ${getCommonTransform(rotation, scale)};
    }
    ${
      swipeDirectionY < 0 //If directionY was negative, the swipe was going slightly upward, give the sticker a little bouncey
        ? css`
            ${BOUNCE_AT_PERCENT}% {
              transform: ${getCommonTransform(rotation, scale)} translate(${swipeDirectionX * (0.01 * BOUNCE_AT_PERCENT)}px, ${swipeDirectionY}px);
            }
          `
        : ''
    }
    100% {
      transform: ${getCommonTransform(rotation, scale)} translate(${swipeDirectionX}px, 120vh) rotate(${rotation * FALL_ROTATION_MULTIPLIER}deg);
    }
  `;
};

export const StyledRemovableSticker = styled(Sticker)`
  position: absolute;
  z-index: ${Z_INDEX.removableSticker};
  left: ${(props: IStyledRemovableStickerProps) => props.xPosition}%;
  top: ${(props: IStyledRemovableStickerProps) => props.yPosition}%;

  transform-origin: center center;

  ${(props: IStyledRemovableStickerProps) => {
    //If we have a swipe direction for the sticker, animate it out
    if (props.swipeDirection && props.swipeDirection.length === 2) {
      return css`
        animation: ${getStickerRemoveFrames(props)} 1.5s ease-out forwards;
      `;
    }

    //Entry bounce in animation
    const duration = props.delay + 0.5;
    return css`
      animation: ${getStickerAppearFrames(props)} ${duration}s ease-in-out forwards;
    `;
  }};
`;

// The :not(.isPanning) above prevents our drag from being initiated (and stuck) on a sticker.
// pointer-events will disable interaction with sticker, shoot the event through to StyledStickerRemoverInner
// so the drag can begin there instead. Without this, only the sticker the event was initiated on, will respond
export const StyledStickerRemoverInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:not(.isPanning) {
    ${StyledRemovableSticker} {
      pointer-events: none;
    }
  }
`;
