import { STRENGTH_TYPE } from '../constants';
import { IStrengthCard } from '../models/IStrengthCard';
import Content from './Content';
import PlayerData from './PlayerData';

export interface ITeamFeedback extends IStrengthCard {
    badgeText: string;
    contributors: string[];
  }

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getUserFeedback = () => {
    const onFieldStrengthCards = Content.getStrengthCards(STRENGTH_TYPE.OnField);
    const offFieldStrengthCards = Content.getStrengthCards(STRENGTH_TYPE.OffField);
    const allFieldStrengthCards = Content.getStrengthCards();
    const personalFeedback = PlayerData.getPersonalFeedback();
    const strengths = PlayerData.getUserStrengths();

    const cookedStrengths = Object.keys(strengths).flatMap((id) => {
        const value = strengths[id];
        return [...value.StrengthsOffField, ...value.StrengthsOnField].map((key) => ({
            key,
            id,
            firstName: value.FirstName,
            lastName: value.LastName,
        }));
    });

    const feedbackCards: ITeamFeedback[] = allFieldStrengthCards.map((baseStrengthCard) => {
        const contributorList = cookedStrengths.filter((value) => value.key === baseStrengthCard.slug);

        return {
            text: baseStrengthCard.text,
            icon: baseStrengthCard.icon,
            slug: baseStrengthCard.slug,
            badgeText: `${contributorList.length}`,
            contributors: contributorList.map((contributor) => `${contributor.firstName} ${contributor.lastName[0]}`),
        };
    });

    const onFieldFeedback = feedbackCards.filter((feedbackCard) => onFieldStrengthCards.find((strengthCard) => strengthCard.slug === feedbackCard.slug) && feedbackCard.contributors.length !== 0);
    const offFieldFeedback = feedbackCards.filter((feedbackCard) => offFieldStrengthCards.find((strengthCard) => strengthCard.slug === feedbackCard.slug) && feedbackCard.contributors.length !== 0);
    const personalCookedFeedback = feedbackCards.filter((feedbackCard) => personalFeedback.includes(feedbackCard.slug));

    return {
        onFieldFeedback,
        offFieldFeedback,
        personalCookedFeedback,
    }
}