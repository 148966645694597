import styled, { css } from 'styled-components';
import { IStyledProps } from '../../theme';

interface StyledCardProps extends IStyledProps {
  isDisabled?: boolean
  isActive?: boolean
  isClickable?: boolean
}

export const StyledCard = styled.div`
  position: relative;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
  transition: background-color 0.5s ease-in;

  ${(props: StyledCardProps) => {
    if (props.isDisabled) {
      return css`
        opacity: 0.35;
        background-color: ${props.theme.colors.greys.a};
        color: ${props.theme.colors.white};
        svg {
          path, rect {
            fill: ${props.theme.colors.white};
          }
        }
      `;
    } else if (props.isActive) {
      return css`
        cursor: ${props.isClickable ? 'pointer' : 'default'};
        background-color: ${props.theme.colors.primary.a}
        color: ${props.theme.colors.white};
        svg {
          path, rect {
            fill: ${props.theme.colors.white};
          }
        }
      `;
    } else {
      return css`
      cursor: ${props.isClickable ? 'pointer' : 'default'};
        background-color: ${props.theme.colors.greys.c};
        color: ${props.theme.colors.primary.a};
        svg {
          path, rect {
            fill: ${props.theme.colors.primary.a};
          }
        }
      `;
    }
  }};
`;

export const StyledUpperCardContent = styled.div`
  flex: 2;
  display: flex;
  align-items: flex-end;
`;

export const StyledLowerCardContent = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
`;