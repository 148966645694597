import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../TextField/TextField';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { StyledTeamCodeContainer, StyledTip, StyledTipBody, StyledTipButton, StyledTipHeading } from './TeamCodeInput.styled';
import { IconContext } from 'react-icons/lib';
import { ThemeContext } from 'styled-components'

interface ITeamCodeInputProps {
  placeholder?: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  initialValue?: string;
}
  

export const TeamCodeInput : React.FC<ITeamCodeInputProps> = ({ placeholder, onChange, initialValue, errorMessage, ...rest }) => {
  const [showTip, setShowTip] = useState(false);
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  return <StyledTeamCodeContainer showTip={showTip} {...rest}>
    {showTip && <StyledTip>
      <StyledTipHeading>What’s this?</StyledTipHeading>
      <StyledTipBody>Your team will be given its own unique Team Code to access the app. Listen in during the workshop for yours.</StyledTipBody>
      <StyledTipButton onClick={() => setShowTip(false)} label="Ok" />
    </StyledTip>}
    <TextField
      initialValue={initialValue}
      type='uppercase'
      placeholder={placeholder || t('form.teamCode.placeholder')}
      onChange={onChange}
      errorMessage={errorMessage}
    />
    <IconContext.Provider value={{ color: showTip ?  themeContext.colors.primary.a : themeContext.colors.greys.b, size: '1.2em' }}>
      <AiOutlineQuestionCircle onClick={() => setShowTip(!showTip)}/>
    </IconContext.Provider>
  </StyledTeamCodeContainer>;
}