import styled from 'styled-components';
import { StyledCardList } from '../CardList/CardList.styled';
import { Heading } from '../Heading/Heading';
import { StyledNavButton } from '../NavButton/NavButton.styled';
import { IMAGES_URL } from '../../constants';
import { IStyledProps } from '../../theme';

interface IStyledTeamFeedbackProps extends IStyledProps {
    image: string
}

export const StyledTeamFeedbackContainer = styled.div`
    background-image: ${(props: IStyledTeamFeedbackProps) => `linear-gradient(to bottom, rgba(216, 216, 216, 0.7), rgba(255, 255, 255, 0.7)), url(${IMAGES_URL}${props.image})`};
    background-position: 50%;
    background-size: cover;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: 325px) {
        overflow-x: scroll;
    }
`;

export const StyledTeamFeedbackContent = styled.div`
    flex-direction: column;
    backdrop-filter: blur(5px);
    display: flex;
`;

export const StyledTeamFeedbackTitle = styled(Heading).attrs({ level: 'h4' })`
    font-weight: 400;
    margin: 1em 0 2em 0;
`;

export const StyledTeamFeedbackSubTitle = styled(Heading).attrs({ level: 'h5' })`
    font-weight: 700;
    font-size: 19px;
    margin-bottom: 1em;
`;

export const StyledTeamFeedbackCardList = styled(StyledCardList)`
    margin-bottom: 3em;
    justify-content: center;
`;

export const StyledCloseIcon = styled(StyledNavButton)`
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
    svg {
        filter: none;
        width: 30px;
        height: 30px;
    }
`

export const StyledNotificationText = styled.span`
    color: ${(props: IStyledProps) => props.theme.colors.white};
    font-style: normal;
    text-align: center;
    font-weight: 700;
`;

export const StyledTeamFeedbackCircleContainer = styled.div`
    position: absolute;
    z-index: 2;
    border-radius: 30px;
    background-color: ${(props: IStyledProps) => props.theme.colors.accent.count};
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -15px;
    top: -18px;

    svg {
        width: 40px;
        height: 40px;
    }
`;

export const StyledTeamFeedbackCount = styled.span``