import styled from 'styled-components';

export const StyledPlayerAvatar = styled.div<{ team?: number }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  background: ${(props) => props.team === 1 ? 'red' : 'blue'};
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
`;

export const StyledPlayerImage = styled.img`
`;

export const StyledPlayerResponse = styled.div<{ team?: number }>`
  background-color: ${(props) => props.team === 1 ? 'red' : 'blue'};
  padding: 4px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  position: absolute;
  transform: translateX(${props => props.team === 1 ? '-' : ''}60px);
  font-size: 32px;
`;