export enum GAME_STAGE {
  LOBBY = 'LOBBY',

  CHOOSE_THOUGHTS = 'CHOOSE_THOUGHTS',
  HOW_YOU_FEEL = 'HOW_YOU_FEEL',
  TRUE_OR_FALSE = 'TRUE_OR_FALSE',
  
  FINALE = 'FINALE',
}

export default interface IPlayerState {
  playerId: string,
  avatar: string,
  points: number,
  team?: number

  //temporary game properties
  isCompleted?: boolean
  response?: string
}

export interface IGameState {
  stage: GAME_STAGE
  isFrozen: boolean
  playerStates: IPlayerState[]
  teams: {
    name: string
    points: number
  }[]
  
  lastUpdated: number
}