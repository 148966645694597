import React, { useEffect, useState } from 'react';
import { StyledTimerPanelUpper, StyledTimerPanelLower, StyledTimerHeading, StyledTimerBar } from './VideoOverlayTimer.styled';

interface IVideoOverlayTimerProps {
  duration: number
  heading?: string
  isVisible: boolean
  onFinish: () => void
}

export const VideoOverlayTimer: React.FC<IVideoOverlayTimerProps> = ({ duration, heading, onFinish, isVisible }) => {
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    setHasStarted(true);

    //A duration is passed in, fire 'onFinish' when it is up
    const timeout = setTimeout(() => onFinish(), duration * 1000);
    //Clear timeout on unmount
    return () => {
      clearTimeout(timeout);
    };
  }, [duration, onFinish]);

  const isShown = isVisible && hasStarted;

  return <>
    <StyledTimerPanelUpper isShown={isShown}>
      {heading && <StyledTimerHeading>{heading}</StyledTimerHeading>}
     </StyledTimerPanelUpper>
    <StyledTimerPanelLower isShown={isShown}>
      <StyledTimerBar duration={duration}/>
    </StyledTimerPanelLower>
  </>
}