import styled, { css } from 'styled-components';
import { IStyledProps } from '../../theme';

export const StyledErrorMessage = styled.div`
  font-size: 0.8rem;
  
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 1.6rem;
  height: 1.5rem;
  border: solid 2px ${(props: IStyledProps) => props.theme.colors.black};
  border-radius: 2px;
  margin-left: 0;
  margin-right: 10px;
  margin-top: 0;
  opacity: 0.7
  outline: none;
  &:checked {
    opacity: 1;
    background-size: auto;
    border: solid 2px ${(props: IStyledProps) => props.theme.colors.primary.a};
    ${(props: IStyledProps) => {
      const color = props.theme.colors.primary.a.replace('#', '%23');
      return css`background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Crect width='20' height='20' style='fill:${color};stroke:white;stroke-width:5;opacity:1' /%3E%3C/svg%3E") no-repeat center center;`
    }};
  }
`;

export const StyledCheckboxContainer = styled.div`
  position: relative;
  margin-bottom: 1em;
  display: flex;
  align-items: flex-start;

  ${StyledErrorMessage} {
    opacity: 1;
    color: ${(props: IStyledProps) => props.theme.colors.accent.error};
  }
`;

export const StyledCheckboxClickableContainer = styled.div`
  display: inline;
  cursor: pointer;

  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const StyledCheckboxLabel = styled.label`
  cursor: pointer;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.7;
  color : ${(props: IStyledProps) => props.theme.colors.black};
  a {
    text-decoration: underline;
    color : ${(props: IStyledProps) => props.theme.colors.black};
  }
`;

