import React, { useState, useEffect, PropsWithChildren } from 'react';
import { StyledIconContainer } from './SvgIcon.styled';
import { IMAGES_URL, CHAPTER_IDENTIFIER } from '../../constants';
import { ReactComponent as StrategyChoose } from '../../images/icons/strategy/thoughts.svg';
import { ReactComponent as StrategyStrength } from '../../images/icons/strategy/strength.svg';
import { ReactComponent as StrategyPlan } from '../../images/icons/strategy/plan.svg';
import { ReactComponent as StrategyControl } from '../../images/icons/strategy/control.svg';
import { ReactComponent as StrategyBreathe } from '../../images/icons/strategy/breathe.svg';

interface ISvgIconProps {
  className?: string;

  //Either specify the icon as children <SvgIcon><TickIcon /></SvgIcon>, OR:
  cmsSrc?: string; //If specified, will fetch an svg from the cms
}

//For SVGs specified in the CMS
export const SvgIcon: React.FC<PropsWithChildren<ISvgIconProps>> = ({ cmsSrc, children, className }) => {
  const [fetchedSvg, setFetchedSvg] = useState('');

  const componentProps = {
    className,
  };

  useEffect(() => {
    if (cmsSrc) {
      const url = `${IMAGES_URL}${cmsSrc}.svg`;
      fetch(url)
        .then((res: Response) => res.text())
        .then((text: string) => {
          if (text.startsWith('<svg')) {
            setFetchedSvg(text);
          } else {
            throw Error('Invalid SVG url');
          }
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log('Fetch img failed, url:', url);
        });
    }
  }, [cmsSrc]);

  //Pass props down so that className is maintained
  return fetchedSvg ? (
    <StyledIconContainer
      {...componentProps}
      dangerouslySetInnerHTML={{ __html: fetchedSvg }}
    />
  ) : (
    <StyledIconContainer {...componentProps}>{children}</StyledIconContainer>
  );
};

interface IChapterIconProps {
  chapterIdentifier: string;
  className?: string;
}

export const ChapterIcon: React.FC<IChapterIconProps> = ({ chapterIdentifier, className }) => {
  let chapterIcon;
  switch (chapterIdentifier) {
    case CHAPTER_IDENTIFIER.ManagingYourThoughts:
      chapterIcon = <StrategyChoose />;
      break;
    case CHAPTER_IDENTIFIER.ProblemSolving:
      chapterIcon = <StrategyPlan />;
      break;
    case CHAPTER_IDENTIFIER.PlayingToYourStrengths:
      chapterIcon = <StrategyStrength />;
      break;
    case CHAPTER_IDENTIFIER.ControllingTheControllables:
      chapterIcon = <StrategyControl />;
      break;
    case CHAPTER_IDENTIFIER.KeepingYourCool:
      chapterIcon = <StrategyBreathe />;
      break;
  }

  return <SvgIcon className={className}>{chapterIcon}</SvgIcon>;
};
