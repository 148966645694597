import React, { useEffect, useRef } from 'react';
import { IChapter } from '../../models/IChapter';
import PlayerData from '../../services/PlayerData';
import {
  StyledSummaryHeader,
  StyledSummaryHeaderIcon,
  StyledSummarySubTitle,
  StyledSummaryTitle,
  StyledSummaryContent,
  StyledSummaryContentHeadingText,
  StyledSummaryContentText,
  StyledSummaryContentTextList,
  StyledSummaryContentImage,

  StyledIcon,

  StyledIconQuote,
  StyledButton,

  StyledSummaryContainer,
  StyledNavWrapper,
  StyledSummaryHeaderButton,
} from './StrategySummary.styled';

import { NavButton } from '../NavButton/NavButton';

import ch1HeaderIcon from './images/chapter1/C1-image1.webp';
import ch1Header2Icon from './images/chapter1/C1-image2.webp';
import ch1PositiveIcon from './images/chapter1/C1-positive.webp';

import { ReactComponent as Ch2Step1Image } from './images/chapter2/problem.svg';
import { ReactComponent as Ch2Step2Image } from './images/chapter2/solutions.svg';
import { ReactComponent as Ch2Step3Image } from './images/chapter2/thumbs.svg';
import { ReactComponent as Ch2Step4Image } from './images/chapter2/option.svg';
import { ReactComponent as Ch2Step5Image } from './images/chapter2/trending.svg';
import { ReactComponent as Ch2Step6Image } from './images/chapter2/review.svg';

import ch3Image1 from './images/chapter3/C3-image1.webp';
import ch3Image2 from './images/chapter3/C3-image2.webp';

import ch4Image1 from './images/chapter4/C4-image1.webp';
import ch4Image2 from './images/chapter4/C4-image2.webp';

import ch5Image1 from './images/chapter5/C5-image.webp';

import { getQueryParam } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { CHAPTER_IDENTIFIER } from '../../constants';
import { SvgIcon } from '../SvgIcon/SvgIcon';
import DataLayer, { GTM4Events } from '../../services/DataLayer';

interface IStrategySummaryProps {
  chapter: IChapter;
  onComplete?: () => void;
}

export const StrategySummary: React.FC<IStrategySummaryProps> = ({ chapter, onComplete }) => {
  const navigate = useNavigate();

  const scrollRef: React.RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  })

  const onAchievementComplete = () => onComplete
    ? onComplete()
    : navigate(`/dashboard?cid=${getQueryParam('from') ?? chapter.chapterIdentifier}`);

  const getStrategySummaryThought = () => {
    return <>
      <StyledSummaryContent>
        <StyledSummaryContentImage src={ch1HeaderIcon} />
        <StyledSummaryContentHeadingText>Don't</StyledSummaryContentHeadingText>
        <StyledSummaryContentText>beat yourself up with unhelpful thoughts.</StyledSummaryContentText>
        <StyledSummaryContentImage src={ch1Header2Icon} />
        <StyledSummaryContentHeadingText>Choose</StyledSummaryContentHeadingText>
        <StyledSummaryContentText>thoughts that are <b>helpful</b>.</StyledSummaryContentText>
        <StyledIcon src={ch1PositiveIcon} />
        <StyledSummaryContentHeadingText>Helpful thoughts</StyledSummaryContentHeadingText>
        <StyledIconQuote>"I messed up. But it'll be ok."</StyledIconQuote>
        <StyledIconQuote>"I'm doing my best."</StyledIconQuote>
        <StyledIconQuote>"I've done this before. I've got this."</StyledIconQuote>
        <br /><br />
        <StyledSummaryContentHeadingText>Remember</StyledSummaryContentHeadingText>
        <StyledSummaryContentTextList>
          <ol>
            <li>There's helpful and unhelpful thoughts</li>
            <li>Change how you think to change how you feel</li>
            <li>Choose your thoughts</li>
            <li>Lean on your team</li>
          </ol>
        </StyledSummaryContentTextList>
        <StyledButton primary onClick={onAchievementComplete} label={'Got it, thanks!'} />
      </StyledSummaryContent>
    </>;
  };

  const sport = PlayerData.getSport();
  const getStrategySummaryPlan = () => {
    return <>
      <StyledSummaryContent>
        <Ch2Step1Image />
        <StyledSummaryContentHeadingText>Step 1</StyledSummaryContentHeadingText>
        <StyledSummaryContentText>Define the challenge</StyledSummaryContentText>
        <Ch2Step2Image />
        <StyledSummaryContentHeadingText>Step 2</StyledSummaryContentHeadingText>
        <StyledSummaryContentText>Brainstorm Solutions</StyledSummaryContentText>
        <Ch2Step3Image />
        <StyledSummaryContentHeadingText>Step 3</StyledSummaryContentHeadingText>
        <StyledSummaryContentText>Workout pros and cons</StyledSummaryContentText>
        <Ch2Step4Image />
        <StyledSummaryContentHeadingText>Step 4</StyledSummaryContentHeadingText>
        <StyledSummaryContentText>Choose an option</StyledSummaryContentText>
        <Ch2Step5Image />
        <StyledSummaryContentHeadingText>Step 5</StyledSummaryContentHeadingText>
        <StyledSummaryContentText>Plan the steps</StyledSummaryContentText>
        <Ch2Step6Image />
        <StyledSummaryContentHeadingText>Step 6</StyledSummaryContentHeadingText>
        <StyledSummaryContentText>Give it a go and review</StyledSummaryContentText>
        <StyledSummaryContentHeadingText>Remember</StyledSummaryContentHeadingText>
        <StyledSummaryContentTextList>
          <ol>
            <li>When you fear the worst: make a plan</li>
            <li>Brainstorm lots of solutions, weigh up pros and cons</li>
            <li>If one solution doesn't work, try the next</li>
            <li>Lean on your team</li>
          </ol>
        </StyledSummaryContentTextList>
        <StyledButton primary onClick={onAchievementComplete} label={'Got it, thanks!'} />
      </StyledSummaryContent>
    </>;
  };

  const getStrategySummaryStrength = () => {
    return <>
      <StyledSummaryContent>
        <StyledSummaryContentImage src={ch3Image1} />
        <StyledSummaryContentHeadingText>Think</StyledSummaryContentHeadingText>
        <StyledSummaryContentText>about your strengths ON and OFF the field.</StyledSummaryContentText>
        <StyledSummaryContentImage src={ch3Image2} />
        <StyledSummaryContentHeadingText>Think</StyledSummaryContentHeadingText>
        <StyledSummaryContentText style={{ marginBottom: '0px' }}>about how to play to these strengths.</StyledSummaryContentText>
        <StyledSummaryContentText>
          <ul>
            <li>Use this strength more</li>
            <li>Improve through training</li>
            <li>Help others develop this strength</li>
          </ul>
        </StyledSummaryContentText>
        <StyledButton primary onClick={onAchievementComplete} label={'Got it, thanks!'} />
      </StyledSummaryContent>
    </>;
  };

  const getStrategySummaryControl = () => {
    return <>
      <StyledSummaryContent>
        <StyledSummaryContentImage src={ch4Image1} />
        <StyledSummaryContentHeadingText>Focus</StyledSummaryContentHeadingText>
        <StyledSummaryContentText>on the things you CAN control, not the things you can't.</StyledSummaryContentText>
        <StyledSummaryContentImage src={ch4Image2} />
        <StyledSummaryContentHeadingText>When</StyledSummaryContentHeadingText>
        <StyledSummaryContentText>in a challenging situation think about things you can control and act on them.</StyledSummaryContentText>
        <StyledButton primary onClick={onAchievementComplete} label={'Got it, thanks!'} />
      </StyledSummaryContent>
    </>;
  };


  const getStrategySummaryBreathe = () => {
    return <>
      <StyledSummaryContent>
        <StyledSummaryContentText>It's easy to get fired up, or anxious in high-pressure moments. But pausing for a moment to take a few breaths can help keep us cool, calm and in control.</StyledSummaryContentText>
        <StyledSummaryContentImage src={ch5Image1} />
        <StyledSummaryContentText style={{ marginBottom: '0px' }}>Repeat, whenever you need it.</StyledSummaryContentText>
        <StyledButton primary onClick={onAchievementComplete} label={'Finish'} />
      </StyledSummaryContent>
    </>;
  };

  let content;
  switch (chapter.chapterIdentifier) {
    case CHAPTER_IDENTIFIER.ProblemSolving:
      content = getStrategySummaryPlan();
      break;
    case CHAPTER_IDENTIFIER.PlayingToYourStrengths:
      content = getStrategySummaryStrength()
      break;
    case CHAPTER_IDENTIFIER.ControllingTheControllables:
      content = getStrategySummaryControl();
      break;
    case CHAPTER_IDENTIFIER.KeepingYourCool:
      content = getStrategySummaryBreathe();
      break;
    case CHAPTER_IDENTIFIER.ManagingYourThoughts:
    default:
      content = getStrategySummaryThought();
      break;
  }


  // open the support services page from a specific chapter
  // or open it from the dash
  const goBack = () => {
    const fromPath = getQueryParam('from');
    chapter?.chapterIdentifier ? navigate(`/support-services?from=/chapter/${chapter.chapterIdentifier}/summary${fromPath ? `?from=${fromPath}` : ''}`) : navigate('/support-services?from=/dashboard');

    const customEvent: GTM4Events['clickCTA'] = {
      link_text: 'Get Support',
      link_url: '',
      location: chapter.chapterIdentifier,
    };
    DataLayer.pushClickCTA_GA4(customEvent);
  }

  return (<StyledSummaryContainer>
    <StyledNavWrapper ref={scrollRef}>
      {PlayerData.hasCompletedChapter(chapter.chapterIdentifier) && <NavButton chapterIdentifier={chapter.chapterIdentifier} />}
      <StyledSummaryHeaderButton label='Get Support' onClick={() => goBack()} />
    </StyledNavWrapper>
    <StyledSummaryHeader>
      <StyledSummaryHeaderIcon><SvgIcon cmsSrc={chapter.icon} /></StyledSummaryHeaderIcon>
      <StyledSummarySubTitle>REMEMBER</StyledSummarySubTitle>
      <StyledSummaryTitle text={chapter.strategy} />
    </StyledSummaryHeader>
    {content}
  </StyledSummaryContainer>);
}